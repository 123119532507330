import React, { memo, useState, useEffect } from 'react';
import { useSelector,  useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { getLocalQuestion, isEmpty, createKidId } from 'assets/js/helper';
import RenderInputWithIconItem from './RenderInputWithIconItem';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const RenderInputWithIcon = props => {
  const dispatch = useDispatch();
	const { t } = useTranslation();
  const { name, updateAnswer, page, selectedAnswer, icons, editModal } = props;
  const reduxIcons = useSelector(state => state.QuestionReducer.icons);
  const iconList = isEmpty(reduxIcons) ? icons : reduxIcons;
  const initInputIcon = [
    { name: '', icon: '', id: `--${createKidId(selectedAnswer)}` },
  ];
  const [inputWithIconData, setInputWithIconData] = useState(initInputIcon);
  const [flexStart, setFlexStart] = useState(false);

  const addInputIcon = () => {
    dispatch(pushInteractionToDataLayer({
      eventCategory: 'a_name_icon',
      eventAction: `A0c: Add another view`,
      eventLabel: `Add another view`,
    }))
    updateAnswer(name, [...selectedAnswer, ...initInputIcon], page);
  };

  // if can't get current answer then get from local storage
  useEffect(() => {
    if (isEmpty(selectedAnswer)) {
      const questionsSelectedAnswers = getLocalQuestion() || {};
      const answer = questionsSelectedAnswers[name];
      if (!isEmpty(answer)) {
        updateAnswer(name, answer, page);
      } else {
        updateAnswer(name, initInputIcon, page);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputWithIconData(selectedAnswer);
  }, [selectedAnswer]); // eslint-disable-line react-hooks/exhaustive-deps

  //   useEffect(() => {
  //     let pageRef = document.getElementsByClassName('page')[0];
  //     pageRef.scrollTo(0, pageRef.scrollHeight);
  //   }, [inputWithIconData]); // eslint-disable-line react-hooks/exhaustive-deps

  const judgeFlexStart = () => {
    if (window.innerWidth > 1024) {
      setFlexStart(inputWithIconData?.length > 4 ? true : false);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 768) {
      setFlexStart(inputWithIconData?.length > 2 ? true : false);
    } else {
      setFlexStart(false);
    }
  };

  window.addEventListener('resize', () => {
    judgeFlexStart();
  });

  useEffect(() => {
    judgeFlexStart();
  }, [inputWithIconData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row className="answer">
        <div
          className={cx('kids-answer', {
            'flex-start': flexStart,
          })}
        >
          {inputWithIconData?.map((item, index) => (
            <RenderInputWithIconItem
              item={item}
              key={index}
              index={index}
              selectedAnswer={selectedAnswer}
              page={page}
              name={name}
              inputWithIconData={inputWithIconData}
              updateAnswer={updateAnswer}
              iconList={iconList}
              editModal={editModal}
            />
          ))}
        </div>
      </Row>
      <button
        type="button"
        className="input-icon-add-btn"
        tabIndex="0"
        aria-label="Add Another"
        onClick={addInputIcon}
      >
        {t('ui.recommendation.meology_kids_add_to_cart_title11')}
      </button>
      <p className="kids-promotion-text">
        {t('ui.recommendation.meology_kids_edit_pop_up_title7')}
      </p>
    </>
  );
};

export default memo(RenderInputWithIcon);
