import React, {useMemo} from 'react';
import Swiper from 'react-id-swiper';
import { Image } from 'react-bootstrap';
import 'swiper/css/swiper.css';
import { useHideMeology } from 'assets/js/hooks';

const Navigation = ({ t, isMobile }) => {
 const { hideKids,  hidePrenatal, } = useHideMeology();
  const params = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    }
  };
	const slides = useMemo(() => {
		const result = []
		if(!hidePrenatal) {
			result.push({
				title: 'Meology for Prenatal',
				ariaLabel: 'Meology for Prenatal',
				src: isMobile ? t('ui.marketing.carousel-1-mobile') : t('ui.marketing.carousel-1')
			})
		}
		if(!hideKids) {
			result.push({
				title: 'New! Meology Kids.A picture of a young girl with new vitamin gummy pack designed with a teddy bear.',
				ariaLabel: 'New! Meology Kids.A picture of a young girl with new vitamin gummy pack designed with a teddy bear.',
				src: isMobile ? t('ui.marketing.carousel-2-mobile') : t('ui.marketing.carousel-2')
			})
		}
		result.push(
			{
				title: 'Meology for Adults',
				ariaLabel: 'Meology for Adults',
				src: isMobile ? t('ui.marketing.carousel-3-mobile') : t('ui.marketing.carousel-3')
			},
			{
				title: 'Meology for Adults',
				ariaLabel: 'Meology for Adults',
				src: isMobile ? t('ui.marketing.carousel-4-mobile') : t('ui.marketing.carousel-4')
			},
		)
		

		return result
	}, [hideKids, hidePrenatal, isMobile, t]);
  return (
    <section className="swiper-section-container" aria-label="meology landing page navigation carousel" tabIndex="0">
		<h2 className="slide-title">
			{t('ui.landing_page_1.how_it_works_module_title_13')}
		</h2>
		<h2 className="slide-sub-title">
			{t('ui.landing_page_1.how_it_works_module_content')}
		</h2>
      <Swiper {...params} rebuildOnUpdate={true}>
	      {slides.map(({ title, ariaLabel, src }, index) => (
			<div className="slide-pic" key={index}>
	          <Image
	            title={title}
	            aria-label={ariaLabel}
	            src={src}
	            className="slide-pic-img"
				alt={`Meology_LP_carousel_${index + 1}`}
	          />
	        </div>
	      ))}
      </Swiper>
    </section>
  );
};

export default Navigation;
