import React, {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import { Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import UnitDropdown from './UnitDropdown';
import RenderInput from './RenderInput';
import {OverlayTrigger, Tooltip, Image} from 'react-bootstrap';

const Item = props => {
  const { t, name, options, option } = props;
	const target = useRef(null);
  const { validate_question } = useSelector(state => state.QuestionReducer);
  const { error, error_type, error_message } =
    (validate_question && validate_question[option]) || {};

  const hasInputError = useMemo(
    () => error && error_type === 'input' && options.length > 1,
    [error, error_type, options]
  );
	const addTip = ['hba1c', 'hscrp', 'omega_3_index'];
  return (
    <Row className="answer input-unit">
	    <div className="label row">
		    {name === 'lab_results' &&
		    (<>
			    {t(`questions.${name}.${option}.placeholder`)}
			    {addTip.indexOf(option) !== -1 &&
			      (
			      	<OverlayTrigger
				      target={target.current}
					      key={option}
					      placement="bottom"
					      delay={{ show: 250, hide: 400 }}
					      overlay={
						      <Tooltip className="blood-test-tooltip" id={`tooltip-${option}`} placement='right'>
							      <span>{t(`tooltip.${option}`)}</span>
						      </Tooltip>
					      }
				      >
					      <Image className="q-tooltip" src="https://images.shaklee.com/meology/icons/info.svg" />
				      </OverlayTrigger>
			      )}
	        </>)
		    }
		    {name === 'core_measurement' && t(`questions.${option}.placeholder`)}
	    </div>
      <RenderInput
        {...props}
        showError={hasInputError}
        error_message={error_message}
      />
      <UnitDropdown {...props} />
    </Row>
  );
};

const RenderInputWithUnit = props => {
  const { options } = props;
  if (options.length > 0) {
    return (
      <div className="answer-wrap">
        {options.map(({ answer }, index) => (
          <Item key={index} {...{ ...props, option: answer }} />
        ))}
      </div>
    );
  } else {
    return <Item {...props} />;
  }
};

export default withTranslation()(RenderInputWithUnit);
