import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DummyLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <div>{children}</div>
      </>
    );
  }
}

DummyLayout.propTypes = {
  children: PropTypes.node
};

export default DummyLayout;
