import React, { memo, useContext, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import {
  useProfileId,
  getImpersonation,
  useMeologyHistory,
  useHideMeology,
} from 'assets/js/hooks';
import { isEmpty, setLocalStorage, changeModeUrl, getCurrentEventCategory } from 'assets/js/helper';
import { RecommendationContext } from 'components/results/ContextProvider';
import { changePrenatalStep } from 'components/results/Recommendations/RecommendationsActions';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import { getAnswersByHealthProfileId } from 'components/questions/QuestionActions';

const Toggle = ({ theme }) => {
  const dispatch = useDispatch();
  const {
    changeMeologyType,
    setShowErrorTemplate,
    setPage,
    showErrorTemplate,
  } = useContext(RecommendationContext);

  const { isImpersonation, isSponsor } = getImpersonation();
  const { kidsProfileId, regularProfileId, prenatalProfileId } = useProfileId();
  const { accountEmail, meology_type } = useMeologyHistory();
  const { hideKids, hidePrenatal } = useHideMeology();
  const { reproductiveStatus } = useSelector(
    state => state.recommendationsReducer
  );

  const { is_mobile: isMobile } = useSelector(state => state.QuestionReducer);

  const meologyType = useMemo(() => showErrorTemplate || meology_type, [
    meology_type,
    showErrorTemplate,
  ]);

  const location = useLocation();

  const { t } = useTranslation();
  const sendSwitchAnalysis = useCallback((currentType) => {
    const eventCategory = getCurrentEventCategory();
    const eventActionMap = {
      'REGULAR': 'Adult Switch',
      'PRENATAL': 'Prenatal Switch',
      'KIDS': 'Kids Switch',
    }
    dispatch(
      pushInteractionToDataLayer({
        event: 'interactionEvent',
        eventCategory,
        eventAction: eventActionMap?.[currentType] ?? '',
        eventLabel: '',
      })
    );
  }, [dispatch])

  const handleClick = useCallback(
    ({ currentType, page, profileId }) => {
      if (meologyType !== currentType) {
        changeModeUrl(currentType?.toLowerCase(), location);

        // Google Analysis
        sendSwitchAnalysis(currentType)

        // Select the current phase
        if (currentType === 'PRENATAL') {
          dispatch(changePrenatalStep(reproductiveStatus));
        }

        if (isEmpty(profileId)) {
          setLocalStorage('meologyType', currentType);
          if (isImpersonation && !isSponsor) {
            const request = {
              email: accountEmail,
              meology_type: currentType,
            };
            if (accountEmail) {
              dispatch(getAnswersByHealthProfileId({ postData: request }));
            }
            setShowErrorTemplate(currentType);
          } else {
            setShowErrorTemplate(null);
            setPage(page);
          }
        } else {
          setShowErrorTemplate(null);
          changeMeologyType(currentType, profileId);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountEmail, isImpersonation, meologyType]
  );

  return (
    <ul
      className={cx('meology-toggle', {
        regular: meologyType === 'REGULAR',
        kids: meologyType === 'KIDS',
        prenatal: meologyType === 'PRENATAL',
        [theme]: theme,
      })}
    >
      <li
        {...(!isMobile && {tabIndex: '0'})}
        role="button"
        aria-label="toggle regular"
        className={cx({ active: meologyType === 'REGULAR' })}
        onClick={() =>
          handleClick({
            currentType: 'REGULAR',
            page: 'marketing',
            profileId: regularProfileId,
          })
        }
      >
        {t('ui.recommendation.toggle.regular')}
        <span className="meology-toggle-tips right">
          {t('ui.recommendation.toggle.regular.tooltip')}
        </span>
      </li>
      {!hidePrenatal && (
        <li
          {...(!isMobile && {tabIndex: '0'})}
          role="button"
          aria-label="toggle prenatal"
          className={cx({ active: meologyType === 'PRENATAL' })}
          onClick={() =>
            handleClick({
              currentType: 'PRENATAL',
              page: 'prenatalMarketing',
              profileId: prenatalProfileId,
            })
          }
        >
          {t('ui.recommendation.toggle.prenatal')}
          <span className="meology-toggle-tips">
            {t('ui.recommendation.toggle.prenatal.tooltip')}
          </span>
        </li>
      )}
      {!hideKids && (
        <li
          {...(!isMobile && {tabIndex: '0'})}
          role="button"
          aria-label="toggle kids"
          className={cx({ active: meologyType === 'KIDS' })}
          onClick={() =>
            handleClick({
              currentType: 'KIDS',
              page: 'kidsMarketing',
              profileId: kidsProfileId,
            })
          }
        >
          {t('ui.recommendation.toggle.kids')}
          <span className="meology-toggle-tips">
            {t('ui.recommendation.toggle.kids.tooltip')}
          </span>
        </li>
      )}
    </ul>
  );
};

export default memo(Toggle);
