import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import CtaBtn from 'components/common/CtaBtn';

const Nutrition = ({ t }) => {
  const { prenatal_landing_page: prenatalLandingPage } = useSelector(
    state => state.QuestionReducer
  );
  return (
    <div>
      <div className="mkt-nutrition-container">
        <h2 className="mkt-section-title">
          {t('ui.prenatal_marketing.section_6.headline')}
        </h2>
        <div className="text-1">
          <h3 className="mkt-section-sub-title">
            {t('ui.prenatal_marketing.section_6_2.title')}
          </h3>
          <p dangerouslySetInnerHTML={{
            __html: t('ui.prenatal_marketing.section_6_2.content')
          }}></p>
        </div>
        <div className="text-2">
          <h3 className="mkt-section-sub-title">
            {t('ui.prenatal_marketing.section_6_1.title')}
          </h3>
          <p  dangerouslySetInnerHTML={{
            __html:t('ui.prenatal_marketing.section_6_1.content')
          }}></p>
        </div>
        <div className="text-3">
          <h3 className="mkt-section-sub-title">
            {t('ui.prenatal_marketing.section_6_3.title')}
          </h3>
          <p dangerouslySetInnerHTML={{
            __html: t('ui.prenatal_marketing.section_6_3.content')
          }}></p>
        </div>
        <CtaBtn
          type="prenatal"
          status={prenatalLandingPage}
          eventLabel="Marketing Page"
          eventAction="Start Here 3"
          page='prenatalMarketing'
        />
      </div>
    </div>
  );
};

export default memo(Nutrition);
