import React, { createContext, useState, useCallback, memo } from 'react';
import Container from './Container';

export const Context = createContext(null);
let id = 1;

const Provider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [delay, setDelay] = useState(3000);
  const [className, setClassName] = useState('');

  const addToast = useCallback((content, delay = 3000, className = '') => {
    setToasts(toasts => [
      ...toasts,
      {
        id: id++,
        content,
      },
    ]);
    setDelay(delay);
    setClassName(className);
  }, []);

  const removeToast = useCallback(
    id => setToasts(toasts => toasts.filter(item => item.id !== id)),
    [setToasts]
  );

  return (
    <Context.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      <Container className={className} toasts={toasts} delay={delay} />
      {children}
    </Context.Provider>
  );
};

export default memo(Provider);
