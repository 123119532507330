import React from 'react';

const RecommendationMessage = ({ message, showMessage, setShowMessage }) => {
  if (!showMessage) {
    return null
  }
  return (
    <div className="recommendations-message-wrap">
      <div className="recommendations-message">
        <div className="recommendations-message-left">
          <img src={'https://images.shaklee.com/wellness/Shape.svg'} alt="" />
          {message}
        </div>
        <div className="recommendations-message-right">
          <button
            type="button"
            className="close"
            onClick={() => {
              setShowMessage(false);
            }}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecommendationMessage;
