import React, {
  memo,
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import {
  useMeologyHistory,
  useDebounce,
  useUpdateEffect,
  useOrderUrl,
  useKidsSachetSku,
  isBottledPill,
} from 'assets/js/hooks';
import { getIfHasError, hasValidationError, getExtByCode, setLocalStorage } from 'assets/js/helper';
import RenderInputWithIcon from 'components/questions/widgets/RenderInputWithIcon';
import {
  postUpdate,
  validateQuestions,
  removeValidateQuestion,
} from 'components/questions/QuestionActions';
import { RecommendationContext } from 'components/results/ContextProvider';
import { fetchAddToCart } from '../Recommendations/RecommendationsActions';

const KIDS_QUESTION_NAME = 'kids_info';
export const KidsIconContext = createContext(null);

const EditModal = ({ show, closeModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { country, lang } = useSelector(state => state.user);
  const { validate_question } = useSelector(state => state.QuestionReducer);
  const recommendationData = useSelector(state => state.recommendationsReducer.data);
  const {
    kids_info,
    icons,
    autoshipKidsId,
    meology_autoship_info,
    email,
    opt_in,
  } = useMeologyHistory();

  const [selectedAnswer, setSelectedAnswer] = useState(_.cloneDeep(kids_info));
  const debouncedSelectedAnswer = useDebounce(selectedAnswer);
  const { isEditedKidsRef, checkoutRequestData, setIsAddToCart, currentPills } = useContext(RecommendationContext);

  const kidsSachetSku = useKidsSachetSku(country);
  const { error, error_message } =
    validate_question?.[KIDS_QUESTION_NAME]?.all ?? '';

  const orderUrl = useOrderUrl();
  const introText = useMemo(() => {
    const text1 = t('ui.recommendation.meology_kids_introtext1');
    const text2 = t('ui.recommendation.meology_kids_introtext2', {
      href: orderUrl,
    });
    if (autoshipKidsId?.length > 0) {
      return `${text1} ${text2}`;
    } else {
      return text1;
    }
  }, [orderUrl, autoshipKidsId, t]);

  const onHide = useCallback(() => {
    closeModal();
    setSelectedAnswer(_.cloneDeep(kids_info));
    dispatch(removeValidateQuestion({ question: KIDS_QUESTION_NAME }));
  }, [closeModal, dispatch, kids_info]);

  const ifHasError = useCallback(
    async () =>
      getIfHasError({
        dispatch,
        validateQuestions,
        question: KIDS_QUESTION_NAME,
        selectedAnswer,
        t,
      }),
    [dispatch, selectedAnswer, t]
  );

  const hasError = useMemo(
    () =>
      hasValidationError({
        isValidQuestion: validate_question,
        question: KIDS_QUESTION_NAME,
      }),
    [validate_question]
  );

  const autoshipItems = useMemo(() => {
    const sub_items = currentPills?.filter(item => item.selected)
      .filter(({ code }) =>  !isBottledPill(String(code)))
      .map(({ code, quantity }) => ({
        product_code: code,
        quantity: quantity * 25,
        ext: getExtByCode(recommendationData, code),
      }));

      const autoshipKidsIdNum = autoshipKidsId?.map(item => item.split('-')[2]);
      return (
        selectedAnswer?.filter(item => autoshipKidsIdNum?.includes(item.id.split('-')[2]))?.map(item => ({
          product_code: kidsSachetSku,
          quantity: 1,
          ext: getExtByCode(recommendationData, kidsSachetSku),
          sub_items,
          kids_info: item,
        })) ?? []
      );

  }, [autoshipKidsId, currentPills, kidsSachetSku, recommendationData, selectedAnswer]);
  
  const handleSave = async () => {
    const isError = await ifHasError();
    if (isError) return;
    dispatch(
      postUpdate({ kids_info: selectedAnswer, email, opt_in }, country, lang)
    );

    if (autoshipKidsId && selectedAnswer && !autoshipKidsId?.every(id  => selectedAnswer?.map(item => item.id).includes(id))) {
      checkoutRequestData.items = autoshipItems;
      checkoutRequestData.add_to_sachet_autoship = true;
      checkoutRequestData.replace_kids_autoship = true;
      if (meology_autoship_info && meology_autoship_info.code) {
        checkoutRequestData.autoship_cart_code = meology_autoship_info.code;
      }
      setIsAddToCart(true);
      setLocalStorage('isEditSave', true);
      dispatch(fetchAddToCart(checkoutRequestData));
    }
    closeModal();
    isEditedKidsRef.current = true;
    window.scrollTo(0, 0);
  };

  const updateAnswer = (name, value) => setSelectedAnswer(value);

  useUpdateEffect(() => {
    if (debouncedSelectedAnswer) {
      dispatch(
        validateQuestions(
          KIDS_QUESTION_NAME,
          debouncedSelectedAnswer,
          undefined,
          t
        )
      );
    }
  }, [debouncedSelectedAnswer, dispatch]);

  return (
    <Modal
      className="kids-edit-modal"
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <h3 className="kids-edit-modal-title">
        {t('ui.recommendation.meology_kids_edit_pop_up_title2')}
      </h3>
      <p
        className="kids-edit-modal-intro"
        dangerouslySetInnerHTML={{ __html: introText }}
      ></p>
      <KidsIconContext.Provider value={{ where: 'edit' }}>
        <RenderInputWithIcon
          name={KIDS_QUESTION_NAME}
          page="1"
          updateAnswer={updateAnswer}
          selectedAnswer={selectedAnswer}
          icons={icons}
          editModal={true}
        />
      </KidsIconContext.Provider>
      <div className="kids-edit-modal-btn">
        <button
          type="button"
          className="modal-button-white"
          onClick={handleSave}
          disabled={hasError}
        >
          {t('ui.recommendation.meology_kids_edit_pop_up_title9')}
        </button>
        <button type="button" className="modal-button-no-bg" onClick={onHide}>
          {t('ui.recommendation.meology_kids_edit_pop_up_title10')}
        </button>
        {error && error_message && (
          <div className="error-message">
            <div>{error_message}</div>
            <span className="arrow"></span>
          </div>
        )}
      </div>
      <button type="button" className="modal-button-close" onClick={onHide}>
        X
      </button>
    </Modal>
  );
};

export default memo(EditModal);
