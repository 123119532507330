import React from 'react';
import { useSelector } from "react-redux";
import cx from "classnames";

const PackagedSection = ({ t, isMobile }) => {
	const { country, lang } = useSelector(state => state.user);
	return (
		<div className={cx('packages-container', {
			'spanish': lang === 'es',
			'canada': country === 'CA'
		})}
			tabIndex="0"
			role="img"
			aria-label="Packaged with a Purpose"
		>
			<div className="packages-text">
				<h2 className="title">{t('ui.landing_page_1.packaged_with_a_purpose_title_1')}</h2>
				<h3 className="sub_title">{t('ui.landing_page_1.packaged_with_a_purpose_title_2')}</h3>
			</div>
		</div>
	);
};

export default PackagedSection;
