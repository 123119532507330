/* eslint-disable array-callback-return */
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHybrisHost, useHybrisUrlParams, useMeologyHistory } from 'assets/js/hooks';
import { isEmpty } from 'assets/js/helper';
import { useTranslation } from 'react-i18next';

const RecommendationsDagger = ({ productsInfos, page, isMeologyKids, showProveItStep, showProveIt, showDSHEAstatement}) => {
  const { t } = useTranslation();
  const { country, lang } = useSelector(state => state.user);
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);

  const { isMeologyPrenatal } = useMeologyHistory();
  const productHasDagger = useMemo(
    () => productsInfos.filter(item => item[`${page}PageDagger`]),
    [page, productsInfos]
  );
  const showDagger = useMemo(
    () => !isMeologyPrenatal && !isEmpty(productHasDagger),
    [isMeologyPrenatal, productHasDagger]
  );
  const dsheaStatement = useMemo(() => {
    if(isMeologyKids && page === 'reco') {
      return t('ui.recommendation.meology_kids_add_to_cart_title3')
    }
    if(showDSHEAstatement || !isEmpty(productsInfos.filter(item => item.dsheaFlag))) {
      return `*${t('ui.pdp.DSHEAstatement')}`
    }
    return ''
  }, [isMeologyKids, page, productsInfos, showDSHEAstatement, t]);

  return (
    <>
      {
        !isEmpty(productsInfos) &&
        <div className="dagger-container">
          {!isMeologyPrenatal && (showProveItStep || showProveIt) &&
            <div className="dagger-container-provelt-title">
              {t('ui.provelt.DSHEAstatement1')}<a href={`${hybrisHost}/current-offers${hybrisUrlParams}`} target="_Blank" rel="noopener noreferrer">{t('ui.provelt.DSHEAstatement2')}</a>
            </div>
          }
          {dsheaStatement &&
            <div className="dagger-container-title">
              {dsheaStatement}
            </div>
          }
          {(isMeologyPrenatal && page === 'reco') && 
          <div className="dagger-container-content">
           <div className="dagger-container-content-item"
              dangerouslySetInnerHTML={{ __html: '†' + t('ui.reco.prenatal_rsw.be_sure_doctor')
             }}></div>
          </div>
          }
          {/* disclaimers for the Canada */}
          {
            country === 'CA' && (
              <p className="cannada-disclaimers">
                {'*' + t('ui.disclaimer.ca_landing_page')}
              </p>
            )
          }
          {showDagger && (
            <div className="dagger-container-content">
              {productHasDagger.map(({ disclaimer }, index) => (
                <div
                  className="dagger-container-content-item"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: disclaimer }}
                ></div>
              ))}
            </div>
          )}
	        {isMeologyKids &&
		        <div>
			        {t('ui.recommendation.meology_kids_add_to_cart_title4')}
		        </div>
	        }
        </div>
      }
    </>
  );
};

export default memo(RecommendationsDagger);
