import React, { useEffect, useCallback, useState, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import cx from 'classnames';
import ResultSelect from 'components/results/ResultSelect';
import { PrenatalStepTag, AnimationImages } from './';
import RecommendationsPill from 'components/results/Recommendations/RecommendationsPill';
import RecommendationCard from 'components/results/Recommendations/RecommendationCard';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import { pushProductClick } from 'components/results/Recommendations/RecommendationsPill';
import {
  PIC_PRICE_US,
  PIC_PRICE_CA,
} from 'components/results/Recommendations/RecommendationsConstants';
import { RecommendationContext } from 'components/results/ContextProvider';

const PackLink = ({ className, isExpand, onToggle }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx('rsw-pack-link-container', {
        [className]: className,
      })}
      role="button"
      tabIndex="0"
      onClick={() => onToggle(isExpand)}
    >
      <span className="rsw-product-detail-link pack-link">
        {t(isExpand ? 'ui.reco.prenatal_rsw.hide_what_in_my_pack': 'ui.reco.prenatal_rsw.show_what_in_my_pack')}
      </span>
      <span
        className={cx('link-icon', {
          expand: isExpand,
        })}
      />
    </div>
  );
};

const PackPanel = ({ isExpand, recommendationsPillProps }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

  useEffect(() => {
    setOpen(isExpand);
  }, [isExpand]);

  const onToggle = useCallback((isExpand) => {
    setOpen(_open => !_open);
	  dispatch(
		  pushInteractionToDataLayer({
			  event: 'interactionEvent',
			  eventCategory: 'RSW Prenatal',
			  eventAction: t(isExpand ? 'ui.reco.prenatal_rsw.hide_what_in_my_pack': 'ui.reco.prenatal_rsw.show_what_in_my_pack'),
			  eventLabel: 'Reco Page',
		  })
	  );
  }, [dispatch, t]);
  return (
    <>
      {open && (
        <>
          <PackLink isExpand={open} onToggle={onToggle} />
          {recommendationsPillProps?.productsInfo?.length ? (
            <div className="pill-section rsw-pack-pills">
              <RecommendationsPill {...recommendationsPillProps} />
            </div>
          ) : (
            <div className="rsw-no-result">{t('ui.reco.prenatal_rsw.no_result')}</div>
          )}
        </>
      )}
      <PackLink isExpand={open} onToggle={onToggle} />
    </>
  );
};

const StepCard = ({
  bundleCode,
  stepNo,
  imgUrls = [],
  title,
  summary,
  isPack,
  isExpand,
  recommendationsPillProps = {},// RecommendationsPill Component Props
  recommendationCardProps = {}, // RecommendationCard Component Props,
  selectComponents, // [{ResultSelect Component Props}]
  onMore = () => {}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.user);
  const { showContent } = useContext(RecommendationContext);

  const onDetail = useCallback(() => {
    const map = {
      US: PIC_PRICE_US,
      CA: PIC_PRICE_CA,
    };
    // analytics
    pushProductClick({
      dispatch,
      product: { name: title, code: bundleCode + '_' + stepNo , price: { currencyIso: 'USD' }},
      price: map[country],
      stepNo,
      country
    });
    onMore()
  }, [bundleCode, country, dispatch, onMore, stepNo, title])

  return (
    <div className="rsw-step-card-container">
      <div className="step-card-content">
          <div className={cx("step-card-img",{
            'step-card-img-reco': isPack
          })}>
          {isPack ? (
            <RecommendationCard
              {...recommendationCardProps}
              meologyType="PRENATAL"
            />
          ) : (
            <AnimationImages imgUrls={imgUrls} swiperImgClassName='swiper-img' />
          )}
        </div>
        <PrenatalStepTag stepNo={stepNo} />
        <h2 className="rsw-card-title font-gilmer-regular" dangerouslySetInnerHTML={{
          __html:title
        }} />
        <p className="step-card-text" dangerouslySetInnerHTML={{
          __html:summary
        }} />
        {!isPack && selectComponents?.length > 0 && (
          <div className="select-component-container">
            {selectComponents.map((dropdownprop, index) => {
              return <ResultSelect key={index} {...dropdownprop} />;
            })}
          </div>
        )}
      </div>
      {isPack ? (
        <PackPanel
          isExpand={isExpand}
          recommendationsPillProps={recommendationsPillProps}
        />
      ) : showContent && (
        <button className="meology-text-button rsw-product-detail-link detail-link-position" onClick={onDetail}>
          {t('ui.recommendation.learn_more')}
        </button>
      )}
    </div>
  );
};

export default memo(StepCard);
