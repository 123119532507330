import React, { useState, useCallback, useEffect,useMemo, memo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import DisplayQuestion from '../../questions/DisplayQuestion';
import PrenatalConfirm from './PrenatalConfirm';
import TransitionPage from "../../questions/TransitionPage"
import Managing from 'components/header/Managing';
import { useSelector } from 'react-redux';
import { useMeologyHistory } from 'assets/js/hooks';

const PageType = {
  question: 'question',
  confirm: 'confirm',
  transition:'transition'
};

const PHASES ={
  phase1:'phase1',
  phase2:'phase2'
}

const PrenatalConfirmModal = ({
  show,
  onHide,
  user,
  isMobile,
  phase,
  submitPrenatalConfirm,
  hasAutoship,
  dataOptions
}) => {
  const {t} = useTranslation()
  const [currenValue, setCurrentValue] = useState('');
  const [pageType, setPageType] = useState(PageType.question);
  const [isLoading, setLoading] = useState(false);
  const validate_question = useSelector(state => state.QuestionReducer.validate_question)
  const reproductiveStatus = useSelector(state => state.recommendationsReducer.reproductiveStatus)
  const { prenatal_due_date } = useMeologyHistory();
  
  useEffect(() => {
    setPageType(PageType.question);
    setCurrentValue('');
    setLoading(false)
  }, [show]);
  const updateAnswerLocally = useCallback((_, value, __) => {
    setCurrentValue(value);
  }, []);
  const setNextPage = useCallback(_currenValue =>() => {
    if(phase === PHASES.phase1) {
      setPageType(PageType.confirm);
    }
    if(phase === PHASES.phase2) {
      const isNo = Number(_currenValue) === 0
      if((reproductiveStatus === 'POSTPARTUM') && !isNo) {
        onHide()
        return
      }
     const _type = isNo ? PageType.transition : PageType.confirm;
     setPageType(_type)
    }
  }, [onHide, phase, reproductiveStatus]);
  const anonymous = useCallback(() => {}, []);

  const onConfirm = useCallback((_currenValue) => () => {
    setLoading(true)
    const Phases = {
      [PHASES.phase1]: {
        prenatal_due_date: _currenValue,
        prenatal_feeding: '',
        prenatal_reproductive_status: 'PREGNANT',
      },
      [PHASES.phase2]: {
        prenatal_feeding: _currenValue,
        prenatal_reproductive_status: 'POSTPARTUM',
        prenatal_due_date,
      },
    };
    submitPrenatalConfirm(phase,Phases[phase] || null)
  }, [phase, submitPrenatalConfirm, prenatal_due_date]);

  const goPrePage = useCallback(() => {
    setPageType(PageType.question);
  }, [])

  const labels = useMemo(() => {
    switch(phase) {
      case PHASES.phase1: {
        return {
          question: 'prenatal_due_date',
          widget:"date_picker",
          instructionsModal: true,
          answers:[]
        }
      }
      case PHASES.phase2: {
        return {
          question: 'prenatal_feeding',
          widget:"button",
          instructionsModal:false,
          answers:[{answer:'0'}, {answer:'1'}]
        }
      }
      default: return {}
    }
  }, [phase])

  const showBackButton = useMemo(
    () =>
      PageType.transition === pageType ||
      (PageType.confirm === pageType && isMobile),
    [isMobile, pageType]
  );

  const showCloseButton = useMemo(() => PageType.question === pageType, [
    pageType,
  ]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      keyboard={false}
      className='prenatal-confirm-custom-model'
      // dialogClassName={`recommendation-prenatal-confirm-model ${PageType.question === pageType ? '' :'full-page'}`}
      dialogClassName={cx('recommendation-prenatal-confirm-model', {
        'full-page': !showCloseButton,
        'no-button': !showBackButton && !showCloseButton,
      })}
    >
      {
        !showCloseButton && (
          <Managing name={'guest'} />
        )
      }
      <Modal.Header closeButton={showCloseButton}>
        <Modal.Title>
          {showBackButton && (
            <img
              onClick={goPrePage}
              src="https://images.shaklee.com/meology/icons/arrow_back.svg"
              alt="back"
            ></img>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div
          className={`quiz-page ${
            pageType === PageType.confirm ? 'item-center bg-'+ phase : ''
          }`}
          style={{
            backgroundColor: t('ui.background_color.prenatal'),
          }}
        >
          <div>
              <Row className="page">
                {pageType === PageType.question && (
                  <DisplayQuestion
                    colLength={12}
                    question={labels.question}
                    pageId={1}
                    widget={labels.widget}
                    selectedAnswers={{}}
                    updateAnswer={updateAnswerLocally}
                    setNextPage={setNextPage(currenValue)}
                    pageQuestion={{ [labels.question]: currenValue }}
                    required={true}
                    instructionsModal={labels.instructionsModal}
                    counter={1}
                    value={currenValue}
                    t={t}
                    pageLength={2}
                    pageCategory="prenatal"
                    isValidQuestion={validate_question}
                    isMobile={isMobile}
                    setPreSubmitCheck={anonymous}
                    rank={user?.rankId}
                    shakleeId={user?.shakleeId}
                    showNextButton={true}
                    displayNextButton={true}
                    setLogs={anonymous}
                    answers={labels.answers}
                    isDatepickerFloat={true}
                  />
                )}
                {pageType === PageType.confirm && (
                  <PrenatalConfirm
                    phase={phase}
                    onBack={onHide}
                    hasAutoship={hasAutoship}
                    onClick={onConfirm(currenValue)}
                    loading={isLoading}
                  ></PrenatalConfirm>
                )}
                {pageType === PageType.transition && (
                  <TransitionPage
                      question={'transition_to_regular'}
                      updateAnswer={updateAnswerLocally}
                      pageId={3}
                      required={false}
                      setNextPage={anonymous}
                      setAnswer={anonymous}
                      pageQuestion={{}}
                      pageLength={2}
                      counter={2}
                      pageCategory="prenatal"
                      widget='transition'
                      setPreSubmitCheck={anonymous}
                      isMobile={isMobile}
                      rank={user?.rankId}
                      shakleeId={user?.shakleeId}
                      isUserLoggedin={user?.isUserLoggedin}
                      displayNextButton
                      selectedAnswers={dataOptions || {}}
                    />
                )}
              </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(PrenatalConfirmModal);
