import React, { memo, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { getMeologyType } from 'assets/js/helper';
import {
  useHybrisUrlParams,
  useHybrisHost,
  getImpersonation,
} from 'assets/js/hooks';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const AnimateCheckbox = ({ className, initValue, onChange, meologyType }) => {
  const dispatch = useDispatch();
  const { country, lang } = useSelector(state => state.user);
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(initValue);
  const { isRegularType, isPrenatalType, isKidsType } = getMeologyType();
  const emailCaptureText = useMemo(() => {
    if (isRegularType) return t('ui.email_capture.logged_user');
    if (isPrenatalType) return t('ui.email_capture.logged_user-prenatal');
    if (isKidsType) return t('ui.email_capture.logged_user-kids');
  }, [isKidsType, isPrenatalType, isRegularType, t]);
  const { isSponsor } = getImpersonation();

  useEffect(() => {
    setIsChecked(initValue)
  }, [initValue])

  useEffect(() => {
    if (isSponsor) {
      setIsChecked(true);
      onChange(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckout = event => {
    setIsChecked(event.target.checked);
    onChange(event.target.checked);
  };

  const handleClick = () => {
    // analytics
    dispatch(
      pushInteractionToDataLayer({
        eventAction: 'Privacy Policy',
        eventLabel: 'email page',
      })
    );
  }
  return (
    <label
      className={cx('animate-checkbox', className, {checked: isChecked})}
    >
	    {!isKidsType &&
	    <>
		    <input type="checkbox" onChange={handleCheckout} checked={isChecked}/>
		    <svg
			    xmlns="http://www.w3.org/2000/svg"
			    xmlnsXlink="http://www.w3.org/1999/xlink"
			    version="1.1"
			    width="18px"
			    height="18px"
			    viewBox="0 0 24 24"
          tabIndex="0"
          role="checkbox"
          aria-labelledby="email-capture-intro"
          aria-checked={isChecked}
          onKeyPress={(e) => {
            if (e.charCode === 13 || e.charCode === 32) {
              setIsChecked(!isChecked);
              onChange(!isChecked);
            }
          }}
		    >
			    <rect
				    x="2"
				    y="2"
				    width="20"
				    height="20"
				    rx="2"
				    ry="2"
				    fill="#000"
				    strokeWidth="1"
				    stroke="#000"
			    />
			    <path
				    d="M8,7 v7 h12"
				    fill="none"
				    strokeWidth="2"
				    stroke="#fff"
				    transform="rotate(-45, 12, 12)"
			    />
		    </svg>
	    </>
	    }
      <p id="email-capture-intro">
        {<span dangerouslySetInnerHTML={{__html: emailCaptureText}}></span>}
        {
          <span>
            <a
              href={`${hybrisHost}/${
                country === 'CA'
                  ? 'privacy-security-policy'
                  : 'Privacy-Security-Policy'
              }${hybrisUrlParams}`}
              title={t('ui.footer-privacy')}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              &nbsp;{t('ui.email_capture.privacy_policy')}
            </a>
          </span>
        }
      </p>
    </label>
  );
};

export default memo(AnimateCheckbox);
