import React from 'react';

const HappySection = ({ t, isMobile }) => {
  return (
    <div className="happy-section" style={{ backgroundImage: `url(${isMobile ? t('ui.landing.image.module-6.mobile') : t('ui.landing.image.module-6')})` }}>
      <div className="happy-section-context">
        <h2 className="happy-section-context-header">
          {t('ui.landing_page_1.happy_headline')}
        </h2>
        <div className="happy-section-context-text">
          {t('ui.landing_page_1.happy_headline.copy')}
        </div>
      </div>
    </div>
  );
};

export default HappySection;
