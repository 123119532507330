import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ScienceNavigation from './ScienceNavigation';
import ImageSafe from 'assets/images/shaklee_safe_proven.png';

const ScienceSection = () => {
  const { t } = useTranslation();
  return (
    <div className="science-section">
      <div className="science-section-header">
        <h2
          className="science-section-title"
          dangerouslySetInnerHTML={{
            __html: t(`ui.landing_page_1.banner_ribbon_title_2`),
          }}
        ></h2>
        <img
          className="science-section-logo"
          src={ImageSafe}
          alt="wellness"
        />
      </div>
      <div className="science-section-swiper">
        <ScienceNavigation />
      </div>
    </div>
  );
};

export default memo(ScienceSection);
