import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { getImpersonation, useIsMarketing } from 'assets/js/hooks';
import { isEmpty } from 'assets/js/helper';
import {
  setLocalStorage,
  removeLocalQuestion,
  resetQuiz,
  getAssessmentPathName,
  changeModeUrl,
} from 'assets/js/helper';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const getCase = ({ type, status }) => {
  if (['regular', 'prenatal'].includes(type)) {
    if ([1, 3].includes(status)) return 'isTake';
    if ([2, 4].includes(status)) return 'isReturn';
    if ([5, 6].includes(status)) return 'isView';
  }

  if (type === 'kids') {
    if (status === 1) return 'isTake';
    if (status === 2) return 'isView';
  }
};

const CtaBtn = ({
  type,
  status,
  eventLabel,
  isTextBtn,
  page,
  showRetake = false,
  eventAction,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { isExact: isHome } = useRouteMatch('/') || {};
  const { isPrenatalMarketing, isRegularMarketing } = useIsMarketing(page);
  const { isImpersonation, isSponsor } = getImpersonation();
  const { isUserLoggedin } = useSelector(state => state.user);
  const currentCase = useMemo(() => getCase({ type, status }), [type, status]);
  const [currentEventAction, setCurrentEventAction] = useState(eventAction);

  // button text
  const btnTextMap = useMemo(
    () => ({
      regular: {
        isTake: t('ui.landing_page_1.cta_button_1'),
        isReturn: t('ui.landing_page_2.cta_button_1'),
        isView: isRegularMarketing
          ? t('ui.cta.button.text1')
          : t('ui.landing_page_1.cta_button_3'),
      },
      prenatal: {
        isTake: t( isPrenatalMarketing ? 'ui.prenatal_marketing.cta' : 'ui.landing_page_1.cta_button_1'),
        isReturn: t('ui.landing_page_2.cta_button_1'),
        isView: t('ui.cta.button.text1')
      },
      kids: {
        isTake: t('ui.landing_page_1.two_doorways_title_12'),
        isView: t('ui.cta.button.text2')
      },
    }),
    [isPrenatalMarketing, isRegularMarketing, t]
  );

  const btnText = useMemo(() => btnTextMap?.[type]?.[currentCase] ?? '', [
    btnTextMap,
    currentCase,
    type,
  ]);

  // analytic category
  const eventCategory = useMemo(() => {
    if (type === 'regular') return 'Meology';
    if (type === 'kids') return 'Meology Kids';
    if (type === 'prenatal') return 'Prenatal';
  }, [type]);

  const jump = useCallback(() => {
    let pathname;
    if (type === 'regular') setLocalStorage('meologyType', 'REGULAR');
    if (type === 'kids') {
      setLocalStorage('meologyType', 'KIDS');
      if (currentCase === 'isTake') {
        removeLocalQuestion();
      }
    }
    if (type === 'prenatal') {
      setLocalStorage('meologyType', 'PRENATAL');
    }

    changeModeUrl(type, location);

    if (['isTake', 'isReturn'].includes(currentCase)) {
      pathname = getAssessmentPathName(type);
    }
    if (currentCase === 'isView') {
      pathname = '/reco';
    }

    if (pathname) {
      history.push({
        ...location,
        pathname,
      });

      if (currentCase === 'isView') {
        window.scrollTo(0, 0);
      }
    }
  }, [currentCase, history, location, type]);

  const onClick = useCallback(() => {
    jump();
    // analytics
    if (currentEventAction) {
      dispatch(
        pushInteractionToDataLayer({
          eventCategory,
          eventAction: currentEventAction,
          eventLabel,
        })
      );
    }
  }, [currentEventAction, dispatch, eventCategory, eventLabel, jump])

  useEffect(()=> {
    if (currentEventAction) return;
    let eventAction = '';

    if (currentCase === 'isTake') {
      if (type === 'regular') eventAction = "Let's Do This";
      if (type === 'kids') eventAction = "Let's Do This";
      if (type === 'prenatal') eventAction = 'Take Assessment';
    }

    if (currentCase === 'isView') {
      if (type === 'regular') eventAction = 'View Personalized Plan';
      if (type === 'kids') eventAction = 'View Personalized Plan';
      if (type === 'prenatal') eventAction = 'View Results';
    } 

    setCurrentEventAction(eventAction)
  }, [currentCase, currentEventAction, type])

  // Don't display button when is Impersionation mode(not BL sponsor someone flow ) or is getting history status
  if ((isImpersonation && !isSponsor) || isEmpty(status)) return;

  return (
    <>
      <button
      className={cx({
        'cta-button': !isTextBtn,
        'cta-text-button': isTextBtn,
      })}
      tabIndex="0"
      type="button"
      onClick={onClick}
    >
      {btnText}
    </button>

      {isHome && (type === 'regular' || type === 'prenatal') && currentCase === 'isView' && showRetake && (
	      <button
          className="cta-button retake"
          type="button"
          aria-label={`Retake your meology for ${type === 'regular' ? 'adults' : 'prenatal'} assessment`}
          onClick={() => {
            localStorage.setItem('meologyType', type.toUpperCase());
            changeModeUrl(type, location);
            resetQuiz({ location, isUserLoggedin, type });

            // analytics
            dispatch(
              pushInteractionToDataLayer({
                eventCategory,
                eventAction: 'Retake Assessment',
                eventLabel,
              })
            );
          }}
        >
          {t('ui.recommendation.restart')}
        </button>
      )}
    </>
  );
};

export default memo(CtaBtn);
