import React, {memo} from 'react';
import { useTranslation} from 'react-i18next';
import cx from 'classnames';
import ResultSelect from '../ResultSelect';

export const  StepTag = ({className,stepNo = 1}) => {
    const {t} = useTranslation()
    return <span className={cx('rsw-step-tag', {
        [className]: className
    })}>{`${t('ui.recommendation.proveit_section_step')} ${stepNo} `}</span>
}

export const CompletedTag = ({className, isHide}) => {
    return <span className={cx('rsw-completed-tag', {
        [className]: className,
        'rsw-hide': isHide
    })} aria-label='completed'></span>
}

const PromoCard = ({
    stepNo,
    imgUrl,
    title, //'Prenatal Vitamin Pack'
    summaryOption, // = { summaryTitle: 'Includes Prenatal Core Nutrition:', items: ['1  Prenatal MultiV Drink','1  MultiM Tablet','1  Omega-3 Gellys™'   ]},
    selectComponents, // [{ResultSelect Component Props}]
    isCompleted,
    showDetailLink
}) => {
    const {t} = useTranslation()
    return <div className='rsw-promo-card-container'>
        <StepTag className='step-tag-position' stepNo={stepNo} />
        <CompletedTag className='completed-tag-position' isHide={!isCompleted} />
        <div className='promo-card-img'>
            <img src={imgUrl} onError={(e) => {e.target.src="https://images.shaklee.com/meology/coming-soon.jpg"}} alt=""/>
        </div>
        <div className='promo-card-content'>
            <p className='rsw-card-title'>{title}</p>
            {summaryOption && <>
                {summaryOption.summaryTitle && <p className='rsw-card-sub-title'>{summaryOption.summaryTitle}</p>}
                {summaryOption.items?.length > 0 && <div className='rsw-card-text'>
                    {summaryOption.items.map((text, index) => {
                        return <span key={index} className='text-item'>{text}</span>
                    })}
                </div>}
            </>}
            {selectComponents?.length > 0 && <div className='select-component-container'>
                {selectComponents.map((dropdownprop, index) => {
                    return <ResultSelect key={index} {...dropdownprop} />
                })}
            </div>}

        </div>
        <button className={cx('meology-text-button rsw-product-detail-link detail-link-position', {
           'rsw-hide': !showDetailLink 
        })}>{t('ui.recommendation.learn_more')}</button>
    </div>
}

export default memo(PromoCard)


