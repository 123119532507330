import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CtaBtn from 'components/common/CtaBtn';
import { useIsMarketing } from 'assets/js/hooks';

const ExtraPerks = ({page}) => {
  const { t } = useTranslation();
  const { landing_page: landingPage, kids_landing_page: kidsLandingPage } = useSelector(
    state => state.QuestionReducer
  );
  const { isRegularMarketing, isKidsMarketing } = useIsMarketing(page);
  return (
  <div className="extra-bg-container">
    <div className="extra-container">
      <h2 className="extra-title">
        {t('ui.landing_page_1.perks_benefits_title_2')}
      </h2>
      <div className="extra-section">
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/calendar.png"
              alt=""
            />
          </div>

          <div className="extra-section-item-header">{t('ui.landing_page_1.perks_benefits_title_3')}</div>
          <div className="extra-section-item-context">
            {t('ui.landing_page_1.perks_benefits_title_4')}
          </div>
        </div>
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/icon-mobile-copy.png"
              alt=""
            />
          </div>
          <div className="extra-section-item-header">{t('ui.landing_page_1.perks_benefits_title_5')}</div>
          <div className="extra-section-item-context">
            {t('ui.landing_page_1.perks_benefits_title_6')}
          </div>
        </div>
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/original.svg"
              alt=""
            />
          </div>
          <div className="extra-section-item-header">
            {t('ui.landing_page_1.perks_benefits_title_7')}
          </div>
          <div className="extra-section-item-context">{t('ui.landing_page_1.perks_benefits_title_8')}</div>
        </div>
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/icon-15-copy.png"
              alt=""
            />
          </div>
          <div className="extra-section-item-header">{t('ui.landing_page_1.perks_benefits_title_9')}</div>
          <div className="extra-section-item-context">
            {t('ui.landing_page_1.perks_benefits_title_10')}
          </div>
        </div>
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/icon-shipping-copy.png"
              alt=""
            />
          </div>
          <div className="extra-section-item-header">{t('ui.landing_page_1.perks_benefits_title_11')}</div>
          <div className="extra-section-item-context">{t('ui.landing_page_1.perks_benefits_title_12')}</div>
        </div>
        <div className="extra-section-item">
          <div className="extra-section-item-img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/loyalty.png"
              alt=""
            />
          </div>
          <div className="extra-section-item-header">{t('ui.landing_page_1.perks_benefits_title_13')}</div>
          <div className="extra-section-item-context">
            {t('ui.landing_page_1.perks_benefits_title_14')}
          </div>
        </div>
      </div>
      {
        isRegularMarketing && (
          <div className="cta-button-wrap cta-button-wrap3">
              <CtaBtn
                type="regular"
                status={landingPage}
                eventLabel="marketing page cta section"
              />
          </div>
        )
      }
      {isKidsMarketing && (
        <div className="cta-button-wrap cta-button-wrap-kids cta-button-wrap3">
          <CtaBtn
            type="kids"
            status={kidsLandingPage}
            eventLabel="marketing page cta section"
          />
        </div>
      )}
    </div>
  </div>
)};

export default ExtraPerks;
