import React, { memo, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Toggle } from 'components/results/Kids';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  useMeologyHistory,
  useProfileId,
  useHideMeology,
} from 'assets/js/hooks';
import { RecommendationContext } from 'components/results/ContextProvider';
import MarketingBanner from 'components/Marketing/MarketingBanner';

const NoMeology = () => {
  const { showErrorTemplate: type } = useContext(RecommendationContext);
  const isNoRegular = useMemo(() => type === 'REGULAR', [type]);
  const isNoPrenatal = useMemo(() => type === 'PRENATAL', [type]);
  const isNoKids = useMemo(() => type === 'KIDS', [type]);
  const { reproductiveStatus } = useSelector(
    state => state.recommendationsReducer
  );

  const { t } = useTranslation();
  const { first_name: firstName, last_name: lastName } = useMeologyHistory();
  const { isShowToggle } = useHideMeology();

  const { regularProfileId, prenatalProfileId } = useProfileId();

  const logo = useMemo(() => {
    const map = {
      REGULAR: 'https://images.shaklee.com/meology/images/meology-white.svg',
      PRENATAL:
        'https://images.shaklee.com/meology/prenatal/prenatal_logo1.svg',
      KIDS: 'https://images.shaklee.com/meology/images/kids-logo-black.svg',
    };
    return map?.[type] ?? '';
  }, [type]);

  const title = useMemo(() => {
    const map = {
      REGULAR: regularProfileId
        ? t('ui.opt_out.bl_quiz_taken')
        : t('ui.opt_in.bl_no_quiz_taken'),
      PRENATAL:
        prenatalProfileId && reproductiveStatus !== 'REGULAR'
          ? t('ui.opt_out.bl_quiz_taken')
          : t('ui.opt.in.no.meology.prenatal.title'),
      KIDS: t('ui.opt_in.bl_no_quiz_taken_kids'),
    };
    return map?.[type] ?? '';
  }, [prenatalProfileId, regularProfileId, reproductiveStatus, t, type]);

  const intro = useMemo(() => {
    const param = {
      firstName,
      lastName,
      name: `${firstName || ''} ${lastName || ''}`,
    };
    const map = {
      REGULAR: regularProfileId
        ? t('ui.opt_out.bl_quiz_taken_2', param)
        : t('ui.opt_in.bl_no_quiz_taken_2', param),
      PRENATAL:
        prenatalProfileId && reproductiveStatus !== 'REGULAR'
          ? t('ui.opt.out.no.meology.prenatal.intro', param)
          : '',
      KIDS: t('ui.opt_in.bl_no_quiz_taken_2_kids', param),
    };
    return map?.[type] ?? '';
  }, [
    firstName,
    lastName,
    regularProfileId,
    t,
    prenatalProfileId,
    reproductiveStatus,
    type,
  ]);

  return (
    <div
      className={cx('no-meology', {
        [`no-meology-${type.toLowerCase()}`]: type,
        'no-toggle': !isShowToggle,
      })}
    >
      <div className="no-meology-main">
        {isShowToggle && <Toggle {...(isNoRegular && { theme: 'light' })} />}
        <img src={logo} alt="Meology Logo" className="no-meology-logo" />
        {isNoPrenatal && (
          <div className="no-meology-subtitle">
            {t('ui.recommendation.no.meology.prenatal.subtitle')}
          </div>
        )}
        <div className="no-meology-content">
          <div className="no-meology-title">{title}</div>
          <div className="no-meology-intro">
            <div className="no-meology-intro-inner">{intro}</div>
          </div>
        </div>
      </div>

      {isNoPrenatal && (
        <div className="no-meology-brand">
          <h3
            className="no-meology-brand-title"
            dangerouslySetInnerHTML={{
              __html: t('ui.recommendation.no-meology.prenatal.brand.title'),
            }}
          ></h3>
          <div className="no-meology-brand-box">
            <p className="no-meology-brand-intro">
              {t('ui.recommendation.no-meology.prenatal.brand.intro')}
            </p>
            <div className="no-meology-brand-logo"></div>
          </div>
        </div>
      )}

      {isNoKids && (
        <div className="no-meology-video-wrap">
          <div className="no-meology-video-inner">
            <MarketingBanner
              resource={t('kids.marketing.banner.resource')}
              videoTitle="MEOLOGY™ KIDS | All-In-One Superior Nutrition for Kids from Shaklee Corporation on Vimeo"
              imageAlt="MEOLOGY™ KIDS Banner"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(NoMeology);
