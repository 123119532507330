import React, { memo, useRef } from 'react';
import ProductItem from './ProductItem';
import { useTranslation } from 'react-i18next';
import { getMeologyType } from 'assets/js/helper';

const OtherRecommendations = ({
  isPrenatal,
  productsInfo,
  setPDPInfo,
  selectedAnswers,
  showContent,
}) => {
  const { t } = useTranslation();
  const productContainer = useRef();

  const typeTitleMap = {
    'REGULAR': t('ui.result.other-recommendations'),
    'KIDS': t('ui.recommendation.meology_kids_other_recommendations_title1')
  };
  const { meologyType } = getMeologyType();

  return (
    <>
    {isPrenatal && productsInfo &&
      productsInfo.length > 0 &&
      productsInfo.map((item, i) => {
        return (
          <ProductItem
            isPrenatal={isPrenatal}
            key={i}
            index={i}
            item={item}
            setPDPInfo={setPDPInfo}
            selectedAnswers={selectedAnswers}
            showContent={showContent}
          />
        );
      })
    }
    {!isPrenatal &&
      <div className="add-on-section">
        {productContainer.current &&
          productContainer.current.children.length > 0 && (
            <div className="add-on-section-text">
              {
                typeTitleMap[meologyType]
              }
            </div>
          )}
        <div className="add-on-section-content" ref={productContainer}>
          {productsInfo &&
            productsInfo.length > 0 &&
            productsInfo.map((item, i) => 
            (
                <ProductItem
                  key={i}
                  index={i}
                  item={item}
                  setPDPInfo={setPDPInfo}
                  selectedAnswers={selectedAnswers}
                  showContent={showContent}
                />
              )
            )}
        </div>
      </div>
    }
    </>
  );
};

export default memo(OtherRecommendations);
