import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            'fr-CA': ['en-CA', 'en'],
            'default': ['en']
        },
        lng: 'en',
        // have a common namespace used around the full app
        ns: ['translation'],
        defaultNS: 'translation',
        keySeparator: false,
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true
        }
    });

export default i18n;
