import React, { Component } from 'react';
import RenderNumber from './widgets/RenderNumber';
import RenderInput from './widgets/RenderInput';
import RenderInputGroup from './widgets/RenderInputGroup';
import RenderInputWithUnit from './widgets/RenderInputWithUnit';
import RenderInputWithIcon from './widgets/RenderInputWithIcon';
import RenderButton from './widgets/RenderButton';
import Checkbox from './widgets/Checkbox';
import Radiobutton from './widgets/Radiobutton';
import HeightDropdown from './widgets/HeightDropdown';
import UpLoad from './widgets/UpLoad';
import RadioWithImage from './widgets/RadioWithImage';
import { filterAnswer } from 'assets/js/helper';
import _ from 'lodash';
import RenderDatepicker from './widgets/RenderDatepicker'

class Input extends Component {
  updateAnswerForNestedInputs(parentKey) {
    return (key, value, page) => {
      let obj = _.cloneDeep(this.props.selectedAnswer) || {};
      obj[key] = value;
      this.props.updateAnswer(parentKey, obj, page);
    }
  }

  renderMultiple(parent, answers) {
    if (answers == null) {
      return null;
    }
    return answers.map((answer, index) => {
      return (
        <Input
          key={index}
          btnRef={this.props.btnRef}
          widget={answer.answer_widget}
          selectedAnswer={this.props.selectedAnswer}
          name={answer.answer}
          updateAnswer={this.updateAnswerForNestedInputs(parent).bind(this)}
          page={this.props.page}
          setNextPage={this.props.setNextPage}
          setAnswer={this.props.setAnswer}
          pageQuestion={this.props.pageQuestion}
          isMobile={this.props.isMobile}
          showNextButton={this.props.showNextButton}
          btnNextContainer={this.props.btnNextContainer}
          editBtnNextContainer={this.props.editBtnNextContainer}
          rank={this.props.rank}
          shakleeId={this.props.shakleeId}
          isClickedRef={this.props.isClickedRef}
          setIsClickedRef={this.props.setIsClickedRef}
          parent={parent}
        />)
    })
  }

  render() {
    const {
      btnRef,
      name,
      options,
      widget,
      selectedAnswer,
      updateAnswer,
      updateEmailAnswer,
      resetValidateEmail,
      validateEmail,
      page,
      selectedAnswers,
      setNextPage,
      pageQuestion,
      setAnswer,
      isMobile,
      showNextButton,
      btnNextContainer,
      editBtnNextContainer,
      rank,
      shakleeId,
      isClickedRef,
      setIsClickedRef,
      parent,
	    setLogs,
      isDatepickerFloat
    } = this.props;
    const opts = options
      ? options.map(opt => {
          filterAnswer(opt, selectedAnswers);
          return opt;
        })
      : [];

    switch (widget) {
      case 'multiple':
        return this.renderMultiple(name, opts);
	    case 'input_with_icon':
		    return (
			    <RenderInputWithIcon
				    name={name}
				    updateAnswer={updateAnswer}
				    page={page}
            selectedAnswer={selectedAnswer}
			    />
		    );
      case 'input_with_unit':
        return (
          <RenderInputWithUnit
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            btnNextContainer={btnNextContainer}
            editBtnNextContainer={editBtnNextContainer}
            isMobile={isMobile}
          />
        );
      case 'input':
        return (
          <RenderInput
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            updateEmailAnswer={updateEmailAnswer}
            resetValidateEmail={resetValidateEmail}
            validateEmail={validateEmail}
            setLogs={setLogs}
            page={page}
            btnNextContainer={btnNextContainer}
            editBtnNextContainer={editBtnNextContainer}
            isMobile={isMobile}
            parent={parent}
          />
        );
      case 'input_group':
        return (
          <RenderInputGroup
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            validateEmail={validateEmail}
            page={page}
            btnNextContainer={btnNextContainer}
            editBtnNextContainer={editBtnNextContainer}
            isMobile={isMobile}
          />
        );
      case 'number':
        return (
          <RenderNumber
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
          />
        );
      case 'single_choice':
        return (
          <Radiobutton
            btnRef={btnRef}
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            setNextPage={setNextPage}
            setAnswer={setAnswer}
            pageQuestion={pageQuestion}
            isMobile={isMobile}
            showNextButton={showNextButton}
            rank={rank}
            shakleeId={shakleeId}
            isClickedRef={isClickedRef}
            setIsClickedRef={setIsClickedRef}
          />
        );
      case 'multi_choice':
        return (
          <Checkbox
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            isMobile={isMobile}
          />
        );
      case 'button':
        return (
          <RenderButton
            btnRef={btnRef}
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            setNextPage={setNextPage}
            setAnswer={setAnswer}
            pageQuestion={pageQuestion}
            selectedAnswers={selectedAnswers}
            showNextButton={showNextButton}
            rank={rank}
            shakleeId={shakleeId}
            isClickedRef={isClickedRef}
            setIsClickedRef={setIsClickedRef}
          />
        );
      case 'dropdown':
        return (
          <HeightDropdown
            name={name}
            options={opts}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            parent={parent}
          />
        );
      case 'upload':
        return (
          <UpLoad
            name={name}
            btnRef={btnRef}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
          />
        );
      case 'radio_with_image':
        return (
          <RadioWithImage
            question={name}
            updateAnswerLocally={updateAnswer}
            options={options}
            currentAnswer={selectedAnswer}
          />
        );
        case 'date_picker':
        return (
          <RenderDatepicker
            name={name}
            parent={parent}
            selectedAnswer={selectedAnswer}
            updateAnswer={updateAnswer}
            page={page}
            options={opts}
            setLogs={setLogs}
            btnNextContainer={btnNextContainer}
            editBtnNextContainer={editBtnNextContainer}
            isMobile={isMobile}
            isFloat={isDatepickerFloat}
          />
        );
      default:
        return null;
    }
  }
}

export default Input;
