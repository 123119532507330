import React, { useContext, memo } from 'react';
import CopyPageLink from 'components/CopyPageLink';
import { MarketingContext } from 'components/Marketing/Marketing';
import MarketingBanner from 'components/Marketing/MarketingBanner';
import BannerSlider from './BannerSlider';
const Banner = () => {
  const { t, prenatalLandingPage } = useContext(MarketingContext);

  return (
    <div
      className="banner-container prenatal"
    >
      {/* Copy Page Link */}
      <CopyPageLink className="copy-page-link-marketing-page" />

      <div className="banner">
        <div
          className="brand-logo-text"
          data-text={t('ui.prenatal_marketing.page_eyebrow')}
        >
          <img
            src="https://images.shaklee.com/meology/prenatal/mkt_prenatal_logo.svg"
            alt="meology"
          />
        </div>
        <h2 className="mkt-section-title">
          {t('ui.prenatal_marketing.section_1.headline')}
        </h2>
        <MarketingBanner
          resource={t('prenatal.marketing.banner.resource')}
          videoTitle="Introducing Meology Prenatal"
        />
        <BannerSlider t={t} prenatalLandingPage={prenatalLandingPage} />
      </div>
      <div className="expert-container">
        <div className="expert-text">
          <h2 className="mkt-section-sub-title">
            {t('ui.prenatal_marketing.section_3.headline')}
          </h2>
          <p>{t('ui.prenatal_marketing.section_3.content')}</p>
        </div>
        <div className="expert-logo"></div>
      </div>
    </div>
  );
};

export default memo(Banner);
