import {
	Q_CURRENT_PAGE,
	Q_FETCH_QUESTIONS,
	list_questions_url,
	list_questions_history_url,
	list_file_upload_url,
	list_file_delete_url,
	Q_UPDATE_ANSWER,
	Q_PREVIOUS_PAGE,
	Q_NEXT_PAGE,
	Q_FETCH_RESULTS,
	CREATE_HISTORY,
	Q_FETCH_HEALTHPROFILE,
	Q_SET_ANSWER,
	Q_EDIT_QUESTION,
	Q_RESET_ANSWER,
	Q_VALIDATE_QUESTION,
	Q_KIDS_LANDING_PAGE,
  Q_PRENATAL_LANDING_PAGE,
	Q_LANDING_PAGE,
	USERINFO,
	IS_MOBILE,
	SUBMIT_QUESTION_RESULT,
	PRE_SUBMIT_CHECK,
	SET_ACCOUNT,
	SET_CKID,
	SET_BANNER,
	Q_NEXT_SECTION,
	Q_PREVIOUS_SECTION,
	REMOVE_QUESTION_VALIDATION,
	SET_HISTORY,
	SET_MARKETING_TYPE, UPDATE_HISTORY,
} from './QuestionConstants';
import { getEventAction, getEventLabel, getEventCategory } from 'components/analytics/quizAnalytics';
import { getImpersonation } from 'assets/js/hooks';
import Cookies from 'js-cookie';
import _ from 'lodash';

import {
  setLocalStorage,
  isInputUnit,
  isInputGroup,
  getUrlVars,
  getFileExtension,
  isEmpty,
  getLocalQuestion,
  removeLocalQuestion,
  getLocalStorage,
  removeLocalHealthProfile,
  updateLocalHealthProfile,
  hasInput,
  removeQuestionsNumber,
  setLocalQuestion,
  getMeologyType,
  removeLocalStorage,
  PRENATAL_TYPE,
  REGULAR_TYPE,
  KIDS_TYPE,
  getNameFromAnswers,
  getCurrentEventCategory,
  post,
} from 'assets/js/helper';

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from '../async/asyncActions';
import {
  pushToDataLayer,
  pushNavigationToDataLayer,
  pushInteractionToDataLayer,
} from '../analytics/analyticsActions';
import {
  validateInput,
  validateHeight,
  validateWeight,
} from './QuestionValidations';
import moment from 'moment';

export const fetchQuestionsData = (country, type = 'REGULAR') => {
  return async dispatch => {
    try {
      dispatch(asyncActionStart());
      const result = await fetch(list_questions_url + country + `/${type}`)
        .then(response => {
          return response.json();
        })
        .then(record => {
          return record;
        })
        .catch(err => {
          throw err;
        });
      dispatch({
        type: Q_FETCH_QUESTIONS,
        payload: result,
      });
      dispatch(asyncActionFinish());
      return result.data;
    } catch (error) {
      dispatch(asyncActionError(error.message));
    }
  };
};

export const setAnswer = answer => {
  return dispatch => {
    dispatch({
      type: Q_SET_ANSWER,
      payload: answer,
    });
  };
};

export const resetAnswer = () => {
  return dispatch => {
    dispatch({
      type: Q_RESET_ANSWER,
    });
  };
};

export const editQuestionDetails = details => {
  return dispatch => {
    dispatch({
      type: Q_EDIT_QUESTION,
      payload: details,
    });
  };
};


export const updateAnswer = (
  key,
  value,
  pageId = null,
) => {
  return async dispatch => {
    try {
      dispatch({
        type: Q_UPDATE_ANSWER,
        payload: {
          key: key,
          value: value && typeof value === 'string' ? value.trim() : value,
        },
      });
      const pushAnalytics = (question, answer) => {
        // click analytics
        const eventAction = getEventAction(question);
        const { isRegularType, isPrenatalType, isKidsType } = getMeologyType();
        if (hasInput(question)) {
          let eventLabel = answer;
          if (question === 'name') eventLabel = '';
          if (['prenatal_name', 'prenatal_due_date'].includes(question)) {
            eventLabel = getEventLabel(question);
          }

          const eventCategory = getEventCategory(question);
          dispatch(
            pushInteractionToDataLayer({
              eventCategory,
              eventAction,
              eventLabel,
            })
          );
        } else if (question === 'kids_info') {
          const eventLabel =
            answer?.map(({ icon }) => `${icon}_icon`)?.join(', ') ?? '';
          dispatch(
            pushInteractionToDataLayer({
              eventCategory: 'a_name_icon',
              eventAction: 'A0: kids_info',
              eventLabel,
            })
          );
        } else if (question === 'email') {

          const eventCategory = (() => {
            if (isRegularType) return 'Meology Quiz';
            // TODO:
            if (isPrenatalType) return '';
            if (isKidsType) return 'b_before_result';
          })()

          const eventAction = (() => {
            if (isRegularType) return 'Op_G01: submit email page';
            // TODO:
            if (isPrenatalType) return '';
            if (isKidsType) return 'B0: Get Meology Kid result';
          })()

          const eventLabel = (() => {
            if (isRegularType) return 'submit email page';
            // TODO:
            if (isPrenatalType) return '';
            if (isKidsType) return 'Get Meology Kid result';
          })()

          dispatch(pushInteractionToDataLayer({
            eventCategory,
            eventAction,
            eventLabel,
          }))
        } else {
          if (question === 'opt_in') {
            if (isPrenatalType) {
              const { prenatal_reproductive_status: status } = getLocalQuestion() || {};
              const gaMap = {
                TRYING: {
                  eventCategory: 'Prenatal Quiz',
                  eventAction: 'Op_B01_preparing',
                  eventLabel: 'email_capture'
                },
                PREGNANT: {
                  eventCategory: 'Prenatal Quiz',
                  eventAction: 'Op_B03_pregnant',
                  eventLabel: 'email_capture'
                },
                POSTPARTUM: {
                  eventCategory: 'Prenatal Quiz',
                  eventAction: 'Op_B02_postpartum',
                  eventLabel: 'email_capture'
                },
              }

              dispatch(pushInteractionToDataLayer({
                eventCategory: gaMap?.[status].eventCategory ?? '',
                eventAction: gaMap?.[status].eventAction ?? '',
                eventLabel: gaMap?.[status].eventLabel ?? '',
              }))
            }
          } else {
            const eventLabel = getEventLabel(question, answer);
            const eventCategory = getEventCategory(question);
            dispatch(pushInteractionToDataLayer({
              eventCategory,
              eventAction,
              eventLabel,
            }))
          }
        }
      };
      pushAnalytics(key, value)
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteQuestionFromList = question => {
  const questionsSelectedAnswers = getLocalQuestion();
  const isValid = questionsSelectedAnswers?.hasOwnProperty(question);
  if (isValid) {
    delete questionsSelectedAnswers[question];
  }
  setLocalQuestion(questionsSelectedAnswers);
};

const getSectionQuestions = (pageId, questions) => {
  if (!questions || !Array.isArray(questions)) return;
  if (questions.length === 0) return;

  const currentCategory = _.get(questions, `[${pageId}].category`, null);
  if (!currentCategory) return;
  return _.cloneDeep(questions)
    .splice(pageId)
    .filter(({ category }) => category === currentCategory)
    .map(({ question }) => question);
};

export const setNextSection = (data, dataLayer = true) => {
  return async (dispatch, getState) => {
    try {
      const { type } = getMeologyType();
      const { QuestionReducer: { [`${type}PageQuestions`]: pageQuestions } = {} } = await getState();
      const { pageId, updateAnswer, question, label } = data;
      const sectionQuestions = getSectionQuestions(pageId, pageQuestions);
      if (!sectionQuestions || sectionQuestions.length === 0) return;

      // remove data from local
      sectionQuestions.forEach(question => {
        updateAnswer(question, [], pageId, true);
      });

      // remove data from redux and localstorage
      const sectionLength = sectionQuestions.length + 1;
      dispatch({
        type: Q_NEXT_SECTION,
        sectionLength,
        sectionQuetions: sectionQuestions,
      });

      // Remember the skipped length
      setLocalStorage('skipedSectionLength', sectionLength);

      // if (dataLayer) {
      //   dispatch(pushToDataLayer({ pageId, label, rank, shakleeId }));
      // }
      const eventAction = getEventAction(question);
      const eventLabel = label;
      const eventCategory = getEventCategory(question);
      dispatch(pushInteractionToDataLayer({
        eventCategory,
        eventAction,
        eventLabel,
      }))
    } catch (error) {
      console.log(error);
    }
  };
};

export const setPreviousSection = data => ({
  type: Q_PREVIOUS_SECTION,
  data,
});

export const setNextPage = (data, dataLayer = true) => {
  return dispatch => {
    if (data.deleteQuestion) {
      deleteQuestionFromList(data.question);
    }
    dispatch({
      type: Q_NEXT_PAGE,
    });
    // if (dataLayer) {
    //   dispatch(pushToDataLayer(data));
    // }
  };
};

export const setPreviousPage = (data, dataLayer = true) => {
  return dispatch => {
    if (data.deleteQuestion) {
      deleteQuestionFromList(data.question);
    }
    dispatch({
      type: Q_PREVIOUS_PAGE,
      newPageId: data.pageId,
    });
    if (dataLayer) {
      dispatch(pushToDataLayer(data));
    }
  };
};

export const setCurrentPage = (data, rank = '', shakleeId = '', firstName = '', lastName = '') => {
  return dispatch => {
    dispatch({
      type: Q_CURRENT_PAGE,
      payload: data,
    });
    dispatch(
      pushToDataLayer({
        pageId: data,
        label: 'current',
        rank: rank,
        shakleeId: shakleeId,
        firstName : firstName,
        lastName : lastName
      })
    );
  };
};

export const setNavigationDataLayer = ({
  isLoggedin = false,
  type = null,
  rank = null,
  contactId = null,
  email = null,
  firstName = null,
  lastName = null
}) => {
  return async (dispatch, getState) => {
    let user = {};
    let finalCkid = null;
    const { QuestionReducer: { ckid } } = await getState();
    const urlParams = new URLSearchParams(window.location.search);
    const { isImpersonation } = getImpersonation();

    if (!ckid && urlParams.get('ckid')) {
      const { message } = await getDecryptCkidReuslt(encodeURIComponent(urlParams.get('ckid')));
      finalCkid = message;
      await dispatch(setCkid(message || null));
    }

    if (type === 'Guest') {
      user = {
        id: ckid || finalCkid,
        loggedin: 'false',
        type: type,
      };
    } else {
      user = {
        id: contactId,
        loggedin: isLoggedin ? 'true' : 'false',
        email: email,
        type: type,
        rank: rank,
      };
      if(firstName)user.firstName = firstName
      if(lastName)user.lastName = lastName
    }

    if (isImpersonation) user.impersonation = 'true';

    dispatch(
      pushNavigationToDataLayer({
        event: 'navigation',
        page: {
          path: window.location.pathname,
          title: 'Meology',
          type: 'Meology',
          category: 'Meology',
        },
        user: {
          ...user,
        },
      })
    );
  };
};

export const setLandingPage = (val, rank = '', shakleeId = '', firstName = '', lastName = '') => {
  return dispatch => {
    dispatch({
      type: Q_LANDING_PAGE,
      payload: val,
    });
    dispatch(
      pushToDataLayer({
        pageId: '0',
        label: 'landing',
        rank: rank,
        shakleeId: shakleeId,
        firstName,
        lastName
      })
    );
  };
};

export const setKidsLandingPage = (val) => {
  return dispatch => {
    dispatch({
      type: Q_KIDS_LANDING_PAGE,
      payload: val,
    });
  };
};

export const setPrenatalLandingPage = (val) => {
  return dispatch => {
    dispatch({
      type: Q_PRENATAL_LANDING_PAGE,
      payload: val,
    });
  };
};

export const setMarketingType = (val) => ({
  type: SET_MARKETING_TYPE,
  payload: val,
});

export const checkUserEmail = async (user, selAns) => {
  // check if logged in user email is same as localstorage email, else delete data
  if (user && user.isUserLoggedin && selAns && user.email && selAns.email) {
    if (selAns.email !== user.email) {
      // logged in user is not equal to localstorage email, delete the localstorage
      removeLocalQuestion();
      removeQuestionsNumber();
      removeLocalHealthProfile();
      return true;
    }
  } // end if
  return false;
};

const isDiffEmail = async (type, user, selAns) => {
  // check if logged in user email is same as localstorage email, else delete data
  if (user && user.isUserLoggedin && selAns && user.email && selAns.email) {
    if (selAns.email !== user.email) {
      // logged in user is not equal to localstorage email, delete the localstorage
      const _meologyType = type?.toLowerCase()
      removeLocalStorage(`${_meologyType}QuestionsSelectedAnswers`)
      removeLocalStorage(`${_meologyType}QuestionsPageNumber`)
      removeLocalStorage(`${_meologyType}HealthProfile`)
      return true;
    }
  } // end if
  return false;
};
// set state of CtaBtn
const holdProfile =async (dispatch,user, type, actionType)=>{
  const _meologyType = type?.toLowerCase()
  let questionsSelectedAnswers = getLocalStorage(`${_meologyType}QuestionsSelectedAnswers`);
  // check if logged in user email is same as localstorage email, else delete data
  let diffEmail = await isDiffEmail(type,user, questionsSelectedAnswers);
  let profile = getLocalStorage(`${_meologyType}HealthProfile`);
  let questionsPageNumber = getLocalStorage(`${_meologyType}QuestionsPageNumber`);
  if (profile) {
    if (user && user.isUserLoggedin) {
      // health profile exist in local storage so ask to go to result page or retake quiz and so landing page 3
      const hpResult = await dispatch(
        getAnswersByHealthProfileId({
          postData: { health_profile_id: profile?.health_profile_id },
        })
      );
      diffEmail = await isDiffEmail(REGULAR_TYPE,user, hpResult?.questions);
    }
    if (diffEmail) {
      questionsPageNumber = null;
      profile = null;
    } else {
      // if emails are same we show page 5 or 6
      if (!questionsPageNumber) {
        dispatch({ type: actionType, payload: 5 });
      } else {
        dispatch({ type: actionType, payload: 2 });
      }
    }
  }
  if (!profile) {
    if (!questionsPageNumber) {
      // no question number that means questions is not started and we will show landing page 1
      dispatch({ type: actionType, payload: 1 });
    } else {
      // question number exists and so we show landing page 2 where user can continue the quiz
      dispatch({ type: actionType, payload: 2 });
    }
  }
}

// function hpidInLocalStorage to check landing page based on localstorage
export const hpidInLocalStorage = (user = null,  meologyType, isALL) => async (dispatch) => {
  const kidsProfile = async() =>{
    let kidsHealthProfile = getLocalStorage("kidsHealthProfile");
    if (!user || !user.isUserLoggedin) {
      if (kidsHealthProfile) {
        dispatch({ type: Q_KIDS_LANDING_PAGE, payload: 2 })
      } else {
        dispatch({ type: Q_KIDS_LANDING_PAGE, payload: 1 })
      }
    }
  }
  if(isALL){
  await holdProfile(dispatch, user,REGULAR_TYPE, Q_LANDING_PAGE)
  await holdProfile(dispatch, user,PRENATAL_TYPE, Q_PRENATAL_LANDING_PAGE)
  await  kidsProfile()
  }else {
    const _meologyType = meologyType || getLocalStorage("meologyType")
    if(!_meologyType) return
    if(_meologyType === KIDS_TYPE) {
      await  kidsProfile()
    } else {
      const actions = {[REGULAR_TYPE]: Q_LANDING_PAGE, [PRENATAL_TYPE]: Q_PRENATAL_LANDING_PAGE}
      await holdProfile(dispatch, user,_meologyType, actions[_meologyType])
    }
  }
};

export const setHistory = data => ({
  type: SET_HISTORY,
  data,
})

export const getAnswersByHealthProfileId = ({
  postData,
  changeHistory = true,
  setAsyncStatus = true,
}) => {
  return async dispatch => {
    if (setAsyncStatus) {
      dispatch(asyncActionStart());
    }
    const result = await post({ url: list_questions_history_url, postData });
    if (setAsyncStatus) {
      dispatch(asyncActionFinish())
    }
    if (result.status === 9997 || result.data.length === 0) return;
    const history = result?.data?.[0];
    if (changeHistory) {
      dispatch(setHistory(history));
    }

    return { ...history, beta: result?.beta };
  };
};

export const fileUpload = (file, t) => {
  let formData = new FormData();
  formData.append('file', file);
  return async dispatch => {
    try {
      await dispatch(asyncActionStart());
      const result = await fetch(list_file_upload_url, {
        method: 'POST',
        body: formData,
      })
        .then(async response => {
          if (response.ok) {
            return response.json().catch(() => Promise.reject('decode error'));
          } else {
            await Promise.reject('network error');
          }
        })
        .then(record => {
          const { status, data, message } = record || {};
          if (status === 0) {
            const { userId } = data || {};
            return userId;
          } else if (status === 9997) {
            if (message === 'wrong_file_format') {
              return Promise.reject(t('questions.dna_id.validation'));
            }
          } else {
            return Promise.reject('upload error');
          }
        })
        .catch(err => {
          throw err;
        });
      await dispatch(asyncActionFinish());
      return result;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const fileDelete = Uuid => {
  return async dispatch => {
    try {
      await dispatch(asyncActionStart());
      const result = await fetch(`${list_file_delete_url}/${Uuid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_DELETE_API_TOKEN}`,
        },
      })
        .then(response => {
          dispatch({
            type: Q_UPDATE_ANSWER,
            payload: {
              key: 'dna_id',
              value: null,
            },
          });
          return response.json();
        })
        .then(record => {
          return record;
        })
        .catch(err => {
          throw err;
        });
      await dispatch(asyncActionFinish());
      return result;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const getAnswersByShakleeId = ShakleeId => {
  return async dispatch => {
    try {
      await dispatch(asyncActionStart());
      const result = await fetch(
        list_questions_history_url + '/' + encodeURIComponent(ShakleeId),
        {
          method: 'GET',
        }
      )
        .then(response => {
          return response.json();
        })
        .then(record => {
          return record;
        })
        .catch(err => {
          throw err;
        });
      if (result.status === 9997) {
        window.location.href = '/';
        return;
      }
      await dispatch(asyncActionFinish());
      dispatch(setHistory(result?.data?.[0]));
      return { ...result.data[0], beta: result.beta };
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const cleanupAnswers = selAnswer => {
  if (!selAnswer) return selAnswer;
  Object.keys(selAnswer).forEach(rec => {
    if (typeof selAnswer[rec] === 'string') {
      if (selAnswer[rec] === 'NONE' || selAnswer[rec] === 'OTHER' || selAnswer[rec] === 'NOT_SAY') {
        selAnswer[rec] = null;
      }
    } else if (Array.isArray(selAnswer[rec])) {
      selAnswer[rec].forEach((subRec, subKey) => {
        if (subRec === 'NONE' || subRec === 'OTHER' || selAnswer[rec] === 'NOT_SAY') {
          selAnswer[rec][subKey] = null;
        }
      });
    }
  });

  return selAnswer;
};

export const postUpdate = (selectedAnswers, country_code, language) => {
  return async (dispatch, getState) => {
    try {
      const state = await getState();
      let { referrer_id, host_name } = state?.QuestionReducer?.sponsor_get_pws ?? {};
      const newAnswer = cleanupAnswers({ ...selectedAnswers });
      const { email, opt_in = false, dna_id, kids_info } = newAnswer || {};
      const name = getNameFromAnswers(newAnswer);
      const { isKidsType, meologyType: meology_type } = getMeologyType();
      let postData = {};

      if (isKidsType) {
        newAnswer.meology_status = kids_info?.length > 0 ? 'ACTIVE' : 'DELETED';
      } else {
        newAnswer.meology_status = 'ACTIVE';
      }

      if (!isKidsType) {
        const [first_name, last_name] = name?.split(' ') ?? [];
        postData = {
          ...postData,
          first_name,
          last_name,
        }
      }

      postData = {
        ...postData,
        ...(dna_id && { dna_id }),
        ...(referrer_id && { referrer_id }),
        ...(host_name && { host_name }),
        email,
        opt_in: isKidsType || opt_in,
        questions: {
          ...newAnswer,
          country_code,
          language,
          meology_type,
        }
      }

      if (!host_name) {
        const pwsName = Cookies.get('pwsName');
        if (pwsName) {
          postData.host_name = pwsName;
        }
      }

      const result = await fetch(CREATE_HISTORY, {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then(response => {
          return response.json();
        })
        .then(record => {
          return record;
        })
        .catch(err => {
          throw err;
        });
      let healthProfile = null;
      if (result.status === 0) {
        healthProfile = {
          health_profile_id: result.health_profile_id,
          email: email,
        };  
        updateLocalHealthProfile(healthProfile);
        removeQuestionsNumber();
        removeLocalQuestion();

        // Google Analysis
        // User successfully submits their email
        const eventCategory = getCurrentEventCategory();

        dispatch(
          pushInteractionToDataLayer({
            eventCategory,
            eventAction: 'Email Capture',
            eventLabel: '',
          })
        );
      } else {
        console.warn('bad status: ', result.status, ':', result.message);
        result.json = JSON.stringify(postData);
        throw result;
      }
      dispatch({
        type: Q_FETCH_RESULTS,
        payload: result,
      });
      dispatch({
        type: Q_FETCH_HEALTHPROFILE,
        payload: healthProfile,
      });
      return healthProfile;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

const validate = (schema, input, t) => {
  if (!schema) {
    return null;
  }

  if (schema['required'] && !input) {
    return {
      error: true,
      error_message: '',
    };
  }
  let regex;
  switch (schema['type']) {
    case 'regexp':
      regex = new RegExp(schema['value'], 'i');
      if (!regex.test(input.trim())) {
        return {
          error: true,
          error_message: t('questions.error_invalid_input'),
        };
      }
      if (schema['max_length'] && input.length > schema['max_length']) {
        return {
          error: true,
          error_message: `${schema['max_length']} character maximum`,
        };
      }
      break;
    case 'minmax':
      regex = new RegExp(schema['value']);
      if (!regex.test(input)) {
        return {
          error: true,
          error_message: t('questions.error_invalid_input'),
        };
      }
      if (input < schema['min']) {
        return {
          error: true,
          error_message: t('questions.error_minmax'),
        };
      }
      if (input > schema['max']) {
        return {
          error: true,
          error_message: t('questions.error_minmax'),
        };
      }
      break;
    case 'digits':
      if (!input) break;
      regex = new RegExp(schema['value']);
      if (!regex.test(input)) {
        return {
          error: true,
          error_message: t('questions.error_digits'),
        };
      }
      if (schema['min'] && input.length < schema['min']) {
        return {
          error: true,
          error_message: t('questions.error_digits'),
        };
      }
      if (schema['max'] && input.length > schema['max']) {
        return {
          error: true,
          error_message: t('questions.error_digits'),
        };
      }
      if (schema['minValue'] && input < schema['minValue']) {
        return {
          error: true,
          error_message: t('questions.error_digits'),
        };
      }
      if (schema['maxValue'] && input > schema['maxValue']) {
        return {
          error: true,
          error_message: t('questions.error_digits'),
        };
      }
      break;
      case 'date': 
        regex = new RegExp(schema['value'], 'i');
        if (!regex.test(input.trim())) {
          return {
            error: true,
            error_message: t('questions.error_invalid_input'),
          };
        }
        if(schema['min']) {
          const minDate = moment(schema['min']).format("MM/DD/yyyy")
          if(moment(minDate).isAfter(input)) {
            return {
              error: true,
              error_message: t('questions.error_invalid_min_date'),
            };
          }
        }

        if (schema['max']) {
          const maxDate = moment(schema['max']).format('MM/DD/yyyy');

          if (moment(maxDate).isBefore(input)) {
            return {
              error: true,
              error_message: t('questions.error_invalid_max_date'),
            };
          }
        }
       
      break;
      default:
      break;
  }
  return null;
};

export const validateQuestions = (
  question,
  val,
  isRequired,
  t,
  option,
  options,
  isClickNextButton,
) => {
  return async dispatch => {
    try {
      dispatch(asyncActionStart());
      if (typeof val === 'string') {
        val = val.trim();
      }
      let msg = '';
      const validate_question = {};
      if (isRequired) {
        validate_question[question] = null;
        if ((typeof val === 'object' && val.length === 0) || (question !== 'kids_info' && isEmpty(val))) {
          if (typeof val === 'object' && val.length === 0) {
            msg =
              question === 'health_goals' ? '' : t('questions.error_options');
          }
          validate_question[question] = {
            error: true,
            error_message: msg,
          };
          dispatch({
            type: Q_VALIDATE_QUESTION,
            payload: validate_question,
          });
          return validate_question;
        }
      }
      const { nameKey } = getMeologyType();
      const schema = {
        [nameKey]: {
          type: 'regexp',
          value: "^([a-z]+('?[a-z]){0,}(\\.?\\s)?){0,}[a-z]{0,}$",
          required: true,
          max_length: 12,
        },
        age: {
          type: 'minmax',
          min: 18,
          max: 99,
          value: '^[0-9]+$',
          required: true,
        },
        weight: {
          type: 'digits',
          value: /^[0-9]\d*$/,
          min: 2,
          max: 3,
          minValue: 50,
          maxValue: 999,
        },
        height: {
          type: 'digits',
          value: /^[0-9]\d*$/,
          min: 3,
          max: 121,
          minValue: 3,
          maxValue: 121,
        },
        prenatal_due_date: {
          type: 'date',
          value: '^(0[1-9]|1[0-2])\\/(0[1-9]|[12][0-9]|3[01])\\/\\d{4}$',
          min: new Date(),
          max: moment().add(280, 'days').valueOf(),
          required: true,
        }
      };
      if (schema[question]) {
        validate_question[question] = validate(schema[question], val, t);
      } else if (question === 'health_goals') {
        validate_question[question] = null;
        if (val.length > 3) {
          validate_question[question] = {
            error: true,
            error_message: t('questions.error_choose_3_goals'),
          };
        }
        if (val.length < 3) {
          validate_question[question] = {
            error: true,
            error_message: '',
          };
        }
      } else if (isInputUnit(question)) {
        let value = '';
        if (question === 'core_measurement') {
          value = val;
        } else {
          value = val[option];
        }
        if (value && value.value && !validateInput(option, value)) {
          validate_question[question] = { error: true };
          validate_question[option] = {
            error: true,
            error_type: 'input',
            belong_to: question,
            error_message: t('errors.meology'),
          };
        } else {
          if (value && value.value) {
            validate_question[question] = false;
          }
          validate_question[option] = false;
        }
      } else if (isInputGroup(question)) {
        let value = val[option];
        if (!isEmpty(value) && !validateInput(option, value)) {
          validate_question[question] = { error: true };
          validate_question[option] = {
            error: true,
            error_type: 'input',
            belong_to: question,
            error_message: t('questions.bp_reading.validation'),
          };
        } else {
          validate_question[question] = false;
          validate_question[option] = false;
        }
      } else if (question === 'dna_id') {
        const { name, size } = val || {};
        if (name && size >= 0) {
          const fileExtention = getFileExtension(name);
          if (!fileExtention || fileExtention !== 'txt') {
            validate_question[question] = {
              error: true,
              error_message: t('questions.dna_id.format_validation'),
            };
          }
          if (size === 0 || size > 25 * 1024 * 1014) {
            validate_question[question] = {
              error: true,
              error_message:
                'Sorry, the file size must be bigger than 0 byte and less than 25 MB',
            };
          }
        } else {
          validate_question[question] = false;
        }
      } else if (question === 'height_and_weight') {
        if (!_.isEmpty(val.weight) && !validateWeight(val.weight)) {
          validate_question[question] = {
            error: true,
            error_message: t('questions.error_digits'),
          };
        } else if (val.height && !validateHeight(val.height)) {
          validate_question[question] = {
            error: true,
            error_message: t('errors.meology'),
          };
        } else {
          validate_question[question] = false;
        }
      } else if (question === 'kids_info') {
        if (Array.isArray(val) && val.length > 0) {
          const hasEmpty = val?.some(({ name, icon }) => !name || !icon);
          const regex = new RegExp(schema.name.value, 'i');
          validate_question[question] = {};
          if (isClickNextButton) {
            if (hasEmpty) {
              validate_question[question].all = {
                error: true,
                error_message: t('questions.error_invalid_cards'),
              };
            } else {
              validate_question[question] = {
                all: false,
              };
            }
          }

          val.forEach(({ name }, index) => {
            if (name && (!regex.test(name))) {
              validate_question[question][index] = {
                error: true,
                error_message: t('questions.error_invalid_input'),
              };
            } else if (name && (name.length > schema.name.max_length)) {
              validate_question[question][index] = {
                error: true,
                error_message: `${schema.name.max_length} character maximum`,
              };
            } else {
              validate_question[question][index] = false;
            }
          });
        }
      } else if (question === 'prenatal_reproductive_status') {
        validate_question[question] = null;
        if(val === 'NONE') {
          validate_question[question] = {
            error: true,
            error_message: '',
          };
        }
      }
      dispatch({
        type: Q_VALIDATE_QUESTION,
        payload: validate_question,
      });
      dispatch(asyncActionFinish());
      return validate_question;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const resetValidateQuestion = () => {
  return async dispatch => {
    dispatch({
      type: 'Q_VALIDATE_QUESTION_RESET',
    });
  };
};

export const removeValidateQuestion = data => ({
  type: REMOVE_QUESTION_VALIDATION,
  data,
});

export const setLogOnQuiz = async query => {
  const endpoint = `/sponsor/printPWSInformation?${query}`;
  const response = await fetch(endpoint);
  return await response.json();
};

export const setLogs = (key, value) => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());
    const state = await getState();
    const pwsName = Cookies.get('pwsName');
    const selectedAnswers = getLocalQuestion();
    let query = '';

    if (state.QuestionReducer.sponsor_get_pws.host_name) {
      query =
        query + '&pwsName=' + state.QuestionReducer.sponsor_get_pws.host_name;
    } else if (pwsName) {
      query = query + '&pwsName=' + pwsName;
    }

    if (['name', 'prenatal_name'].includes(key)) {
      query = query + '&userName=' + encodeURIComponent(value.trim());
    } else if (selectedAnswers) {
      const name = getNameFromAnswers(selectedAnswers);
      query = query + '&userName=' + name;
    }

    if (state.QuestionReducer.sponsor_get_pws.referrer_id) {
      query =
        query +
        '&sponsorId=' +
        state.QuestionReducer.sponsor_get_pws.referrer_id;
    }
    if (key === 'email') {
      query = query + '&email=' + encodeURIComponent(value.trim());
    }

    const log = await setLogOnQuiz(query);
    console.log('log', log);
    dispatch(asyncActionFinish());
  } catch (error) {
    dispatch(asyncActionError(error.message));
  }
};

export const getUserInfo = (url) => {
  return async dispatch => {
    try {
      dispatch(asyncActionStart());
      dispatch({
        type: 'SPONSOR_GET_PWS',
        payload: {
          referrer_id: '',
          host_name: '',
          pws_list: [],
        },
      });
      let returnObj = {};
      const { pwsName } = getUrlVars(url);
      const record = await fetch(url)
        .then(res => {
          return res.json();
        })
        .then(result => {
          if (result.status === 0) {
            const data = result.data;
            if (!data) return null;
            if (Object.keys(data).length === 0) return null;
            if (data.errors) return null;
            const pws_name = _.get(data, 'pwsList[0].site.pws_name', '');
            const host_name = pwsName || pws_name;
            const referrer_id = _.get(data, 'user.shaklee_id', '');
            dispatch({
              type: 'SPONSOR_GET_PWS',
              payload: {
                referrer_id,
                host_name,
                pws_list: data.pwsList,
              },
            });
            if (data) {
              const userInfo = data.user;
              returnObj = {
                ambassador: userInfo.ambassador,
                picture_url: userInfo.picture_url,
                sponsor: userInfo.first_name,
                contactEmail: userInfo.email,
                contactPhone: userInfo.phone,
                pwsList: data.pwsList,
                rewards: userInfo.rewards,
              };
            }

            return {
              status: 'success',
              content: returnObj,
            };
          } else {
            console.error(`UserInfo Error: ${result.status}: ${result.error}`);
            return {
              status: 'failed',
              content: `Error: ${result.status}: ${result.error}`,
            };
          }
        })
        .catch(error => {
          console.log(error);
        });

      if (record) {
        dispatch({ type: USERINFO, payload: record });
      }
      dispatch(asyncActionFinish());
      return record;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const setIsMobile = val => {
  return dispatch => {
    dispatch({
      type: IS_MOBILE,
      payload: val,
    });
  };
};

export const setSubmitQuestionResult = val => {
  return dispatch => {
    dispatch({
      type: SUBMIT_QUESTION_RESULT,
      payload: val,
    });
  };
};

export const setPreSubmitCheck = val => {
  return dispatch => {
    dispatch({
      type: PRE_SUBMIT_CHECK,
      payload: val,
    });
  };
};

const setAccount = data => ({
  type: SET_ACCOUNT,
  data,
});

const setCkid = data => ({
  type: SET_CKID,
  data,
});

export const getAccountReuslt = shakleeId =>
  fetch(`/account/${shakleeId}`)
    .then(response => response.json())
    .catch(error => console.error(error));

export const getDecryptCkidReuslt = ckid =>
  fetch(`/util/decrypt?shaklee_id=${ckid}`)
    .then(response => response.json())
    .catch(error => console.error(error));

export const getAccount = shakleeId => async dispatch => {
  try {
    const account = await getAccountReuslt(shakleeId);
    dispatch(setAccount(account));
  } catch (error) {
    dispatch(asyncActionError(error.message));
  }
};

const setBanner = data => ({
  type: SET_BANNER,
  data,
});

const getBannerReuslt = () =>
  fetch('/banner/get')
    .then(response => response.json())
    .catch(error => console.error(error));

export const getBanner = () => async dispatch => {
  try {
    dispatch(asyncActionStart());
    const account = await getBannerReuslt();
    dispatch(asyncActionFinish());
    dispatch(setBanner(account));
  } catch (error) {
    dispatch(asyncActionError(error.message));
  }
};

export const updateAssessment = (optIn, history) => async dispatch => {
	try {
		dispatch(asyncActionStart());
		const healthProfile = history.health_profile_id;
		if(healthProfile) {
			let postData = {
				opt_in: optIn,
				health_profile_id: history.health_profile_id
			};
			const result = await fetch(UPDATE_HISTORY, {
				method: 'POST',
				body: JSON.stringify(postData),
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
			})
				.then(response => {
					return response.json();
				})
				.then(record => {
					if(record.status === 0) {
						history.opt_in = optIn;
						dispatch(setHistory(history));
					} else {
						console.warn('bad status: ', result.status, ':', result.message);
						result.json = JSON.stringify(postData);
						throw result;
					}
				})
				.catch(err => {
					throw err;
				});
			await dispatch(asyncActionFinish());
		}
	} catch (error) {
		dispatch(asyncActionError(error.message));
	}
};
