import React, { memo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FileDrop } from 'react-file-drop'
import { Row } from 'react-bootstrap';
import { getfilesize } from 'assets/js/helper';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import {
  validateQuestions,
  resetValidateQuestion,
  fileUpload,
  fileDelete,
} from 'components/questions/QuestionActions';
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
} from 'assets/js/helper';
import { useToast } from 'components/Toast';


const UpLoad = ({ selectedAnswer, name, updateAnswer, page }) => {
  const dispatch = useDispatch();
  const { asyncError, asyncMessage } = useSelector(state => state.async);
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const { addToast } = useToast();

  const [file, setFile] = useState(null);
  const [fileSection, setFileSection] = useState(false);

  const [feError, setFeError] = useState('');

  const fileInputRef = useRef(null);
  const onFileInputChange = event => {
    handleFile(event.target.files[0])
  }
  const handleDrop = files => {
    handleFile(files[0]);
  };

  const handleFile = async files => {
    fileInputRef.current.value = "";
    setFile(files);
    setFileSection(true);
    setFeError('');
    if (files && files.name && files.size >= 0) {
      dispatch(resetValidateQuestion());
      const validate_question = await dispatch(
        validateQuestions(
          name,
          { name: files.name, size: files.size },
          false,
          t
        )
      );
      const { error, error_message } = validate_question[name] || {};
      if (error) {
        setFeError(error_message);
        dispatch(pushInteractionToDataLayer({
          eventCategory: 'Quiz',
          eventAction: 'Op_F5b: dna_upload_error',
          eventLabel: 'dna_upload_error',
        }))
        return;
      }

      setLoad(true);
      const Uuid = await dispatch(fileUpload(files, t));
      if (Uuid) {
        setLoad(false);
        updateAnswer(name, Uuid, page);
        setLocalStorage('fileUploadName', files.name);
        setLocalStorage('fileUploadSize', files.size);
        addToast('File uploaded successfully.', 3000, 'toast-wrap-upload')
      } else {
        setLoad(false);
      }
    }
  }

  const handleClick = () => {
    fileInputRef.current.click();
  }

  const deleteFile = () => {
    setFileSection(false);
    if (!feError && !asyncError) {
      updateAnswer(name, null, page);
      dispatch(fileDelete(selectedAnswer));
      removeLocalStorage('fileUploadName');
      removeLocalStorage('fileUploadSize');
    }
  }

  useEffect(() => {
    const name = getLocalStorage('fileUploadName') || '';
    const size = getLocalStorage('fileUploadSize') || -1;
    if (name && size) {
      setFileSection(true);
      setFile({ name, size });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (asyncError) {
      dispatch(pushInteractionToDataLayer({
        eventCategory: 'Quiz',
        eventAction: 'Op_F5b: dna_upload_error',
        eventLabel: 'dna_upload_error',
      }))
    }
  }, [asyncError, dispatch]);
  return (
    <Row className="answer">
      <FileDrop onDrop={handleDrop}>
        <div className="upload-drag">
          <div className="upload-container">
            <input
              className="upload-container-input"
              onChange={onFileInputChange}
              ref={fileInputRef}
              type="file"
            />
            {!fileSection && <div className="upload-text" onClick={handleClick}>
              <div>Drag and drop a ( .txt ) file here</div>
              <div>or</div>
            </div>}



            {fileSection && <div className="upload-file-container">
              <div className="upload-file">
                <div className="upload-file-left">
                  {load && <div className="loader"></div>}
                  {!load && !feError && !asyncError &&
                    <img
                      className="complete"
                      src="https://images.shaklee.com/meology/icons/Checkmark.svg"
                      alt="complete"
                    />
                  }
                  <div className="information">
                    {file && file.name && <div className="name">{file.name}</div>}
                    {file && file.size >= 0 && <div className="size">{getfilesize(file.size)}</div>}
                  </div>
                </div>
                <div className="upload-file-right" role="button" tabIndex="0" aria-label="Delete your file">
                  {!load &&
                    <img
                      className="close"
                      src="https://images.shaklee.com/meology/icons/Close.svg"
                      alt="close"
                      onClick={deleteFile}
                    />
                  }
                </div>
              </div>
              {(feError || asyncError) && (
                <div className="error-message">
                  <div className="arrow"></div>
                  <span>{feError || asyncMessage}</span>
                </div>
              )}
            </div>}

            <button
              className="upload-btn"
              onClick={handleClick}
              disabled={load}
              tabIndex="0"
              title={`${fileSection ? 'Upload your new DNA file' : 'Upload your DNA file'}`}
              aria-label={`${fileSection ? 'Upload your new DNA file' : 'Upload your DNA file'}`}
            >
              {fileSection ? t('questions.dna_id.upload.title') : 'Upload a file'}
            </button>
            <div className="tip">
              Importing requires .txt format. Max file size 25 MB
            </div>
          </div>
        </div>
      </FileDrop>
    </Row>
  );
};

export default memo(UpLoad);
