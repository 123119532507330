import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const PersonalSection = ({ t, landingPage, history, location, isMobile }) => {
  const dispatch = useDispatch();
  const [asmParam, setASMParam] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setASMParam(urlParams.get('asm'));
  }, [])
  return (
    <div className="personal-container">
      <div className="personal-section" style={{ backgroundImage: `url(${isMobile ? t('ui.landing.image.module-4.mobile') : t('ui.landing.image.module-hero-600')})` }}>
        <div className="personal-section-text">
          <div className="personal-section-text-header">
            {t('ui.landing_page_1.personalization_headline')}
          </div>
          <div className="personal-section-text-context">
            <Trans>{t('ui.landing_page_1.personalization_headline.copy_1a')}</Trans>
          </div>
          <br />
          <div className="personal-section-text-context">
            <Trans>{t('ui.landing_page_1.personalization_headline.copy_1b')}</Trans>
          </div>
          <br />
          <div className="personal-section-text-context">
            <Trans>{t('ui.landing_page_1.personalization_headline.copy_1c')}</Trans>
          </div>
          {(landingPage === 1 || landingPage === 3) && !asmParam && (
            <button
              type="button"
              className="home-button-1"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: '/assessment',
                })

                // analytics
                dispatch(
                  pushInteractionToDataLayer({
                    eventCategory: 'Meology',
                    eventAction: "Let's Do This",
                    eventLabel: 'home page personal section',
                  })
                );
              }
              }
            >
              {t('ui.landing_page_1.cta_button_4')}
            </button>
          )}
          {(landingPage === 2 || landingPage === 4) && !asmParam && (
            <button
              type="button"
              className="home-button-1"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: '/assessment',
                })
              }
              }
            >
              {t('ui.landing_page_2.cta_button_1')}
            </button>
          )}
          {(landingPage === 5 || landingPage === 6) && !asmParam && (
            <button
              type="button"
              className="home-button-1"
              onClick={() => {
                history.push({
                  ...location,
                  pathname: '/reco',
                })
                window.scrollTo(0, 0);

                // analytics
                dispatch(
                  pushInteractionToDataLayer({
                    eventCategory: 'Meology',
                    eventAction: "View Personalized Plan",
                    eventLabel: 'home page personal section',
                  })
                );
              }
              }
            >
              {t('ui.landing_page_1.cta_button_3')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
};

export default PersonalSection;
