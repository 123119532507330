import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';

import RenderInput from './RenderInput';

const Item = props => {
  const { options, option } = props;
  const { validate_question } = useSelector(state => state.QuestionReducer);
  const { error, error_type, error_message } =
    (validate_question && validate_question[option]) || {};

  const hasInputError = useMemo(
    () => error && error_type === 'input' && options.length > 1,
    [error, error_type, options]
  );
  return (
    <Row className="answer input-group">
      <RenderInput
        {...props}
        showError={hasInputError}
        error_message={error_message}
      />
    </Row>
  );
};

const RenderInputGroup = props => {
  const { options } = props;
  if (options.length > 0) {
    return (
      <div className="answer-wrap">
        {options.map(({ answer }, index) => (
          <Item key={index} {...{ ...props, option: answer }} />
        ))}
      </div>
    );
  } else {
    return <Item {...props} />;
  }
};

export default memo(RenderInputGroup);
