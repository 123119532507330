import {
  ADD_MEOLOGY_EVENT,
  PUSH_TO_DATALAYER,
  RESET_DATALAYER,
  ADD_NAVIGATION_EVENT,
  ADD_INTERACTION_EVENT,
  ADD_PRODUCT_EVENT,
  ADD_TO_CART_EVENT,
  ADD_GA4_EVENT,
} from './analyticsConstants';
import { getCurrentEventCategory } from 'assets/js/helper';

// Meology event
const addMeologyEvent = (data = {}) => ({
  type: ADD_MEOLOGY_EVENT,
  data,
});
export const pushToDataLayer = (data = {}) => dispatch => {
  dispatch(addMeologyEvent(data));
  dispatch({ type: PUSH_TO_DATALAYER });
};

// Navigation event
const addNavigationEvent = (data = {}) => ({
  type: ADD_NAVIGATION_EVENT,
  data,
});
export const pushNavigationToDataLayer = (data = {}) => dispatch => {
  dispatch(addNavigationEvent(data));
  dispatch({ type: PUSH_TO_DATALAYER });
};

// Interaction event
const addInteractionEvent = (data = {}) => ({
  type: ADD_INTERACTION_EVENT,
  data,
});
export const pushInteractionToDataLayer = (data = {}) => dispatch => {
  const eventCategory = getCurrentEventCategory();

  dispatch(
    addInteractionEvent({
      eventCategory,
      ...data,
    })
  );
  dispatch({ type: PUSH_TO_DATALAYER });
};

// Product event
const addProductEvent = (data = {}) => ({ type: ADD_PRODUCT_EVENT, data });
export const pushProductToDatalayer = (data = {}) => dispatch => {
  dispatch(addProductEvent(data));
  dispatch({ type: PUSH_TO_DATALAYER });
};

// Add to cart event
const addToCartEvent = (data = {}) => ({ type: ADD_TO_CART_EVENT, data });
export const pushCartToDatalayer = (data = {}) => dispatch => {
  dispatch(addToCartEvent(data));
  dispatch({ type: PUSH_TO_DATALAYER });
};
// Add to cart event
const addGA4Event = (data = {}) => ({ type: ADD_GA4_EVENT, data });
export const pushGA4DataToDatalayer = (data = {}) => dispatch => {
  dispatch(addGA4Event(data));
  dispatch({ type: PUSH_TO_DATALAYER });
};

export const resetDataLayer = () => ({ type: RESET_DATALAYER });

export const getDataLayer = state => state.analytics.dataLayer;
