import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  useLocation,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import {
  getUrlVars,
  isEmpty,
  URL_TYPES,
  useWindowSize,
} from 'assets/js/helper';
import {
  useHybrisUrlParams,
  useHybrisHost,
  getImpersonation,
} from 'assets/js/hooks';
import { useTranslation } from 'react-i18next';
import {
  updateCountry,
  updateLanguage,
} from 'components/userProfile/userActions';
import { getBanner } from 'components/questions/QuestionActions';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import PromotionBanner from 'components/header/PromotionBanner';
import SherpaBanner from 'components/sherpaBanner/SherpaBanner';

const Header = ({ setHeaderRef, isCalledRecomendation }) => {
  const headerRef = useRef(null);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    isUserLoggedin,
    countries,
    country,
    languages,
    lang,
    rankId,
  } = useSelector(state => state.user);
  const { account } = useSelector(state => state.QuestionReducer);
  const { data: { account_type } = {} } = account || {};
  const { width: windowWidth } = useWindowSize();
  const isChanged = useMemo(() => windowWidth < 768, [windowWidth]);
  const [isOpen, setIsOpen] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const sdi = urlParams.get('sdi');
  const hpId = urlParams.get('hpId');
  const { id } = useParams();
  const logoBgMap = {
    en: 'https://images.shaklee.com/meology/icons/Shaklee_Logo_ENGLISH.png',
    zh: 'https://images.shaklee.com/meology/icons/Shaklee_Logo_ENGLISH.png',
    es: 'https://images.shaklee.com/meology/icons/Shaklee_Logo_SPANISH.png',
    fr: 'https://images.shaklee.com/meology/icons/Shaklee_Logo_FRENCH.png',
  };
  const logoBg = logoBgMap[lang];
  const { banner } = useSelector(state => state.QuestionReducer);
  const { banners } = banner || {};
  const { isExact: showBanner } = useRouteMatch('/') || {};
  useEffect(() => {
    if (!showBanner) return;
    dispatch(getBanner());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const history = useHistory();
  let match = useRouteMatch('/reco');
  const isReco = useMemo(() => {
    if (!match) return;
    return match.path.includes('reco');
  }, [match]);
  const hideDropdown = useMemo(() => isUserLoggedin || isReco, [
    isUserLoggedin,
    isReco,
  ]);
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);

  const isAboveDistributor = useMemo(() => rankId && Number(rankId) >= 300, [
    rankId,
  ]);
  const isAboveMember = useMemo(() => rankId && Number(rankId) >= 12, [rankId]);
  const isImpersonateMember = useMemo(
    () => account_type && account_type.toUpperCase() === 'MEMBER',
    [account_type]
  );
  const isImpersonateDistributor = useMemo(
    () => account_type && account_type.toUpperCase() === 'DISTRIBUTOR',
    [account_type]
  );
  const showMember = useMemo(
    () =>
      sdi ? !isImpersonateMember && !isImpersonateDistributor : !isAboveMember,
    [isAboveMember, isImpersonateDistributor, isImpersonateMember, sdi]
  );
  const showDistributor = useMemo(
    () => (sdi ? !isImpersonateDistributor : !isAboveDistributor),
    [isAboveDistributor, isImpersonateDistributor, sdi]
  );

  const changeUrlParams = useCallback(
    (country, language) => {
      urlParams.set('lang', `${language}_${country}`);
      urlParams.set('country', country);
      history.push({
        ...location,
        search: urlParams.toString(),
      });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const changeI18nLanguage = useCallback((country, language) => {
    if (country === 'US') {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(language + '-' + country);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lang && country) {
      if (sdi || hpId || id) return;
      // change according to cookie
      changeI18nLanguage(country, lang);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeLanguage = useCallback(
    (country, language) => {
      changeUrlParams(country, language);
      changeI18nLanguage(country, language);
      dispatch(updateLanguage({ country, language }));
      if (isCalledRecomendation && isCalledRecomendation.current) {
        isCalledRecomendation.current = false;
      }
    },
    [changeI18nLanguage, changeUrlParams, dispatch, isCalledRecomendation]
  );

  const changeCountry = useCallback(
    (country, language) => {
      changeUrlParams(country, language);
      changeI18nLanguage(country, language);
      dispatch(updateCountry({ country, language }));
      if (isCalledRecomendation && isCalledRecomendation.current) {
        isCalledRecomendation.current = false;
      }
    },
    [changeI18nLanguage, changeUrlParams, dispatch, isCalledRecomendation]
  );
  const getUrl = useCallback((country, language) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', `${language}_${country}`);
    urlParams.set('country', country);
    return '/?' + urlParams.toString() + window.location.hash;
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setShowCountry(false);
    setShowLang(false);
  }, []);

  useEffect(() => {
    if (!setHeaderRef) return;
    setHeaderRef(headerRef);
  }, [headerRef, setHeaderRef]);

  const handleHybrisLinkClick = event => {
    const eventLabel = event.target.getAttribute('href') || '';
    dispatch(
      pushInteractionToDataLayer({
        eventAction: 'Header Link',
        eventLabel,
      })
    );
  };

  const [pageTitle, setPageTitle] = useState('home page');
  const { isExact: isMeologyIntro } = useRouteMatch('/meology') || {};
  const { isExact: isMeologyKidsIntro } = useRouteMatch('/meologykids') || {};
  const titleRef = useRef(null);

  const { isCSR, isBL, isSponsor, isImpersonation } = getImpersonation();
  const meologyHomeUrl = useMemo(() => {
    const searchParams = new URLSearchParams();
    const { type, mode, site } = getUrlVars();

    if (lang) {
      if (lang === 'zh') {
        searchParams.append('lang', 'zh_CN');
      } else {
        searchParams.append('lang', `${lang}_${country}`);
      }
    }

    if (country) {
      searchParams.append('country', country);
    }

    if (isBL) {
      searchParams.append('asm', 'true');
    }

    if (isCSR) {
      searchParams.append('csm', 'true');
    }

    if (URL_TYPES.includes(type) && mode?.startsWith('restore')) {
      searchParams.append('type', type);
      searchParams.append('mode', mode);
    }

    if (!isEmpty(site)) {
      searchParams.append('site', site);
    }

    return `/?${searchParams.toString()}`;
  }, [country, lang, isBL, isCSR]);

  useEffect(() => {
    if (isReco) {
      setPageTitle('Result page');
    } else if (isMeologyIntro) {
      setPageTitle('Meology intro page');
    } else if (isMeologyKidsIntro) {
      setPageTitle('Meology kids intro page');
    }
    if (titleRef.current) {
      titleRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Log = () => (
    <ul className="app-header-log">
      {!isImpersonation && (
        <li>
          <a
            className={`glyphicon${
              isChanged ? ' glyphicon-log-in' : ' glyphicon-user'
            }`}
            href={
              isUserLoggedin ? '/Logout' : '/login/?' + urlParams.toString()
            }
          >
            {isUserLoggedin ? t('ui.sign_out') : t('questions.sign_in')}
          </a>
        </li>
      )}
      <li>
        <a
          className="glyphicon glyphicon-shopping-cart"
          href={`${hybrisHost}${
            isUserLoggedin ? '/securedResource' : ''
          }/cart${hybrisUrlParams}`}
          onClick={handleHybrisLinkClick}
        >
          {!isChanged && t('ui.header-cart')}
        </a>
      </li>
    </ul>
  );

  const Nav = () => {
    return (
      <ul className={`app-header-nav${isChanged ? '-fixed' : ''}`}>
        <li>
          <a
            href={`${hybrisHost}${
              isUserLoggedin ? '/securedResource' : ''
            }/shop${hybrisUrlParams}`}
            onClick={handleHybrisLinkClick}
          >
            {t('ui.shop')}
          </a>
        </li>
        {!isUserLoggedin && (
          <li>
            <a
              href={`${hybrisHost}${isUserLoggedin ? '/securedResource' : ''}/${
                country === 'US' ? 'about-us' : 'the-shaklee-difference'
              }${hybrisUrlParams}`}
              onClick={handleHybrisLinkClick}
            >
              {t('ui.about_us')}
            </a>
          </li>
        )}
        {!isSponsor && (
          <li>
            <a href={meologyHomeUrl} onClick={handleHybrisLinkClick}>
              {t('ui.healthprint')}
            </a>
          </li>
        )}
        {isAboveDistributor && (
          <li>
            <a
              href={process.env.REACT_APP_SALESFORCE_DOMAIN}
              onClick={handleHybrisLinkClick}
            >
              {t('ui.my_business')}
            </a>
          </li>
        )}
        {showMember && (
          <li>
            <a
              href={`${hybrisHost}/member-benefits/join-options${hybrisUrlParams}`}
              onClick={handleHybrisLinkClick}
            >
              {t('ui.member_benefits')}
            </a>
          </li>
        )}
        {showDistributor && (
          <li>
            <a
              href={`${hybrisHost}${
                isUserLoggedin ? '/securedResource' : ''
              }/distributor-benefits${hybrisUrlParams}`}
              onClick={handleHybrisLinkClick}
            >
              {t('ui.become_dist')}
            </a>
          </li>
        )}
      </ul>
    );
  };

  const Dropdown = () => (
    <>
      <NavDropdown
        id={country}
        className={hideDropdown ? 'hide-dropdown' : ''}
        title={[
          <img key="0" src={t('ui.flag-' + country)} alt="country" />,
          <strong key="1">{country}</strong>,
        ]}
      >
        {!hideDropdown &&
          countries.map(variant => (
            <NavDropdown.Item
              key={variant}
              href={getUrl(variant, languages[0])} // refresh this page to reset cookies
              className="country"
              disabled={variant === country}
            >
              <img src={t('ui.flag-' + variant)} alt={variant} />
              {t('ui.country-' + variant)}
            </NavDropdown.Item>
          ))}
      </NavDropdown>
      <NavDropdown className="dropdown-lang" id={lang} title={t('ui.' + lang)}>
        {languages.map(variant => (
          <NavDropdown.Item
            key={variant}
            onClick={() => changeLanguage(country, variant)}
            disabled={variant === lang}
            className={variant === lang ? 'on' : ''}
          >
            {t('ui.' + variant)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </>
  );

  const Submenu = ({
    data,
    current,
    title,
    type,
    handleHeadClick,
    handleListClick,
  }) => (
    <div className="app-header-submenu">
      <div className="app-header-submenu-head" onClick={handleHeadClick}>
        {title}
      </div>
      <ul className="app-header-submenu-list">
        {data.map(item => (
          <li
            key={item}
            className={current === item ? 'disabled' : ''}
            onClick={() => {
              if (current !== item) {
                if (type === 'country') {
                  handleListClick(item, lang);
                } else {
                  handleListClick(country, item);
                }
                handleClose();
              }
            }}
          >
            {type && <img src={t('ui.flag-' + item)} alt={item} />}
            {type === 'country' ? t('ui.country-' + item) : t('ui.' + item)}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <header
        className={`app-header${isChanged ? ' changed' : ''}`}
        ref={headerRef}
      >
        <Helmet>
          <title>{t('ui.page_title')}</title>
          <meta name="description" content={t('ui.meta_description')} />
        </Helmet>
        <a href="#main-content" className="skip-to-content">
          Skip to main content
        </a>
        <span ref={titleRef} className="sr-only" tabIndex="-1">
          {pageTitle}
        </span>
        {showBanner && banners && <PromotionBanner banners={banners} />}
        {!isChanged && (
          <div className="app-header-top-align">
            <div className="app-header-top">
              <Log />
              <Dropdown />
            </div>
          </div>
        )}
        <div className="app-header-bottom">
          <div className="app-header-bottom-left">
            {isChanged && (
              <button
                type="button"
                className="app-header-toggle"
                onClick={() => setIsOpen(true)}
              ></button>
            )}
            <h1 className="mb-0">
              <a
                className="app-header-logo"
                href={
                  hybrisHost +
                  (isUserLoggedin ? '/securedResource/' : '/') +
                  hybrisUrlParams
                }
                style={{
                  backgroundImage: `url(${logoBg})`,
                }}
                onClick={handleHybrisLinkClick}
              >
                Shaklee
              </a>
            </h1>
          </div>
          {!isChanged && <Nav />}
          {isChanged && <Log />}
        </div>
        {isChanged && (
          <div className={`app-header-menu${isOpen ? ' is-open' : ''}`}>
            <div className="app-header-menu-top">
              <div>{t('ui.menu')}</div>
              <button
                type="button"
                className="app-header-menu-close glyphicon glyphicon-remove"
                onClick={handleClose}
              ></button>
            </div>
            <Nav />
            {hideDropdown && (
              <div className="app-header-menu-country">
                {<img src={t('ui.flag-' + country)} alt={country} />}
                {t('ui.country-' + country)}
              </div>
            )}
            <ul className="app-header-menu-lang">
              {!hideDropdown && (
                <li onClick={() => setShowCountry(true)}>
                  {t('ui.menu.choose_country')}
                </li>
              )}
              <li onClick={() => setShowLang(true)}>
                {t('ui.menu.lang_select')}
              </li>
            </ul>
            {showCountry && (
              <Submenu
                data={countries}
                current={country}
                title={t('ui.menu.choose_country')}
                type="country"
                handleListClick={changeCountry}
                handleHeadClick={() => setShowCountry(false)}
              />
            )}
            {showLang && (
              <Submenu
                data={languages}
                current={lang}
                title={t('ui.menu.lang_select')}
                handleListClick={changeLanguage}
                handleHeadClick={() => setShowLang(false)}
              />
            )}
          </div>
        )}
      </header>
      {!isReco && <SherpaBanner headerRef={headerRef} />}
    </>
  );
};
export default memo(Header);
