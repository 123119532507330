import React, { useEffect } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
import {
  getLocalQuestion,
  isEmpty,
  isChecked,
} from 'assets/js/helper';
// import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
// import { getEventAction, getEventLabel } from 'components/analytics/quizAnalytics';

const Radiobutton = props => {
  // const dispatch = useDispatch();
  const {
    name,
    options,
    selectedAnswer,
    t,
    isMobile,
    updateAnswer,
  } = props;

  // if can't get current answer then get from local storage
  useEffect(() => {
    if (isEmpty(selectedAnswer)) {
      const questionsSelectedAnswers = getLocalQuestion() || {};
      const answer = questionsSelectedAnswers[name];
      if (!isEmpty(answer)) {
        updateAnswer(name, answer);
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  let tinyOptions = ['vegetables', 'fruits', 'dairy', 'dairy_substitute'];
  let rowClass = name;
  if (!isMobile) {
    if (options.length > 3 && options.length % 2 === 1) {
      rowClass = rowClass + ' justify-content-start';
    }
    if (options.length > 4) {
      rowClass = rowClass + ' options-4-plus';
    }
    if (options.length === 3) {
      rowClass = rowClass + ' options-3';
    }
    if (tinyOptions.includes(name)) {
      rowClass = rowClass + ' options-tiny';
    }
  }

  let columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-12';
  if (isMobile) {
    columnClass = 'col-xs-12 col-sm-12 col-md-6 col-lg-4';
  } else if (options.length >= 5) {
    columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-4';
  } else if (options.length === 4) {
    columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-6';
  }

  // const pushAnalytics = (question, answer) => {
  //   const eventAction = getEventAction(question);
  //   const eventLabel = getEventLabel(question, answer);
  //   dispatch(
  //     pushInteractionToDataLayer({
  //       eventCategory: 'Quiz',
  //       eventAction,
  //       eventLabel,
  //     })
  //   );
  // };

  const fieldChange = async e => {
    // click analytics
    // pushAnalytics(name, e.target.value);

    e.persist();
    await props.updateAnswer(props.name, e.target.value, props.page);
  };
  return (
    <Row className={'answer radio-container ' + rowClass} role="radiogroup">
      {options.map((option, index) => {
        if (option.restrict) {
          return null;
        }
        const ifChecked = isChecked(option.answer, selectedAnswer);
        return (
          <label key={index} className={columnClass + ' round-label'}>
            <input
              type="radio"
              className="radio"
              name={name}
              value={option.answer}
              onChange={fieldChange}
              checked={ifChecked}
            />
            <span
              className="label"
              role="radio"
              tabIndex="0"
              aria-checked={ifChecked}
              onKeyPress={(e) => {
                if (e.charCode === 13 || e.charCode === 32) {
                  e.target.checked = !ifChecked;
                  e.target.value = option.answer;
                  fieldChange(e)
                }
              }}
            >
              <span className="text">
                <Trans>
                  {t('answers.' + name + '.' + option.answer + '.title')}
                </Trans>
              </span>
            </span>
          </label>
        );
      })}
    </Row>
  );
};

export default withTranslation()(Radiobutton);
