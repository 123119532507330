import React, { memo, useState } from 'react';
import cx from 'classnames';
import { Modal } from 'react-bootstrap';

const IconModal = ({
  isOpen,
  closeModal,
  content,
  handleIcon,
  index,
  iconList,
  isEdit,
  t,
}) => {
  const [currentIcon, setCurrentIcon] = useState('pick-icon');
  const [activeIndex, setActiveIndex] = useState(false);

  // detemine if the keys are 'Enter' or 'Space'
  const isEnterOrSpacePress = (e) => (e.key === 'Enter' || e.key === ' ');

  const handleKeyDownIcon = (e, index) => {

    // if it's not enter or space key press, the function shouldn't execute
    if (!isEnterOrSpacePress(e)) {
      return;
    }
    const children = e.target.children;
    const currentValue = children && children.length && children[0].value;

    if (currentValue) {
      // Access and manipulate the input element
      setActiveIndex(index)
      setCurrentIcon(currentValue);
    }
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={closeModal}
        className={`instructions-modal icon-modal ${content}`}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-container">
            <div>
              <div className="modal-body-title">{t('ui.recommendation.meology_kids_add_to_cart_title15')}</div>
              <div className="modal-body-text modal-icon">
                {iconList?.map((icon, index) => (
                  <label
                    key={index}
                    role="button"
                    tabIndex="0"
                    className={cx('icon', {
                      active: activeIndex === index,
                    })}
                    onClick={() => setActiveIndex(index)}
                    onKeyDown={(e) => handleKeyDownIcon(e, index)}
                  >
                    <input
                      type="radio"
                      className="radio"
                      name="icon"
                      value={icon}
                      checked={icon === currentIcon}
                      onChange={event => {
                        setCurrentIcon(event.target.value);
                      }}
                    />
                    <div className="label">
                      <img
                        src={
                          icon
                            ? `https://images.shaklee.com/meology/sachet_icons/${icon}.svg`
                            : 'https://images.shaklee.com/meology/icons/smile.png'
                        }
                        alt={`${icon ? icon : 'smile'}`}
                      />
                    </div>
                  </label>
                ))}
              </div>
            </div>
            <div>
              <button
                tabIndex="0"
                aria-label="save"
                className={cx('instructions-modal-back', {
                  disabled: !activeIndex && activeIndex !== 0,
                })}
                onClick={() => {
                  if (activeIndex || activeIndex === 0) {
                    handleIcon(currentIcon, index);
                    closeModal();

                    // analytics
                    // dispatch(
                    //   pushInteractionToDataLayer({
                    //     event: 'interactionEvent',
                    //     eventCategory: 'Meology Kids',
                    //     eventAction: 'Choose Icon',
                    //     eventLabel: currentIcon,
                    //   })
                    // );
                  }
                }}
              >
                {t('ui.recommendation.meology_kids_add_to_cart_title13')}
              </button>
              <br />
              <br />
              <button className="btn btn-link cancel" onClick={closeModal}
                tabIndex="0"
                aria-label="cancel">
                {isEdit ? 'Go Back' : t('ui.recommendation.meology_kids_edit_pop_up_title10')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(IconModal);
