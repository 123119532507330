import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import Item from './Item';

const Container = ({ toasts, delay, className }) => {
  return createPortal(
    <div className={`toast-wrap${className ? ` ${className}` : ''}`}>
      <ul className="toast-list">
        {toasts.map(({ id, content }) => (
          <Item key={id} id={id} delay={delay}>
            {content}
          </Item>
        ))}
      </ul>
    </div>,
    document.body
  );
};

export default memo(Container);
