import React, {
    memo
  } from 'react';
  import { Modal } from 'react-bootstrap';
  import FAQPannel from 'components/faqs/FAQPannel'
  import {FAQ_TYPE} from 'components/faqs/faqConfig'
  import { useTranslation } from 'react-i18next';

const LearnMoreModal = ({show, closeModal, ...rest}) => {
    const { t } = useTranslation();
    return (
        <Modal className="learn-more-modal"
            show={show}
            size="xl"
            centered
            onHide={closeModal}
            {...rest}
        >
            <h3 className="learn-more-modal__title">
                {t('ui.recommendation.learn_more_modal.title')}
            </h3>
	        <div className="green-border"></div>
            <FAQPannel type={FAQ_TYPE.prenatal} className='accordion-customized' ></FAQPannel>
            <button type="button" className="modal-button-close" onClick={closeModal}>
                X
            </button>
        </Modal>
    )
};

export default memo(LearnMoreModal);
