import React, { memo } from 'react';
import cx from 'classnames';

const Video = ({ id, title, className }) => {
  return (
    <div className={cx('video-wrap', { [className]: className })}>
      <iframe
        title={title}
        src={`https://player.vimeo.com/video/${id}`}
        width="100%"
        allowFullScreen
        allow="autoplay; fullscreen; picture-in-picture"
      ></iframe>
    </div>
  );
};

export default memo(Video);
