import React, { memo } from 'react';
import classnames from 'classnames';

const Award = ({ text, awards, className }) => {
  return (
    <div
      className={classnames('award-wrap', {
        [`${className}`]: className,
      })}
    >
      <div className="award">
        <h2
          className="award-text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></h2>
        <ul className="award-list">
          {awards?.map((item, index) => (
            <li key={index}>
              <img
                className={classnames({
                  'award-biba': item === 'BIBA',
                  'award-ptpa': item === 'PTPA',
                  'award-tnpc': item === 'TNPC',
                })}
                src={`https://images.shaklee.com/meology/marketing/awards_${item}.png`}
                onError={event => (event.target.style.display = 'none')}
                alt={item}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default memo(Award);
