import React, { memo, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  usePrenatalStatus,
  useOrderUrl,
  useMeologyHistory,
  getImpersonation,
} from 'assets/js/hooks';
import LearnMoreModal from 'components/results/Recommendations/LearnMoreModal';

const Next = ({ onConfirm, trimester }) => {
  const { t } = useTranslation();
  const {phaseStatus,prenatalStep, reproductiveStatus, isPreviewPregnancy } = usePrenatalStatus()
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const {hasMeologyPrenatalOrderEntry, hasPurchasedPrenatalConceptionPack, isPrenatalToRegular} = useMeologyHistory();
  const labelStep = useMemo(() => {
    if(isPreviewPregnancy) {
      return 'TRYING'
    } else {
      return prenatalStep
    }
  } , [isPreviewPregnancy, prenatalStep])

  const { isImpersonation, isSponsor } = getImpersonation();
  
  const onClick = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onConfirm?.(labelStep);
  }, [onConfirm, labelStep]);
  
  const onLink = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onConfirm?.('PREGNANT');
  }, [onConfirm]);

  const showBanner = useMemo(() => {
    if (isPrenatalToRegular) return {};

    let main = false;
    let subBanner = false;

    if (phaseStatus.current) {
      subBanner = hasMeologyPrenatalOrderEntry;

      if (
        reproductiveStatus === 'TRYING' &&
        hasPurchasedPrenatalConceptionPack
      ) {
        main = true;
      }

      if (reproductiveStatus === 'PREGNANT' && trimester >= 2) {
        main = true;
      }

      if (
        reproductiveStatus === 'POSTPARTUM' &&
        (!isImpersonation || isSponsor)
      ) {
        main = true;
      }
    }

    if (isPreviewPregnancy) {
      main = true;
    }

    return { main, subBanner };
  }, [
    isPrenatalToRegular,
    phaseStatus,
    isPreviewPregnancy,
    hasMeologyPrenatalOrderEntry,
    reproductiveStatus,
    hasPurchasedPrenatalConceptionPack,
    trimester,
    isImpersonation,
    isSponsor,
  ]);

  const autoshipLink = useOrderUrl()
  const labelMap = useMemo(() =>({
    TRYING: {
      img1: 'https://images.shaklee.com/meology/prenatal/pregnant_tester.svg',
      img1Class: 'img-1',
      img2: 'https://images.shaklee.com/meology/prenatal/edit_icon.svg',
      span1: t('ui.recommendation.prenatal_next.description_1'),
      span2: t('ui.recommendation.prenatal_next.description_2', {href: autoshipLink}),
      btn: t('ui.recommendation.prenatal_next.btn_1'),
    },
    PREGNANT: {
      img1: 'https://images.shaklee.com/meology/prenatal/Baby.svg',
      img1Class: 'img-2',
      img2: 'https://images.shaklee.com/meology/prenatal/edit_icon.svg',
      span1: t('ui.recommendation.prenatal_next.description_3'),
      span2: t('ui.recommendation.prenatal_next.description_2', {href: autoshipLink}),
      btn: t('ui.recommendation.prenatal_next.btn_2'),
    },
    POSTPARTUM: {
      img1: 'https://images.shaklee.com/meology/prenatal/milk_bottle.svg',
      img1Class: 'img-3',
      img2: 'https://images.shaklee.com/meology/prenatal/edit_icon.svg',
      span1: t('ui.recommendation.prenatal_next.description_4'),
      span2: t('ui.recommendation.prenatal_next.description_2', {href: autoshipLink}),
      link: t('ui.recommendation.prenatal_next.description_link')
    },
  }),[autoshipLink, t])

  const onMore = useCallback(event => {
    const { nodeName, dataset } = event.target;

    if (nodeName === 'U' && dataset.reco === 'learn-more') {
      setShowLearnMoreModal(true);
    }

    event.stopPropagation();
  }, []);

  return (showBanner && (
    <>
      <div className="confirm-container">
        {showBanner.main && <div className="confirm-banner banner-flex">
          <div className="confirm-banner-icon flex-0">
            <img className={labelMap?.[labelStep]?.img1Class} src={labelMap?.[labelStep]?.['img1']} alt="" />
          </div>
          <div className=" banner-flex flex-1">
            {labelMap?.[labelStep]?.['link'] ? (
              <span>
                {labelMap?.[prenatalStep]?.['span1']}
                &emsp;
                <u role="button" tabIndex="0" onClick={onLink}>
                  {labelMap?.[prenatalStep]?.['link']}
                </u>
              </span>
            ) : (
              <span
                onClick={onMore}
                dangerouslySetInnerHTML={{
                  __html: labelMap?.[labelStep]?.['span1'],
                }}
              ></span>
            )}
            {!phaseStatus.past && labelMap?.[labelStep]?.['btn'] && (
              <button className="confirm-banner-btn" onClick={onClick}>
                {labelMap?.[labelStep]?.['btn']}
              </button>
            )}
          </div>
        </div>}
        {showBanner.subBanner && <div className="sub-confirm-banner banner-flex">
          <div className="confirm-banner-icon flex-0">
            <img src={labelMap?.[prenatalStep]?.['img2']} alt="" />
          </div>
          <span
            className="flex-1"
            dangerouslySetInnerHTML={{
              __html: labelMap?.[prenatalStep]?.['span2'],
            }}
          ></span>
        </div>}
      </div>
      <LearnMoreModal
        show={showLearnMoreModal}
        closeModal={()=>{
          setShowLearnMoreModal(false);
      }}
      />
    </>
  ));
};

export default memo(Next);
