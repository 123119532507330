import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getUrlVars } from 'assets/js/helper';
import { useHybrisUrlParams, useHybrisHost } from 'assets/js/hooks';
import { getAccount } from 'components/questions/QuestionActions';

const aTagAttribute = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

function Footer({ isGetAccount }) {
  const { t } = useTranslation();
  const { rankId, lang, country } = useSelector(state => state.user);
  const showGlobal = rankId && Number(rankId) > 5;
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);
  const dispatch = useDispatch();
  const { sdi } = getUrlVars() || {};
  const { account } = useSelector(state => state.QuestionReducer);
  const { data: { account_type } = {} } = account || {};
  const [showReferFriend, setShowReferFriend] = useState(false);

  const part1Urls = useMemo(() => {
    const map = {
      US: [
        {
          title: t('ui.footer-about-shaklee'),
          urls: [
            {
              isHybris: true,
              url: '/about-us',
              text: t('ui.footer-shaklee-diff'),
              superscript: '®',
            },
            {
              isHybris: true,
              url: '/current-offers',
              text: t('ui.footer.current_offers'),
            },
            {
              isHybris: true,
              url: '/refer-friend/give10',
              text: t('ui.refer_friend'),
              show: showReferFriend,
            },
            {
              isHybris: true,
              url: '/shakleecares',
              text: t('ui.footer.shaklee-cares'),
            },
            {
              isHybris: false,
              url: 'https://athletes.shaklee.com/',
              text: t('ui.footer.athletes'),
            },
            {
              isHybris: true,
              url: '/dsa',
              text: t('ui.footer-proud-ethics'),
            },
            {
              isHybris: true,
              url: '/careers',
              text: t('ui.footer-careers'),
            },
            {
              isHybris: true,
              url: '/leadership',
              text: t('ui.footer-leadership'),
            },
          ],
        },
        {
          title: `<span class="sr-only">${t('ui.footer-about-shaklee')}</span>`,
          urls: [
            {
              isHybris: true,
              url: '/no-animal-testing',
              text: t('ui.footer-no-animal-testing'),
            },
            {
              isHybris: true,
              url: '/sustainability',
              text: t('ui.footer-sustainability'),
            },
            {
              isHybris: true,
              url: '/beyond-organic',
              text: t('ui.footer-organic'),
            },
            {
              isHybris: true,
              url: '/non-gmo',
              text: t('ui.footer-no-gmo-policy'),
            },
            {
              isHybris: false,
              url: 'https://events.shaklee.com/global-conference/',
              text: t('ui.footer-global-conference'),
              show: showGlobal,
            },
            {
              isHybris: false,
              url: 'https://healthresource.shaklee.com/',
              text: t('ui.footer-clinical'),
            },
            {
              isHybris: true,
              url: '/accessibility',
              text: t('ui.footer-accessibility'),
            },
          ],
        },
        {
          title: t('ui.footer-support'),
          urls: [
            {
              isHybris: true,
              url: '/contact',
              text: t('ui.footer-customer-service'),
            },
            {
              isHybris: true,
              url: '/shipping-return-policy',
              text: t('ui.footer-shipping-return-policy'),
            },
          ],
        },
        {
          title: t('ui.footer-stay-connected'),
          urls: [
            {
              isHybris: false,
              url: 'http://go.shaklee.com/naturally/',
              text: t('ui.footer-naturally-blog'),
            },
          ],
          urls2: [
            {
              isHybris: false,
              url: 'https://www.facebook.com/ShakleeHQ/',
              text: 'Facebook',
              src: t('ui.facebook-img'),
            },
            {
              isHybris: false,
              url: 'https://twitter.com/ShakleeHQ',
              text: 'Twitter',
              src: t('ui.twitter-img'),
            },
            {
              isHybris: false,
              url: 'https://www.instagram.com/shakleehq/',
              text: 'Instagram',
              src: t('ui.instagram-img'),
            },
            {
              isHybris: false,
              url: 'https://www.pinterest.com/shakleehq/',
              text: 'Pinterest',
              src: t('ui.pinterest-img'),
            },
          ],
        },
      ],
      CA: [
        {
          title: t('ui.footer-about-shaklee'),
          urls: [
            {
              isHybris: true,
              url: '/about-us',
              text: t('ui.footer-shaklee-diff') + '™',
            },
            {
              isHybris: true,
              url: '/current-offers',
              text: t('ui.footer.current_offers'),
            },
            {
              isHybris: true,
              url: '/refer-friend/give10',
              text: t('ui.refer_friend'),
              show: showReferFriend,
            },
            {
              isHybris: false,
              url: 'https://athletes.shaklee.com/',
              text: t('ui.footer.athletes'),
            },
            {
              isHybris: true,
              url: '/beyond-organic',
              text: t('ui.footer-organic'),
            },
            {
              isHybris: true,
              url: '/non-gmo',
              text: t('ui.footer-no-gmo-policy'),
            },
          ],
        },
        {
          title: `<span class="sr-only">${t('ui.footer-about-shaklee')}</span>`,
          urls: [
            {
              isHybris: true,
              url: '/sustainability',
              text: t('ui.footer-sustainability'),
            },
            {
              isHybris: true,
              url: '/leadership',
              text: t('ui.footer-leadership'),
            },
            {
              isHybris: false,
              url: 'https://www.dsa.ca/',
              text: 'Proud Member of the DSA',
            },
            {
              isHybris: true,
              url: '/accessibility',
              text: t('ui.footer-accessibility'),
            },
          ],
        },
        {
          title: t('ui.footer-support'),
          urls: [
            {
              isHybris: true,
              url: '/contact-us',
              text: t('ui.footer-customer-service'),
            },
            {
              isHybris: true,
              url: '/order-charges-shipping-policies',
              text: 'Order Charges and Shipping Policies',
            },
            {
              isHybris: true,
              url: '/refund-return-policy',
              text: 'Refund and Return Policy',
            },
          ],
        },
        {
          title: t('ui.footer-stay-connected'),
          urls: [
            {
              isHybris: false,
              url: 'http://go.shaklee.com/naturally/',
              text: t('ui.footer-naturally-blog'),
            },
          ],
          urls2: [
            {
              isHybris: false,
              url:
                'https://www.facebook.com/Shaklee-Canada-Inc-2132024190404640/',
              text: 'Facebook',
              src: t('ui.facebook-img'),
            },
            {
              isHybris: false,
              url: 'https://twitter.com/shakleehq?lang=en',
              text: 'Twitter',
              src: t('ui.twitter-img'),
            },
            {
              isHybris: false,
              url: 'https://www.instagram.com/shakleecanada/',
              text: 'Instagram',
              src: t('ui.instagram-img'),
            },
            {
              isHybris: false,
              url: 'https://www.pinterest.com/shakleehq/',
              text: 'Pinterest',
              src: t('ui.pinterest-img'),
            },
          ],
        },
      ],
    };
    return map[country];
  }, [country, showGlobal, showReferFriend, t]);

  const part2Urls = useMemo(() => {
    const map = {
      US: [
        {
          col: {
            xs: 12,
            md: 3,
          },
          urls: [
            {
              isHybris: false,
              url: 'https://www.dsa.org/',
              text: t('ui.footer-dsa-member'),
            },
            {
              isHybris: false,
              url:
                'https://www.bbb.org/us/ca/pleasanton/profile/nutrition/shaklee-corporation-1116-11381#bbblogo',
              text: t('ui.footer-bbb'),
            },
          ],
        },
        {
          col: {
            xs: 12,
            md: 4,
          },
          urls: [
            {
              isHybris: true,
              url: '/terms-and-conditions',
              text: t('ui.footer-terms'),
            },
            {
              isHybris: true,
              url: '/Privacy-Security-Policy',
              text: t('ui.footer-privacy'),
            },
            {
              isHybris: true,
              url: '/legal',
              text: t('ui.footer-legal'),
            },
          ],
        },
      ],
      CA: [
        {
          col: {
            xs: 12,
            md: 3,
          },
          urls: [
            {
              isHybris: false,
              url: 'https://www.dsa.ca/',
              text: t('ui.footer-dsa-member'),
            },
            {
              isHybris: false,
              url:
                'https://www.bbb.org/ca/on/burlington/profile/health-products/shaklee-canada-inc-0107-1171',
              text: t('ui.footer-bbb'),
            },
          ],
        },
        {
          col: {
            xs: 12,
            md: 4,
          },
          urls: [
            {
              isHybris: true,
              url: '/terms-and-conditions',
              text: t('ui.footer-terms'),
            },
            {
              isHybris: true,
              url: '/privacy-security-policy',
              text: t('ui.footer-privacy'),
            },
            {
              isHybris: true,
              url: '/legal',
              text: t('ui.footer-legal'),
            },
          ],
        },
      ],
    };
    return map[country];
  }, [country, t]);

  const getHref = useCallback(
    (isHybris, url) => (isHybris ? hybrisHost + url + hybrisUrlParams : url),
    [hybrisHost, hybrisUrlParams]
  );

  const Link = ({ urls, className }) => (
    <ul className={className}>
      {urls.map(({ isHybris, url, text, show, superscript, src }, index) => {
        let href = getHref(isHybris, url);
        if (show !== undefined && !show) return null;
        return (
          <li key={index}>
            <a href={href} title={text} {...(!isHybris && aTagAttribute)}>
              {src ? (
                <img src={src} alt={text} />
              ) : (
                <>
                  {text}
                  {superscript && <sup>{superscript}</sup>}
                </>
              )}
            </a>
          </li>
        );
      })}
    </ul>
  );

  useEffect(() => {
    if (!sdi || !isGetAccount) return;
    dispatch(getAccount(sdi));
  }, [dispatch, isGetAccount, sdi]);

  useEffect(() => {
    // CSR/BL impersonate
    if (account_type) {
      setShowReferFriend(account_type.toUpperCase() === 'MEMBER');
    } else if (!sdi && rankId) {
      setShowReferFriend(Number(rankId) === 12);
    }
  }, [account_type, rankId, sdi]);

  return (
    <footer className="footer">
      <Row>
        {part1Urls.map(({ title, urls, urls2 }, index) => (
          <Col xs={12} md={3} key={index}>
            <h3 className="footer-title" dangerouslySetInnerHTML={{__html: title}}></h3>
            {urls && <Link urls={urls} className="footer-list1" />}
            {urls2 && <Link urls={urls2} className="footer-list2" />}
          </Col>
        ))}
      </Row>
      <Row>
        {part2Urls.map(({ col, urls }, index) => (
          <Col key={index} {...col}>
            {urls && <Link urls={urls} className="footer-list3" />}
          </Col>
        ))}
        <Col
          xs={12}
          md={5}
          className={`footer-copyright${
            country === 'CA' ? ' footer-copyright-ca' : ''
          }`}
        >
          {t('ui.footer-copyright')}
          <sup>{country === 'US' ? '© ' : '® '}</sup>
          {country === 'US'
            ? t('ui.footer-shaklee')
            : 'Shaklee Canada Inc. 2000–'}
          {new Date().getFullYear()}. {t('ui.footer-all-rights')}
        </Col>
      </Row>
    </footer>
  );
}

export default memo(Footer);
