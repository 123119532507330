import React, { memo } from 'react';

const DisplayItem = ({ title, content, imgUrl }) => {
  return (
    <div className="mkt-display-item-container">
      <div className="display-img">
        {imgUrl && <img src={imgUrl} alt=""></img>}
      </div>
      <div className="display-text">
        {title && <p dangerouslySetInnerHTML={{
            __html: title
          }}></p>}
        {content && <p dangerouslySetInnerHTML={{
            __html: content
          }}></p>}
      </div>
    </div>
  );
};

export default memo(DisplayItem);
