import React, { useContext } from 'react';
import CtaBtn from 'components/common/CtaBtn';
import { MarketingContext } from 'components/Marketing/Marketing';

const GoodStuff = () => {
  const {
    t,
    isMarketing,
    kidsLandingPage,
  } = useContext(MarketingContext);
  return (
  <div 
    className="good-stuff-bg-container"
    tabIndex="0"
    role="img"
    aria-label="Good Stuff"
  >
    <div className="good-stuff-container">
      <h2 className="good-stuff-title">{t('ui.marketing.why_title4')}</h2>
      <div className="good-stuff-section">
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-1.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title5')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-7.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title6')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-10.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">{t('ui.marketing.why_title7')}</div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-2.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title8')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/peanuts.png"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title9')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-16.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title10')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-12.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title11')}
          </div>
        </div>
        <div className="good-stuff-section-item">
          <div className="good-stuff-section-item-img">
            <img
              src="https://images.shaklee.com/meology/marketing/group-3.svg"
              alt=""
            />
          </div>
          <div className="good-stuff-section-item-context">
            {t('ui.marketing.why_title12')}
          </div>
        </div>
      </div>
      {
        isMarketing && (
          <div className="cta-button-wrap cta-button-wrap-kids cta-button-wrap3">
              <CtaBtn
                type="kids"
                status={kidsLandingPage}
                eventLabel="marketing page cta section"
              />
          </div>
        )
      }
    </div>
  </div>
)};

export default GoodStuff;
