export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const DECREMENT_COUNTER = 'DECREMENT_COUNTER';

export const Q_FETCH_QUESTIONS = 'Q_FETCH_QUESTIONS';
export const Q_UPDATE_ANSWER = 'Q_UPDATE_ANSWER';
export const Q_SET_ANSWER = 'Q_SET_ANSWER';
export const list_questions_url = '/form_questions/app/';
export const list_questions_history_url = '/history';
export const list_file_upload_url = '/services/dna-upload';
export const list_file_delete_url = '/services/dna-delete'
export const Q_NEXT_PAGE = 'Q_NEXT_PAGE';
export const Q_NEXT_SECTION = 'Q_NEXT_SECTION';
export const Q_PREVIOUS_SECTION = 'Q_PREVIOUS_SECTION';
export const Q_PREVIOUS_PAGE = 'Q_PREVIOUS_PAGE';
export const Q_RESET_ANSWER = 'Q_RESET_ANSWER';
export const Q_FETCH_RESULTS = 'Q_FETCH_RESULTS';
export const Q_EDIT_QUESTION = 'Q_EDIT_QUESTION';

export const CREATE_HISTORY = '/history/create';
export const UPDATE_HISTORY = '/history/update';
export const Q_FETCH_HEALTHPROFILE = 'Q_FETCH_HEALTHPROFILE';
export const Q_CURRENT_PAGE = 'Q_CURRENT_PAGE';

export const Q_VALIDATE_QUESTION = 'Q_VALIDATE_QUESTION';
export const Q_LANDING_PAGE = 'Q_LANDING_PAGE';
export const Q_KIDS_LANDING_PAGE = 'Q_KIDS_LANDING_PAGE';
export const Q_PRENATAL_LANDING_PAGE = 'Q_PRENATAL_LANDING_PAGE';

export const USERINFO = 'USERINFO';
export const IS_MOBILE = 'IS_MOBILE';
export const SUBMIT_QUESTION_RESULT = 'SUBMIT_QUESTION_RESULT';
export const PRE_SUBMIT_CHECK = 'PRE_SUBMIT_CHECK';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_BANNER = 'SET_BANNER';

export const REMOVE_QUESTION_VALIDATION = 'REMOVE_QUESTION_VALIDATION';
export const SET_HISTORY = 'SET_HISTORY';
export const SET_MARKETING_TYPE = 'SET_MARKETING_TYPE';
export const SET_CKID = 'SET_CKID';

