import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { persistStore } from "redux-persist";
// import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './app/store/configureStore';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import './index.scss';

const store = configureStore();
const rootEl = document.getElementById('root');
// const persistor = persistStore(store)

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      {/* </PersistGate> */}
    </Provider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept('./components/App', () => {
    setTimeout(render);
  });
}

window['GoogleAnalyticsObject'] = 'ga';
window['ga'] =
  window['ga'] ||
  function() {
    (window['ga'].q = window['ga'].q || []).push(arguments);
  };

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
