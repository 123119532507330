import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const PwsShareLink = ({ pwsBasicInfo }) => {
  const { t } = useTranslation();
  const socialLinks = pwsBasicInfo?.socialLinks?.entry;
  const link = useMemo(() => {
    if (pwsBasicInfo?.wgName) {
      return pwsBasicInfo?.wgName;
    } else {
      if (pwsBasicInfo?.wgURL?.length > 25) {
        return pwsBasicInfo?.wgURL?.substring(0, 25) + '...';
      } else {
        return pwsBasicInfo?.wgURL;
      }
    }
  }, [pwsBasicInfo]);
  const goToSocial = (key, value) => {
    let url;
    if (value.match(/(https?:\/\/)(www\.)?\w+\.\w+/)) {
      url = value;
    } else if (value.match(/(www\.)?\w+\.\w+/)) {
      url = 'https://' + value;
    } else {
      url = 'https://' + key + '.com/' + value;
    }
    window.open(url);
  };
  if (!socialLinks) return null;
  return (
    <>
      <div className="social-link-list">
        {socialLinks?.map((item, index) => {
          if (!(item.key && item.value)) {
            return null;
          }
          const regex = new RegExp('^\\w+\\d+$');
          if (regex.test(item.key)) {
            item.key = item.key.replace(/\d+$/, '');
          }
          if (item.key === 'Other') {
            if (!item.value.match(/(https?:\/\/)?(www\.)?\w+\.\w+/)) {
              return null;
            }
          }
          return (
            <div
              className="social-link-list-item"
              onClick={() => goToSocial(item.key, item.value)}
              key={index}
            >
              <img
                src={require(`../../assets/images/${item.key.toLowerCase()}.svg`)}
                alt=""
              />
            </div>
          );
        })}
      </div>
      {pwsBasicInfo?.wgURL && (
        <div className="wg-content">
          <span className="wg-word">{t('pws.sherpa.join.my.group')}:</span>
          <span
            className="wg-link"
            onClick={() =>
              goToSocial(pwsBasicInfo?.wgName, pwsBasicInfo?.wgURL)
            }
          >
            {link}
          </span>
        </div>
      )}
    </>
  );
};

export default memo(PwsShareLink);
