import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useCopyToClipboard, useCurrentLink } from 'assets/js/hooks';
import { useTranslation } from 'react-i18next';

const CopyPageLink = ({ className }) => {
  const curentUrl = useCurrentLink();
  const { isUserLoggedin } = useSelector(state => state.user);
  const [copied, copy] = useCopyToClipboard(curentUrl, 5000);
  const { t } = useTranslation();

  if (!isUserLoggedin || !curentUrl.includes('site')) {
    return null;
  }

  return (
    <button
      type="button"
      className={cx('copy-page-link', className)}
      onClick={copy}
    >
      <span className="glyphicon glyphicon-link"></span>
      {copied ? t('ui.marketing.copied') : t('ui.marketing.copy-link')}
    </button>
  );
};

export default memo(CopyPageLink);
