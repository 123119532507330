import React, { memo, useContext, useState, useEffect, useMemo } from 'react';
import { PrenatalStepCard, PrenatalTopStepBanner } from './';
import { RecommendationContext } from 'components/results/ContextProvider';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowScroll } from 'assets/js/helper';
import { pushProductToDatalayer, pushGA4DataToDatalayer } from 'components/analytics/analyticsActions';

const JoinFlow = () => {
  const [currentStep, setCurrentStep] = useState(2)
  const {
    t,
    essentialPills,
    showContent,
    prenatalProductInfo,
    setPDPInfo,
    currentPills,
    pillsCount,
    selectedAnswers,
    proveitProductsInfo,
    lifeShakeOptions,
    boostOptions,
  } = useContext(RecommendationContext);

  const dispatch = useDispatch();
  const { prenatalStep  } = useSelector(state => state.recommendationsReducer);
  const { y: windowScrollY } = useWindowScroll();
  const [isFixed, setIsFixed] =useState(false)
  const { country } = useSelector(state => state.user);
  useEffect(() => {
    setIsFixed(windowScrollY > 120)
  }, [windowScrollY])

  useEffect(() => {
    if(!essentialPills?.length) return
    dispatch(
      pushProductToDatalayer({
        event: 'product-detail',
        ecommerce: {
          currencyCode: essentialPills?.[0]?.price?.currencyIso || 'USD',
          detail: {
            products: essentialPills?.map(p => {
              const { name = '', code = '', price: { value } = {} } = p || {};
              return {
                name: name,
                id: code,
                price: Number(value),
                brand: '',
                category: 'Meology Prenatal Join',
                size: '',
                flavor: '',
              };
            }),
          },
        },
      })
    );

    
    dispatch(pushGA4DataToDatalayer({ ecommerce: null })); // Clear the previous ecommerce object.
    const getItemVariant = baseOptions => {
      let itemVariant = '';
      if (baseOptions?.length > 0) {
        var flavor = baseOptions[0].selected.flavor || '';
        var size = baseOptions[0].selected.size || '';
        if (flavor && size) {
          itemVariant = flavor + ',' + size;
        } else {
          itemVariant = flavor || size;
        }
      }
      return itemVariant;
    };
    // GA view_item
    dispatch(
      pushGA4DataToDatalayer({
        event: "ecommEvent",
        event_name: "view_item",
        ecommerce: {
          items: essentialPills?.map((p,index) => {
            const { name = '', code = '', price: { value } = {}} = p || {};
            return {
              item_name: name,
              item_id: code,
              affiliation: 'shaklee' + country,
              currency: essentialPills?.[0]?.price?.currencyIso || 'USD',
              index: index,
              price: Number(value),
              item_brand: '',
              item_category: 'Meology Prenatal Join',
              item_variant: getItemVariant(p.baseOptions),
              quantity: Number(p.quantity)
            };
          }),
        }
      })
    );
  }, [dispatch, essentialPills, country]);

  useEffect(() => {
    const step2Completed = lifeShakeOptions?.every?.(p => p.currentSelect)
    if(step2Completed) {
      setCurrentStep(3)
    }
    const step3Completed = boostOptions?.every?.(p => p.currentSelect)
    if(step2Completed && step3Completed) {
      setCurrentStep(4)
    }
  }, [boostOptions, lifeShakeOptions])

  const recommendationsPillProps = useMemo(
    () => ({
      productsInfo: essentialPills,
      setPDPInfo: (pill, index) =>
        setPDPInfo(pill, false, prenatalProductInfo, 'pill', index),
      showContent,
      showToggle: false,
      isEssentialPills: true,
    }),
    [essentialPills, prenatalProductInfo, setPDPInfo, showContent]
  );

  const recommendationCardProps = useMemo(
    () => ({
      currentPills,
      pillsCount,
      selectedAnswers,
    }),
    [currentPills, pillsCount, selectedAnswers]
  );

  const stepData = useMemo(() => {
    const reg = /^\s*(Life Shake™)\s*(.*)$/
    const lifeShakes = lifeShakeOptions?.map?.(item => {
      const options = item.data?.map?.(p => ({...p, name: p.name?.replace(reg, '$2')}))
      return {
        ...item,
        data: options,
        defaultValue: item.defaultValue?.replace(reg, '$2'),
        placeholder: t('ui.reco.prenatal_rsw.step2_placeholder'),
      }
    })
    const boosts = boostOptions?.map?.(item => ({
      ...item,
      placeholder: t('ui.reco.prenatal_rsw.step3_placeholder')
    }))
    const pInfo = proveitProductsInfo?.[0] || {}
    return [
      {
        bundleCode: pInfo.code,
        stepNo: 1,
        title: t(`ui.recommendation.prenatal.title.${prenatalStep}`),
        summary: t(`ui.recommendation.prenatal.subtitle.${prenatalStep}`),
      },
      {
        bundleCode: pInfo.code,
        stepNo: 2,
        imgUrls: [t('ui.reco.prenatal_rsw.step2_join_img')],
        title: t('ui.reco.prenatal_rsw.step2.card_title'),
        summary: t('ui.reco.prenatal_rsw.step2.card_text'),
        selectComponents: lifeShakes,
        onMore: () => setPDPInfo(pInfo, false, prenatalProductInfo, 'rswStep2', 0)
      },
      {
        bundleCode: pInfo.code,
        stepNo: 3,
        imgUrls: [t(`ui.reco.prenatal_rsw.step3_join_img.${pInfo.code}`)],
        title: t('ui.reco.prenatal_rsw.step3.card_title'),
        summary: t('ui.reco.prenatal_rsw.step3.card_text'),
        selectComponents: boosts,
        onMore: () => setPDPInfo(pInfo, false, prenatalProductInfo, 'rswStep3', 1)
      },
    ]
  }, [boostOptions, lifeShakeOptions, prenatalProductInfo, prenatalStep, proveitProductsInfo, setPDPInfo, t]);

  return (
    <>
      <PrenatalTopStepBanner className={isFixed ? 'top-fixed' : ''} currentStep={currentStep} />
      <div className="rsw-join-flow-container">
        <PrenatalStepCard
          isPack
          recommendationCardProps={recommendationCardProps}
          recommendationsPillProps={recommendationsPillProps}
          {...stepData[0]}
        ></PrenatalStepCard>
        {stepData?.length > 1 &&
          stepData.slice(1).map(props => {
            return (
              <PrenatalStepCard
                key={props.stepNo}
                {...props}
              ></PrenatalStepCard>
            );
          })}
      </div>
    </>
  );
};

export default memo(JoinFlow);
