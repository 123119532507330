import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsMobile } from '../questions/QuestionActions';
const mapState = state => {
  return {};
};

const actions = {
  setIsMobile
};
class RouteWithLayout extends Component {
  componentWillUnmount() {       
    window.removeEventListener('resize',this.resize.bind(this));
  }
  async componentDidMount() {
    this.setBodyClass();
    await this.props.setIsMobile(this.isMobile());
    window.addEventListener('resize',this.resize.bind(this));
  }

  isMobile() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      if (window.orientation === 180 || window.orientation === 0) { 
        return true
      } else if ((window.orientation === 90 || window.orientation === -90) && window.screen.width > 768) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  resize() {
    this.props.setIsMobile(this.isMobile())
  }
  setBodyClass() {
    const { path } = this.props;
    const hasNoScrollClass = document.body.classList.contains('no-scroll');
    if (path && path.includes('assessment')) {
      if (!hasNoScrollClass) {
        document.body.classList.add('no-scroll');
      }
    } else {
      if (hasNoScrollClass) {
        document.body.classList.remove('no-scroll');
      }
    }
  }
  componentDidUpdate() {
    this.setBodyClass();
  }
  render() {
    const { layout: Layout, component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={matchProps => {
          return (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          );
        }}
      />
    );
  }
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default connect(mapState, actions)(RouteWithLayout);
