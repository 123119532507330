/* eslint-disable array-callback-return */
import React, { memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  formatDate,
  getProductSummary,
  getIsBackorder,
  getCurrentEventCategory,
} from 'assets/js/helper';
import {
  usePerDayPrice,
  usePrice,
  usePrenatalStatus,
  useMeologyHistory,
  useFixedProductPrice
} from 'assets/js/hooks';
import RecommendationProductAttributes from './RecommendationProductAttributes';
import { pushProductToDatalayer, pushGA4DataToDatalayer } from 'components/analytics/analyticsActions';
import {
  OMEGA_GELLY,
  DREAM_SERENE,
  TURMERIC_BOOST,
  PRENATAL_MULTIV_DRINK,
} from './RecommendationsConstants';

const baseSiteUidMap = {
  US: 'shakleeUS',
  CA: 'shakleeCA'
};

export const pushProductClick = ({ dispatch, product, price, index, country }) => {
  const eventCategory = getCurrentEventCategory();
  const { name = '', code = '', price: { currencyIso = 'USD' } = {} } = product || {};
  dispatch(
    pushProductToDatalayer({
      event: 'product-click',
      ecommerce: {
        currencyCode: currencyIso,
        click: {
          actionField: { list: eventCategory },
          products: [
            {
              name,
              id: code,
              price: Number(price),
              brand: '',
              category: 'Meology',
              position: index + 1,
            },
          ],
        },
      },
    })
  );

  
  dispatch(pushGA4DataToDatalayer({ ecommerce: null })); // Clear the previous ecommerce object.
  let viewItemListIds = [];
  let viewItemListNames = [];
  if (product.customizbleBundleComponentList && product.customizbleBundleComponentList.length > 0) {
    product.customizbleBundleComponentList.forEach((item) => {
      if (item.productOptions) {
        viewItemListIds.push(item.uid);
        if (item.name) {
          viewItemListNames.push(item.name);
        }
      }
    });
  } else if (product.nonCustomizbleBundleComponentList && product.nonCustomizbleBundleComponentList.length > 0) {
    product.nonCustomizbleBundleComponentList.forEach((item) => {
      if (item.productOptions) {
        viewItemListIds.push(item.uid);
        if (item.name) {
          viewItemListNames.push(item.name);
        }
      }
    });
  }
  const getProductVariant = baseOptions => {
    let productVariant = '';
    if (baseOptions?.length > 0 && baseOptions[0].options) {
      var selectedOption = baseOptions[0].options.filter((item) => item.code === product.code);
      var flavor = selectedOption[0].flavor || '';
      var size = selectedOption[0].size || '';
      if (flavor && size) {
        productVariant = flavor + ',' + size;
      } else {
        productVariant = flavor || size;
      }
    }
    return productVariant;
  };
  //GA4: select_item
  dispatch(
    pushGA4DataToDatalayer({
      event: "ecommEvent",
      event_name: "select_item",
      ecommerce: {
        items: [{
          item_id: code,
          item_name: name,
          affiliation: baseSiteUidMap[country],
          currency: currencyIso,
          index: 0,
          item_list_id: viewItemListIds.join(','),
          item_list_name: viewItemListNames.join(','),
          item_variant: getProductVariant(product.baseOptions),
          item_brand: '',
          item_category: 'Meology',
          price: Number(price),
        }],
      }
    })
  );
};

const Pill = ({
  onChange,
  setPDPInfo,
  pill,
  index,
  isSelectedProveIt,
  showToggle = true,
	showContent,
  isEssentialPills,
  isOptionalPills,
  isPlus,
}) => {
  const { isMeologyRegular, isMeologyKids, isMeologyPrenatal } = useMeologyHistory();
  const { isPrenatalPreview } = usePrenatalStatus();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.user);
  const {
    product_info: productInfo,
    conception_product_info: conceptionProductInfo,
    postnatal_product_info: postnatalProductInfo,
    pregnant_product_info: pregnantProductInfo,
    prenatalStep
  } = useSelector(
    state => state.recommendationsReducer
  );
  const { t } = useTranslation();
  const perDayPrice = usePerDayPrice(pill, null, country);
  const fixedPrice = useFixedProductPrice(pill, country)
  const price = usePrice(pill);
	const reasonData = [];
  let reasonKeyData = [];
  const productImage = useMemo(() =>{
    if(isMeologyPrenatal) {
      if(isEssentialPills) {
       return  t(`recommendation.prenatal.image_url.essential_pack.${pill.sku}`)
      } else {
        const stepNO = {
          TRYING: 1,
          PREGNANT:2,
          POSTPARTUM: 3
        }
        return  t(`recommendation.prenatal.image_url.option_pack.${pill.sku}_${stepNO[prenatalStep]}`)
      }
    }else {
      return `https://images.shaklee.com/meology/pills/small/${pill.sku}.png?v=2`;
    }
  }, [isEssentialPills, isMeologyPrenatal, pill.sku, prenatalStep, t]) 
  const summary = useMemo(() => {
    if(isMeologyPrenatal) {
      return t(`recommendation.prenatal_detail_summary.${pill.sku}.${prenatalStep}`,
      t(`recommendation.prenatal_detail_summary.${pill.sku}`))
    }
    return  getProductSummary(pill)
  }, [isMeologyPrenatal, pill, prenatalStep, t]);

  const quantityText = useMemo(() => {
    if (isMeologyPrenatal && isOptionalPills) return '';

    const { quantity, code } = pill;
    let quantityUnit = '';

    if (isMeologyRegular || isMeologyPrenatal) {
      quantityUnit =
        quantity > 1
          ? t('ui.pdf.p4_tablets')
          : t('ui.recommendation.daily_pills_quantity');
    }

    if (isMeologyKids) {
      quantityUnit =
        quantity > 1
          ? t('ui.recommendation.kids_gummies')
          : t('ui.recommendation.kids_gummy');
    }

    if (code === OMEGA_GELLY) {
      quantityUnit = t('ui.recommendation.kids_gelly');
    }

    if (code === PRENATAL_MULTIV_DRINK) {
      quantityUnit = t('ui.recommendation.prental_stick');
    }

    return `${quantity} ${quantityUnit}`;
  }, [
    isMeologyKids,
    isMeologyPrenatal,
    isMeologyRegular,
    isOptionalPills,
    pill,
    t,
  ]);

  const prenatalCorePill = useMemo(() => isMeologyPrenatal && isEssentialPills, [isEssentialPills, isMeologyPrenatal]) 

  const priceText = useMemo(() => {
    if (isMeologyKids || prenatalCorePill) return '';
    if (isMeologyPrenatal && (isOptionalPills || isPlus)) return `$${fixedPrice}`;
    return ` | $${perDayPrice} ${t('ui.recommendation.daily_pills_cost')}`;
  }, [isMeologyKids, prenatalCorePill, isMeologyPrenatal, isOptionalPills, isPlus, fixedPrice, perDayPrice, t]);

  const showBackorderMessage = useMemo(() => getIsBackorder(pill), [pill])

  const backorderMessage = useMemo(() => {
    const nextAvailabilityDateFormat = pill?.stock?.nextAvailabilityDateFormat;
    const date = nextAvailabilityDateFormat
      ? formatDate(nextAvailabilityDateFormat)
      : 'TBD';
    return `${t('ui.recommendation.backorder_ship_date')}: ${date}`;
  }, [pill, t]);

  let products = productInfo
  if(prenatalCorePill) {
    const _info = {
      TRYING: conceptionProductInfo,
      PREGNANT:pregnantProductInfo,
      POSTPARTUM: postnatalProductInfo
    }
    products = _info[prenatalStep]
  }

	if (products?.[pill.code]?.reason_categories) {
    products[pill.code].reason_categories.map(item => {
        item.reasons.map(val => {
        reasonData.push(val.title);
      });
    });
    reasonKeyData = [...new Set(reasonData)];
  }
  const prenatalOptionPill = isMeologyPrenatal && isOptionalPills
  return (
    <div key={index} className="pill-container">
      <div className="pill-line"></div>
      <div key={index} className="pill-info">
        <div className="product-info">
          <div className="product-info-left">
            <div className= {cx("product-info-image", {
              'prenatal-option': isMeologyPrenatal && isOptionalPills,
              'prenatal-essential-1': prenatalCorePill && index === 0
            })} >
            <img className="product-info-left-picture" src={productImage} onError={(e) => {e.target.src="https://images.shaklee.com/meology/pills/pill.png"}} alt=""/>
            </div>
            <div className={cx('product-info-left-name', {
              'font-gilmer-medium': prenatalCorePill || prenatalOptionPill
            })}>{pill.name}</div>
            <div className={cx('product-info-left-desc', {
              'font-gilmer-light':  prenatalCorePill,
              'font-gilmer-regular':  prenatalOptionPill,
            })} dangerouslySetInnerHTML={{
               __html: isMeologyPrenatal 
               ? summary 
               : (`${summary}${pill.dsheaFlag ? '*' : ''}${pill.recoPageDagger ? '‡' : ''}`)
            }}></div>
            <div className="product-info-left-why">
              <RecommendationProductAttributes pill = {pill}/>
            </div>
            {showBackorderMessage && (
              <div className="product-info-left-backorder">
                {backorderMessage}
              </div>
            )}
            {DREAM_SERENE.includes(String(pill.code)) && (
              <div className="product-info-note">
                {t('ui.recommendation.gentle_sleep')}
              </div>
            )}
            {TURMERIC_BOOST.includes(String(pill.code)) && (
              <div className="product-info-note">
                {t('ui.recommendation.turmeric_boost')}
              </div>
            )}

          </div>
          { (!isMeologyPrenatal || prenatalCorePill ) && showContent &&
            <div className="product-info-right">
              <div className={cx('product-info-right-why', {
                'font-gilmer-medium': prenatalCorePill
              })}>{t('ui.recommendation.why')}</div>
              <ul className={cx('reason-list', {
                'font-gilmer-light': prenatalCorePill
              })}>
                {reasonKeyData.slice(0,5).map((item,index) => (
                    <li className="reason-list-item" key={index}>
                      {t('recommendation.'+ item)}
                    </li>
                ))}
              </ul>
            </div>
          }
        </div>
        {!isMeologyPrenatal && showToggle && (
              <Form.Check
                type="switch"
                label={pill.selected ? t('ui.recommendation.included') : t('ui.recommendation.include')}
                className="product-info-switch"
                id={_.uniqueId('pill-product-switch-')}
                checked={pill.selected}
                disabled={isSelectedProveIt}
                onChange={event => onChange(index, event, price, pill)}
                tabIndex="0"
                aria-label="include toggle btn"
                onKeyDown={event => {
                  if(event.keyCode === 13){
                    event.target.checked = !event.target.checked
                    onChange(index, event, price, pill)
                  }
                }}
              />
            )}
        <div>
          <div className="product-footer">
            <div className="product-footer-left">
              {showContent && (
                <button
                  aria-label={
                    `${pill.name} ` + t('ui.recommendation.learn_more')
                  }
                  className={cx('btn btn-link learn-more', {
                    'font-gilmer-bold': prenatalCorePill,
                    'font-gilmer-medium': prenatalOptionPill,
                  })}
                  onClick={() => {
                    // analytics
                    pushProductClick({ dispatch, product: pill, price, index,country} );

                    setPDPInfo(pill, index);
                  }}
                >
                  {t('ui.recommendation.learn_more')}
                </button>
              )}
            </div>
            <div
              className={cx('product-footer-right', {
                'font-gilmer-regular': prenatalCorePill,
                'font-gilmer-medium': prenatalOptionPill,
              })}
            >
              {quantityText}
              {priceText}
            </div>
          </div>
          {isMeologyPrenatal && isOptionalPills && (
            <label
              className={cx('checkbox-button', {
                selected: pill.selected,
                disabled: isPrenatalPreview,
              })}
              htmlFor={`checkbox-button-pill-${index}`}
              tabIndex="0"
              aria-label="add to pack button"
            >
              <input
                className="sr-only"
                type="checkbox"
                id={`checkbox-button-pill-${index}`}
                checked={pill.selected}
                disabled={isPrenatalPreview}
                onChange={event => onChange(index, event, price, pill)}
              />
              {pill.selected
                ? t('ui.recommendation.prenatal.cta6')
                : t('ui.recommendation.prenatal.cta5')}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

const RecommendationsPill = ({productsInfo, ...props}) => {
  return (
    <>
      {productsInfo.length > 0 &&
        productsInfo.map((pill, index) => (
          <Pill
            pill={pill}
            key={index}
            index={index}
            {...props}
          />
        ))}
    </>
  );
};

export default memo(RecommendationsPill);
