import React, { useContext } from 'react';
import 'swiper/css/swiper.css';
import { MarketingContext } from 'components/Marketing/Marketing';

const KidsNavigation = () => {
  const { t } = useContext(MarketingContext);
  return (
    <div className="swiper-kids-container">
      <div className="slide-pic">
        <div className="img">
          <img
            src="https://images.shaklee.com/meology/marketing/reviewer-kids.png?v=1"
            alt="persona-01"
          />
        </div>
        <div className="text">
          <div className="star">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/rating.png"
              alt="five stars"
            />
          </div>
          <div className="description">
            {t('ui.marketing.review-4-text')}
          </div>
          <div className="name">{t('ui.marketing.review-4-name')}</div>
        </div>
      </div>
    </div>
  );
};

export default KidsNavigation;
