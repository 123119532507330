import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAttributesFromProduct } from 'assets/js/helper';
import {
  ProductAttributes,
  PRODUCT_ATTRIBUTE_TYPES,
} from 'components/results/prenatalRSW';
import Accordion from 'components/results/LearnMore/Accordion';
import { Nav } from 'react-bootstrap';

const codesMap = {
'22076': 'ZT405', //CoQ10
'20656': 'ZU578', // Stress Relief Complex
'22093': 'ZU574', //Chewable Cal Mag Plus
'21320': 'ZH038', //Optiflora© DI
'21364': 'ZC178', //Vita-D3
}

const BoostDetail = ({ product = {}, reason = {} }) => {
  const { t } = useTranslation();
  const { code, name, recommendationLink } = product;

  const attrs = useMemo(
    () => getAttributesFromProduct(product, PRODUCT_ATTRIBUTE_TYPES),
    [product]
  );
  const reasonKeyData = useMemo(() => {
    const { reason_categories: reasonItems } = reason;
    return (
      reasonItems
        ?.map(({ category, reasons: rs }) =>
          rs?.map(({ title, description }) => ({
            category,
            title,
            description,
          }))
        )
        .reduce((accumulator, item) => accumulator.concat(item ?? {}), []) || []
    );
  }, [reason]);

  return (
    <>
      <div className="boost-deatil-content">
        <div className="boost-img">
          <img
            src={t(`ui.reco.prenatal_rsw.pdp_img.${code}`)}
            onError={e => {
              e.target.src =
                'https://images.shaklee.com/meology/coming-soon.jpg';
            }}
            alt=""
          />
        </div>
        <p
          className="boost-title "
          dangerouslySetInnerHTML={{
            __html: name,
          }}
        />
        <p
          className="boost-short"
          dangerouslySetInnerHTML={{
            __html: t(`recommendation.prenatal_detail_summary.${codesMap[code] || code}`),
          }}
        />
        <p
          className="boost-long"
          dangerouslySetInnerHTML={{
            __html: t(`recommendation.prenatal_detail_description.${codesMap[code] || code}`),
          }}
        />
        <ProductAttributes attributes={attrs} />
      </div>

      {reasonKeyData?.length > 0 && (
        <>
          <div className="recommend-title">
            {t('ui.learn_more.recommended_because')}
          </div>
          <div className="recommend-content">
            {reasonKeyData.map((item, index) => (
              <div className="recommend-content-item" key={index}>
                <div className="recommend-content-item-header">
                  <img
                    src={
                      t('reason_tag.prenatal.pdp.' +
                      item.category +
                      '.image_black')
                    }
                    className="recommend-content-item-header-img"
                    onError={e => {
                      e.target.src =
                        'https://dummyimage.com/55x55/fff/026854.png';
                    }}
                    alt=""
                  />
                  <div className="recommend-content-item-header-title">
                    {t(`recommendation.${item.title}`)}
                  </div>
                </div>
                <div
                  className="recommend-content-item-desc"
                  dangerouslySetInnerHTML={{
                    __html: t(`recommendation.${item.description}`),
                  }}
                ></div>
              </div>
            ))}
          </div>
        </>
      )}

      <Accordion title={t('ui.pdp.labels')}>
        {recommendationLink && (
          <Nav.Link href={recommendationLink} target="_blank">
            {t('ui.pdp.product_label')}
          </Nav.Link>
        )}
      </Accordion>
    </>
  );
};
const BoostDetailList = ({ products = [], reasons = {} }) => {
  return (
    <div className="boost-deatil-list-container">
      {products?.map?.((p, idx) => {
        return <BoostDetail key={idx} product={p} reason={reasons?.[p.code]} />;
      })}
    </div>
  );
};

export default memo(BoostDetailList);
