import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { truncateNameFromAnswers } from 'assets/js/helper';
import InstructionsModal from './InstructionsModal';
import {
  getEventAction,
  getEventLabel,
  getEventCategory,
} from 'components/analytics/quizAnalytics';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const actions = {
  pushInteractionToDataLayer,
};
class Question extends Component {
  state = {
    isOpen: false,
  };

  openModal = content => {
    this.setState({ isOpen: true });
    const eventCategory = getEventCategory(`${content}_howto_popup`);
    const eventAction = getEventAction(`${content}_howto_popup`);
    const eventLabel = getEventLabel(`${content}_howto_popup`);

    this.props.pushInteractionToDataLayer({
      eventCategory,
      eventAction,
      eventLabel,
    });
  };
  closeModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const {
      t,
      i18n,
      content,
      widget,
      selectedAnswers,
      instructionsModal,
      comeFrom,
      required,
    } = this.props;
    const ans = truncateNameFromAnswers(selectedAnswers);
    const noSkipText = ['lab_results', 'email', 'kids-email'];
    return (
      <div className="question">
        <div
          className="question-title"
          dangerouslySetInnerHTML={{
            __html: t(`questions.${content}.title`, ans),
          }}
        ></div>
        {i18n.exists('questions.' + content + '.subtitle') && (
          <div className="question-sub-title">
            {t('questions.' + content + '.subtitle', ans)}
            {!required &&
              comeFrom !== 'reviewPage' &&
              !noSkipText.includes(content) &&
              ' ' + t('questions.subtitle_skip', ans)}
          </div>
        )}
        {widget === 'multi_choice' && content !== 'health_goals' && (
          <div className="question-check">
            {t('questions.check_all_that_apply')}
          </div>
        )}
        {instructionsModal && (
          <>
            <div className="modal-text">
              <div
                className="modal-text-modal"
                onClick={() => this.openModal(content)}
                role="link"
                tabIndex="0"
                title={t('questions.' + content + '.instructions_modal_title')}
                aria-label={t(
                  'questions.' + content + '.instructions_modal_title'
                )}
              >
                {t('questions.' + content + '.instructions_modal_title')}
              </div>
            </div>
            <InstructionsModal
              isOpen={this.state.isOpen}
              closeModal={this.closeModal}
              content={content}
              title={t('questions.' + content + '.instructions_modal_title')}
              text={t('questions.' + content + '.instructions_modal_text')}
              comeFrom={comeFrom}
            />
          </>
        )}
      </div>
    );
  }
}
export default withTranslation()(connect(null, actions)(Question));
