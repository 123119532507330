const regular = [
  {
    question: 'ui.faq.meology_steps',
    answer: 'ui.faq.meology_steps_answer',
  },
  {
    question: 'ui.faq.meology_quiz_time',
    answer: 'ui.faq.meology_quiz_time_answer',
  },
  {
    question: 'ui.faq.meology_health_conditions',
    answer: 'ui.faq.meology_health_conditions_answer',
  },
  {
    question: 'ui.faq.meology_medications',
    answer: 'ui.faq.meology_medications_answer',
  },
  {
    question: 'ui.faq.meology_privacy',
    answer: 'ui.faq.meology_privacy_answer',
  },
  {
    question: 'ui.faq.meology_tiers',
    answer: 'ui.faq.meology_tiers_answer',
  },
  {
    question: 'ui.faq.meology_order',
    answer: 'ui.faq.meology_order_answer',
  },
  {
    question: 'ui.faq.meology_products_detail',
    answer: 'ui.faq.meology_products_detail_answer',
  },
  {
    question: 'ui.faq.meology_allergy',
    answer: 'ui.faq.meology_allergy_answer',
  },
  {
    question: 'ui.faq.meology_other_products',
    answer: 'ui.faq.meology_other_products_answer',
  },
  {
    question: 'ui.faq.meology_recyclable',
    answer: 'ui.faq.meology_recyclable_answer',
  },
  {
    question: 'ui.faq.meology_intake',
    answer: 'ui.faq.meology_intake_answer',
  },
  {
    question: 'ui.faq.meology_retake_quiz',
    answer: 'ui.faq.meology_retake_quiz_answer',
  },
  {
    question: 'ui.faq.meology_auto_ship',
    answer: 'ui.faq.meology_auto_ship_answer',
  },
  {
    question: 'ui.faq.meology_membership',
    answer: 'ui.faq.meology_membership_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_test',
    answer: 'ui.faq.meology_kids_blood_test_answer',
  },
  {
    question: 'ui.faq.meology_kids_retake',
    answer: 'ui.faq.meology_kids_retake_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_pressure',
    answer: 'ui.faq.meology_kids_blood_pressure_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_test_same',
    answer: 'ui.faq.meology_kids_blood_test_same_answer',
  },
  {
    question: 'ui.faq.meology_kids_medication',
    answer: 'ui.faq.meology_kids_medication_answer',
  },
  {
    question: 'ui.faq.meology_kids_explanation',
    answer: 'ui.faq.meology_kids_explanation_answer',
  },
  {
    question: 'ui.faq.meology_kids_dna_data',
    answer: 'ui.faq.meology_kids_dna_data_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_test_helpful',
    answer: 'ui.faq.meology_kids_blood_test_helpful_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_test_certain',
    answer: 'ui.faq.meology_kids_blood_test_certain_answer',
  },
  {
    question: 'ui.faq.meology_kids_23andme',
    answer: 'ui.faq.meology_kids_23andme_answer',
  },
  {
    question: 'ui.faq.meology_kids_23andme_inform',
    answer: 'ui.faq.meology_kids_23andme_inform_answer',
  },
  {
    question: 'ui.faq.meology_kids_blood_test_change',
    answer: 'ui.faq.meology_kids_blood_test_change_answer',
  },
];
const kids = [
  {
    question: 'ui.faq.meology_kids_works',
    answer: 'ui.faq.meology_kids_works_answer',
  },
  {
    question: 'ui.faq.meology_gummies',
    answer: 'ui.faq.meology_gummies_answer',
  },
  {
    question: 'ui.faq.meology_nutrients',
    answer: 'ui.faq.meology_nutrients_answer',
  },
  {
    question: 'ui.faq.meology_gelly',
    answer: 'ui.faq.meology_gelly_answer',
  },
  {
    question: 'ui.faq.meology_kids_expiration_date',
    answer: 'ui.faq.meology_kids_expiration_date_answer',
  },
  {
    question: 'ui.faq.meology_kids_supplements',
    answer: 'ui.faq.meology_kids_supplements_answer',
  },
  {
    question: 'ui.faq.meology_kids_under',
    answer: 'ui.faq.meology_kids_under_answer',
  },
  {
    question: 'ui.faq.meology_kids_daily',
    answer: 'ui.faq.meology_kids_daily_answer',
  },
  {
    question: 'ui.faq.meology_kids_graduate',
    answer: 'ui.faq.meology_kids_graduate_answer',
  },
  {
    question: 'ui.faq.meology_kids_sachets',
    answer: 'ui.faq.meology_kids_sachets_answer',
  },
  {
    question: 'ui.faq.meology_kids_ingredients',
    answer: 'ui.faq.meology_kids_ingredients_answer',
  },
  {
    question: 'ui.faq.meology_kids_vegetarian',
    answer: 'ui.faq.meology_kids_vegetarian_answer',
  },
  {
    question: 'ui.faq.meology_kids_vegan',
    answer: 'ui.faq.meology_kids_vegan_answer',
  },
  {
    question: 'ui.faq.meology_kids_kosher',
    answer: 'ui.faq.meology_kids_kosher_answer',
  },
  {
    question: 'ui.faq.meology_kids_sugar',
    answer: 'ui.faq.meology_kids_sugar_answer',
  },
  {
    question: 'ui.faq.meology_kids_gelatin',
    answer: 'ui.faq.meology_kids_gelatin_answer',
  },
  {
    question: 'ui.faq.meology_personalized',
    answer: 'ui.faq.meology_personalized_answer',
  },
  {
    question: 'ui.faq.meology_kids_packs',
    answer: 'ui.faq.meology_kids_packs_answer',
  },
  {
    question: 'ui.faq.meology_recommended_products',
    answer: 'ui.faq.meology_recommended_products_answer',
  },
  {
    question: 'ui.faq.meology_kids_purchase',
    answer: 'ui.faq.meology_kids_purchase_answer',
  },
  {
    question: 'ui.faq.meology_kids_free_shipping',
    answer: 'ui.faq.meology_kids_free_shipping_answer',
  },
  {
    question: 'ui.faq.meology_kids_loyalty_orders',
    answer: 'ui.faq.meology_kids_loyalty_orders_answer',
  },
  {
    question: 'ui.faq.meology_kids_adult',
    answer: 'ui.faq.meology_kids_adult_answer',
  },
  {
    question: 'ui.faq.meology_kids_box',
    answer: 'ui.faq.meology_kids_box_answer',
  },
  {
    question: 'ui.faq.meology_kids_order',
    answer: 'ui.faq.meology_kids_order_answer',
  },
  {
    question: 'ui.faq.meology_kids_discount',
    answer: 'ui.faq.meology_kids_discount_answer',
  },
  {
    question: 'ui.faq.meology_kids_one_households',
    answer: 'ui.faq.meology_kids_one_households_answer',
  },
  {
    question: 'ui.faq.meology_kids_vitamin_angels',
    answer: 'ui.faq.meology_kids_vitamin_angels_answer',
  },
  {
    question: 'ui.faq.meology_personal_information',
    answer: 'ui.faq.meology_personal_information_answer',
  },
  {
    question: 'ui.faq.meology_kids_25_days',
    answer: 'ui.faq.meology_kids_25_days_answer',
  },
  {
    question: 'ui.faq.meology_kids_recyclable',
    answer: 'ui.faq.meology_kids_recyclable_answer',
  },
  {
    question: 'ui.faq.meology_kids_consumes',
    answer: 'ui.faq.meology_kids_consumes_answer',
  },
  {
    question: 'ui.faq.meology_berry_orange',
    answer: 'ui.faq.meology_berry_orange_answer',
  },
];
const prenatal = [
  {
    question: 'ui.faq.meology_prenatal_works',
    answer: 'ui.faq.meology_prenatal_works_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_supplements_prep',
    answer: 'ui.faq.meology_prenatal_supplements_prep_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_supplements_pregnant',
    answer: 'ui.faq.meology_prenatal_supplements_pregnnt_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_supplements_postnatal',
    answer: 'ui.faq.meology_prenatal_supplements_postnatal_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_nutrients',
    answer: 'ui.faq.meology_prenatal_nutrients_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_MultiV_drink',
    answer: 'ui.faq.meology_prenatal_MultiV_drink_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_folate',
    answer: 'ui.faq.meology_prenatal_folate_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_take',
    answer: 'ui.faq.meology_prenatal_take_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_expiry',
    answer: 'ui.faq.meology_prenatal_expiry_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_ingredients',
    answer: 'ui.faq.meology_prenatal_ingredients_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_vegan',
    answer: 'ui.faq.meology_prenatal_vegan_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_vegetarian',
    answer: 'ui.faq.meology_prenatal_vegetarian_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_kosher',
    answer: 'ui.faq.meology_prenatal_kosher_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_soy',
    answer: 'ui.faq.meology_prenatal_soy_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_dairy',
    answer: 'ui.faq.meology_prenatal_dairy_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_caffeine',
    answer: 'ui.faq.meology_prenatal_caffeine_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_customize',
    answer: 'ui.faq.meology_prenatal_customize_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_products',
    answer: 'ui.faq.meology_prenatal_products_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_nauseous',
    answer: 'ui.faq.meology_prenatal_nauseous_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_discontinue',
    answer: 'ui.faq.meology_prenatal_discontinue_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_othermedications',
    answer: 'ui.faq.meology_prenatal_othermedications_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_vitalizer',
    answer: 'ui.faq.meology_prenatal_vitalizer_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_membership',
    answer: 'ui.faq.meology_prenatal_membership_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_freeship',
    answer: 'ui.faq.meology_prenatal_freeship_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_loyaltyorders',
    answer: 'ui.faq.meology_prenatal_loyaltyorders_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_adult_both',
    answer: 'ui.faq.meology_prenatal_adult_both_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_status',
    answer: 'ui.faq.meology_prenatal_status_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_personalinformation',
    answer: 'ui.faq.meology_prenatal_personalinformation_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_order_eachmonth',
    answer: 'ui.faq.meology_prenatal_order_eachmonth_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_donation',
    answer: 'ui.faq.meology_prenatal_donation_answer',
  },
  {
    question: 'ui.faq.meology_prenatal_recyclable',
    answer: 'ui.faq.meology_prenatal_recyclable_answer',
  },
];

export const FAQ_TYPE = {
    regular: 'regular',
    kids: 'kids',
    prenatal: 'prenatal'
}

export const displayFaqs ={
    [FAQ_TYPE.prenatal]: prenatal,
    [FAQ_TYPE.kids]: kids,
    [FAQ_TYPE.regular]:regular
}
