import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import useToast from './Context';

const Item = ({ id, delay, children }) => {
  const { removeToast } = useToast();
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const className = useMemo(() => {
    const classes = ['toast-list-item'];
    if (fadeOut) classes.push('fadeOutUp');
    if (fadeIn) classes.push('fadeInUp');
    return classes.join(' ');
  }, [fadeIn, fadeOut]);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const handleClose = useCallback(() => {
    setFadeIn(false);
    setFadeOut(true);
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (!fadeOut) return;
    removeToast(id);
  }, [fadeOut, id, removeToast]);

  useEffect(() => {
    const timer = setTimeout(() => handleClose(), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [delay, handleClose, id]);

  return (
    <>
      {(fadeIn || fadeOut) && (
        <li className={className} key={id} onAnimationEnd={onAnimationEnd}>
          <img
            src="https://images.shaklee.com/meology/icons/circle-check.svg"
            className="toast-list-item-icon"
            alt="circle check"
          />
          {children}
          <span className="toast-list-item-close" onClick={handleClose}></span>
        </li>
      )}
    </>
  );
};

export default memo(Item);
