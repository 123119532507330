import React, {Component} from 'react';
import {Row} from "react-bootstrap";

class RenderNumber extends Component {
    fieldChange = (e) => {
        this.props.updateAnswer(this.props.name, e.target.value, this.props.page);
    }
	render() {
        const {name, selectedAnswer} = this.props;
		return (
			<Row className="answer">
                <input type="number" name={name} className="form-control" onChange={this.fieldChange} value={selectedAnswer || ''}/>
            </Row>
		);
	}
}

export default RenderNumber;