import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import exampleReducer from '../../components/example/exampleReducer';
import asyncReducer from '../../components/async/asyncReducer';
import analyticsReducer from '../../components/analytics/analyticsReducer'
import userReducer from "../../components/userProfile/userReducer";
import QuestionReducer from '../../components/questions/QuestionReducer';
import RecommendationsReducer from '../../components/results/Recommendations/RecommendationsReducer';

const rootReducer = combineReducers({
    form: FormReducer,
    example: exampleReducer,
	toastr: toastrReducer,
    async: asyncReducer,
    analytics: analyticsReducer,
    user: userReducer,
    QuestionReducer: QuestionReducer,
    recommendationsReducer: RecommendationsReducer
});

export default rootReducer;