import React, { memo } from 'react';

const LearnMoreBtn = ({ text, onClick = () => {} }) => {
  return (
    <div className="mkt-learn-more-btn" onClick={onClick}>
      <span>{text}</span>
      <img
        src="https://images.shaklee.com/meology/icons/circel-arrow.svg"
        alt="circle-logo"
      />
    </div>
  );
};

export default memo(LearnMoreBtn);
