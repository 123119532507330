import React, { memo, useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import PwsShareLink from './PwsShareLink';
import SherpaBannerModal from './SherpaBannerModal';
import {
  getHybrisHost,
  getSessionStorage,
  setSessionStorage,
  useSize,
  useWindowScroll,
} from 'assets/js/helper';
import { useIsMarketing } from 'assets/js/hooks';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'assets/js/helper';
import PwsProfileImage from './PwsProfileImage';
import ImageCa from 'assets/images/CA.svg';
import ImageUs from 'assets/images/US.svg';

const SherpaBanner = ({ headerRef }) => {
  const { t } = useTranslation();
  const { pws_list } = useSelector(
    state => state.QuestionReducer.sponsor_get_pws
  );
  const { width: windowWidth } = useWindowSize();
  const isMobileChanged = useMemo(() => windowWidth < 768, [windowWidth]);
  const { country, shakleeId, accountType } = useSelector(state => state.user);
  const { isMarketing } = useIsMarketing();
  const { height: headerHeight } = useSize(headerRef);
  const { y: windowScrollY } = useWindowScroll();
  const pwsBasicInfo = pws_list[0]?.site;
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [displayMini, setDisplayMini] = useState(false);
  const [isSherpaFixed, setIsSherpaFixed] = useState(false);
  const [showSherpaModal, setShowSherpaModal] = useState(false);

  const descriptionRef = useRef(null);
  const moreDescription = useMemo(() => pwsBasicInfo?.message, [pwsBasicInfo]);
  const lessDescription = useMemo(
    () => pwsBasicInfo?.message?.substring(0, 200) + '...',
    [pwsBasicInfo]
  );
  const curShakleeId = useMemo(() => shakleeId || 'Anonymous', [shakleeId]);
  const displaySherpaBannerMini =
    getSessionStorage(curShakleeId + 'Mini') || false;

  const showShopMyFavoritesButton = useMemo(() => {
    const pwsBaseSiteId = pwsBasicInfo?.pwsBaseSiteId ?? '';
    return pwsBaseSiteId.slice(-2) === country;
  }, [country, pwsBasicInfo])

  useEffect(() => {
    setDisplayMini(displaySherpaBannerMini);
    if (
      !displayMini &&
      descriptionRef?.current &&
      pwsBasicInfo?.message?.length > 200
    ) {
      setShowReadMore(true);
      setReadMore(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!headerHeight || isMarketing) return;
    if (windowScrollY > headerHeight) {
      setDisplayMini(true);
      setIsSherpaFixed(true);
    } else {
      setDisplayMini(displaySherpaBannerMini);
      setIsSherpaFixed(false);
    }
  }, [headerHeight, windowScrollY, isMarketing, displaySherpaBannerMini]);

  const hideSherpaModal = () => {
    setShowSherpaModal(false);
  };

  if (
    !pwsBasicInfo ||
    ['BL', 'DISTRIBUTOR'].includes(accountType?.toUpperCase())
  )
    return null;

  return (
    <>
      <div
        className={cx('sticky-sherpa-banner-box', {
          sticky: !isMarketing && isSherpaFixed,
        })}
      >
        <div
          className={cx('sticky-sherpa-banner-container', {
            hidden: displayMini,
          })}
        >
          <div className="sticky-sherpa-banner">
            <button
              type="button"
              id="sherpaClose"
              className="close-sticky-sherpa-banner"
              onClick={() => {
                setDisplayMini(true);
                setSessionStorage(curShakleeId + 'Mini', true);
              }}
            ></button>
            {pwsBasicInfo?.shopOtherSiteLink && ['ANONYMOUS'].includes(accountType?.toUpperCase()) && (
              <>
                <a
                  className="shop-other-site-link"
                  href={pwsBasicInfo?.shopOtherSiteLink}
                >
                  <span>{t('pws.sherpa.shop.other.site.link')}:</span>
                  {country === 'US' && <img src={ImageCa} alt="ca_site_img" />}
                  {country === 'CA' && <img src={ImageUs} alt="us_site_img" />}
                </a>
              </>
            )}

            <div className="sticky-sherpa-banner-image">
              <PwsProfileImage pwsBasicInfo={pwsBasicInfo} />
            </div>
            <div className="sticky-sherpa-banner-details">
              <div className="ambassador">
                <span>{t('pws.view.Ambassador')}</span>
              </div>
              <div className="name">{pwsBasicInfo?.userName}</div>
              {isMobileChanged ? (
                <>
                  <PwsShareLink pwsBasicInfo={pwsBasicInfo} />
                  <div className="description-container">
                    <div
                      className="description sticky-sherpa-banner-details-description"
                      ref={descriptionRef}
                      dangerouslySetInnerHTML={{
                        __html: readMore ? lessDescription : moreDescription,
                      }}
                    ></div>
                    {showReadMore && (
                      <>
                        {readMore ? (
                          <div
                            className="sherpa-read-more sherpa-read-more"
                            onClick={() => {
                              setReadMore(false);
                            }}
                          >
                            {t('pws.view.popup.readmore')}
                          </div>
                        ) : (
                          <div
                            className="sherpa-read-less sherpa-read-less"
                            onClick={() => {
                              setReadMore(true);
                            }}
                          >
                            {t('pws.view.popup.seeless')}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="description-container">
                    <div
                      className="description sticky-sherpa-banner-details-description"
                      ref={descriptionRef}
                      dangerouslySetInnerHTML={{
                        __html: readMore ? lessDescription : moreDescription,
                      }}
                    ></div>
                    {showReadMore && (
                      <>
                        {readMore ? (
                          <div
                            className="sherpa-read-more sherpa-read-more"
                            onClick={() => {
                              setReadMore(false);
                            }}
                          >
                            {t('pws.view.popup.readmore')}
                          </div>
                        ) : (
                          <div
                            className="sherpa-read-less sherpa-read-less"
                            onClick={() => {
                              setReadMore(true);
                            }}
                          >
                            {t('pws.view.popup.seeless')}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <PwsShareLink pwsBasicInfo={pwsBasicInfo} />
                </>
              )}

              <div className="sticky-sherpa-banner-btn">
                <button
                  className="btn sticky-sherpa-banner-contact"
                  onClick={() => {
                    setShowSherpaModal(true);
                  }}
                >
                  {t('pws.view.sherpa.banner.btn1')}
                </button>

                {showShopMyFavoritesButton && (
                  <button
                    className="btn go-pwp-view"
                    onClick={() => {
                      var siteName = pwsBasicInfo?.pws_name;
                      if (siteName) {
                        window.location.href =
                          getHybrisHost(country) +
                          '/site/' +
                          siteName +
                          '/storefront';
                      }
                    }}
                  >
                    {t('pws.view.sherpa.banner.btn2')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx('sticky-sherpa-banner-container-mini', {
            hidden: !displayMini,
          })}
        >
          <div className="sticky-sherpa-banner">
            <div className="sticky-sherpa-banner-image">
              <PwsProfileImage pwsBasicInfo={pwsBasicInfo} />
            </div>
            <div className="sticky-sherpa-banner-description">
              {t('pws.view.sherpa.banner.mini.description')}
              <div
                className="name open-sticky-sherpa-banner"
                onClick={() => {
                  setDisplayMini(false);
                  setSessionStorage(curShakleeId + 'Mini', false);
                }}
              >
                {pwsBasicInfo?.userName}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SherpaBannerModal
        className="pws-contact-me-popup"
        show={showSherpaModal}
        onHide={hideSherpaModal}
        pwsBasicInfo={pwsBasicInfo}
        showShopMyFavoritesButton={showShopMyFavoritesButton}
      />
    </>
  );
};

export default memo(SherpaBanner);
