/* eslint-disable array-callback-return */
import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { isBottledPill } from 'assets/js/hooks';
import { PRENATAL_MULTIV_DRINK } from 'components/results/Recommendations/RecommendationsConstants';

const RecommendationCard = ({ currentPills, selectedAnswers, meologyType }) => {
  const ref = useRef();
  const { t } = useTranslation();
  const { name, prenatal_name } = selectedAnswers || {};

  const getCurrentPills = currentPills => {
    if (currentPills && !_.isEmpty(currentPills)) {
      // let sliceCount = 0;
      // let sliceIndex1 = false;
      // let sliceIndex2 = false;
      let pillCount = 0;
      let pillsLessorEqualto8 = false;
      let firstArray = [];
      let secondArray = [];
      let pillData = [];
      // If pill count is less than or equal to 8 then display - no changes
      // Else Sort it based on pill quantity
      // then start allocating to first array until it reaches 8
      // if overflowing 8, allocate that to second array
      // Always try on first array, if it exceeds put it on second array
      currentPills = currentPills
        .filter(item => item.selected)
        .filter(
          ({ sku }) => !isBottledPill(sku) && sku !== PRENATAL_MULTIV_DRINK
        );
      currentPills.some((item,index) => {
        pillCount = pillCount + item.quantity;
        pillsLessorEqualto8 = pillCount <= 8;
      });

      if(pillsLessorEqualto8){
        pillData.push(currentPills);
      }
      else
      {
        currentPills = currentPills.sort((a,b) =>  b.quantity - a.quantity);
        currentPills.forEach((item,index) => {
          if(firstArray.length === 0){
            firstArray.push(item);
          }
          else
          {
            let firstArrayPillCount = 0;
            firstArray.forEach((firstItem,index) => {
              firstArrayPillCount = firstArrayPillCount + firstItem.quantity;
            });
            if(firstArrayPillCount + item.quantity <= 8) {
              firstArray.push(item);
            } else {
              secondArray.push(item);
            }
          }
        });
        pillData.push(firstArray, secondArray);
      }

      // if (sliceIndex1) {
      //   pillData.push(currentPills.slice(0, sliceIndex1));
      //   if (sliceIndex1 < currentPills.length) {
      //     pillData.push(currentPills.slice(sliceIndex1, currentPills.length))
      //   }
        // currentPills.slice(sliceIndex1, currentPills.length).some((item,index) => {
        //   sliceCount = sliceCount + item.quantity;
        //   if (sliceCount === 8) {
        //     sliceIndex2 = index + 1;
        //     return true
        //   }
        //   if (sliceCount > 8) {
        //     sliceIndex2 = index;
        //     return true
        //   }
        // })
        // if (sliceIndex2) {
        //   pillData.push(currentPills.slice(sliceIndex1, currentPills.length).slice(0, sliceIndex2));
        // } else {
        //   if (sliceIndex1 < currentPills.length) {
        //     pillData.push(currentPills.slice(sliceIndex1, currentPills.length))
        //   }
        // }
      // } else {
      //   pillData.push(currentPills)
      // }
      return pillData
    }
    return false;
  };

  const slicedPills = getCurrentPills(currentPills);
  const slicedPillsLength = slicedPills ? slicedPills.length : 0;

  return (
    <div className={`sachet-image-wrap`}>
      <ul ref={ref} className="sachet-image-origin">
        {slicedPills &&
          slicedPills.map((item, index) => (
            <li className="sachet-image" key={index}>
              <div className="sachet-image-index">
                {slicedPillsLength > 1 && `Pack ${index + 1}/${slicedPillsLength}`}
              </div>
              <div className="sachet-image-name">
                {t('ui.recommendaiton.hi') + ` ${meologyType === "PRENATAL" ? (prenatal_name ||'') : (name || '')}`}
              </div>
              <div className="pills-subtitle">{t('ui.personalized_supplements')}</div>
              <ul className="pills-list">
                {item &&
                  item.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      {subItem.quantity}&nbsp;&nbsp;&nbsp;{subItem.name}
                    </li>
                  ))}
              </ul>
              {/* <p className="text-caution">
                      {t('ui.recommendation.out-of-children')}
                    </p> */}
              <div className="sachet-image-footer">
                <div className="img-container">
                  <img
                    src={
                      meologyType === "PRENATAL" 
                      ? 'https://images.shaklee.com/meology/prenatal/sachetlogo_new.svg' 
                      : 'https://images.shaklee.com/meology/images/sachetlogo.svg'
                    }
                    alt="logo"
                  />
                </div>
              </div>
            </li>
          ))}
	      {meologyType === "PRENATAL" &&
	      <li className="sachet-powder">
		      <img src="https://images.shaklee.com/meology/pills/small/sachet-powder.png?v=1" alt=""/>
	      </li>
	      }
      </ul>
    </div>
  );
};

export default memo(RecommendationCard);
