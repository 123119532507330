import React, { useMemo, useContext, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MarketingContext } from 'components/Marketing/Marketing';
import { Toggle } from 'components/Marketing';
import CtaBtn from 'components/common/CtaBtn';
import { useHideMeology } from 'assets/js/hooks';
import { useWindowScroll, useOffsetTop } from 'assets/js/helper';
import cx from 'classnames'


const StickyHeader = () => {
  const {
    t,
    landingPage,
    kidsLandingPage,
    prenatalLandingPage,
    history,
    location,
    isCalledRecomendation,
    page,
    setPage,
    isRegularMarketing,
    isKidsMarketing,
    isPrenatalMarketing,
  } = useContext(MarketingContext);
  const marketingType = useSelector(
    state => state.QuestionReducer.marketingType
  );
  const { isShowToggle } = useHideMeology();
  const [hideTagline, sethideTagline] = useState(false);
  const topRef = useRef(null)

  const titleImg = useMemo(() => {
    if (isRegularMarketing)
      return 'https://images.shaklee.com/meology/icons/me-logo-1.svg?v=2';
    if (isKidsMarketing)
      return 'https://images.shaklee.com/meology/images/kids-logo-black.svg?v=1';
    if (isPrenatalMarketing) return ['https://images.shaklee.com/meology/prenatal/mkt_prenatal_logo.svg','https://images.shaklee.com/meology/prenatal/prenatal_white_logo.svg'];
  }, [isKidsMarketing, isPrenatalMarketing, isRegularMarketing]);
  const { y: windowScrollY } = useWindowScroll();
  const  offsetTop = useOffsetTop(topRef);

  useEffect(() => {
    if(!isPrenatalMarketing || !topRef.current) return
    if(offsetTop > 150) {
      sethideTagline(true)
      return
    }
     if (windowScrollY < 120) {
      sethideTagline(false)
      return
    }
    if(windowScrollY >= offsetTop) {
      sethideTagline(true)
    } else {
      sethideTagline(false)
    }
  }, [isPrenatalMarketing, offsetTop, windowScrollY])

  return (
    <>
    <div id="main-content" ref={topRef} className={`brand-container ${isPrenatalMarketing ? 'brand-container-prenatal' : ''}`}>
      <div className={cx('brand', {
        'marketing-2': isShowToggle && marketingType === 2
      })}>
        {marketingType === 1 && (
          <button
            className={cx('brand-icon', {
              'brand-icon-white': isPrenatalMarketing
            })}
            type="button"
            onClick={() => {
              history.push({
                ...location,
                pathname: '/',
              });
            }}
          >
            <span className="sr-only">Back to home page</span>
          </button>
        )}

        {isShowToggle && marketingType === 2 ? (
          <Toggle
            isCalledRecomendation={isCalledRecomendation}
            setPage={setPage}
          />
        ): (marketingType === 1 ? <></> : <div />)}

        <div className={`brand-title ${isPrenatalMarketing ? 'brand-logo-text' : ''}`} data-text={hideTagline ? '' : t('ui.prenatal_marketing.page_eyebrow')}>
          {isPrenatalMarketing 
          ? <>
          <img src={titleImg[0]} alt="meology" className="brand-logo" />
          <img src={titleImg[1]} alt="meology" className="brand-logo" />
          </>
          : <img src={titleImg} alt="meology" className="brand-logo" />
        }
        </div>
        {isRegularMarketing && (
          <div className="btn-container">
            <CtaBtn
              type="regular"
              status={landingPage}
              eventLabel="Marketing page cta section"
            />
          </div>
        )}
        {isKidsMarketing && (
          <div className="btn-container">
            <CtaBtn
              type="kids"
              status={kidsLandingPage }
              eventLabel="Marketing page cta section"
            />
          </div>
        )}
        {isPrenatalMarketing && (
          <div className="btn-container">
            <CtaBtn
              type="prenatal"
              status={prenatalLandingPage}
              eventLabel="Marketing Page"
              eventAction="Start Here 1"
              page={page}
            />
          </div>
        )}
      </div>
    </div>
    {isShowToggle && marketingType === 2 && (
      <Toggle
        isCalledRecomendation={isCalledRecomendation}
        setPage={setPage}
      />
    )}
    </>
  );
};

export default StickyHeader;
