import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Button = styled.button`
      position: absolute;
      left: 40px;
      background: transparent;
      border: none;

      @media only screen and (max-width: 768px) {
        left: 15px;
      }
`;

const Text = styled.span`
      padding-left: 8px;
      font-family: Gilmer-Medium;
      font-size: 18px;
      letter-spacing: 0.48px;
      font-weight: 500;
      vertical-align: middle;
      @media only screen and (max-width: 768px) {
        font-size: 15px;
        letter-spacing: 0.4px;
      }
`;

const Image = styled.img`
      @media only screen and (max-width: 768px) {
        width: 20px;
      }
`;

const BackBtn = ({
  className,
  onClickEvent,
}) => {
  const { t } = useTranslation();

  return (
    <Button className={className}
        onClick={onClickEvent}
        tabIndex="0"
        aria-label="Back"
      >
        <Image
          src="https://images.shaklee.com/meology/icons/arrow_back.svg"
          alt="back"
        />
          <Text>{t('ui.reco.prenatal_rsw.pdp.back')}</Text>
      </Button>
  );
};

export default memo(BackBtn);
