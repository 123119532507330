import { INCREMENT_COUNTER, DECREMENT_COUNTER } from './exampleConstants';

const initialState = {
    data: 42
};

const exampleReducer = (state = initialState, action) => {
	switch (action.type) {
		case INCREMENT_COUNTER:
			state = {
				...state,
				data: state.data + 1
			}
			break;
		case DECREMENT_COUNTER:
			state = {
				...state,
				data: state.data - 1
			}
			break;
		default:
			break;
	}

	return state;
};

export default exampleReducer;