import {
  RECOMMENDATION_POST,
  SET_ADD_TO_CART_RESULT,
  PRODUCT_LIFESHAKE_INFO,
  PRODUCT_BOOST_INFO,
  PRODUCT_INFO,
  CONCEPTION_PRODUCT_INFO,
  POSTNATAL_PRODUCT_INFO,
  PREGNANT_PRODUCT_INFO,
  PRODUCT_CODES,
  HYBRIS_PRODUCT_INFO,
  PRENATAL_STEP,
  SET_REPRODUCTIVE_STATUS,
  HYBRIS_CORE_PRODUCT_INFO
} from './RecommendationsConstants';

const initialState = {
  data: [],
  addToCartResult: {},
  product_info: null,
  conception_product_info: null,
  postnatal_product_info: null,
  pregnant_product_info: null,
  product_lifeshake_info: null,
  product_boost_info: null,
  product_codes: null,
  hybrisProductsInfo: null,
  prenatalStep: '',
  reproductiveStatus: '',
  hybrisCoreProductsInfo: null
};

const RecommendationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RECOMMENDATION_POST:
      state = {
        ...state,
        data: action.payload,
      };
      break;
    //  for future purpose, will delete if not needed
    case PRODUCT_CODES:
      state = {
        ...state,
        product_codes: action.payload,
      };
      break;
    case SET_ADD_TO_CART_RESULT:
      state = {
        ...state,
        addToCartResult: action.data,
      };
      break;
    //  for future purpose, will delete if not needed
    case PRODUCT_INFO:
      state = {
        ...state,
        product_info: action.payload,
      };
      break;
    case CONCEPTION_PRODUCT_INFO:
      state = {
        ...state,
        conception_product_info: action.payload,
      };
      break;
    case POSTNATAL_PRODUCT_INFO:
      state = {
        ...state,
        postnatal_product_info: action.payload,
      };
      break;
    case PREGNANT_PRODUCT_INFO:
      state = {
        ...state,
        pregnant_product_info: action.payload,
      };
      break;
    case PRODUCT_LIFESHAKE_INFO:
      state = {
        ...state,
        product_lifeshake_info: action.payload,
      };
      break;
    case PRODUCT_BOOST_INFO:
      state = {
        ...state,
        product_boost_info: action.payload,
      };
      break;

    case HYBRIS_PRODUCT_INFO:
      state = {
        ...state,
        hybrisProductsInfo: action.productsInfo,
      };
      break;
    case HYBRIS_CORE_PRODUCT_INFO:
      state = {
        ...state,
        hybrisCoreProductsInfo: action.coreProducts,
      };
      break;
    case PRENATAL_STEP:
      state = {
        ...state,
        prenatalStep: action.data,
      };
      break;
    case SET_REPRODUCTIVE_STATUS:
      state = {
        ...state,
        reproductiveStatus: action.data,
      };
      break;
    default:
      break;
  }

  return state;
};

export default RecommendationsReducer;
