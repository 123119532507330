import React, { memo, forwardRef, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { useEnsuredForwardRef, getIsKidsFromLocal, removeUrlParam, getUrlVars } from 'assets/js/helper';
import { KidsSachet, Toggle } from 'components/results/Kids';
import { useKidsPricePerDay, getImpersonation, useHideMeology } from 'assets/js/hooks';
import { RecommendationContext } from 'components/results/ContextProvider';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions'
import { KidsEditModal } from 'components/results/Kids';
import { useTranslation } from 'react-i18next';

const Banner = (
  props,
  topRef
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ensuredTopRef = useEnsuredForwardRef(topRef);
  const [showKidsEditModal, setShowKidsEditModal] = useState(false);
  const { currentPillsDailyTablets, isView } = useContext(RecommendationContext);
  const pricePerDay = useKidsPricePerDay();
  const { isImpersonation } = getImpersonation();
  const location = useLocation();
  const { isShowToggle } = useHideMeology();

  const { mode, type } = getUrlVars();
  useEffect(() => {
    if (!isImpersonation && (mode === 'edit' && type === 'kids') && getIsKidsFromLocal()) {
      setTimeout(() => {setShowKidsEditModal(true)}, 2000);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="kids-banner" ref={ensuredTopRef}>
      <div className="kids-banner-top">
        {isShowToggle && <Toggle meologyType="KIDS" />}
        <img
          className="kids-banner-top-logo"
          src="https://images.shaklee.com/meology/images/kids-logo-black.svg"
          alt="Meology Kids"
        />
      </div>
      <h2 className="kids-banner-title">
        {t('ui.recommendation.meology_kids_title1')}
      </h2>
      <p className="kids-banner-subtitle">
        {t('ui.recommendation.meology_kids_title2')}
      </p>
      <KidsSachet />
      {!isImpersonation && !isView && (
        <button
          className="kids-banner-edit"
          aria-label="edit button"
          onClick={() => {
            dispatch(
              pushInteractionToDataLayer({
                eventCategory: 'Meology Kids',
                eventAction: 'Edit',
                eventLabel: 'edit kids sachet',
              })
            );
            setShowKidsEditModal(true);
          }}
        >
          {t('ui.recommendation.meology_kids_title3')}
        </button>
      )}

      <p className="kids-banner-price">
        {currentPillsDailyTablets}{' '}
        {t('ui.recommendation.meology_kids_title4_1')} |{' '}
        {t('ui.recommendation.meology_kids_title4_2')} | ${pricePerDay}{' '}
        {t('ui.recommendation.meology_kids_title4_3')}
      </p>
      <KidsEditModal
        show={showKidsEditModal}
        closeModal={() => {
          setShowKidsEditModal(false);
          if (mode === 'edit' && type === 'kids') {
            removeUrlParam('mode', location);
            removeUrlParam('type', location);
          }
        }}
        setShowKidsEditModal={setShowKidsEditModal}
      />
    </div>
  );
};

export default memo(forwardRef(Banner));
