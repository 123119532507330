import React, { memo, useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';
import { Modal } from 'react-bootstrap';
import PwsProfileImage from './PwsProfileImage';
import PwsShareLink from './PwsShareLink';
import PhoneImg from 'assets/images/Phone.svg';
import ResultSelect from 'components/results/ResultSelect';
import { wordCount, getHybrisHost } from 'assets/js/helper';

const SherpaBannerModal = ({ show, onHide, className, pwsBasicInfo, showShopMyFavoritesButton }) => {
  const { isUserLoggedin, country } = useSelector(state => state.user);
  const [interestOpinion, setInterestOpinion] = useState('');
  const [firstNameValidate, setFirstNameValidate] = useState(true);
  const [lastNameValidate, setLastNameValidate] = useState(true);
  const [emailValidate, setEmailValidate] = useState(true);
  const [methodValidate, setMethodValidate] = useState(true);
  const [mobileValidate, setMobileValidate] = useState(true);
  const messageRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const methodEmailRef = useRef(null);
  const methodCallRef = useRef(null);
  const methodTextRef = useRef(null);
  const { t } = useTranslation();
  const interestData = [
    { code: 'Nutrition', name: t('pws.view.popup.interest.nutrition') },
    { code: 'Beauty', name: t('pws.view.popup.interest.skin') },
    { code: 'Weight Loss', name: t('pws.view.popup.interest.weight') },
    { code: 'Home', name: t('pws.view.popup.interest.home') },
    {
      code: 'Performance',
      name: t('pws.view.popup.interest.healthNutrition'),
    },
    {
      code: 'Opportunity',
      name: t('pws.view.popup.interest.opportunity'),
    },
  ];

  const {
    REACT_APP_PWS_CONTACT_ME_SUBMIT_URL: CONTACT_ME_SUBMIT_URL,
    REACT_APP_PWS_CONTACT_ME_FIRST_NAME_KEY: CONTACT_ME_FIRST_NAME_KEY,
    REACT_APP_PWS_CONTACT_ME_LAST_NAME_KEY: CONTACT_ME_LAST_NAME_KEY,
    REACT_APP_PWS_CONTACT_ME_INTEREST_KEY: CONTACT_ME_INTEREST_KEY,
    REACT_APP_PWS_CONTACT_ME_EMAIL_KEY: CONTACT_ME_EMAIL_KEY,
    REACT_APP_PWS_CONTACT_ME_MOBILE_KEY: CONTACT_ME_MOBILE_KEY,
    REACT_APP_PWS_CONTACT_ME_METHOD_KEY: CONTACT_ME_METHOD_KEY,
    REACT_APP_PWS_CONTACT_ME_MESSAGE_KEY: CONTACT_ME_MESSAGE_KEY,
    REACT_APP_PWS_CONTACT_ME_OID_KEY: CONTACT_ME_OID_KEY,
    REACT_APP_PWS_CONTACT_ME_SHERPA_ID_KEY: CONTACT_ME_SHERPA_ID_KEY,
    REACT_APP_PWS_CONTACT_ME_REFERRING_PAGE_KEY: CONTACT_ME_REFERRING_PAGE_KEY,
  } = process.env;

  const changeSelectCode = useCallback(item => {
    setInterestOpinion(item.code);
  }, []);
  const validateName = () => {
    const nameRegex = new RegExp("^[a-zA-Z\\s.,'`\\-]+$");
    const firstName = firstNameRef?.current?.value;
    const lastName = lastNameRef?.current?.value;
    setFirstNameValidate(
      _.isEmpty(firstName?.trim()) ? false : nameRegex.test(firstName?.trim())
    );
    setLastNameValidate(
      _.isEmpty(lastName?.trim()) ? false : nameRegex.test(lastName?.trim())
    );
  };
  const validateEmail = () => {
    const email = emailRef?.current?.value;
    const emailRegex = new RegExp(
      /^[A-Za-z\d._+-]+@[A-Za-z\d.-]*[A-Za-z\d]+\.[A-Za-z]{2,4}$/
    );
    setEmailValidate(
      _.isEmpty(email?.trim()) ? false : emailRegex.test(email?.trim())
    );
  };
  const validateMobile = e => {
    const mobile = e?.target?.value;

    let phoneStr = mobile?.replace(/[^0-9]/g, '');
    if (phoneStr !== '' && phoneStr?.length < 10) {
      setMobileValidate(false);
    } else {
      setMobileValidate(true);
    }
  };
  const validateMethod = () => {
    const methodEmail = methodEmailRef?.current?.checked;
    const methodCall = methodCallRef?.current?.checked;
    const methodText = methodTextRef?.current?.checked;
    setMethodValidate(methodEmail || methodCall || methodText);
  };
  const validateForm = () => {
    validateName();
    validateEmail();
    validateMethod();
  };

  useEffect(() => {
    wordCount(messageRef?.current, 'pws-personalize-message-counter');
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = e => {
    if (
      !firstNameValidate ||
      !lastNameValidate ||
      !emailValidate ||
      !methodValidate ||
      !mobileValidate
    ) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      show={show}
      className={className}
      centered
      {...(onHide && { onHide })}
    >
      <Modal.Header closeButton={Boolean(onHide)}>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="popup-contact-me" className="popup-contact-me">
          <div
            className={cx('popup-contact-me-left', {
              'full-width': isUserLoggedin,
            })}
          >
            <div className="popup-contact-me-left-header">
              {t('pws.view.sherpa.banner.btn1')}
            </div>
            <div className="popup-contact-me-left-image">
              <PwsProfileImage pwsBasicInfo={pwsBasicInfo} />
            </div>
            <div className="popup-contact-me-left-details">
              <div className="name">{pwsBasicInfo?.userName}</div>
              {pwsBasicInfo?.phoneNumber && (
                <div className="phone">
                  <img src={PhoneImg} alt="" />
                  {pwsBasicInfo?.phoneNumber}
                </div>
              )}
              <PwsShareLink pwsBasicInfo={pwsBasicInfo} />
            </div>
            {showShopMyFavoritesButton && (
              <div className="popup-contact-me-left-btn">
                <button
                  className="btn go-pwp-view"
                  onClick={() => {
                    var siteName = pwsBasicInfo?.pws_name;
                    if (siteName) {
                      window.location.href =
                        getHybrisHost(country) +
                        '/site/' +
                        siteName +
                        '/storefront';
                    }
                  }}
                >
                  {t('pws.view.sherpa.banner.btn2')}
                </button>
              </div>
            )}
          </div>
          {!isUserLoggedin && (
            <div className="popup-contact-me-right">
              <div className="popup-contact-me-right-message">
                {t('pws.view.popup.message')}
              </div>
              <div className="popup-contact-me-right-name">
                {`${t('pws.view.popup.hello')} ${
                  pwsBasicInfo?.userName?.split(' ')[0]
                }`}
                ,
              </div>
              <form
                id="pws-contact-me-popup"
                className="pws-basic-form"
                action={CONTACT_ME_SUBMIT_URL}
                method="POST"
                onSubmit={onSubmit}
              >
                <input
                  type="hidden"
                  name="retURL"
                  value={window.location.href}
                />
                <input
                  type="hidden"
                  name="oid"
                  value={CONTACT_ME_OID_KEY}
                />
                <input
                  type="hidden"
                  name="submit"
                  value="Submit"
                />
                <input
                  type="hidden"
                  name={CONTACT_ME_SHERPA_ID_KEY}
                  value={pwsBasicInfo?.shakleeId}
                />
                <input type="hidden" name="lead_source" value="Storefront" />
                <input
                  type="hidden"
                  name={CONTACT_ME_REFERRING_PAGE_KEY}
                  value={pwsBasicInfo?.pws_name}
                />

                <div className="pws-basic-form-section">
                  <div className="form-group contact-me-name">
                    <label htmlFor="contact-me-first-name">{t('pws.view.popup.name')}</label>
                    <div className="contact-me-name-container">
                      <input
                        type="text"
                        placeholder={t('pws.view.popup.first.name.placeholder')}
                        className="form-control contact-me-first-name"
                        id="contact-me-first-name"
                        name={CONTACT_ME_FIRST_NAME_KEY}
                        maxLength="40"
                        size="20"
                        ref={firstNameRef}
                        onChange={validateName}
                      />
                      {!firstNameValidate && (
                        <div className="pws-error-wrap">
                          {t('pws.view.popup.name.error')}
                        </div>
                      )}
                    </div>
                    <div className="contact-me-name-container">
                      <input
                        type="text"
                        placeholder={t('pws.view.popup.last.name.placeholder')}
                        className="form-control contact-me-last-name"
                        id="contact-me-last-name"
                        name={CONTACT_ME_LAST_NAME_KEY}
                        size="20"
                        maxLength="80"
                        ref={lastNameRef}
                        onChange={validateName}
                      />
                      {!lastNameValidate && (
                        <div className="pws-error-wrap">
                          {t('pws.view.popup.name.error')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact-me-interest">
                      {t('pws.view.popup.interest')}
                    </label>
                    <div className="contact-me-interest-container">
                      <input
                        type="hidden"
                        name={CONTACT_ME_INTEREST_KEY}
                        id="contact-me-interest"
                        value={interestOpinion}
                      />
                      <ResultSelect
                        data={interestData}
                        defaultValue={t('pws.view.popup.interest.placeholder')}
                        onChange={changeSelectCode}
                        className={cx('pws-interest-select', {
                          default: !interestOpinion,
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact-me-email">{t('pws.view.popup.email')}</label>
                    <div className="contact-me-email-container">
                      <input
                        type="text"
                        placeholder={t('pws.view.popup.email.placeholder')}
                        className="form-control contact-me-email"
                        id="contact-me-email"
                        name={CONTACT_ME_EMAIL_KEY}
                        maxLength="100"
                        ref={emailRef}
                        onChange={validateEmail}
                      />
                      {!emailValidate && (
                        <div className="pws-error-wrap">
                          {t('pws.view.popup.email.error')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact-me-mobile">{t('pws.view.popup.mobile')}</label>
                    <div className="contact-me-mobile-container">
                      <MaskedInput
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        type="text"
                        placeholder={t('pws.view.popup.mobile.placeholder')}
                        className="form-control contact-me-mobile"
                        id="contact-me-mobile"
                        name={CONTACT_ME_MOBILE_KEY}
                        maxLength="20"
                        ref={mobileRef}
                        onChange={validateMobile}
                      />
                      {!mobileValidate && (
                        <div className="pws-error-wrap">
                          {t('pws.view.popup.mobile.error')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwp-contact-me-method">
                      {t('pws.view.popup.method')}
                    </label>
                    <div id="pwp-contact-me-method" className="pwp-contact-me-method-container pwp-contact-me-method">
                      <div className="checkbox-container">
                        <div className="shk-checkbox-square shk-green-checkbox">
                          <label htmlFor="contact-me-method-email">
                            {t('pws.view.popup.method.email')}
                            <input
                              name={CONTACT_ME_METHOD_KEY}
                              id="contact-me-method-email"
                              value="Email"
                              type="checkbox"
                              className="contact-me-method-email"
                              ref={methodEmailRef}
                              onChange={validateMethod}
                            />
                            <span className="shk-checkbox-label"></span>
                          </label>
                        </div>
                        <div className="shk-checkbox-square shk-green-checkbox">
                          <label htmlFor="contact-me-method-call">
                            {t('pws.view.popup.method.call')}
                            <input
                              name={CONTACT_ME_METHOD_KEY}
                              id="contact-me-method-call"
                              value="Call"
                              type="checkbox"
                              className="contact-me-method-call"
                              ref={methodCallRef}
                              onChange={validateMethod}
                            />
                            <span className="shk-checkbox-label"></span>
                          </label>
                        </div>
                        <div className="shk-checkbox-square shk-green-checkbox">
                          <label htmlFor="contact-me-method-text">
                            {t('pws.view.popup.method.text')}
                            <input
                              name={CONTACT_ME_METHOD_KEY}
                              id="contact-me-method-text"
                              value="Text"
                              type="checkbox"
                              className="contact-me-method-text"
                              ref={methodTextRef}
                              onChange={validateMethod}
                            />
                            <span className="shk-checkbox-label"></span>
                          </label>
                        </div>
                      </div>
                      {!methodValidate && (
                        <div className="pws-method-error-wrap">
                          {t('pws.view.popup.method.error')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-with-count-limit">
                      <textarea
                        id="contact-me-message"
                        ref={messageRef}
                        className="form-control pws-personalize-message"
                        name={CONTACT_ME_MESSAGE_KEY}
                        maxLength="180"
                        rows="4"
                        placeholder={t('pws.view.popup.message.placeholder')}
                      ></textarea>
                    </div>
                  </div>
                  <span className="contact-me-popup-required">
                    {t('pws.view.popup.required')}
                  </span>
                  <button
                    className="contact-me-popup-btn contact-me-popup-btn"
                    onClick={() => {
                      validateForm();
                    }}
                  >
                    {t('pws.view.popup.submit')}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default memo(SherpaBannerModal);
