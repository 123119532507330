import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import rootReducer from '../reducers/rootReducer';
import { getDataLayer } from '../../components/analytics/analyticsActions';
import {
  PUSH_TO_DATALAYER,
  RESET_DATALAYER,
} from '../../components/analytics/analyticsConstants';

const configureStore = preloadedState => {
  const analytics = ({ getState }) => next => action => {
    if (!window.google_tag_manager) return next(action);
    const _dataLayer = window.dataLayer || [];
    const _gtm = window.google_tag_manager['GTM-P2BV9MB'];

    if (action.type === PUSH_TO_DATALAYER) {
      _dataLayer.push(getDataLayer(getState()));
    } else if (action.type === RESET_DATALAYER) {
      _gtm['dataLayer'].reset();
    }
    return next(action);
  };

  const middlewares = [thunk];
  middlewares.push(analytics);
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const storeEnhancers = [middlewareEnhancer];
  /*const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		  // Specify extension�s options like name, actionsBlacklist, actionsCreators, serialize...
		}) : compose;
	const enhancer = composeEnhancers(
	  ...storeEnhancers
	);*/
  const enhancer = composeWithDevTools(...storeEnhancers);
  
//   const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist: ['QuestionReducer'],
//   };
//   const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(rootReducer, preloadedState, enhancer);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers/rootReducer', () => {
        const newRootReducer = require('../reducers/rootReducer').default;
        store.replaceReducer(newRootReducer);
      });
    }
  }

  return store;
};


export default configureStore;