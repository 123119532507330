import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserInfo } from '../questions/QuestionActions';
import Cookies from 'js-cookie';
import { getImpersonation } from 'assets/js/hooks';
import { getUrlVars } from 'assets/js/helper';

const mapState = state => {
  return {
    user_info: state.QuestionReducer.user_info,
    user: state.user,
  };
};

const actions = {
  getUserInfo,
};

class MainLayout extends Component {
  componentDidMount() {
    if (this.props.user_info) return;

    let query = '';
    let hasSponsorIdInCookie = false;
    const params = new URLSearchParams();
    const { shakleeId } = this.props.user;
    const { isSponsor } = getImpersonation();
    const {
      ranMID: ranMIDFromUrl,
      sponsorId: sponsorIdFromUrl,
      pwsName: pwsNameFromUrl,
      site: siteFromUrl,
    } = getUrlVars() || {};
    const sponsorIdFromCookie = Cookies.get('sponsorId') || '';
    const pwsNameFromCookie = Cookies.get('pwsName');
    const siteFromCookie = Cookies.get('SITE');
    const site = siteFromUrl || siteFromCookie

    if (site) {
      params.append('pwsName', site);
    } else if (isSponsor) {
      hasSponsorIdInCookie = true;
      params.append('sponsorId', sponsorIdFromCookie);
    } else if (shakleeId) {
      params.append('shakleeId', shakleeId);
    } else if (ranMIDFromUrl || sponsorIdFromUrl || pwsNameFromUrl) {
      if (ranMIDFromUrl) {
        params.append('sponsorId', 'Rakuten');
      } else if (sponsorIdFromUrl) {
        params.append('sponsorId', sponsorIdFromUrl);
      }

      if (pwsNameFromUrl) {
        params.append('pwsName', pwsNameFromUrl);
      }
    } else if (sponsorIdFromCookie) {
      hasSponsorIdInCookie = true;
      params.append('sponsorId', sponsorIdFromCookie);
    } else if (pwsNameFromCookie) {
      params.append('pwsName', pwsNameFromCookie);
    }

    query = params.toString();
    const url = hasSponsorIdInCookie
      ? `/sponsor/route_cookie?${query}`
      : `/sponsor/get?${query}`;

    if (query) {
      this.props.getUserInfo(url);
    }
  }
  render() {
    const { children } = this.props;
    return (
      <>
        <div>{children}</div>
      </>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapState, actions)(MainLayout);
