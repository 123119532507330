import React from 'react';
import cx from 'classnames';
import { useHideMeology } from 'assets/js/hooks';
import CtaBtn from 'components/common/CtaBtn';
import LearnMoreBtn from 'components/common/LearnMoreBtn';

const SectionTwo = ({
  t,
  landingPage,
  kidsLandingPage,
  prenatalLandingPage,
  footer,
}) => {
  const { hideKids, hidePrenatal, isOne } = useHideMeology();

  return (
    <div className="cta-container">
      <div className="cta-section">
        <div
          className={cx('cta-section-card', {
            'two': isOne,
            'two-items': !isOne && (hideKids || hidePrenatal),
          })}
        >
          <div
            className={cx('cta-section-card-regular', {
              footer: footer,
            })}
          >
            <div className="logo">
              <img
                src={isOne ? "https://images.shaklee.com/meology/images/meology-white.svg" : "https://images.shaklee.com/meology/icons/me-logo.svg"}
                alt=""
              />
            </div>
            {!isOne &&
              <>
                <h3 className="title">{t('ui.landing_page_1.two_doorways_title_3')}</h3>
                {!footer && <div className="sub_title">{t('ui.landing_page_1.two_doorways_title_4')}</div>}
              </>
            }
            <div
              className={cx({
                'btn-group': isOne,
              })}
            >
              <div className="btn-container">
                <CtaBtn type='regular' status={landingPage} eventLabel='home page cta section' />
              </div>
              <LearnMoreBtn
                ariaLabel="Learn More about Meology for adults"
                pathName="/meology"
              />
            </div>
          </div>
          {!hidePrenatal && <div
            className={cx('cta-section-card-prenatal', {
              footer: footer,
            })}
          >
            <div className="logo">
              <img
                src={"https://images.shaklee.com/meology/prenatal/prenatal_logo.svg?v=01"}
                alt=""
              />
            </div>
                <h3 className="title">{t('ui.landing_page_1.additional_cta_module_title_9')}</h3>
                {!footer && <div className="sub_title">{t('ui.landing_page_1.three_doorways_title2')}</div>}
            <div >
              <div className="btn-container">
                <CtaBtn type='prenatal' status={prenatalLandingPage} eventLabel='home page cta section' />
              </div>
              <LearnMoreBtn
                ariaLabel="Learn More about Meology for prenatal"
                pathName="/meologyprenatal"
              />
            </div>
          </div>}
          {!hideKids &&
            <div
              className={cx('cta-section-card-kids', {
                footer: footer,
              })}
            >
              <div className="logo">
                <img
                  src={"https://images.shaklee.com/meology/images/landing/desktop/me-kids-logo.png"}
                  alt=""
                />
              </div>
              <h3 className="title">{t('ui.landing_page_1.two_doorways_title_8')}</h3>
              {!footer && <div className="sub_title">{t('ui.landing_page_1.two_doorways_title_9')}</div>}
              <div className="btn-container">
                <CtaBtn type='kids' status={kidsLandingPage} eventLabel='home page cta section' />
              </div>
              <LearnMoreBtn
                ariaLabel="Learn More about Meology for kids"
                pathName="/meologykids"
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
