import React, { memo, useContext } from 'react';
import cx from 'classnames';
import { useMeologyHistory } from 'assets/js/hooks';
import { RecommendationContext } from 'components/results/ContextProvider';
import { useTranslation } from "react-i18next";

const Sachet = () => {
  const { kids_info, autoshipKidsId } = useMeologyHistory();
  const { setShowOrderManageModal } = useContext(RecommendationContext);
	const { t } = useTranslation();
  return (
    <div className="kids-sachet-wrapper">
      {kids_info?.length > 0 &&
        kids_info?.map(({ icon, id, name }) => {
          const showIndicator = autoshipKidsId?.map(item => item.split('-')[2])?.includes(id.split('-')[2]);
          return (
            <div
              className={cx('kids-sachet', { 'show-indicator': showIndicator })}
              key={id}
            >
              <h3 className="kids-sachet-name">{t('ui.recommendaiton.hi')} {name || ''}</h3>
              {icon && (
                <img
                  alt={icon}
                  className="kids-sachet-icon"
                  src={`https://images.shaklee.com/meology/sachet_icons/${icon}.svg`}
                />
              )}
              <img
                alt="Meology Kids Logo"
                className="kids-sachet-logo"
                src="https://images.shaklee.com/meology/images/kids-logo-black.svg"
              />
              {showIndicator && (
                <button
                  className="kids-sachet-indicator"
                  onClick={() => {
                    setShowOrderManageModal(true);
                  }}
                >
                  {t('ui.recommendation.meology_kids_add_to_cart_title16')}
                </button>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default memo(Sachet);
