import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import ImageProfile from 'assets/images/profile_img.png';
import { getHybrisHost } from 'assets/js/helper';

const PwsProfileImage = ({ pwsBasicInfo }) => {
  const { country } = useSelector(state => state.user);
  if (pwsBasicInfo?.isNameImage && pwsBasicInfo?.accountName) {
    const accountNameArray = pwsBasicInfo?.accountName?.split(' ');
    const accountNameFirstLetter = accountNameArray[0]?.substring(0, 1);
    const accountNameSecondLetter = accountNameArray[1]?.substring(0, 1);
    return (
      <div className="pws-profile-name pws-profile-img">
        {accountNameFirstLetter}
        {accountNameSecondLetter}
      </div>
    );
  } else if (pwsBasicInfo?.profileImage?.url) {
    let profileImageUrl = pwsBasicInfo?.profileImage?.url;
    const imgUrl = profileImageUrl.match(/(https?:\/\/)(www\.)?\w+\.\w+/)
      ? profileImageUrl
      : getHybrisHost(country) + profileImageUrl;
    return (
      <div
        className="profile-img pws-profile-img"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      ></div>
    );
  } else {
    return (
      <div
        className="profile-img pws-profile-img"
        style={{
          backgroundImage: `url(${ImageProfile})`,
        }}
      ></div>
    );
  }
};

export default memo(PwsProfileImage);
