import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Trans } from 'react-i18next';
import { useWindowSize } from 'assets/js/helper';
import CopyPageLink from 'components/CopyPageLink'
import CtaBtn from 'components/common/CtaBtn';
import MarketingBanner from 'components/Marketing/MarketingBanner';
import { MarketingContext } from 'components/Marketing/Marketing';

const Banner = () => {
  const {
    isMarketing,
    isRegularMarketing,
    isKidsMarketing,
    isPrenatalMarketing,
    isChanged,
    t,
  } = useContext(MarketingContext);

  const { width: windowWidth } = useWindowSize();
  const isMobileChanged = useMemo(() => windowWidth < 768, [windowWidth]);
  const { landing_page: landingPage, kids_landing_page: kidsLandingPage } = useSelector(
    state => state.QuestionReducer
  );

  return (
    <div className={cx('banner-container', {
        regular: isRegularMarketing,
        kids: isKidsMarketing,
        prenatal: isPrenatalMarketing,
      })}
    >
      {/* Copy Page Link */}
      <CopyPageLink className="copy-page-link-marketing-page" />

      <div className="banner">
        <h2 className="title">
          <Trans>
            {isRegularMarketing && t('ui.marketing.meology_title2')}
            {isKidsMarketing && t('ui.marketing.what_it_is_title2')}
            {isPrenatalMarketing && <></>}
          </Trans>
        </h2>
        {isKidsMarketing && (
          <MarketingBanner
            resource={t('kids.marketing.banner.resource')}
            videoTitle="MEOLOGY™ KIDS | All-In-One Superior Nutrition for Kids from Shaklee Corporation on Vimeo"
            imageAlt="MEOLOGY™ KIDS Banner"
          />
        )}
        {isRegularMarketing && <div className="media"></div>}
        
        {isRegularMarketing && <>
          <div className="sub_title">
            <Trans><h2>{t('ui.marketing.meology_title3')}</h2></Trans>
            <div className="desc">{t('ui.marketing.meology_title4')}</div>
          </div>
          <div className="how-it-works-text">
            <div className="section">
              <div className="step">{t('ui.marketing.how_it_works_title2_step')}</div>
              <div className="num">{t('ui.marketing.how_it_works_title2_step1')}</div>
              <div className="text">{t('ui.marketing.how_it_works_title3')}</div>
              <div className="sub_text">{t('ui.marketing.how_it_works_title4')}</div>
            </div>
            <div className="section">
              <div className="step">{t('ui.marketing.how_it_works_title2_step')}</div>
              <div className="num">{t('ui.marketing.how_it_works_title2_step2')}</div>
              <div className="text">{t('ui.marketing.how_it_works_title6')}</div>
              <div className="sub_text">{t('ui.marketing.how_it_works_title7')}</div>
            </div>
            <div className="section">
              <div className="step">{t('ui.marketing.how_it_works_title2_step')}</div>
              <div className="num">{t('ui.marketing.how_it_works_title2_step3')}</div>
              <div className="text">{t('ui.marketing.how_it_works_title9')}</div>
              <div className="sub_text">{t('ui.marketing.how_it_works_title10')}</div>
            </div>
          </div>
          {
            isMarketing && (
              <div className="cta-button-wrap cta-button-wrap1">
                  <CtaBtn
                    type="regular"
                    status={landingPage}
                    eventLabel="marketing page cta section"
                  />
              </div>
            )
          }
        </>
        }
        {isKidsMarketing && <>
          <div className="all-in-one-container">
            <div className="all-in-one">
              {/*<div className="text">{t('ui.marketing.what_it_is_title3')}</div>*/}
              <img src={isMobileChanged ? t('ui.marketing.all-in-one-superior-nutrition-mobile') : t('ui.marketing.all-in-one-superior-nutrition')} alt="all-in-one-superior-nutrition" className="bg" />
              {
                isMarketing && (
                  <div className="cta-button-wrap cta-button-wrap-kids cta-button-wrap5">
                      <CtaBtn
                        type="kids"
                        status={kidsLandingPage}
                        eventLabel="marketing page cta section"
                      />
                  </div>
                )
              }
            </div>
          </div>
        </>
        }
      </div>
      {isKidsMarketing && <div className="blue-bg-container">
        <div className="blue-bg">
          <div className="text">
            <h2 className="title">{t('ui.marketing.why_title2')}</h2>
            <div className="sub_title">{t('ui.marketing.why_title3')}</div>
          </div>
          <img src={isChanged ? "https://images.shaklee.com/meology/marketing/vitamin-angel-mobile.jpg?v=1" : "https://images.shaklee.com/meology/marketing/vitamin-angel-desktop.jpg?v=1"} alt="vitamin-angel" />
        </div>
      </div>}
    </div>
  );
};

export default Banner;
