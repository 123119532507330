import React, { useRef } from 'react';
import cx from 'classnames';
import Question from './Question';
import Answer from './Answer';
import NextQuestion from './NextQuestion';

const DisplayQuestion = ({
  question,
  pageId,
  widget,
  instructionsModal,
  selectedAnswers,
  updateAnswer,
  value,
  setNextPage,
  pageQuestion,
  setAnswer,
  required,
  answers,
  counter,
  t,
  pageLength,
  pageCategory,
  showNextButton,
  isValidQuestion,
  isMobile,
  setPreSubmitCheck,
  rank,
  shakleeId,
  isClickedRef,
  setIsClickedRef,
  editBtnNextContainer,
  displayNextButton,
	setLogs,
  comeFrom,
  isDatepickerFloat,
}) => {
  let options = answers ? answers : [];
  if (
    question === 'health_goals_priority' &&
    selectedAnswers &&
    selectedAnswers['health_goals']
  ) {
    options = selectedAnswers['health_goals'].map((element, key) => {
      return {
        answer: element
      };
    });
  }

  const btnRef = useRef();
  const btnNextContainer = useRef();

  return (
    <>
      <div 
        className={cx('questionnaire', {
          [question]: question,
        })}
      >
        <Question
          content={question}
          page={pageId}
          widget={widget}
          selectedAnswers={selectedAnswers}
          instructionsModal={instructionsModal}
          comeFrom={comeFrom}
          required={required}
        />
        <Answer
          btnRef={btnRef}
          widget={widget}
          name={question}
          options={options}
          selectedAnswer={value}
          selectedAnswers={selectedAnswers}
          updateAnswer={updateAnswer}
          page={pageId}
          setNextPage={setNextPage}
          setAnswer={setAnswer}
          pageQuestion={pageQuestion}
          isMobile={isMobile}
          showNextButton={showNextButton}
          btnNextContainer={btnNextContainer}
          editBtnNextContainer={editBtnNextContainer}
          rank={rank}
          shakleeId={shakleeId}
          isClickedRef={isClickedRef}
          setIsClickedRef={setIsClickedRef}
          setLogs={setLogs}
          isDatepickerFloat={isDatepickerFloat}
        />
      </div>
      {displayNextButton && (
        <NextQuestion
          btnRef={btnRef}
          updateAnswer={updateAnswer}
          pageId={pageId}
          question={question}
          required={required}
          setNextPage={setNextPage}
          setAnswer={setAnswer}
          pageQuestion={pageQuestion}
          selectedAnswer={value}
          selectedAnswers={selectedAnswers}
          counter={counter}
          pageLength={pageLength}
          pageCategory={pageCategory}
          isValidQuestion={isValidQuestion}
          widget={widget}
          btnNextContainer={btnNextContainer}
          setPreSubmitCheck={setPreSubmitCheck}
          rank={rank}
          shakleeId={shakleeId}
          isClickedRef={isClickedRef}
          setIsClickedRef={setIsClickedRef}
          options={options}
        />
      )}
    </>
  );
};

DisplayQuestion.defaultProps = {
  showNextButton: true
};

export default DisplayQuestion;
