import React, { useMemo, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Nav, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import Accordion from './Accordion';
import Header from '../../header/Header';
import Footer from '../../footer/Footer';
import {
  toThousands,
  getProductSummary,
  getKidsProductSummary,
  getProductDescription,
  getKidsProductDescription,
  getImageUrl,
  enhanceTimeout,
  sortIngredient,
} from 'assets/js/helper';
import { isBottledPill, useMeologyHistory, usePrice } from 'assets/js/hooks';
import RecommendationsDagger from '../Recommendations/RecommendationsDagger';
import { pushProductToDatalayer } from 'components/analytics/analyticsActions';
import {
  ORGANIC_GREENS_BOOSTER,
  LIFE_SHAKE,
  OPTION_SKUS_US,
  PRENATAL_MULTIV_DRINK,
  CHEWABLE_CAL_MAG,
  MULTIM_TABLET,
  PIC_PRICE_US,
  PIC_PRICE_CA,
  OMEGA_GELLY,
} from 'components/results/Recommendations/RecommendationsConstants';
import {AnimationImages, BoostDetailList, ProductAttributes, PRODUCT_ATTRIBUTE_TYPES} from 'components/results/prenatalRSW'
import BackBtn from 'components/common/BackBtn';

const getMergedIngredients = ({ data, prenatalStep }) => {

  let names = data.map(({ type: { name } = {} }) => name).filter(Boolean);
  names = [...new Set(names)];
  const result = names.map(item =>
    data.filter(({ type: { name } = {} }) => name === item)
  );
  return result.map(item => {
    const amountPerServing = item.reduce(
      (acc, { amountPerServing }) => acc + amountPerServing,
      0
    );
    let form = item.map(({ type: { form } = {} }) => form).filter(Boolean);
    form = [...new Set(form)].join(' and ');
    const {
      amountUnit,
      recommendedDailyAmount,
      pregnancyRecommendedDailyAmount,
      type: { name, type } = {},
    } = item[0] || {};

    let rda;
    if (['PREGNANT', 'POSTPARTUM'].includes(prenatalStep)) {
      rda = pregnancyRecommendedDailyAmount || recommendedDailyAmount;
    } else {
      rda = recommendedDailyAmount;
    }

    return {
      amountPerServing,
      amountUnit,
      recommendedDailyAmount: rda,
      type: { form, name, type },
    };
  });
};

function LearnMore(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { show, info, onHide } = props;
  const { country } = useSelector(state => state.user);
  const { 
    data: { recommendations } = {},
    product_lifeshake_info: rswLifeshakeInfos = [],
    product_boost_info: rswboostInfos = [],
    prenatalStep
  } = useSelector(
    state => state.recommendationsReducer
  );
  const isMobile = useSelector(state => state.QuestionReducer.isMobile);
  const [isFixed, setIsFixed] = useState(false)
  
  useEffect(() => {
    if (!show) return;
    const el = document.querySelector('.all-detail-page.modal-box')
    if(!el) return
   const hander = (e) => {
    let top = 120
    if(isMobile) {
      top = 55
    }
    setIsFixed(e?.target?.scrollTop > top)
   }
    const onScoll = enhanceTimeout(hander, 100)
    el.addEventListener('scroll', onScoll, {
      capture: false,
      passive: true,
    })
    return () => {
      el.removeEventListener('scroll', onScoll)
    }
  }, [isMobile, show])

  const {
    pill,
    type,
    defaultProduct,
    product_reasons: { reason_categories: reason, warnings: warningTags } = {},
    index,
  } = info || {};
  const { isMeologyKids, isMeologyPrenatal, isMeologyRegular } = useMeologyHistory();
  const pillPrice = usePrice(pill);
  const addOnPrice = usePrice(pill, 'addon');

  const isRSWDetail = type === 'rswStep2' || type === 'rswStep3'
  const isRSWStep2= type === 'rswStep2'
  const isRSWStep3= type === 'rswStep3'

  useEffect(() => {
    if (!show) return;
    const { name = '', code = '', price: { currencyIso = 'USD' } = {} } =
      pill || {};
      let proPrice = type === 'pill' ? pillPrice : addOnPrice
      if(isRSWDetail) {
        const map = {
          US: PIC_PRICE_US,
          CA: PIC_PRICE_CA,
        };
        proPrice = map[country]
      }
    dispatch(
      pushProductToDatalayer({
        event: 'product-impressions',
        ecommerce: {
          currencyCode: currencyIso,
          impressions: [
            {
              name,
              id: code,
              price: Number(proPrice),
              brand: '',
              category: 'Meology',
              list: 'Meology',
              position: index + 1,
            },
          ],
        },
      })
    );
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const pillQtyPlural = useMemo(() => {
    if (pill.code === OMEGA_GELLY)
      return `${t('ui.recommendation.kids_gelly')}`;
    else if (pill.code === MULTIM_TABLET) {
      return pill.quantity > 1 ? `${t('ui.pdp.tablets')}` : `${t('ui.pdp.tablet')}`;
    }
    else if (pill.code === CHEWABLE_CAL_MAG) {
      return pill.quantity > 1 ? `${t('ui.pdp.chewable_tablets')}` : `${t('ui.pdp.chewable_tablet')}`;
    }
    else if (isMeologyKids) {
      return pill.quantity > 1 ? `${t('ui.pdp.gummies')}` : `${t('ui.pdp.gummy')}`;
    } else {
      return pill.quantity > 1 ? `${t('ui.pdp.capsules')}` : `${t('ui.pdp.capsule')}`;
    }
  }, [pill.code, pill.quantity, t, isMeologyKids]);
  const { supplementFacts, recommendationLink: product_label_url, code } = pill || {};
  const [supplement, setSupplement] = useState(supplementFacts);
  const { ingredients, warning, genericWarning, directions, otherIngredients, otherSupplementFactsInfo } =
    supplement || {};

  const getDirections = () => {
    const productInfo = defaultProduct ? defaultProduct : pill;
    const { code } = productInfo || {};
    if (ORGANIC_GREENS_BOOSTER.includes(code) && isMeologyKids) {
      return `${t(
        'ui.faq.meology_kids_orggreenbooster_direction'
      )}`;
    } else if ((LIFE_SHAKE.includes(code) || OPTION_SKUS_US.includes(code)) && isMeologyKids) {
      return `${t(
        'ui.faq.meology_kids_lifeshake_direction'
      )}`;
    } else {
      return false;
    }
  }

  const otherIngredientNames = useMemo(() => {
    if (_.isEmpty(otherIngredients)) return [];
    return otherIngredients
      .map(({ type: { name } = {} }) => name)
      .filter(Boolean);
  }, [otherIngredients]);

  let mergedIngredients = ingredients
    ? getMergedIngredients({ data: ingredients, prenatalStep })
    : [];
  
  sortIngredient(mergedIngredients)
  const summary = useMemo(() => {
    const curPill = defaultProduct || pill;
    if(isMeologyPrenatal) {
      if(isRSWDetail) {
        return t(`reco.prenatal_detail.${type}_long.${prenatalStep}`,
        t(`reco.prenatal_detail.${type}_long`))
      }
      return t(`recommendation.prenatal_detail_summary.${curPill.sku}.${prenatalStep}`,
       t(`recommendation.prenatal_detail_summary.${curPill.sku}`))
    }
    if (isMeologyKids) {
      return getKidsProductSummary(curPill, t);
    } else {
      return getProductSummary(curPill)
    }
  }, [defaultProduct, isMeologyKids, isMeologyPrenatal, isRSWDetail, pill, prenatalStep, t, type]);
  const description = useMemo(() => {
    const curPill = defaultProduct ? defaultProduct : pill;
    if(isMeologyPrenatal) {
      return t(`recommendation.prenatal_detail_description.${curPill.sku}.${prenatalStep}`,
       t(`recommendation.prenatal_detail_description.${curPill.sku}`))
    }
    if (isMeologyKids) {
      return getKidsProductDescription(curPill, t);
    } else {
      return getProductDescription(curPill)
    }
  }, [defaultProduct, pill, isMeologyPrenatal, isMeologyKids, t, prenatalStep]);

  // update products supplement info when changing language
  useEffect(() => {
    if (!recommendations || !pill) return;
    const { code } = pill;
    const flatObjectValues = _.flow(_.values, _.flatten);
    const productsInfo = flatObjectValues(recommendations);
    if (!_.isEmpty(productsInfo)) {
      const currentProduct = _.filter(
        productsInfo,
        item => item.code === code
      )[0];
      const supplementFacts = _.get(currentProduct, 'supplementFacts', {});
      if (!_.isEmpty(supplementFacts)) {
        setSupplement(supplementFacts);
      }
    }
  }, [recommendations, pill]);

  const reasonKeyData = useMemo(
    () =>
      reason
        ?.map(({ category, reasons }) =>
          reasons?.map(({ title, description }) => ({
            category,
            title,
            description,
          }))
        )
        .reduce((accumulator, item) => accumulator.concat(item ?? {}), []) || [],
    [reason]
  );

  const showMultiVCitaion1 = useMemo(
    () =>
      reasonKeyData.some(({ description }) =>
        [
          'detail_multiV_drink_prep_comprehensive_nutrition',
          'detail_multiV_drink_pregnancy_comprehensive_nutrition',
          'detail_multiM_comprehensive_nutrition'
        ].includes(description)
      ),
    [reasonKeyData]
  );

  const showMultiVCitaion2 = useMemo(
    () =>
      reasonKeyData.some(
        ({ description }) => description === 'detail_multiV_drink_choline'
      ),
    [reasonKeyData]
  );

  const showCoq10Citaion1 = useMemo(
    () => {
      const coq10 = 'detail_coq10_conception_support'
      if(isRSWStep3) {
        let keys = []
        let allReasons = info?.product_reasons
        if(!allReasons) return false
        for(const k in allReasons) {
          let arr = allReasons[k]?.reason_categories || []
          keys.push(...arr)
        }
        return keys.some(({reasons = []}) => reasons.some(({ description }) => description === coq10))
      }
      return reasonKeyData.some(
        ({ description }) => description === coq10)
    },      
    [info, isRSWStep3, reasonKeyData]
  );

  const rswImages = useMemo(() => {
    if(!isRSWDetail) return []
    if(isRSWStep2) {
      return [t('ui.reco.prenatal_rsw.pdp_img.step2')]
    }
    if(isRSWStep3) {
      return [t(`ui.reco.prenatal_rsw.step3_join_img.${pill.code}`)]
    }
  }, [isRSWDetail, isRSWStep2, isRSWStep3, pill.code, t])

  const productImage =useMemo(() => {
    if(isMeologyPrenatal) {
      return t(`recommendation.prenatal.image_url.pdp.${pill.code}`)
    } else {
     return type === 'pill'
      ? `https://images.shaklee.com/meology/pills/big/${pill.sku}.png?v=1`
      : getImageUrl(pill, country);

    }
  }, [country, isMeologyPrenatal, pill, t, type])
   

  const getWarning = (warning, genericWarning, warningTags) => {
    let newArray = [];
    if (warningTags) {
      newArray = [...warningTags]
    }
    if (warning) {
      if (warning?.includes('. ')) {
        warning = warning.replace(/\.\s/, '. <br>')
      }

      if (warning.indexOf('<br>') > -1) {
        newArray = [...newArray, ...warning.split('<br>')]
      } else {
        newArray = [...newArray, warning]
      }
    }
    if (genericWarning) {
      newArray = [...newArray, genericWarning]
    }
    return [...new Set(newArray)]
  };
  const showSupplement = useMemo(() => {
    if (type === 'pill') {
      return !isBottledPill(code) && code !== PRENATAL_MULTIV_DRINK;
    }
  }, [code, type])

  const warningAndDirectionObj  = useMemo(() => {
    if(!showSupplement) return {}
    const result = {}
    let supplementFactsItemType = ''
    if(isMeologyPrenatal) {
      supplementFactsItemType= "MEOLOGY_PRENATAL"
    } else if(isMeologyRegular) {
      supplementFactsItemType= "REGULAR_MEOLOGY"
    }
    const otherSupplement = otherSupplementFactsInfo?.find(t => t.supplementFactsItemType === supplementFactsItemType)
    let newWarning = warning 
    let newGenericWarning = genericWarning 
    let newWarningTags = warningTags
    result.directions = directions
    if(otherSupplement) {
      newWarning =otherSupplement.warning
      newGenericWarning =otherSupplement.genericWarning
      newWarningTags =otherSupplement.warningTags
      result.directions = otherSupplement.directions
    }
    if(!(newWarning || newGenericWarning || newWarningTags)) {
      result.warning = null
      return result
    }
    result.warning = getWarning(newWarning, newGenericWarning, newWarningTags)
    return result
  }, [directions, genericWarning, isMeologyPrenatal, isMeologyRegular, otherSupplementFactsInfo, showSupplement, warning, warningTags])

  const getPrecentage = (item, quantity) => {
    if (
      parseInt(
        (item.amountPerServing / item.recommendedDailyAmount) * quantity * 100
      ) < 1
    ) {
      return '< 1%';
    }
    return `${toThousands(
      Math.round(
        (item.amountPerServing / item.recommendedDailyAmount) * quantity * 100
      )
    )}%`;
  };

  const getAmountPerServing = (data, num, quantity) =>
    toThousands(Math.round((data * quantity) / num) * num);

  const getTd = (item, quantity) => {
    if (item && item.type && item.type.name) {
      switch (item.type.name) {
        case 'Total Fat':
          if (item.amountPerServing * quantity < 0.5) {
            return <></>;
          } else if (item.amountPerServing * quantity < 5) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 0.5, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else if (item.amountPerServing * quantity >= 5) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 1, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {toThousands(item.amountPerServing * quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          }
        case 'Total Carbohydrate':
          if (item.amountPerServing * quantity < 0.5) {
            return <></>;
          } else if (item.amountPerServing * quantity < 1) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>{'< 1 g'}</td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else if (item.amountPerServing * quantity >= 1) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 1, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {toThousands(item.amountPerServing * quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          }
        case 'Dietary Fiber':
          if (item.amountPerServing * quantity < 0.5) {
            return <></>;
          } else if (item.amountPerServing * quantity < 1) {
            return (
              <>
                <td>
                  &emsp;<span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>{'< 1 g'}</td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else if (item.amountPerServing * quantity >= 1) {
            return (
              <>
                <td>
                  &emsp;<span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 1, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else {
            return (
              <>
                <td>
                  &emsp;<span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {toThousands(item.amountPerServing * quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          }
        case 'Protein':
          if (item.amountPerServing * quantity < 0.5) {
            return <></>;
          } else if (item.amountPerServing * quantity < 1) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>{'< 1 g'}</td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else if (item.amountPerServing * quantity >= 1) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 1, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {toThousands(item.amountPerServing * quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          }
        case 'Sodium':
          if (item.amountPerServing * quantity < 5) {
            return <></>;
          } else if (item.amountPerServing * quantity <= 140) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 5, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else if (item.amountPerServing * quantity > 140) {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {getAmountPerServing(item.amountPerServing, 10, quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          } else {
            return (
              <>
                <td>
                  <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                  {item.type.form && `(${item.type.form})`}
                </td>
                <td>
                  {toThousands(item.amountPerServing * quantity)}{' '}
                  {item.amountUnit.name}
                </td>
                <td>
                  {item.amountPerServing && item.recommendedDailyAmount
                    ? `${getPrecentage(item, quantity)}*`
                    : '\u2020'}
                </td>
              </>
            );
          }
        default:
          return (
            <>
              <td>
                <span dangerouslySetInnerHTML={{__html: item.type.name}}></span>
                {item.type.form && `(${item.type.form})`}
              </td>
              <td>
                {toThousands(item.amountPerServing * quantity)}{' '}
                {item.amountUnit.name}
              </td>
              <td>
                {item.amountPerServing && item.recommendedDailyAmount
                  ? getPrecentage(item, quantity)
                  : '\u2020'}
              </td>
            </>
          );
      }
    }
    return <></>;
  };

  const getCalories = (calories, quantity) => {
    if (calories * quantity < 5) {
      return <></>;
    } else if (calories * quantity <= 50) {
      return (
        <>
          <td>{'Calories'}</td>
          <td>{getAmountPerServing(calories, 5, quantity)}</td>
        </>
      );
    } else if (calories * quantity > 50) {
      return (
        <>
          <td>{'Calories'}</td>
          <td>{getAmountPerServing(calories, 10, quantity)}</td>
        </>
      );
    } else {
      return (
        <>
          <td>{'Calories'}</td>
          <td>{toThousands(calories * quantity)}</td>
        </>
      );
    }
  };

  const logoSrc = useMemo(() => {
    if(isMeologyKids) {
      return 'https://images.shaklee.com/meology/images/kids-logo-black.svg'
    }
    if(isMeologyPrenatal) {
      return 'https://images.shaklee.com/meology/prenatal/sachetlogo_new.svg'
    }
    return 'https://images.shaklee.com/meology/icons/meology_black_logo.svg'

  }, [isMeologyKids, isMeologyPrenatal]) 

  const showLabel = useMemo(() => {
    if(isRSWStep2) return true
    if (!product_label_url) return false;
    if (type === 'AddOn') return true;
    if (type === 'pill') {
      return isBottledPill(code) || code === PRENATAL_MULTIV_DRINK;
    }
  }, [code, isRSWStep2, product_label_url, type]);

  const productLabels = useMemo(() => {
    if(!isRSWStep2 ) return [{
      label: t('ui.pdp.product_label'),
      recommendationLink: product_label_url,
    }]
    if(!rswLifeshakeInfos?.length) return []
    const reg = /^\s*(Life Shake™)\s+(.+?)\s+\d+.*$/
    return rswLifeshakeInfos.map(info => {
      return {
        label: t('reco.prenatal_detail.view_label', {name: info.name?.replace(reg, '$2')}),
        recommendationLink: info.recommendationLink,
      }
    })
  }, [isRSWStep2, product_label_url, rswLifeshakeInfos, t])



  const warningAndDirectionElement = useMemo(() => {
   if(!showSupplement) return
    return (
      <>
        {warningAndDirectionObj.directions && (
          <div className="directions">
            <div
              className={cx('title', {
                'font-gilmer-regular': isMeologyPrenatal,
              })}
            >
              {t('ui.pdp.directions')}
            </div>
            <div
              className="item"
              dangerouslySetInnerHTML={{
                __html: `${warningAndDirectionObj.directions}`,
              }}
            ></div>
          </div>
        )}
        {warningAndDirectionObj.warning?.length > 0 && (
          <div className="warning">
            <div
              className={cx('title', {
                'font-gilmer-regular': isMeologyPrenatal,
              })}
            >
              {t(
                isMeologyPrenatal ? 'ui.pdp.caution_warning' : 'ui.pdp.caution'
              )}
            </div>
            {warningAndDirectionObj.warning.map((item, index) => (
              <div
                className="item"
                key={index}
                dangerouslySetInnerHTML={{
                  __html: t(`${item}`),
                }}
              ></div>
            ))}
          </div>
        )}
      </>
    );
  }, [isMeologyPrenatal, showSupplement, t, warningAndDirectionObj.directions, warningAndDirectionObj.warning]);

  const lifeshakeAttributes = useMemo(() => {
    if(!isRSWStep2) return []
    return [
      PRODUCT_ATTRIBUTE_TYPES.veganProduct,
      PRODUCT_ATTRIBUTE_TYPES.dairyProduct,
      PRODUCT_ATTRIBUTE_TYPES.glutenFree,
      PRODUCT_ATTRIBUTE_TYPES.containsNuts,
      PRODUCT_ATTRIBUTE_TYPES.kosherType,
      PRODUCT_ATTRIBUTE_TYPES.containsSoy,
    ]
  }, [isRSWStep2])
  
  return (
    <Modal
      show={show}
      size="lg"
      centered
      className={cx('all-detail-page modal-box container-fluid', {
        'kids-pdp': isMeologyKids,
        'kids-pdp-pill': isMeologyKids && type === 'pill',
        'prenatal-pdp font-gilmer-regular': isMeologyPrenatal
      })}
    >
      <Header />
      <Modal.Body
        className={cx('modal-body', {
          'pill-1': pill.code === 'ZU611',
          'pill-2': pill.code === 'ZU610',
          'pill-3': pill.code === 'ZU609',
        })}
      >
        <div className="modal-container">
          <div  className={cx('brand', {'fixed': isFixed})}>
            <BackBtn 
              className="brand-icon"
              onClickEvent={onHide}
            />
            <div className="brand-title">
              <img
                src={logoSrc}
                alt="meology"
                className="brand-logo"
              />
            </div>
          </div>
          <div className="content">
            <div className={cx('content-inner', {'rsw': isRSWDetail})}>
              <div className="content-left">
                <h2 className={cx("content-left-title", {
                  'font-gilmer-medium': isMeologyPrenatal
                })}>{
                  isRSWDetail 
                    ? t(`reco.prenatal_detail.${type}_short.${prenatalStep}`,
                      t(`reco.prenatal_detail.${type}_short`))
                    : pill.name
                   }</h2>
                <div
                  className="content-left-subTitle"
                  dangerouslySetInnerHTML={{
                    __html: isMeologyPrenatal 
                    ? summary 
                    : (`${summary}${pill.dsheaFlag ? '*' : ''}${pill.pdpPageDagger ? '‡' : ''}`),
                  }}
                ></div>
                {isRSWStep2 && <ProductAttributes attributes={lifeshakeAttributes}/>}
                {isMeologyRegular && <div className="content-left-desc">
                  {t('ui.pdp.goals_concerns')}
                </div>}
                {isMeologyRegular && <div className="content-left-tag">
                  <ul className="tag-list">
                    {reason &&
                      reason.map((item, index) => (
                        <li className="tag-item" key={index}>
                          <img
                            src={t(
                              'reason_tag.' + item.category + '.image_black'
                            )}
                            alt=""
                          />
                          <div className="tag-list-text">
                            {t('reason_tag.' + item.category + '.name') &&
                              t('reason_tag.' + item.category + '.name')
                                .split(' ')
                                .map((subItem, subIndex) => (
                                  <div key={subIndex}>{subItem}</div>
                                ))}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>}
              </div>
              <div className="content-right">
                {isRSWDetail 
                ?  <AnimationImages imgUrls={rswImages} swiperImgClassName='' sigleImgClassName='content-right-picture addon' />
                : <>
                {productImage && !(isMeologyKids && type === "pill") && (
                  <img
                    className={`content-right-picture ${(type === 'AddOn') && isMeologyPrenatal ? 'addon' : ''}`}
                    src={productImage}
                    alt=""
                  />
                )}
                </>
              }
              </div>
            </div>
          </div>
          {!isRSWStep3 && reasonKeyData?.length > 0 && (<>
          <div className={cx('recommend-title', {'rsw-reco-title': isRSWDetail })}>
            {(isMeologyKids || isMeologyPrenatal) ? t('ui.learn_more.recommended_because') : t('ui.pdp.why')}
          </div>
          <div className="recommend-content">
            {reasonKeyData &&
              reasonKeyData.map((item, index) => (
                <div className="recommend-content-item" key={index}>
                  <div className="recommend-content-item-header">
                    <img
                      src={t(isMeologyPrenatal 
                        ? 'reason_tag.prenatal.pdp.' + item.category + '.image_black'
                        : 'reason_tag.' + item.category + '.image_black'
                        )}
                      className="recommend-content-item-header-img"
                      onError={e => {
                        e.target.src =
                          'https://dummyimage.com/55x55/fff/026854.png';
                      }}
                      alt=""
                    />
                    <div className="recommend-content-item-header-title">
                      {t(`recommendation.${item.title}`)}
                    </div>
                  </div>
                  <div
                    className="recommend-content-item-desc"
                    dangerouslySetInnerHTML={{
                      __html: t(`recommendation.${item.description}`),
                    }}
                  ></div>
                </div>
              ))}
          </div>
          </>)}
        </div>

        {!isRSWDetail && (<div className="show-grid why-we-love">
          <div className="title">{t('ui.pdp.why_we_love_title')}</div>
          <div
            className="desc"
            dangerouslySetInnerHTML={{
              __html: isMeologyPrenatal 
              ? description
              : (`${description}${pill.dsheaFlag ? '*' : ''}${pill.pdpPageDagger ? '‡' : ''}`),
            }}
          ></div>
          <ul className="list">
            {pill.kosherType && pill.kosherType === 'KOSHERK' && (
              <li>{t('ui.pdp.kosher')}</li>
            )}
            {pill.kosherType && pill.kosherType === 'KOSHERD' && (
              <li>{t('ui.pdp.kosher_dairy')}</li>
            )}
            {pill.dairyProduct && <li>{t('ui.pdp.dairy')}</li>}
            {pill.vegetarianProduct && !pill.veganProduct && (
              <li>{t('ui.pdp.vegetarian')}</li>
            )}
            {pill.veganProduct && <li>{t('ui.pdp.vegan')}</li>}
            {pill.containsNuts && <li>{t('ui.pdp.nuts')}</li>}
            {pill.containsSoy && <li>{t('ui.pdp.soy')}</li>}
            {pill.glutenFree && <li>{t('ui.pdp.gluten')}</li>}
          </ul>
        </div>)}

        <div className= {cx('supplement-n-ingredients', {
          'border': !isRSWDetail
        })}>
          <div className="accordion">
            {!isRSWDetail && showSupplement && (
              <Accordion
                title={t('ui.pdp.supplement_facts')}
                className="Supplement Facts"
                active={true}
              >
                    <div className="table-container">
                      <div className="table-title">
                        <p>{pill.name}</p>
                        <p>{`${t('ui.pdp.serving_size')} ${pill.quantity} ${pillQtyPlural}`}</p>
                      </div>
                      <Table className="supplement-facts" responsive>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{t('ui.pdp.amt_serving')}</th>
                            <th>%{t('ui.pdp.dv')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pill.calories && (
                            <tr>{getCalories(pill.calories, pill.quantity)}</tr>
                          )}
                          {mergedIngredients &&
                            mergedIngredients.map((item, index) => (
                              <tr key={index}>{getTd(item, pill.quantity)}</tr>
                            ))}
                        </tbody>
                      </Table>
                      <div className="table-footer">
                        {t('ui.pdp.percent_dv')}
                      </div>
                      {!_.isEmpty(otherIngredientNames) && (
                        <div className="table-otherIngredients">
                          <span className="title">
                            {t('ui.pdp.other_ingredients') + ' '}
                          </span>
                          {otherIngredientNames.join(', ') + '.'}
                        </div>
                      )}
                    </div>
                {/* {directions && warning */}
                {warningAndDirectionElement}              
              </Accordion>
            )}
            {
            isRSWStep3
            ? <BoostDetailList products={rswboostInfos} reasons={info?.product_reasons || {}} />
            : <>
            {showLabel && (
                <Accordion title={t('ui.pdp.labels')}>
                  {productLabels?.map(((lbl, index) => {
                    return <Nav.Link key={index} href={lbl.recommendationLink} target="_blank">
                      {lbl.label}
                    </Nav.Link>
                  }))}
                </Accordion>
              )}

            {(type === 'AddOn' && isMeologyKids && getDirections()) && (
              <Accordion title={t('ui.pdp.directions')}>
                {getDirections()}
              </Accordion>
            )}
            
            {(type === 'AddOn' && isMeologyKids && warningAndDirectionObj.warning?.length > 0) && (
              <Accordion title={t('ui.pdp.caution')}>
                {warningAndDirectionObj.warning.map((item, index) => (
                    <div
                      className="item"
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: t(`${item}`),
                      }}
                    ></div>
                  ))}
              </Accordion>
            )}
          </>}
            {(pill || isRSWStep3) && (
              <RecommendationsDagger productsInfos={[pill]} page={'pdp'} showDSHEAstatement={isRSWStep3}/>
            )}

            {showMultiVCitaion1 && (
              <div className="citation">
                {t('recommendation.detail.multiV.citaion1')}
              </div>
            )}
            {showMultiVCitaion2 && (
              <div className="citation">
                {t('recommendation.detail.multiV.citaion2')}
              </div>
            )}
            {showCoq10Citaion1 && (
              <div className="citation">
                {t('recommendation.detail.coq10.citaion1')}
              </div>
            )}
          </div>
        </div>
        
      </Modal.Body>
      <Footer />
    </Modal>
  );
}

export default memo(LearnMore);
