import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

export const ATTRIBUTE_TYPES = {
  vegetarianProduct: 'vegetarianProduct',
  dairyProduct: 'dairyProduct',
  glutenFree: 'glutenFree',
  containsNuts: 'containsNuts',
  kosherType: 'kosherType',
  containsSoy: 'containsSoy',
  kosherDiaryType: 'kosherDiaryType',
  veganProduct: 'veganProduct',
};

const attributeData = {
  [ATTRIBUTE_TYPES.vegetarianProduct]: {
    urlkey: 'ui.recommendation.meology_icon_veggi',
    alt: 'Vegetarian',
  },
  [ATTRIBUTE_TYPES.dairyProduct]: {
    urlkey: 'ui.recommendation.meology_icon_dairy_free',
    alt: 'Dairy Free',
  },
  [ATTRIBUTE_TYPES.glutenFree]: {
    urlkey: 'ui.recommendation.meology_icon_gluten_free',
    alt: 'Gluten Free',
  },
  [ATTRIBUTE_TYPES.containsNuts]: {
    urlkey: 'ui.recommendation.meology_icon_nut_free',
    alt: 'Nut Free',
  },
  [ATTRIBUTE_TYPES.kosherType]: {
    urlkey: 'ui.recommendation.meology_icon_kosher',
    alt: 'Kosherk',
  },
  [ATTRIBUTE_TYPES.containsSoy]: {
    urlkey: 'ui.recommendation.meology_icon_soy_free',
    alt: 'Soy Free',
  },
  [ATTRIBUTE_TYPES.kosherDiaryType]: {
    urlkey: 'ui.recommendation.meology_icon_kosher_dairy',
    alt: 'Kosherd',
  },
  [ATTRIBUTE_TYPES.veganProduct]: {
    urlkey: 'ui.recommendation.meology_icon_vegan',
    alt: 'Vegan',
  },
};

const ProductAttributes = ({ attributes = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="product-attributes-list-container">
      {attributes.map((at, idx) => {
        const item = attributeData[at] || {}
        const url = t(item.urlkey, '');
        return url ? <img key={idx} src={url} alt={item.alt} /> : <></>;
      })}
    </div>
  );
};

export default memo(ProductAttributes);
