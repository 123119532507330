import React, { Component, Suspense } from 'react';
import Result from './results/Result';
import { Switch } from 'react-router-dom';
import '../assets/styles/App.scss';
import Logout from './logout';
import Container from 'react-bootstrap/Container';
import MyErrorBoundary from './MyErrorBoundary';
import RouteWithLayout from './layouts/RouteWithLayout';
import MainLayout from './layouts/MainLayout';
import DummyLayout from './layouts/DummyLayout';
import { Spinner } from 'react-bootstrap';
const ExampleComponent = React.lazy(() => import('./example/Example'));
const Home = React.lazy(() => import('./Home'));
const QuestionPage = React.lazy(() => import('./questions/Page'));
const SummaryPage = React.lazy(() => import('./questions/SummaryPage'));
const PreResultPage = React.lazy(() => import('./questions/Loading'));
const EmailPage = React.lazy(() => import('./questions/Email'));
const ComingSoon = React.lazy(() => import('./ComingSoon'));
const Faqs = React.lazy(() => import('./faqs/Faqs'));
const Marketing = React.lazy(() => import('./Marketing/Marketing'));

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/comingsoon',
    component: ComingSoon,
    exact: true,
  },
  {
    path: '/reco/:id',
    component: Result,
  },
  {
    path: '/reco',
    component: Result,
  },
  {
    path: '/example',
    component: ExampleComponent,
    layout: DummyLayout,
  },
  {
    path: '/:type/assessment',
    component: QuestionPage,
  },
  {
    path: '/assessment',
    component: QuestionPage,
  },
  {
    path: '/summary',
    component: SummaryPage,
  },
  {
    path: '/email',
    component: EmailPage,
  },
  {
    path: '/pre-result',
    component: PreResultPage,
  },
  {
    path: '/FAQ',
    component: Faqs,
  },
  {
    path: '/meology',
    component: Marketing,
  },
  {
    path: '/meologykids',
    component: Marketing,
  },
  {
    path: '/meologyprenatal',
    component: Marketing,
  },
];

class App extends Component {
  viewResults = () => {
    this.setState({ result: true });
  };

  render() {
    return (
      <MyErrorBoundary>
        <Suspense
          fallback={
            <div
              style={{
                margin: '10% 50%',
              }}
            >
              <Spinner animation="border" />
            </div>
          }
        >
          <Container fluid="false" className="fluid-container">
            <Switch>
              {routes.map(({ layout = MainLayout, ...rest }, key) => (
                <RouteWithLayout layout={layout} {...rest} key={key} />
              ))}
            </Switch>
          </Container>
          <Logout></Logout>
        </Suspense>
      </MyErrorBoundary>
    );
  }
}

export default App;
