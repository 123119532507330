import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { getImpersonation, useHybrisHost } from 'assets/js/hooks';
import { getUrlVars, addUrlParam, parseValue } from 'assets/js/helper';
import {
  setLandingPage,
  setKidsLandingPage,
  setPrenatalLandingPage,
} from 'components/questions/QuestionActions';

const Managing = ({ name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isImpersonation, isBL, isSponsor } = getImpersonation();
  const { country } = useSelector(state => state.user);
  const { isExact: isQuizPage } = useRouteMatch('/:type/assessment') || {};
  const { isExact: isHomePage } = useRouteMatch('/') || {};
  const { isExact: isAdultMarketingPage } = useRouteMatch('/meology') || {};
  const { isExact: isKidsMarketingPage } = useRouteMatch('/meologykids') || {};
  const { isExact: isPrenatalMarketingPage } = useRouteMatch('/meologyprenatal') || {};
  const isMarketingPage = useMemo(
    () =>
      isAdultMarketingPage || isKidsMarketingPage || isPrenatalMarketingPage,
    [isAdultMarketingPage, isKidsMarketingPage, isPrenatalMarketingPage]
  );
  const hybrisHost = useHybrisHost(country);

  const returnUrl = useMemo(
    () => (isBL ? `${hybrisHost}/stopEmulation` : hybrisHost),
    [hybrisHost, isBL]
  );
  const returnText = useMemo(
    () => (isBL ? t('ui.bl.return_account') : t('ui.bl.return_home')),
    [isBL, t]
  );

  const returnToHybris = useCallback(() => {
    if (isBL) {
      window.localStorage.clear();
    }
    window.location.href = returnUrl;
  }, [isBL, returnUrl]);

  // sponsor logic
  useEffect(() => {
    let { isClear } = getUrlVars() || {};
    isClear = parseValue(isClear);

    if (
      (isQuizPage || isHomePage || isMarketingPage) &&
      isSponsor &&
      !isClear
    ) {
      Cookies.remove('hpEntity', { path: '/' });
      window.localStorage.clear();

      addUrlParam({
        key: 'isClear',
        value: 'true',
        routerLocation: location,
      });

      // reset CTA button status
      dispatch(setLandingPage(1));
      dispatch(setKidsLandingPage(1));
      dispatch(setPrenatalLandingPage(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isImpersonation) return null;

  return (
    <div className="managing-wrap">
      <div className="managing">
        <div className="managing-left">
          {t('ui.bl.manage_account')} {name}
        </div>
        {returnUrl && (
          <button
            type="button"
            className="managing-button"
            onClick={returnToHybris}
          >
            {returnText}
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(Managing);
