import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import styled from 'styled-components';

const Button = styled.button`
      font-family: "Gilmer-Bold", sans-serif;
      font-size: 22px;
      font-weight: bold;
      color: #2e2e2e;
      background-color: transparent;
      border: none;

      @include focus-indicator();
      
      @media only screen and (max-width: 1200px)  {
        font-size: 17px;
      }
`;

const Text = styled.span`
      cursor: pointer;
`;

const Image = styled.img`
      cursor: pointer;
      margin-left: 10px;
      @media only screen and (max-width: 1200px)  {
        width: 42px;
      }
`;

const LearnMoreBtn = ({
  text,
  ariaLabel,
  pathName,
  eventCategory,
  eventAction,
  eventLabel,
  onClickEvent,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Button
      className="learn_more"
      tabIndex="0"
      aria-label={ariaLabel}
      onClick={() => {
        
        if (!!onClickEvent) {
          onClickEvent();
          return;
        } else {
          history.push({
            ...location,
            pathname: pathName,
          });
        }
    
        // Google Analysis
        if (!eventCategory) return;
        dispatch(
          pushInteractionToDataLayer({
            eventCategory,
            eventAction,
            eventLabel,
          })
        );
      }}
    >
      <Text>{text ? text : t('ui.landing_page_1.ctas_title_4')}</Text>
      <Image
        src="https://images.shaklee.com/meology/icons/circel-arrow.svg"
        alt="circle-logo"
      />
    </Button>
  );
};

export default memo(LearnMoreBtn);
