import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  memo,
  useRef,
  useMemo,
} from 'react';
import {Row} from "react-bootstrap";
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import moment from "moment"
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  validateQuestions,
} from 'components/questions/QuestionActions';


const reg =/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/

const CustomInput = forwardRef(({value, onClick, prenatalDueDate,placeholder,onChange, onInputChange, ...rest }, ref) => (
  <input
    className="form-control text-field"
    placeholder={placeholder}
    onClick={onClick}
    value={prenatalDueDate || ''}
    onChange={onInputChange}
    ref={ref}
    {...rest}
  ></input>
));
	
const RenderDatepicker = ({name,page,selectedAnswer,updateAnswer,showError,error_message, isFloat}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [date, setDate] = useState()
  const popperRef = useRef()
  const maxDate = useMemo(
    () =>
      moment()
        .add(280, 'days')
        .valueOf(),
    []
  );

  useEffect(()=> {
    const minDate = moment().format("MM/DD/yyyy")
    if(reg.test(selectedAnswer) && moment(minDate).isSameOrBefore(selectedAnswer)) {
      setDate(moment(selectedAnswer).toDate())
    }else {
      setDate(null)
    }
    dispatch(validateQuestions(name, selectedAnswer, false, t))
  }, [dispatch, name, selectedAnswer, t])

  const onSelect = useCallback(
    (dt, e) => {
      let dateStr = moment(dt).format('MM/DD/yyyy');
      if (!dt || !reg.test(dateStr)) {
        dateStr = '';
      }
      updateAnswer(name, dateStr, page);
    },
    [name, page, updateAnswer]
  );

  const onChange= useCallback((e) => {
      const val = e?.target?.value
      updateAnswer(name, val, page)
      
   },[name, page, updateAnswer])

  const onCalendarOpen= useCallback(() => {
    if(isFloat) return
    const popper = popperRef.current
    if(!popper)  return
    setTimeout(() => {
      const popperContent = popper?.querySelector('.react-datepicker-popper')
      // eslint-disable-next-line no-unused-expressions
      popperContent?.scrollIntoView?.()
    }, 0)
   },[isFloat])

  const onKeyDown= useCallback((e) => {
    const { key } = e || {}
    if(key === 'ArrowUp' || key === 'ArrowDown') {
      // eslint-disable-next-line no-unused-expressions
      e?.target?.scrollIntoView?.()
    }  
   },[])

  const popperContainer= useCallback(({ children }) => {
    if(isFloat) return <>{children}</>
    return <div ref={popperRef} className='prenatal_due_date_picker_popper_container'>{children}</div>
   },[isFloat])

    return (
        <Row className='answer prenatal_due_date_picker'>
            <DatePicker
              selected={date}
              onChange={onSelect}
              className="form-control text-field"
              placeholderText={t(`questions.${name}.placeholder`)}
              minDate={new Date()}
              maxDate={maxDate}
              openToDate={date}
              onCalendarOpen={onCalendarOpen}
              onKeyDown={onKeyDown}
              onFocus={e => e.target.blur()}
              popperContainer={popperContainer}
              renderCustomHeader={({
                date:cDate,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className='custom-date-picker-header'>
                    <span  onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"></span>
                    <span className='month-and-year'>{moment(cDate).format("MMMM yyyy")}</span>
                    <span onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
                </div>
              )}
              customInput={
                <CustomInput
                  key="prenatal_due_date_id"
                  placeholder={t(`questions.${name}.placeholder`)}
                  onInputChange={onChange}
                  prenatalDueDate={selectedAnswer}
                />
              }
            />
            {showError && (
          <div className="error-message">
            <div className="arrow"></div>
            <span>{error_message}</span>
          </div>
        )}
        </Row>
    );
  }
  export default memo(RenderDatepicker)