export const stepTypes = {
  TRYING: 'TRYING',
  PREGNANT: 'PREGNANT',
  POSTPARTUM: 'POSTPARTUM',
  MEOLOGY: 'MEOLOGY',
};
const getTryingProps = t => ({
  stepType: stepTypes.TRYING,
  sachetImg: t('marketing.prenatal.image_url.step_slider_1'),
  sachetImgMo: t('marketing.prenatal.image_url.step_slider_1_mobile'),
  pills: [
    {
      title: t('ui.prenatal_marketing.section_4_1.product_1.title'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_8.png',
      content: t('ui.prenatal_marketing.section_4_1.product_1.content'),
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_heart.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_cup.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_1.product_2.title'),
      content: t('ui.prenatal_marketing.section_4_1.product_2.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_7.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_heart.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_1.product_3.title'),
      content: t('ui.prenatal_marketing.section_4_1.product_3.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_4.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_fish.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_eye.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_brain.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_1.product_4.title'),
      content: t('ui.prenatal_marketing.section_4_1.product_4.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_2.png?v=1',
      icons: ['https://images.shaklee.com/meology/prenatal/a_icon_zygote.svg'],
      iconClassNames: ['i-large'],
    },
    {
      title: t('ui.prenatal_marketing.section_4_1.product_5.title'),
      content: t('ui.prenatal_marketing.section_4_1.product_5.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_6.png',
      icons: ['https://images.shaklee.com/meology/prenatal/a_icon_smile.svg'],
    },
  ],
  addons: [
    {
      title: t('ui.prenatal_marketing.section_4_1.addon_1.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_3'),
      content: t('ui.prenatal_marketing.section_4_1.addon_1.content'),
    },
  ],
});
const getPregnantProps = t => ({
  stepType: stepTypes.PREGNANT,
  sachetImg: t('marketing.prenatal.image_url.step_slider_2') + '?v=1',
  sachetImgMo: t('marketing.prenatal.image_url.step_slider_2_mobile') + '?v=1',
  pills: [
    {
      title: t('ui.prenatal_marketing.section_4_2.product_1.title'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_8.png',
      content: t('ui.prenatal_marketing.section_4_2.product_1.content'),
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_heart.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_cup.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.product_2.title'),
      content: t('ui.prenatal_marketing.section_4_2.product_2.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_7.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_heart.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.product_3.title'),
      content: t('ui.prenatal_marketing.section_4_2.product_3.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_4.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_fish.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_eye.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_brain.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.product_4.title'),
      content: t('ui.prenatal_marketing.section_4_2.product_4.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_5.png',
      icons: ['https://images.shaklee.com/meology/prenatal/a_icon_circle.svg'],
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.product_5.title'),
      content: t('ui.prenatal_marketing.section_4_2.product_5.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_1.png',
      imgUrlMo: 'https://images.shaklee.com/meology/prenatal/mkt_pill_9.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_foot.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_stomach.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_moon.svg',
      ],
    },
  ],
  addons: [
    {
      title: t('ui.prenatal_marketing.section_4_2.addon_1.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_1'),
      content: t('ui.prenatal_marketing.section_4_2.addon_1.content'),
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.addon_2.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_2'),
      content: t('ui.prenatal_marketing.section_4_2.addon_2.content'),
      tip: t('ui.prenatal_marketing.section_4_2.addon_2.tip')
    },
    {
      title: t('ui.prenatal_marketing.section_4_2.addon_3.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_3'),
      content: t('ui.prenatal_marketing.section_4_2.addon_3.content'),
    },
  ],
});
const getPostnatalProps = t => ({
  stepType: stepTypes.POSTPARTUM,
  sachetImg: t('marketing.prenatal.image_url.step_slider_3'),
  sachetImgMo: t('marketing.prenatal.image_url.step_slider_3_mobile'),
  pills: [
    {
      title: t('ui.prenatal_marketing.section_4_3.product_1.title'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_8.png',
      content: t('ui.prenatal_marketing.section_4_3.product_1.content'),
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_cup.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_3.product_2.title'),
      content: t('ui.prenatal_marketing.section_4_3.product_2.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_7.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_ace.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_apple.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_pregnancy.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_3.product_3.title'),
      content: t('ui.prenatal_marketing.section_4_3.product_3.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_4.png',
      icons: [
        'https://images.shaklee.com/meology/prenatal/a_icon_fish.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_eye.svg',
        'https://images.shaklee.com/meology/prenatal/a_icon_brain.svg',
      ],
    },
    {
      title: t('ui.prenatal_marketing.section_4_3.product_4.title'),
      content: t('ui.prenatal_marketing.section_4_3.product_4.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_5.png',
      icons: ['https://images.shaklee.com/meology/prenatal/a_icon_circle.svg', 'https://images.shaklee.com/meology/prenatal/a_icon_cell.svg',],
    },
    {
      title: t('ui.prenatal_marketing.section_4_3.product_5.title'),
      content:t('ui.prenatal_marketing.section_4_3.product_5.content'),
      imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_pill_3.png',
      icons: ['https://images.shaklee.com/meology/prenatal/a_icon_bone.svg'],
    },
  ],
  addons: [
    {
      title: t('ui.prenatal_marketing.section_4_3.addon_1.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_3'),
      content: t('ui.prenatal_marketing.section_4_3.addon_1.content'),
    },
    {
      title: t('ui.prenatal_marketing.section_4_3.addon_2.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_2'),
      content: t('ui.prenatal_marketing.section_4_3.addon_2.content'),
      tip: t('ui.prenatal_marketing.section_4_3.addon_2.tip'),
    },
  ],
});
const getMeologyProps = t => ({
  stepType: stepTypes.MEOLOGY,
  sachetImg: t('marketing.prenatal.image_url.step_slider_4'),
  sachetImgMo: t('marketing.prenatal.image_url.step_slider_4_mobile'),
  addons: [
    {
      title: t('ui.prenatal_marketing.section_4_4.addon_1.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_3'),
      content: t('ui.prenatal_marketing.section_4_4.addon_1.content'),
    },
    {
      title: t('ui.prenatal_marketing.section_4_4.addon_2.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_4'),
      content: t('ui.prenatal_marketing.section_4_4.addon_2.content'),
    },
    {
      title: t('ui.prenatal_marketing.section_4_4.addon_3.title'),
      imgUrl: t('marketing.prenatal.image_url.step_slider_addon_2'),
      content: t('ui.prenatal_marketing.section_4_4.addon_3.content'),
    },
  ],
});

export const getStepsProps = t => [
  getTryingProps(t),
  getPregnantProps(t),
  getPostnatalProps(t),
  getMeologyProps(t),
];

export const getExperDisplayProps = t => [
  {
    title: t('ui.prenatal_marketing.section_5_1.title'),
    content: t('ui.prenatal_marketing.section_5_1.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_5.png',
  },
  {
    title: t('ui.prenatal_marketing.section_5_2.title'),
    content: t('ui.prenatal_marketing.section_5_2.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_3.png',
  },
  {
    title: t('ui.prenatal_marketing.section_5_3.title'),
    content:  t('ui.prenatal_marketing.section_5_3.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_1.png',
  },
  {
    title: t('ui.prenatal_marketing.section_5_4.title'),
    content:  t('ui.prenatal_marketing.section_5_4.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_2.png',
  },
  {
    title: t('ui.prenatal_marketing.section_5_5.title'),
    content:  t('ui.prenatal_marketing.section_5_5.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_4.png',
  },
  {
    title: t('ui.prenatal_marketing.section_5_6.title'),
    content:  t('ui.prenatal_marketing.section_5_6.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_icon_6.png',
  },
];
export const getReviewProps = t => [
  {
    icon: 'https://images.shaklee.com/meology/prenatal/mkt_5_star.png',
    content:t('ui.prenatal_marketing.section_8_1.content'),
    imgUrl: 'https://images.shaklee.com/meology/prenatal/mkt_reverw_1.jpg',
  }
];

export const getBannerProps = t => [
  {
    role: 'img',
    title: '',
    ariaLabel: '',
    src: t('marketing.prenatal.image_url.banner_slider_1'),
  },
  {
    role: 'img',
    title: '',
    ariaLabel: '',
    src: t('marketing.prenatal.image_url.banner_slider_2'),
  },
  {
    role: 'img',
    title: '',
    ariaLabel: '',
    src: t('marketing.prenatal.image_url.banner_slider_3'),
  },
  {
    role: 'img',
    title: '',
    ariaLabel: '',
    src: t('marketing.prenatal.image_url.banner_slider_4'),
  },
  {
    role: 'img',
    title: '',
    ariaLabel: '',
    src: t('marketing.prenatal.image_url.banner_slider_5'),
  },
];
