import React, {useState, useRef} from 'react';
import { useDispatch } from 'react-redux';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const Accordion = props => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(props.active);
  const contentRef = useRef(null);

  // useEffect(() => {
  //   contentRef.current.style.maxHeight = active
  //     ? `${contentRef.current.scrollHeight}px`
  //     : '0px';
  // }, [contentRef, active]);
  const slice = string => string?.slice(0, 100);

  const toggleActive = () => {
    setActive(!active);

    // analytics
    if (!props.answer) return;
    dispatch(
      pushInteractionToDataLayer({
        eventAction: props.title,
        eventLabel: slice(props.answer),
      })
    );
  };

  return (
    <div className={'toggle ' + (active ? 'open' : '')}>
      <div className="accordion-title">
	      {props.showIcon && <span className="left-icon"></span>}
	      <div className="title" onClick={toggleActive}>{props.title}</div>
        <button type="button" aria-label={props.title} className="icon" onClick={toggleActive}></button>
      </div>
      {active && <div className={`accordion-content ${props.title}`} ref={contentRef}>
        {props.children}
      </div>}
      <div className="accordion-footer"></div>
    </div>
  );
};

export default Accordion;
