/* eslint-disable array-callback-return */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const RecommendationProductAttributes = ({ pill }) => {
  const { t } = useTranslation();
  if(!pill) return;
  return (
    <div className="product-attributes-list">
      {pill.kosherType && pill.kosherType === 'KOSHERK' && (
        <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_kosher')} alt="Kosherk"/></div>
      )}
      {pill.kosherType && pill.kosherType === 'KOSHERD' && (
        <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_kosher_dairy')} alt="Kosherd"/></div>
      )}
      {!pill.dairyProduct && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_dairy_free')} alt="Dairy Free"/></div>}
      {pill.vegetarianProduct && !pill.veganProduct && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_veggi')} alt="Vegetarian"/></div>}
      {pill.veganProduct && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_vegan')} alt="Vegan"/></div>}
      {!pill.containsNuts && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_nut_free')} alt="Nut Free"/></div>}
      {!pill.containsSoy && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_soy_free')} alt="Soy Free"/></div>}
      {pill.glutenFree && <div className="product-attributes-list-item"><img src={t('ui.recommendation.meology_icon_gluten_free')} alt="Gluten Free"/></div>}
    </div>
  );
};

export default memo(RecommendationProductAttributes);
