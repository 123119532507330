import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const ProveItBanner = ({ proveItRef }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleView = useCallback(() => {
    const { offsetTop } = proveItRef.current;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });

    // analytics
    dispatch(
      pushInteractionToDataLayer({
        eventCategory: 'Meology',
        eventAction: 'View Offer',
        eventLabel: '',
      })
    );
  }, [dispatch, proveItRef]);
  return (
    <div className="prove-it-banner">
      <div className="prove-it-banner-content">
        <div className="prove-it-banner-title">
          {t('ui.recommendation.proveit_banner_title1')}
        </div>
        <div className="prove-it-banner-sub_title">
          {t('ui.recommendation.proveit_section_price')}
        </div>
        <p>{t('ui.recommendation.proveit_banner_description_2')}</p>
        <button className="prove-it-banner-button" onClick={handleView} aria-label="View Offer Details">
          {t('ui.recommendation.proveit_banner_description_3')}
        </button>
      </div>
      <ul className="prove-it-banner-image">
        <img src={'https://images.shaklee.com/wellness/RSW_KIT_Animation_Wide.png'} alt="" />
      </ul>
    </div>
  );
};

export default memo(ProveItBanner);
