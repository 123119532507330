import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import CopyPageLink from 'components/CopyPageLink'
import CtaBtn from 'components/common/CtaBtn';
import { useHideMeology } from 'assets/js/hooks';
import LearnMoreBtn from 'components/common/LearnMoreBtn';

const SectionOne = ({
  t,
  landingPage,
  kidsLandingPage,
  prenatalLandingPage,
  isMobile,
}) => {
  const { isUserLoggedin } = useSelector(state => state.user);
  const { hideKids, hidePrenatal, isOne } = useHideMeology();

  return (
    <div className={cx('intro-container', {
      'hide-card': hideKids || hidePrenatal,
    })}
    >
	    {/* Copy Page Link */}
	    <CopyPageLink className="copy-page-link-landing-page" />
      <div
        className={cx('intro-section', {
          'is-mobile': isMobile,
          'log-in': isUserLoggedin,
          'one-card': isOne
        })}
      >
        <div className="intro-section-text">
          <img
            src="https://images.shaklee.com/meology/icons/me-logo-1.svg?v=2"
            alt="meology by shaklee"
            className="brand-logo"
          />
          <h2 className="intro-section-text-header">
            {t('ui.landing_page_1.meology_hero_title_3')}
          </h2>
          <p className="intro-section-text-context">
            {t('ui.landing_page_1.meology_hero_title_4')}
          </p>
        </div>
        <div
          className={cx('intro-section-card', {
            'two-items': !isOne && (hideKids || hidePrenatal),
            'one-item': isOne
          })}
        >
          <div className="intro-section-card-regular">
            {!isOne && <>
              <h3 className="title">{t('ui.landing_page_1.two_doorways_title_3')}</h3>
            </>}
            <div className="btn-container">
              <CtaBtn type='regular' status={landingPage} eventLabel='home page cta section' showRetake={true}/>
            </div>
            <LearnMoreBtn
              ariaLabel="Learn More about Meology for adults"
              pathName="/meology"
            />
          </div>
          {!hidePrenatal && <div className="intro-section-card-prenatal">
            <h3 className="title">{t('ui.landing_page_1.additional_cta_module_title_9')}</h3>
            <div className="btn-container">
              <CtaBtn
                type="prenatal"
                status={prenatalLandingPage}
                eventLabel="Meology Landing Page"
                showRetake={true}
              />
            </div>
            <LearnMoreBtn
              ariaLabel="Learn More about Meology Prenatal"
              pathName="/meologyprenatal"
              eventCategory="Prenatal"
              eventAction="Learn More"
              eventLabel="Meology Landing Page"
            />
          </div>}
          {!hideKids && <div className="intro-section-card-kids">
            <h3 className="title">{t('ui.landing_page_1.two_doorways_title_8')}</h3>
            <div className="btn-container">
              <CtaBtn type='kids' status={kidsLandingPage} eventLabel='home page cta section' />
            </div>
            <LearnMoreBtn
              ariaLabel="Learn More about Meology for kids"
              pathName="/meologykids"
            />
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
