import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAddToCart } from 'assets/js/hooks';
import { is } from 'assets/js/helper';

const RecommendationModal = ({
  show,
  onHide,
  onErrorImg,
  title,
  body,
  btnInfo,
  titleImg,
  className,
}) => {
  const { isAddToCart, setIsAddToCart } = useAddToCart();
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      size="lg"
      className={`modal-result ${className}`}
      centered
      {...(onHide && { onHide })}
    >
      <Modal.Header
        closeButton={Boolean(onHide)}
        className={title ? '' : 'no-title'}
      >
        <Modal.Title>
          {titleImg && (
            <img
              src={titleImg}
              alt=""
              onError={e => {
                e.target.src = `${onErrorImg}`;
              }}
              style={{ width: '60px', height: '60px', marginRight: '20px' }}
            />
          )}
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {is(String, body) && (
          <div
            className="modal-body-content"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        )}
        {is(Object, body) && <div>{body}</div>}
        {
          <div className="btn-container">
            {btnInfo &&
              btnInfo.map((item, index) => {
                const disable = item && item.type === 'checkout' && isAddToCart;
                const link = item?.link;
                if (link) {
                  return (
                    <a
                      className="modal-result-confirm link"
                      key={index}
                      href={link}
                      target={item?.target || "_blank"}
                      rel="noopener noreferrer"
                      onClick={ item?.event}
                    >
                      {item.name}
                    </a>
                  );
                }
                return (
                  <button
                    className="modal-result-confirm"
                    onClick={() => {
                      if (item && item.type === 'checkout') {
                        setIsAddToCart(true);
                      }
                      item.event();
                    }}
                    disabled={disable}
                    key={index}
                  >
                    {disable ? `${t('ui.adding')}...` : item.name}
                  </button>
                );
              })}
          </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default memo(RecommendationModal);
