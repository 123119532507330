import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { useMeologyHistory } from 'assets/js/hooks';

const PrenatalConfirm = ({ phase, onBack, onClick, hasAutoship,loading }) => {
  const { meology_autoship_info } = useMeologyHistory();
  const {t} = useTranslation()
  const labels = useMemo(() => { 
    return {
      title: t(`ui.recommendation.prenatal.${phase}.modal.title`),
      content: hasAutoship
        ? t(`ui.recommendation.prenatal.${phase}.modal.content`, {
            nextProcessingDate:
              meology_autoship_info?.nextProcessingDate || 'XX/XX/XX',
          })
        : t(`ui.recommendation.prenatal.${phase}.modal.content1`),
      btntext: t(`ui.recommendation.prenatal.${phase}.modal.btntext`),
      notpregnant: t(`ui.recommendation.prenatal.${phase}.modal.notpregnant`),
      goback: t(`ui.recommendation.prenatal.${phase}.modal.goback`),
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, phase, meology_autoship_info?.nextProcessingDate])
  return (
    <>
      <Row className="prenatal-confirm">
        <h2 className="title" aria-label={labels.title}>{labels.title}</h2>
        <p className="content" aria-label={labels.content}>{labels.content}</p>
        <div className="button-container">
          <div className="question-buttons">
            <button
              tabIndex="0"
              onClick={onClick}
              title={labels.btntext}
              aria-label={labels.btntext}
              disabled={loading}
              className="btn-next btn">
              {loading ?`${t('ui.adding')}...` : labels.btntext}
            </button>
          </div>
        </div>
        <div className="back-link" aria-label={labels.notpregnant}>
          <span>{labels.notpregnant}</span> &nbsp;
          <span aria-label={labels.goback} onClick={onBack}>{labels.goback}</span>
        </div>
      </Row>
    </>
  );
};

export default memo(PrenatalConfirm);
