import React, { memo } from 'react';
import { PrenatalStep } from 'components/results/Prenatal';
import StepSlider from './StepSlider';
const MKTPrenatalStep = ({ t }) => {
  return (
    <div className="mkt-prenatal-step-container">
      <div className="mkt-content-container">
        <div className="mkt-step-header">
          <h2
            className="mkt-section-title"
            dangerouslySetInnerHTML={{
              __html: t('ui.prenatal_marketing.section_4.headline'),
            }}
          ></h2>
          <p>{t('ui.prenatal_marketing.section_4.eyebrow')}</p>
        </div>
        <PrenatalStep isMKTPage />
        <StepSlider />
      </div>
    </div>
  );
};

export default memo(MKTPrenatalStep);
