import React, { memo, useMemo, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useMeologyHistory } from 'assets/js/hooks';
import { RecommendationContext } from 'components/results/ContextProvider';
import AutoShipInfo from 'components/results/Recommendations/AutoShipInfo';

const CartBtn = ({ where, noSelectedProduct }) => {
  const { t } = useTranslation();

  const {
    isOneBtn,
    hideCartBtn,
    hideAutoCartBtn,
    isMeologyPrenatal,
    hasMeologyPrenatalOrderEntry,
  } = useMeologyHistory();

  const {
    handleCart,
    handleAutoCart,
    isAddToCart,
    basic,
    isProveit,
    isAutoShipCart,
  } = useContext(RecommendationContext);
  const [tipsOpen, setTipsOpen] = useState(false);

  useEffect(() => {
    const handler = () => setTipsOpen(false);
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disable = useMemo(() => noSelectedProduct || isAddToCart, [
    noSelectedProduct,
    isAddToCart,
  ]);

  const tagManagerClass = useMemo(() => {
    let result = 'essential';
    if (!basic && !isProveit) {
      result = 'essentialPlus';
    } else if (basic && isProveit) {
      result = 'proveItEssential';
    } else {
      result = 'proveItEssentialPlus';
    }
    return result;
  }, [basic, isProveit]);

  const btnText = useMemo(
    () =>
      isAddToCart && !isAutoShipCart.current
        ? `${t('ui.adding')}...`
        : t('ui.recommendation.cta_button'),
    [isAddToCart, t, isAutoShipCart]
  );

  const autoBtnText = useMemo(() => {
    if (isAddToCart && isAutoShipCart.current) {
      return `${t('ui.adding')}...`;
    } else {
      if (isMeologyPrenatal && hasMeologyPrenatalOrderEntry) {
        return t('ui.recommendation.prenatal.cta1');
      } else {
        return t('ui.recommendation.meology_kids_add_to_cart_title1_loyal');
      }
    }
  }, [
    isAddToCart,
    isAutoShipCart,
    t,
    isMeologyPrenatal,
    hasMeologyPrenatalOrderEntry,
  ]);

  const buttonData = useMemo(
    () => [
      {
        disabled: disable || (isAddToCart && isAutoShipCart.current),
        buttonText: btnText,
        handleClick: () => {
          handleCart();
        },
        hide: hideCartBtn,
      },
      {
        disabled: disable || (isAddToCart && !isAutoShipCart.current),
        buttonText: autoBtnText,
        handleClick: (e) => {
          e.nativeEvent.stopImmediatePropagation();
          setTipsOpen(true)
        },
        hide: hideAutoCartBtn,
        tips: (
          <div className={cx('cart-btn-tips', { 'open': tipsOpen, [`is-on-${where}`]: where })} onClick={(e) => {e.nativeEvent.stopImmediatePropagation()}}>
            <AutoShipInfo handleAutoCart={handleAutoCart} setTipsOpen={setTipsOpen} dropdown={true}/>
          </div>
        ),
      },
    ],
    [
      disable,
      isAddToCart,
      isAutoShipCart,
      btnText,
      hideCartBtn,
      autoBtnText,
      hideAutoCartBtn,
      tipsOpen,
      where,
      handleAutoCart,
      handleCart,
    ]
  );

  return (
    <div
      className={cx('cart-btn', {
        'is-one-btn': isOneBtn,
        [`is-on-${where}`]: where,
      })}
    >
      {buttonData.map(
        ({ handleClick, disabled, buttonText, hide, tips }, index) =>
          !hide && (
            <div 
              className={cx('cart-btn-item', {
                'auto': buttonText === autoBtnText,
              })}
              key={index}
            >
              <button
                tabIndex="0"
                title={buttonText.replace(/<[^>]+>/g, " ")}
                aria-label={buttonText.replace(/<[^>]+>/g, " ")}
                type="button"
                onClick={handleClick}
                disabled={disabled}
                className={tagManagerClass}
                dangerouslySetInnerHTML={{
                  __html: buttonText,
                }}
              ></button>
              {tips}
            </div>
          )
      )}
    </div>
  );
};
export default memo(CartBtn);
