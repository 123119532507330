import {
  Q_CURRENT_PAGE,
  Q_FETCH_QUESTIONS,
  Q_UPDATE_ANSWER,
  Q_PREVIOUS_PAGE,
  Q_NEXT_PAGE,
  Q_RESET_ANSWER,
  Q_FETCH_RESULTS,
  Q_FETCH_HEALTHPROFILE,
  Q_SET_ANSWER,
  Q_EDIT_QUESTION,
  Q_VALIDATE_QUESTION,
  Q_KIDS_LANDING_PAGE,
  Q_PRENATAL_LANDING_PAGE,
  Q_LANDING_PAGE,
  USERINFO,
  IS_MOBILE,
  SUBMIT_QUESTION_RESULT,
  PRE_SUBMIT_CHECK,
  SET_ACCOUNT,
  SET_BANNER,
  Q_NEXT_SECTION,
  Q_PREVIOUS_SECTION,
  REMOVE_QUESTION_VALIDATION,
  SET_HISTORY,
  SET_MARKETING_TYPE,
  SET_CKID,
} from './QuestionConstants';
import {
  totalPages,
  setLocalQuestion,
  removeLocalQuestion,
  setQuestionsNumber,
  saveQuestionsLength,
  removeQuestionsNumber,
  getMeologyType,
} from 'assets/js/helper';
import _ from 'lodash';

const initialState = {
  landing_page: null,
  kids_landing_page: null,
  prenatal_landing_page: null,

  regularCounter: 0,
  kidsCounter: 0,
  prenatalCounter: 0,

  regularPageId: 1,
  kidsPageId: 1,
  prenatalPageId: 1,
  
  regularPageQuestions: [],
  kidsPageQuestions: [],
  prenatalPageQuestions: [],
    
  regularPageLength: 0,
  kidsPageLength: 0,
  prenatalPageLength: 0,

  regularSelectedAnswers: {},
  kidsSelectedAnswers: {},
  prenatalSelectedAnswers: {},

  regularPagesObj: {},
  kidsPagesObj: {},
  prenatalPagesObj: {},

  regularPagination: [],
  kidsPagination: [],
  prenatalPagination: [],

  question_results: null,
  healthProfile: null,
  edit_question: null,
  validate_question: null,
  pageCategory: '',
  backPage: false,
  nextPage: false,
  user_info: null,
  is_mobile: false,
  submit_question_result: false,
  pre_submit_check: false,
  sponsor_get_pws: { host_name: '', referrer_id: '' , pws_list: [],},
  account: null,
  banner: null,
  history: {},
  marketingType: 1,
  ckid: null,
};

const QuestionReducer = (state = initialState, action = {}) => {
  const { type } = getMeologyType();
  switch (action.type) {
    case 'SPONSOR_GET_PWS':
      state = {
        ...state,
        sponsor_get_pws: action.payload
      };
      break;
    case SET_MARKETING_TYPE:
      state = {
        ...state,
        marketingType: action.payload,
      };
      break;
    case PRE_SUBMIT_CHECK:
      state = {
        ...state,
        pre_submit_check: action.payload
      };
      break;
    case SUBMIT_QUESTION_RESULT:
      state = {
        ...state,
        submit_question_result: action.payload
      };
      break;
    case IS_MOBILE:
      state = {
        ...state,
        is_mobile: action.payload
      };
      break;
    case USERINFO:
      state = {
        ...state,
        user_info: action.payload
      };
      break;
    case Q_LANDING_PAGE:
      state = {
        ...state,
        landing_page: action.payload
      };
      break;
    case Q_KIDS_LANDING_PAGE:
      state = {
        ...state,
        kids_landing_page: action.payload
      };
      break;
    case Q_PRENATAL_LANDING_PAGE:
      state = {
        ...state,
        prenatal_landing_page: action.payload
      };
    break;
    case Q_FETCH_QUESTIONS:
      const q = action.payload.data;
      const icons = action.payload.icons;
      const { pages, pagesObj, pagination } = totalPages(q);

      state = {
        ...state,
        [`${type}PageQuestions`]: q,
        [`${type}PageLength`]: pages,
        [`${type}PagesObj`]: pagesObj,
        [`${type}Pagination`]: pagination,
        icons: icons || []
      };
      break;
    case Q_SET_ANSWER:
      state = {
        ...state,
        [`${type}SelectedAnswers`]:  action.payload
      };
      setLocalQuestion(state?.[`${type}SelectedAnswers`]);
      setLocalQuestion(state?.[`${type}SelectedAnswers`]);
      break;
    case Q_UPDATE_ANSWER:
      //validation ends
      state = {
        ...state,
        [`${type}SelectedAnswers`]:  {
          ...state?.[`${type}SelectedAnswers`],
          [action.payload.key]: action.payload.value
        }
      };
      setLocalQuestion(state?.[`${type}SelectedAnswers`]);
      break;
    case Q_NEXT_SECTION:
      const currentCounter = state?.[`${type}Counter`] + action.sectionLength;
      const currentPageId = state?.[`${type}PageId`] + action.sectionLength;
      state = {
        ...state,
        [`${type}Counter`]: currentCounter < 0 ? 0 : currentCounter,
        [`${type}PageId`]: currentPageId < 1 ? 1 : currentPageId,
        backPage: false,
        nextPage: true,
        [`${type}SelectedAnswers`]:  _.pickBy(state?.[`${type}SelectedAnswers`], (value, key) => !action.sectionQuetions.includes(key)),
      };
      setLocalQuestion(state?.[`${type}SelectedAnswers`]);
      setQuestionsNumber(state?.[`${type}PageId`]);
      saveQuestionsLength(state?.[`${type}PageLength`]);
      break;
    case Q_PREVIOUS_SECTION:
      const skipedSectionLength = action.data;
      const newCounter = state?.[`${type}Counter`] - skipedSectionLength;
      const newPageId = state?.[`${type}PageId`] - skipedSectionLength;
      state = {
        ...state,
        [`${type}Counter`]: newCounter < 0 ? 0 : newCounter,
        [`${type}PageId`]: newPageId < 1 ? 1 : newPageId,
        backPage: true,
        nextPage: false,
      };
      setQuestionsNumber(state?.[`${type}PageId`]);
      saveQuestionsLength(state?.[`${type}PageLength`]);
      break;
    case Q_NEXT_PAGE:
      state = {
        ...state,
        [`${type}Counter`]: state?.[`${type}Counter`] + 1 < 0 ? 0 : state?.[`${type}Counter`] + 1,
        [`${type}PageId`]: state?.[`${type}PageId`] + 1 < 1 ? 1 : state?.[`${type}PageId`] + 1,
        backPage: false,
        nextPage: true
        //pageCategory: state.pageQuestions[state?.[`${type}Counter`] + 1].category,
        /*pageQuestions: filterQuestions(
          state.pageQuestions,
          state.selectedAnswers
        ),*/
      };
      setQuestionsNumber(state?.[`${type}PageId`]);
      saveQuestionsLength(state?.[`${type}PageLength`]);
      break;
    case Q_CURRENT_PAGE:
      const tmpPage = parseInt(action.payload, 10);
      state = {
        ...state,
        [`${type}Counter`]: tmpPage - 1 < 0 ? 0 : tmpPage - 1,
        [`${type}PageId`]: tmpPage < 1 ? 1 : tmpPage,
        backPage: false,
        nextPage: false
        //pageCategory: state.pageQuestions[tmpPage - 1].category,
        /*pageQuestions: filterQuestions(
          state.pageQuestions,
          state.selectedAnswers
        ),*/
      };
      setQuestionsNumber(state?.[`${type}PageId`]);
      break;
    case Q_PREVIOUS_PAGE:
      const prev_page_id = action.newPageId ? action.newPageId : state?.[`${type}PageId`];
      const prev_counter_id = prev_page_id - 1;
      state = {
        ...state,
        [`${type}Counter`]: prev_counter_id - 1 < 0 ? 0 : prev_counter_id - 1,
        [`${type}PageId`]: prev_page_id - 1 < 1 ? 1 : prev_page_id - 1,
        backPage: true,
        nextPage: false
        //pageCategory: state.pageQuestions[state?.[`${type}Counter`] - 1].category,
        /*pageQuestions: filterQuestions(
          state.pageQuestions,
          state.selectedAnswers
        ),*/
      };
      setQuestionsNumber(state?.[`${type}PageId`]);
      saveQuestionsLength(state?.[`${type}PageLength`]);
      break;
    case Q_RESET_ANSWER:
      state = {
        ...state,
        [`${type}SelectedAnswers`]:  {},
        [`${type}Counter`]: 0,
        [`${type}PageId`]: 1
      };
      removeLocalQuestion();
      removeQuestionsNumber();
      break;
    case Q_FETCH_RESULTS:
      state = {
        ...state,
        question_results: action.payload
      };
      break;
    case Q_FETCH_HEALTHPROFILE:
      state = {
        ...state,
        healthProfile: action.payload
      };
      break;
    case Q_EDIT_QUESTION:
      state = {
        ...state,
        edit_question: action.payload
      };
      break;
    case Q_VALIDATE_QUESTION:
      state = {
        ...state,
        validate_question: { ...state.validate_question, ...action.payload }
      };
      break;
    case 'Q_VALIDATE_QUESTION_RESET':
      state = {
        ...state,
        validate_question: null
      };
      break;
    case REMOVE_QUESTION_VALIDATION:
      const { question } = action.data;
      state = {
        ...state,
        validate_question: {
          ...state.validate_question,
          [question]: false,
        }
      }
      break;
    case SET_ACCOUNT:
      state = {
        ...state,
        account: action.data,
      };
      break;
    case SET_CKID:
      state = {
        ...state,
        ckid: action.data,
      };
      break;
    case SET_BANNER:
      state = {
        ...state,
        banner: action.data,
      };
      break;
    case SET_HISTORY:
      state = {
        ...state,
        history: action.data,
      };
      break;
    default:
      break;
  }

  return state;
};

export default QuestionReducer;
