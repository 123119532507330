import React, { memo, useMemo, useContext } from 'react';
import LazyLoad from 'react-lazyload';
import Banner from './Banner';
import MKTPrenatalStep from './MKTPrenatalStep';
import DisplayItem from './DisplayItem';
import { ExtraPerks, SafeProven } from '../../landing';
import { Social, Disclaimer, Packaged } from '../../Marketing';
import Nutrition from './Nutrition';
//import ReviewSlider from './ReviewSlider';
import { getExperDisplayProps } from './ItemConfig';
import { Helmet } from 'react-helmet';
import { MarketingContext } from 'components/Marketing/Marketing';

const PrenatalMarketing = () => {
  const { t, page } = useContext(MarketingContext);
  const expertProps = useMemo(() => getExperDisplayProps(t), [t]);
  return (
    <>
      <Helmet>
        <title>{t('ui.prenatal_marketing.page_title')}</title>
        <meta
          name="description"
          content={t('ui.prenatal_marketing.meta_description')}
        />
      </Helmet>
      <LazyLoad>
        <Banner />
      </LazyLoad>
      <LazyLoad>
        <MKTPrenatalStep t={t} />
      </LazyLoad>
      <LazyLoad>
        <div className="mkt-display-container expert">
          <div className="mkt-display-items expert-items">
            {expertProps?.map((p, idx) => (
              <DisplayItem key={idx} {...p} />
            ))}
          </div>
        </div>
      </LazyLoad>
      <LazyLoad>
        <Nutrition t={t} />
      </LazyLoad>
      <LazyLoad>
        <div className="mkt-vitamin-container">
          <div className="mkt-vitamin-text">
            <div>
              <img alt="" src="https://images.shaklee.com/meology/prenatal/mkt_logo_vitamin.png"></img>
              <h2 className="mkt-section-sub-title">
                {t('ui.prenatal_marketing.section_7.headline')}
              </h2>
              <p>{t('ui.prenatal_marketing.section_7.content')}</p>
            </div>
          </div>
        </div>
      </LazyLoad>
      {/* <LazyLoad>
        <ReviewSlider t={t} />
      </LazyLoad> */}
      <LazyLoad>
        <div className="mkt-signin-container">
          <p>{t('ui.prenatal_marketing.section_9.content')}</p>
          <img className='sign' alt="signature Dr Shaklee" src="https://images.shaklee.com/meology/prenatal/mkt_signature.svg"></img>
        </div>
      </LazyLoad>
      <LazyLoad>
        <Packaged />
      </LazyLoad>
      {/* <LazyLoad>
        <div className="mkt-as-seen-in-container">
          <span className="as-seen-in-text">
            {t('ui.prenatal_marketing.section_10.headline')}
          </span>
          <p className="mkt-section-sub-title">
            {t('ui.prenatal_marketing.section_10.content')}
          </p>
          <div className="as-seen-in-imgs">
            <img src="https://images.shaklee.com/meology/prenatal/mkt_logo_allure.png?v=01" alt=""></img>
            <img src="https://images.shaklee.com/meology/prenatal/mkt_logo_byrdie.png?v=01" alt=""></img>
          </div>
        </div>
      </LazyLoad> */}
      <LazyLoad>
        <SafeProven page={page}/>
      </LazyLoad>
      <LazyLoad>
        <ExtraPerks />
      </LazyLoad>
      <LazyLoad>
        <Social />
      </LazyLoad>
      <LazyLoad>
        <Disclaimer />
      </LazyLoad>
    </>
  );
};

export default memo(PrenatalMarketing);
