import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const PromotionBanner = ({ banners }) => {
  const { t } = useTranslation();
  if (_.isEmpty(banners) || !_.isArray(banners)) return 0;
  return (
    <>
      {banners.map(({ banner, status }) => (
        <div key={banner}>
          {status && (
            <div className="promotion-banner-wrap">
              <div className="promotion-banner">
                {t(`${banner}.promo.title`)}
	              {/*<a
                  className="promotion-banner-link"
                  href={t(`${banner}.promo.more.link`)}
                >
                  {t(`${banner}.promo.more`)}
                </a>*/}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default memo(PromotionBanner);
