import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CtaBtn from 'components/common/CtaBtn';
import { useIsMarketing } from 'assets/js/hooks';

const SafeProven = ({page}) => {
  const { t } = useTranslation();
  const { landing_page: landingPage ,prenatal_landing_page: prenatalLandingPage} = useSelector(
    state => state.QuestionReducer
  );
  const { isMarketing, isPrenatalMarketing,  isRegularMarketing}= useIsMarketing(page);

  return (
  <div className="safe-proven-container">
    <div className="safe-proven">
      <h2 className="title">{t('ui.landing_page_1.quality_module_title_2')}</h2>
      <div className="safe-proven-text">
        <div className="section">
          <h3 className="header">
            {t('ui.landing_page_1.warranty.title_2')}
          </h3>
          <div className="context">
            {t('ui.landing_page_1.warranty.title_2.copy_2')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">
            {t('ui.landing_page_1.warranty.title_1')}
          </h3>
          <div className="context">
            {t('ui.landing_page_1.warranty.title_1.copy_1')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">
            {t('ui.landing_page_1.warranty.title_3')}
          </h3>
          <div className="context">
            {t('ui.landing_page_1.warranty.title_3.copy_3')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">{t('ui.marketing.standards_title7')}</h3>
          <div className="context">
            {t('ui.marketing.standards_title10')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">{t('ui.marketing.standards_title9')}</h3>
          <div className="context">
            {t('ui.marketing.standards_title5_1')}
          </div>
        </div>
      </div>
        {isMarketing && (
          <div className={`cta-button-wrap cta-button-wrap4 ${isPrenatalMarketing ? 'prenatal' : ''}`}>
            {isRegularMarketing && <CtaBtn
              type="regular"
              status={landingPage}
              eventLabel="marketing page cta section"
            />}
            {isPrenatalMarketing && <CtaBtn
              type="prenatal"
              status={prenatalLandingPage}
              eventLabel="Marketing Page"
              eventAction="Start Here 5"
              page={page}
            />}
          </div>
        )}
    </div>
  </div>
);
};

export default SafeProven;
