import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { getLocalStorage, setLocalStorage,getPrenatalPhaseStatus } from 'assets/js/helper';
import { useMeologyHistory } from 'assets/js/hooks';
import { changePrenatalStep,setReproductiveStatus } from 'components/results/Recommendations/RecommendationsActions';
import RecommendationModal from 'components/results/Recommendations/RecommendationModal';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const PrenatalStep = ({isMKTPage}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { prenatalStep, reproductiveStatus } = useSelector(
    state => state.recommendationsReducer
  );
  const {isPrenatalToRegular} = useMeologyHistory()
  const [showPrenatal, setShowPrenatal] = useState(false);
  const isShowPrenatal = getLocalStorage('isShowPrenatal');
  const [currentStep, setCurrentStep] = useState('')

  useEffect(() => {
    setCurrentStep(prenatalStep)
  }, [prenatalStep])

  useEffect(() => {
    if(isMKTPage) {
      setCurrentStep('TRYING')
      dispatch(changePrenatalStep('TRYING'))
      dispatch(setReproductiveStatus(''))
    }
    return () => {
      if(isMKTPage) {
        dispatch(changePrenatalStep(''))
      }
    }
  }, [dispatch, isMKTPage])

  const handleClick = (status, states) => {
    if (states.next && status === 'PREGNANT' && !isShowPrenatal) {
      setShowPrenatal(true);
    } else {
      dispatch(changePrenatalStep(status));
    }

    // Google Analysis
    const eventActionMap = {
      TRYING: 'Preparing Switch',
      PREGNANT: 'Pregnant Switch',
      POSTPARTUM: 'Postnatal Switch',
    };

    dispatch(
      pushInteractionToDataLayer({
        eventCategory: 'Prenatal',
        eventAction: eventActionMap?.[status] ?? '',
        eventLabel: 'Reco Page',
      })
    );
  };

  const stepData = useMemo(
    () => {
      let _steps = [
        {
          title: t('ui.recommendation.prenatal.phase1'),
          status: 'TRYING',
        },
        {
          title: t('ui.recommendation.prenatal.phase2'),
          status: 'PREGNANT',
        },
        {
          title: t('ui.recommendation.prenatal.phase3'),
          status: 'POSTPARTUM',
        },
      ];
    if(isMKTPage) {
      _steps.push({
        title: 'Meology',
        status: 'MEOLOGY',
      })
    }
    return _steps
  },
    [isMKTPage, t]
  );

  return (
    <>
      <div className="prenatal-step-wrap">
        <ol className="prenatal-step">
          {stepData.map(({ title, status }, index) => {
            const isActive = currentStep === status;
            const states = getPrenatalPhaseStatus(status, reproductiveStatus)

            return (
              <li
                key={index}
                tabIndex="0"
                role="button"
                className={cx(
                  'prenatal-step-item',
                  `prenatal-step-${status.toLocaleLowerCase()}`,
                  {
                    active: isActive,
                    'prenatal-step-mkt-item': isMKTPage
                  }
                )}
                onClick={() => handleClick(status, states)}
                onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleClick(status, states)}
              >
                <h3 className="prenatal-step-title">{title}</h3>
                {!isPrenatalToRegular && states.current && isActive && !isMKTPage && (
                  <p className="prenatal-step-current-text">
                    {t('ui.recommendation.prenatal.title1')}
                  </p>
                )}
                {!isPrenatalToRegular && isActive && states.next && !isMKTPage &&  (
                  <p className="prenatal-step-active-text">
                    {t('ui.recommendation.prenatal.title2')}
                  </p>
                )}
                {isActive && (states.past || isPrenatalToRegular) && !isMKTPage && (
                  <p className="prenatal-step-active-text">
                    {t('ui.recommendation.prenatal.title3')}
                  </p>
                )}
              </li>
            );
          })}
        </ol>
      </div>
      <RecommendationModal
        className="preview-preganant-phase-modal"
        show={showPrenatal}
        onHide={() => setShowPrenatal(false)}
        body={
          <>
            <h3 className="title">
              {t('ui.recommendation.transition_popup.title')}
            </h3>
            <p className="content">
              {t('ui.recommendation.transition_popup.text1')}
            </p>
          </>
        }
        btnInfo={[
          {
            name: t('ui.recommendation.transition_popup.button'),
            event: () => {
              dispatch(changePrenatalStep('PREGNANT'));
              setShowPrenatal(false);
              setLocalStorage('isShowPrenatal', true);
            },
          },
        ]}
      />
    </>
  );
};

export default memo(PrenatalStep);
