import React, {
  memo,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';
import {
  getUrlVars,
  getLocalStorage,
  useOffsetTop,
  useWindowScroll,
  useWindowSize,
  useSize,
  removeLocalStorage,
  getLocalHealthProfile,
  isEmpty,
  setLocalStorage,
  getExtByCode,
  removeUrlParam,
  removeLocalHealthProfile,
  getIsBackorder,
  getQuestionsNumber,
  getCurrentTrimester,
  getNextProcessingDate,
  getNameFromAnswers,
  getMeologyType,
  getMeologyPriceTier,
} from 'assets/js/helper';
import {
  useSkus,
  isBottledPill,
  useKidsSachetSku,
  useTotalPrice,
  useDailyTablets,
  useProductsInfo,
  useAddToCart,
  useEdited,
  useSelect,
  useMeologyHistory,
  getImpersonation,
  useProfileId,
  useOrderUrl,
  usePrenatalStatus,
  usePrenatalSachetSku,
  useFixedProductTotalPrice,
  useProductPrice,
  useRSWStatus,
  useKidsSachetPrice,
  useMinimumPrice,
  usePrenatalSachetInfo,
} from 'assets/js/hooks';
import RecommendationsBanner from './RecommendationsBanner';
import { PrenatalBanner, PrenatalNext } from 'components/results/Prenatal';
import RecommendationsStepBanner from './RecommendationsStepBanner';

import RecommendationsStepProvelt from './RecommendationsStepProvelt';
import { KidsBanner } from 'components/results/Kids';
import RecommendationsPill from './RecommendationsPill';
import RecommendationsDagger from './RecommendationsDagger';
import Summary from '../Summary';
import OtherRecommendations from '../OtherRecommendations';
import LearnMore from '../LearnMore/LearnMore';
import {
  getAnswersByHealthProfileId,
  getAnswersByShakleeId,
  checkUserEmail,
  setNavigationDataLayer,
  postUpdate,
  setHistory
} from '../../questions/QuestionActions';
import {
  updateCountry,
  updateLanguage,
  updatePriceTier,
} from 'components/userProfile/userActions';
import {
  fetchAddToCart,
  getRecommendation,
  changePrenatalStep,
  setReproductiveStatus,
	getRecommendationProvelt,
	getProveltCode
} from './RecommendationsActions';
import {
  ADD_ONS,
  PROVE_IT,
  TIER_1,
  TIER_1_PRODUCTS,
  TIER_2,
  TIER_2_PRODUCTS,
  TIER_2_OPTIONAL,
  PROVE_IT_ADD_ONS,
  SACHET_SKU_US,
  SACHET_SKU_CA,
  PROVE_IT_SKU_US,
  PROVE_IT_SKU_CA,
  CONCEPTION_TIER,
  PREGNANT_TIER,
  POSTNATAL_TIER,
  CONCEPTION_TIER_OPTIONAL,
  PREGNANT_TIER_OPTIONAL,
  POSTNATAL_TIER_OPTIONAL,
  CONCEPTION_ADD_ONS,
  PREGNANT_ADD_ONS,
  POSTNATAL_ADD_ONS,
  CONCEPTION_PRODUCTS_PROVE_IT,
  PREGNANT_PRODUCTS_PROVE_IT,
  POSTNATAL_PRODUCTS_PROVE_IT,
  RSW_LIFE_SHAKE,
} from './RecommendationsConstants';
import { pushCartToDatalayer, pushInteractionToDataLayer, pushGA4DataToDatalayer } from 'components/analytics/analyticsActions'

import RecommendationModal from './RecommendationModal';
import RecommendationProveltModal from './RecommendationProveltModal';
import RecommendationMessage from './RecommendationMessage';
import { Spinner } from 'react-bootstrap';
import ProveIt from 'components/results/Recommendations/ProveIt';
import ProveItBanner from 'components/results/Recommendations/ProveItBanner';
import AutoShipInfo from 'components/results/Recommendations/AutoShipInfo';
import NoMeology from 'components/results/Recommendations/NoMeology';
import RetakeOptin from "./RetakeOptin";
import PrenatalConfirmModal from "./PrenatalConfirmModal"
import { PrenatalJoinFlow } from "components/results/prenatalRSW"
import ResultProvider from 'components/results/ContextProvider'

const baseSiteUidMap = {
  US: 'shakleeUS',
  CA: 'shakleeCA'
};

const Recommendations = ({
  headerHeight,
  setName,
  isCalledRecomendation,
  setPage
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const location = useLocation();
  const { lang, country, email, isUserLoggedin, rankId, shakleeId, accountType, contactId, firstName, lastName } = user;
  const { loading } = useSelector(state => state.async);
  const {
    prenatalStep,
    data: recommendationData,
    product_info: productInfo,
    conception_product_info: conceptionProductInfo,
    postnatal_product_info: postnatalProductInfo,
    pregnant_product_info: pregnantProductInfo,
  } = useSelector(state => state.recommendationsReducer);
  const isMobile = useSelector(state => state.QuestionReducer.is_mobile);
  const { isPrenatalPreview, isPreviewPostnatal, isPreviewPregnancy } = usePrenatalStatus();
  const  prenatalProductInfo = useMemo(() => {
     const _info = {TRYING: conceptionProductInfo,
      PREGNANT:pregnantProductInfo,
      POSTPARTUM: postnatalProductInfo}
      return _info[prenatalStep]
  }, [conceptionProductInfo, postnatalProductInfo, pregnantProductInfo, prenatalStep])
  let emailRef = useRef(email);
  const lang_code = useMemo(
    () => (lang === 'zh' ? 'zh_CN' : `${lang}_${country}`),
    [lang, country]
  );
  const sachetSku = useSkus(country, {
    US: SACHET_SKU_US,
    CA: SACHET_SKU_CA,
  });
  const proveItSkus = useSkus(country, {
    US: PROVE_IT_SKU_US,
    CA: PROVE_IT_SKU_CA,
  });
  const kidsSachetSku = useKidsSachetSku(country);
  const prenatalSachetSku = usePrenatalSachetSku(country);

  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [priceTier, setPriceTier] = useState(user.priceTier);
  const [basic, setBasic] = useState(true);
  const [learnMoreInfo, setLearnMoreInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [hpId, setHpId] = useState(null);
  const [showErrorTemplate, setShowErrorTemplate] = useState(null);
  const [asmParam, setASMParam] = useState(false);
  const [shakleeIdParam, setShakleeIdParam] = useState(false);
  const [loginProfileId, setLoginProfileId] = useState(null);
  const { isAddToCart, setIsAddToCart, addToCartResult } = useAddToCart();
  const isEditedKidsRef = useRef(false);
  const { kit_code, lang: langFromUrl } = getUrlVars() || {};
  const healthProfileRef = useRef(getLocalHealthProfile());
  const { healthProfile } = useSelector(state => state.QuestionReducer);
  const kitCodeRef = useRef(getLocalStorage('kit_code') || kit_code);
  const [isShowReplace, setIsShowReplace] = useState(false);
  const [isShowBackorder, setIsShowBackorder] = useState(false);
  const [showPrenatalExisting, setShowPrenatalExisting] = useState(false);
  const [showPrenatalExistingSuccess, setShowPrenatalExistingSuccess] = useState(false);
  const [showPrenatalSavedSuccess, setShowPrenatalSavedSuccess] = useState(false);
  const [isShowMin, setIsShowMin] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const isAutoShipCart = useRef(false);
  const autoshipCode = useRef(false);
  const hideReplace = () => setIsShowReplace(false);
  const hideBackorder = () => setIsShowBackorder(false);
  const hidePrenatalExisting = () => setShowPrenatalExisting(false);
  const hidePrenatalExistingSuccess = () => {
    removeUrlParam('status', location);
    setShowPrenatalExistingSuccess(false);
  };
  const hidePrenatalSavedSuccess = () => {
    removeUrlParam('status', location);
    setShowPrenatalSavedSuccess(false);
  };
  const hideMin = () => setIsShowMin(false);
  const [isPi, setIsPi] = useState(false);
  const [isSelectedProveIt, setIsSelectedProveIt] = useState(false);
  const [profileId, setProfileId] = useState('');
  const [isShowPrenatalConfirm, setIsShowPrenatalConfirm] = useState(false);
  const [phase, setPhase] = useState('');
  const [callAutoship, setCallAutoship] = useState(false)
  const [showRSWIncompletedError, setShowRSWIncompletedError] = useState(false)
  const {
    prenatal_reproductive_status: reproductiveStatus,
    prenatal_due_date,
  } = selectedAnswers || {};

  const hidePrenatalConfirm = useCallback((e, isChanged) => {
    if(!isChanged) {
      dispatch(changePrenatalStep(reproductiveStatus))
    }
    setPhase('')
    setIsShowPrenatalConfirm(false)
  }, [dispatch, reproductiveStatus])

  const showPrenatalConfirm = useCallback((_prenatalStep) => {
    const Phases = {
      TRYING: 'phase1',
      PREGNANT:'phase2',
      POSTPARTUM:'phase3'
    }
    setPhase(Phases[_prenatalStep])
    setIsShowPrenatalConfirm(true)


    // Google Analysis
    const eventActionMap = {
      TRYING: "I'm Pregnant",
      PREGNANT: 'My Baby Was Born',
      POSTPARTUM: '',
    };

    dispatch(
      pushInteractionToDataLayer({
        eventCategory: 'Prenatal',
        eventAction: eventActionMap?.[_prenatalStep] ?? '',
        eventLabel: 'Reco Page',
      })
    );
  }, [dispatch])

  const {
    newKidsInfo,
    newKidsNumber,
    isMeologyRegular,
    isMeologyKids,
    isMeologyPrenatal,
    meology_type,
    meology_autoship_info,
    accountEmail,
    opt_in,
    isView,
    prenatalHistory,
    hasMeologyPrenatalOrderEntry,
    hasRegularMeologyOrderEntry,
    autoshipInfos,
  } = useMeologyHistory();
  const [currentAutoshipCode, setCurrentAutoshipCode] = useState(meology_autoship_info?.code)
  const nextProcessingDate = useMemo(
    () =>
      getNextProcessingDate({
        autoshipInfos,
        currentAutoshipCode,
      }),
    [autoshipInfos, currentAutoshipCode]
  );
  useEffect(() => {
    setCurrentAutoshipCode(meology_autoship_info?.code)
  }, [meology_autoship_info])

  const { isImpersonation, isBL, isCSR, isSponsor } = getImpersonation();
  const { regularProfileId, kidsProfileId, prenatalProfileId } = useProfileId();
  const {
    isJoinFlow,
    isPdpFlow,
    isValid,
    isRegularIndependentFlow,
    isRegularJionFlow,
    isPrenatalJionFlow,
    isIndependentFlow,
    isRSWJionFlow,
  } = useRSWStatus();

  const showAddOn = useMemo(() => isMeologyKids || (isMeologyRegular && !isJoinFlow), [isJoinFlow, isMeologyKids, isMeologyRegular]);


	const isRegularAutoShipCart = useMemo(
		() => isAutoShipCart.current && isMeologyRegular,
		[isMeologyRegular]
	);

  const orderUrl = useOrderUrl();
  const history = useHistory();


  const submitPrenatalConfirm = useCallback(async(_phase,_data) => {
    try {
      if(!_data) return
      let _postData ={opt_in, email: accountEmail, prenatal_name: prenatalHistory?.questions?.prenatal_name || '', ...(_data || {})}
      const response = await dispatch(postUpdate(_postData, country,lang))
      if(!response) return
      setProfileId(response.health_profile_id)
      let _prenatalHistory = {
        ...(prenatalHistory || {}),
        questions: {...(prenatalHistory?.questions || {}),..._postData},
        health_profile_id: response.health_profile_id,
        prenatal_health_profile_id: response.health_profile_id
      }
      setSelectedAnswers(_prenatalHistory.questions);
      dispatch(setHistory(_prenatalHistory))
      if(hasMeologyPrenatalOrderEntry) {
        setCallAutoship(true)
      } else {
        // eslint-disable-next-line no-unused-expressions
        hidePrenatalConfirm(null,true)
      }
    } catch (e) {
      hidePrenatalConfirm()
    }
  },[accountEmail, country, dispatch, hasMeologyPrenatalOrderEntry, hidePrenatalConfirm, lang, opt_in, prenatalHistory])

  const { i18n } = useTranslation();

  const changeI18nLanguage = useCallback((country, language) => {
    if (country === 'US') {
      i18n.changeLanguage(language);
    } else {
      i18n.changeLanguage(language + '-' + country);
    }
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  const Idchange = useRef(false);

  // remove kit_code when click edit link in hybris cart
  useEffect(() => {
    const { mode, type } = getUrlVars();
    if (!mode) return;
    if ((mode === 'edit') && kitCodeRef.current) {
      kitCodeRef.current = null;
      removeLocalStorage('kit_code');
    }

    if (mode === 'edit' && type === 'kids') {
      setLocalStorage('meologyType', 'KIDS');
    } else if (mode === 'edit' || mode === 'restore') {
      if(type === 'prenatal') {
        setLocalStorage('meologyType', 'PRENATAL');
      } else {
        setLocalStorage('meologyType', 'REGULAR');
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isRSWJionFlow ||
      (isPdpFlow && isIndependentFlow && !isMeologyPrenatal)
    ) {
      setIsSelectedProveIt(true);
    } else {
      setIsSelectedProveIt(false);
    }
  }, [isPdpFlow, isRSWJionFlow, isIndependentFlow, isMeologyPrenatal]);

  useEffect(() => {
    if (kit_code) {
      setLocalStorage('kit_code', kit_code);
    } else {
      removeLocalStorage('kit_code');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToHomePage = () => {
    window.location.href = '/';
    return null;
  }

  const name = getNameFromAnswers(selectedAnswers);

  const trimester = useMemo(() => getCurrentTrimester(prenatal_due_date), [prenatal_due_date]);

  const reGetRecommendation = useCallback(
    ({ selectedAnswers, profileId }) => {
      dispatch(
        getRecommendation({
          selectedAnswers: {
            ...selectedAnswers,
            ...(isValid && {
              request_products: ['PROVE_IT'],
            }),
          },
          country,
          lang,
          rank: rankId,
          shakleeId: shakleeId,
          health_profile_id: profileId,
          showSoyLifeShake: t('prenatal.soy_life_shake_visible') === 'true'
        })
      );
    },
    [country, dispatch, isValid, lang, rankId, shakleeId, t]
  );

  // get selected answers
  useEffect(() => {
    const processFunc = async () => {
      let checkLocalStorage = false;
      let health_profile_id_loc = '';
      let result = '';
      let res = '';
      const urlParams = new URLSearchParams(window.location.search);
      const { meologyType } = getMeologyType()
      let shakleeId = urlParams.get('sdi');
      const hpID = urlParams.get('hpId') || urlParams.get('hpid');
      setShakleeIdParam(!!shakleeId);
      setASMParam(urlParams.get('asm'));

      if (shakleeId) {
        if (meologyType === "REGULAR") {
          // Get history by Shaklee ID is regular by default
          res = await dispatch(getAnswersByShakleeId(shakleeId));
        } else {
          // Kids and Prenatal need to use email to get the history
          const response = await dispatch(getAnswersByShakleeId(shakleeId));
          res = await dispatch(
            getAnswersByHealthProfileId({
              postData: {
                email: response?.email,
                meology_type: meologyType,
              },
            })
          );
        }

        const {
          health_profile_id,
          questions,
          account_first_name,
          account_last_name,
          email,
          account_type,
          contact_id,
        } = res || {};

        health_profile_id_loc = health_profile_id;
        result = questions;
        setName(`${account_first_name} ${account_last_name}`);

        // Google Analysis
        dispatch(
          setNavigationDataLayer({
            isLoggedin: true,
            type: account_type,
            contactId: contact_id,
            email,
            firstName: account_first_name,
            lastName: account_last_name
          })
        );
      } else if (isImpersonation && !isSponsor) {
        // No 'sdi' in URL when BL Sponsor someone or CSR agent join as guest
        removeLocalHealthProfile();
        setName('Guest');
      } else if (hpID) {  //email
        health_profile_id_loc = hpID;
        await setHpId(hpID);
        res = await dispatch(
          getAnswersByHealthProfileId({ postData: { health_profile_id: hpID } })
        );
        result = res && res.questions;
      } else if (id) { //url/hpid
        checkLocalStorage = true;
        health_profile_id_loc = id;
        res = await dispatch(
          getAnswersByHealthProfileId({ postData: { health_profile_id: id } })
        );
        result = res && res.questions;
      } else if (isUserLoggedin) {
        checkLocalStorage = true;
        res = await dispatch(
          getAnswersByHealthProfileId({
            postData: {
              email,
              meology_type: meologyType,
            },
          })
        );
        health_profile_id_loc = res?.health_profile_id;
        const kidsProfileId = res?.kids_health_profile_id;
        const regularProfileId = res?.regular_health_profile_id;
        const prenatalProfileId = res?.prenatal_health_profile_id;

        // Remove or change local storage if user is logged in
        if (kidsProfileId) {
          setLocalStorage('kidsHealthProfile', {
            email,
            health_profile_id: kidsProfileId,
          })
        } else {
          removeLocalStorage('kidsHealthProfile')
        }
        if (regularProfileId) {
          setLocalStorage('regularHealthProfile', {
            email,
            health_profile_id: regularProfileId,
          })
        } else {
          removeLocalStorage('regularHealthProfile')
        }
        if (prenatalProfileId) {
          setLocalStorage('prenatalHealthProfile', {
            email,
            health_profile_id: prenatalProfileId,
          })
        } else {
          removeLocalStorage('prenatalHealthProfile')
        }

        setLoginProfileId(health_profile_id_loc);
        result = res && res.questions;
      } else if (healthProfileRef.current || isSponsor) {
        if (isSponsor) {
          setName('Guest');
        }

        checkLocalStorage = true;
        health_profile_id_loc = healthProfileRef.current.health_profile_id;
        res = await dispatch(
          getAnswersByHealthProfileId({
            postData: {
              health_profile_id: health_profile_id_loc,
            },
          })
        );
        result = res && res.questions;
      } else {
        goToHomePage();
      }

      const {
        hasWK,
        meology_type,
        questions,
        pricetier,
      } = res || {};

      // Change price tier base on history
      const meologyPriceTier = getMeologyPriceTier(pricetier);
      setPriceTier(meologyPriceTier);
      dispatch(updatePriceTier(meologyPriceTier));

	    setIsPi(_.isBoolean(hasWK) ? hasWK : hasWK === 'true');
      if (!isEmpty(meology_type)) {
        setLocalStorage('meologyType', meology_type);
      }

      if (isView || isImpersonation) {
        if (questions) {
          setSelectedAnswers(result);
          setProfileId(health_profile_id_loc);
        }
        return;
      }

      setIsPi(_.isBoolean(hasWK) ? hasWK : hasWK === 'true');

      if (!result) {
        goToHomePage();
      }

      if (checkLocalStorage && isUserLoggedin && res) {
        // check if logged in user email is same as localstorage email, else delete data
        let check = await checkUserEmail(user, res);
        if (check) {
          goToHomePage();
        }
      }
      if (isUserLoggedin) {
        await dispatch(
          setNavigationDataLayer({
            isLoggedin: isUserLoggedin,
            type: accountType,
            rank: rankId,
            contactId,
            email,
            firstName,
            lastName
          })
        );
      } else {
        await dispatch(
          setNavigationDataLayer({ isLoggedin: false, type: accountType })
        );
      }
      // anonymous user needs change email to user answer's email
      if (!isUserLoggedin && result.email) {
        emailRef.current = result.email;
      }
      const {prenatal_reproductive_status } = result || {}
      if( prenatal_reproductive_status === 'REGULAR' ) {
        result = {...(result|| {}), prenatal_reproductive_status: 'POSTPARTUM'}
      }
      setSelectedAnswers(result);
      setProfileId(health_profile_id_loc);
    };

    processFunc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    healthProfileRef,
    dispatch,
    history,
    setName,
    isUserLoggedin,
    email,
    rankId,
    contactId,
    accountType,
    isImpersonation,
    isView,
    isBL,
    isCSR,
    isSponsor,
  ]);

  // Re-get history when edit Meology Kids
  useEffect(() => {
    (async () => {
      if (!isEditedKidsRef.current) return;
      const { health_profile_id } = healthProfile || {};
      const questionsPageNumber = getQuestionsNumber();
      if (!health_profile_id) return;
      let request = {};
      if (isUserLoggedin) {
        request = {
          email: emailRef.current,
          meology_type,
        };
      } else {
        request = {
          health_profile_id,
        };
      }
      const history = await dispatch(
        getAnswersByHealthProfileId({ postData: request })
      );

      const { questions } = history || {};
      if (questions) {
        setSelectedAnswers(questions);
        isCalledRecomendation.current = false;
      } else {
        removeLocalHealthProfile();
        if (regularProfileId && !questionsPageNumber) {
          changeMeologyType('REGULAR', regularProfileId);
        } else {
          goToHomePage();
        }
      }
      isEditedKidsRef.current = false;
    })();
  }, [dispatch, healthProfile, isCalledRecomendation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(langFromUrl)) return;
    if (!id && !hpId && !shakleeIdParam) return;
    if (!_.isEmpty(selectedAnswers) && !Idchange.current) {
      const { country_code, language } = selectedAnswers || {};
      if (!country_code || !language) return;
      changeI18nLanguage(country_code, language);
      dispatch(updateCountry({ country: country_code, language: language }));
      dispatch(updateLanguage({ country: country_code, language: language }));
      Idchange.current = true;
    }
  }, [changeI18nLanguage, dispatch, hpId, id, selectedAnswers, shakleeIdParam, langFromUrl])

  // re-get recommendation and products
  useEffect(() => {
    if (!profileId || isCalledRecomendation.current) return;
    reGetRecommendation({ selectedAnswers, profileId });
    isCalledRecomendation.current = true;
  }, [isCalledRecomendation, profileId, reGetRecommendation, selectedAnswers]);

  useEffect(() => {
    window.onpopstate = e => {
      window.location.reload();
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);

  // update prenatal step according to selected answer
  useEffect(() => {
    dispatch(changePrenatalStep(reproductiveStatus));
    dispatch(setReproductiveStatus(reproductiveStatus));
  }, [
    dispatch,
    reproductiveStatus,
  ]);

  const showContent = !isBL;
  //Loop through the recommendations create essential, essentialPlus and addons
  //addon pills list

  const optionalKey = useMemo(() => {
    if (isMeologyRegular) return TIER_2_OPTIONAL;
    if (isMeologyPrenatal) {
      const map = {
        TRYING: CONCEPTION_TIER_OPTIONAL,
        PREGNANT: PREGNANT_TIER_OPTIONAL,
        POSTPARTUM: POSTNATAL_TIER_OPTIONAL,
      };
      return map[prenatalStep];
    }
  }, [prenatalStep, isMeologyPrenatal, isMeologyRegular]);

  const proveitKey = useMemo(() => {
    if (isMeologyRegular) return PROVE_IT;
    if (isMeologyPrenatal) {
      const map = {
        TRYING: CONCEPTION_PRODUCTS_PROVE_IT,
        PREGNANT: PREGNANT_PRODUCTS_PROVE_IT,
        POSTPARTUM: POSTNATAL_PRODUCTS_PROVE_IT,
      };
      return map[prenatalStep];
    }
  }, [prenatalStep, isMeologyPrenatal, isMeologyRegular]);

  const addonsKey = useMemo(() => {
    if (isMeologyRegular) {
      if (isSelectedProveIt) {
        return PROVE_IT_ADD_ONS;
      } else {
        return ADD_ONS;
      }
    }

    if (isMeologyKids) return ADD_ONS;

    if (isMeologyPrenatal) {
      const map = {
        TRYING: CONCEPTION_ADD_ONS,
        PREGNANT: PREGNANT_ADD_ONS,
        POSTPARTUM: POSTNATAL_ADD_ONS,
      };
      return map[prenatalStep];
    }
  }, [isMeologyRegular, isMeologyKids, isMeologyPrenatal, isSelectedProveIt, prenatalStep]);

  const essentialPillsKey = useMemo(() => {
    if (isMeologyRegular || isMeologyKids) return TIER_1;
    if (isMeologyPrenatal) {
      const map = {
        TRYING: CONCEPTION_TIER,
        PREGNANT: PREGNANT_TIER,
        POSTPARTUM: POSTNATAL_TIER,
      };
      return map[prenatalStep];
    }
  }, [isMeologyKids, isMeologyPrenatal, isMeologyRegular, prenatalStep])

  const optionalInfo = useProductsInfo(recommendationData, optionalKey);
  const addonsInfo = useProductsInfo(recommendationData, addonsKey);
  const essentialPillsInfo = useProductsInfo(recommendationData, essentialPillsKey);

  const tier1ProductsInfo = useProductsInfo(
    recommendationData,
    TIER_1_PRODUCTS
  );
  const tier2ProductsInfo = useProductsInfo(
    recommendationData,
    TIER_2_PRODUCTS
  );

  //essential plus pills list
  const tier2Info = useProductsInfo(recommendationData, TIER_2);

  // proveit list
  const proveitProductsInfo = useProductsInfo(recommendationData, proveitKey);


  const essentialPills = useMemo(() => {
    if (isMeologyRegular) {
      return [...essentialPillsInfo, ...tier1ProductsInfo];
    } else {
      return essentialPillsInfo;
    }
  }, [essentialPillsInfo, isMeologyRegular, tier1ProductsInfo]);
  const tier2Pills = useMemo(() => [...tier2Info, ...tier2ProductsInfo], [
    tier2Info,
    tier2ProductsInfo
  ]);

  // edited data when toggle
  const [editedTier2Pills, changeTier2Pills, resetTier2Pills] = useEdited(tier2Pills);

  const [editedAddon, changeAddon] = useEdited(
    addonsInfo
  );
  const [editedOptional, changeOptional] = useEdited(
    optionalInfo
  );

  const currentPills = useMemo(() => {
    if (isMeologyRegular) {
      if (basic) {
        return essentialPills;
      } else {
        return [...editedTier2Pills, ...editedOptional];
      }
    }

    if (isMeologyKids) return essentialPills;

    if (isMeologyPrenatal) {
      const newEssentialPills = essentialPills.map(item => ({
        ...item,
        bundle_pricing: true,
      }))
      const newEditedOptional = editedOptional.map(item => ({
        ...item,
        bundle_pricing: false,
      }))
      return [...newEssentialPills, ...newEditedOptional]
    };

    return [];
  }, [
    isMeologyRegular,
    isMeologyKids,
    essentialPills,
    isMeologyPrenatal,
    editedOptional,
    basic,
    editedTier2Pills,
  ]);

  const isBackOrder = useMemo(() => {
    const hasBackorder = currentPills
      ?.filter(({ selected }) => selected)
      ?.some(getIsBackorder);
    if (isMeologyKids) {
      return hasBackorder && newKidsNumber > 0;
    } else {
      return hasBackorder;
    }
  }, [currentPills, isMeologyKids, newKidsNumber]);

  const currentPillsDailyTablets = useDailyTablets(currentPills, country);
  // total price
  const currentPillsTotalPrice = useTotalPrice(currentPills, priceTier, null, country);
  const prenatalSachetInfo = usePrenatalSachetInfo();
  const prenatalEssentialPillsTotalPrice = useProductPrice(prenatalSachetInfo)
  const kidsSachetPrice = useKidsSachetPrice();
  const optionalTotalPrice = useTotalPrice(
    editedOptional,
    priceTier,
    null,
    country,
  );
  const addonProductsTotalPrice = useTotalPrice(
    editedAddon,
    priceTier,
    'addon',
    country
  );
  const prenatalPlusTotalPrice = useFixedProductTotalPrice(
    editedOptional,
    priceTier,
    country,
  );

  const prenatalCurrentPillsTotalPrice = useMemo(
    () =>
      Number(prenatalPlusTotalPrice) + Number(prenatalEssentialPillsTotalPrice),
    [prenatalEssentialPillsTotalPrice, prenatalPlusTotalPrice]
  );

  let { health_profile_id } = getLocalHealthProfile() || {};

  if (id) {
    health_profile_id = id;
  } else if (hpId) {
    health_profile_id = hpId;
  }

  useEffect(() => {
    if (addToCartResult) {
      let { redirectUrl } = addToCartResult;
      const urlParams = new URLSearchParams(window.location.search);
      const ckid = encodeURIComponent(urlParams.get('ckid'));
      if (!redirectUrl) return;
      if (redirectUrl.includes('?')) {
        redirectUrl += `&source=meology${ckid ? `&ckid=${ckid}` : ''}`
      } else {
        redirectUrl += `?source=meology${ckid ? `&ckid=${ckid}` : ''}`
      }
      window.location.href = redirectUrl;
    }
  }, [addToCartResult]);

  const pillsCount = useMemo(
    () =>
      currentPills
        .filter(item => item.selected)
        .reduce((acc, current) => acc + current.quantity, 0),
    [currentPills]
  );

  const minimumPrice = useMinimumPrice()
  const handleMinPriceModal = useCallback(
    (event, price) => {
      if (isMeologyPrenatal) return;
      if (event.target.checked) return;
      const remainingPrice = currentPillsTotalPrice - price;
      if (remainingPrice < minimumPrice) {
        setIsShowMin(true);
        return true;
      }
    },
    [currentPillsTotalPrice, isMeologyPrenatal, minimumPrice]
  );

  const sachetToggleAnalytics = useCallback((event, pill) => {
    const { name = '' } = pill || {};
    if (event.target.checked) {
      // analytics
      dispatch(
        pushInteractionToDataLayer({
          eventAction: 'Add to Sachet',
          eventLabel: name,
        })
      );
    } else {
      dispatch(
        pushInteractionToDataLayer({
          eventAction: 'Remove from Sachet',
          eventLabel: name,
        })
      );
    }
  }, [dispatch]);

  const isOptionalPills = useCallback(
    code => optionalInfo.some(item => item.code === code),
    [optionalInfo]
  );

  const sachetItem = useMemo(() => {
    const sub_items = currentPills
      .filter(item => item.selected)
      .filter(({ code }) => !isBottledPill(String(code)))
      .map(({ code, quantity, bundle_pricing }) => ({
        product_code: code,
        quantity: quantity * (isMeologyKids ? 25 : (code === '22097' ? 1 : 30)),
        ext: getExtByCode(recommendationData, code),
        ...(isSelectedProveIt &&
          isMeologyRegular && {
          bundle_pricing: !isOptionalPills(code),
        }),
        ...(isMeologyPrenatal &&
          !isEmpty(bundle_pricing) && {
            bundle_pricing,
          }),
      }));

    if (isMeologyKids) {
      return (
        newKidsInfo?.map(item => ({
          product_code: kidsSachetSku,
          quantity: 1,
          ext: getExtByCode(recommendationData, kidsSachetSku),
          sub_items,
          kids_info: item,
        })) ?? []
      );
    } else if (isMeologyPrenatal){
      return {
        product_code: prenatalSachetSku,
        quantity: 1,
        ext: getExtByCode(recommendationData, prenatalSachetSku),
        sub_items,
      };
    } else {
      return {
        product_code: sachetSku,
        quantity: 1,
        ext: getExtByCode(recommendationData, sachetSku),
        sub_items,
      };
    }
  }, [currentPills,
	  isMeologyKids,
	  isMeologyPrenatal,
	  recommendationData,
	  isSelectedProveIt,
	  isOptionalPills,
	  newKidsInfo,
	  kidsSachetSku,
	  prenatalSachetSku,
	  isMeologyRegular,
	  sachetSku]);

  const bottledPillItems = useMemo(
    () =>
      currentPills
        .filter(item => item.selected)
        .filter(({ code }) => isBottledPill(String(code)))
        .map(({ code, quantity }) => ({
          product_code: code,
          quantity,
          ext: getExtByCode(recommendationData, code)
        })),
    [currentPills, recommendationData]
  );
  const addonItems = useMemo(
    () =>
      editedAddon
        .filter(item => item.selected)
        .map(({ code, quantity }) => ({
          product_code: code,
          quantity,
          ext: getExtByCode(recommendationData, code)
        })),
    [editedAddon, recommendationData]
  );

  const options1 = useMemo(() => {
    const customizbleBundleComponentList = _.get(
      proveitProductsInfo[0],
      'customizbleBundleComponentList',
      []
    );
    if (customizbleBundleComponentList.length === 0) return [];
    return customizbleBundleComponentList.filter(item => item.productOptions)[0]
      .productOptions;
  }, [proveitProductsInfo]);

  const options2 = useMemo(() => {
    const customizbleBundleComponentList = _.get(
      proveitProductsInfo[0],
      'customizbleBundleComponentList',
      []
    );
    if (customizbleBundleComponentList.length === 0) return [];
    let itemWithProductOptions = customizbleBundleComponentList.filter(item => item.productOptions);
    if (itemWithProductOptions.length > 1) {
      return itemWithProductOptions[1]?.productOptions;
    } else {
      return [];
    }
  }, [proveitProductsInfo]);

  useEffect(() => {
    if (options1?.length > 1 || options2?.length > 1) {
      dispatch(
        getRecommendationProvelt({
          country,
          lang,
          options1,
          options2,
        })
      );
    }
  }, [country, dispatch, lang, options1, options2]);


  const [select1Code, select1DefaultName, changeSelect1Code] = useSelect(
    isMeologyPrenatal,
    options1
  );
  const [select2Code, select2DefaultName, changeSelect2Code] = useSelect(
    isMeologyPrenatal,
    options1,
    1,
  );
  const [select3Code, select3DefaultName, changeSelect3Code] = useSelect(
    isMeologyPrenatal,
    options2
  );
  const proveitItems = useMemo(() => {
    let currentProveIt = proveitProductsInfo;
    if (country === 'CA') {
      currentProveIt = currentProveIt.filter(
        ({ code }) => proveItSkus.includes(code)
      );
    }
    return currentProveIt
      .filter(item => item.selected)
      .map(({ code, quantity }) => ({
        product_code: code,
        quantity,
        ext: getExtByCode(recommendationData, code),
        ...({
          sub_items: [
            {
              product_code: select1Code,
              quantity: 1,
            },
            {
              product_code: select2Code,
              quantity: 1,
            },
            {
              product_code: select3Code,
              quantity: 1,
            },
          ],
        }),
      }));
  }, [country, proveItSkus, proveitProductsInfo, recommendationData, select1Code, select2Code, select3Code]);


  const isLogin = useMemo(
    () => (shakleeIdParam && asmParam === 'true') || Boolean(isUserLoggedin),
    [asmParam, isUserLoggedin, shakleeIdParam]
  );

  const checkoutRequestData = useMemo(() => {
    let items = [];
    if (!_.isEmpty(sachetItem)) {
      if (Array.isArray(sachetItem) && sachetItem.length > 0) {
        items.push(...sachetItem);
      } else {
        items.push(sachetItem);
      }
    }
    if (!_.isEmpty(bottledPillItems)) {
      items = [...items, ...bottledPillItems];
    }
    if (!_.isEmpty(addonItems)) {
      items = [...items, ...addonItems];
    }

    if (!_.isEmpty(proveitItems) && !isPi && isSelectedProveIt) {
      items = [...items, ...proveitItems];
    }

    return {
      add_to_cart: 'true',
      lang_code,
      ...(name && { meology_name: name }),
      customer_id: emailRef.current,
      registered_user: isLogin,
      profile_id: health_profile_id || loginProfileId,
      base_site_uid: baseSiteUidMap[country],
      ...(kitCodeRef.current && { kit_code: kitCodeRef.current }),
      items,
    };
  }, [
    sachetItem,
    bottledPillItems,
    addonItems,
    proveitItems,
    isPi,
    isSelectedProveIt,
    lang_code,
    name,
    isLogin,
    health_profile_id,
    loginProfileId,
    country,
  ]);

  const daggerProductData = useMemo(() => {
    if (basic) {
      return isSelectedProveIt
        ? [...editedAddon, ...essentialPills, ...proveitProductsInfo]
        : [...editedAddon, ...essentialPills];
    } else {
      return isSelectedProveIt
        ? [
          ...editedAddon,
          ...editedTier2Pills,
          ...optionalInfo,
          ...proveitProductsInfo
        ]
        : [
          ...editedAddon,
          ...editedTier2Pills,
          ...optionalInfo
        ];
    }
  }, [
    basic,
    isSelectedProveIt,
    editedAddon,
    essentialPills,
    proveitProductsInfo,
    editedTier2Pills,
    optionalInfo
  ]);

  // analytics products
  const analyticsProducts = useMemo(() => {
    const result = [];
    const pills = currentPills
      .filter(item => item.selected)
      .filter(({ code }) => !isBottledPill(String(code)))
      .map(({ code }) => code)
      .join(',');
    let sachetProducts = {
      name: 'Meology Sachet',
      quantity: 1,
      brand: '',
      size: '',
    };

    if (isMeologyRegular) {
      sachetProducts = {
        ...sachetProducts,
        id: sachetSku,
        category: 'Meology',
        price: Number(currentPillsTotalPrice),
        variant: pills,
      }
    }

    if (isMeologyPrenatal) {
      sachetProducts = {
        ...sachetProducts,
        id: prenatalSachetSku,
        category: 'Prenatal',
        price: Number(prenatalEssentialPillsTotalPrice),
        variant: pills,
      }
    }

    if (isMeologyKids) {
      const variant = newKidsInfo?.map(({ id }) => id).join(',') ?? '';

      sachetProducts = {
        ...sachetProducts,
        id: kidsSachetSku,
        category: 'Meology Kids',
        price: Number(kidsSachetPrice * newKidsNumber),
        variant,
      };
    }

    const getPrice = prices => {
      if (_.isEmpty(prices)) return 0;
      return Number(_.get(
        prices.filter(item => item.priceTier === priceTier)[0],
        'value',
        0
      ));
    };

    const gentleSleepProduct = currentPills
      .filter(item => item.selected)
      .filter(({ code }) => isBottledPill(String(code)))
      .map(({ name, code, quantity, prices }) => ({
        name,
        id: code,
        quantity,
        price: getPrice(prices),
        brand: '',
        size: '',
      }));

    const addonProduct = editedAddon
      .filter(item => item.selected)
      .map(({ name, code, quantity, prices }) => ({
        name,
        id: code,
        quantity,
        price: getPrice(prices),
        brand: '',
        size: '',
      }));

    const getProveItProduct = () => {
      let currentProveIt = proveitProductsInfo;
      if (country === 'CA') {
        currentProveIt = currentProveIt.filter(({ code }) =>
          proveItSkus.includes(code)
        );
      }
      return currentProveIt
        .filter(item => item.selected)
        .map(({ name, code, quantity, prices }) => ({
          name,
          id: code,
          quantity,
          price: getPrice(prices),
          brand: '',
          size: '',
          ...({
            variant: `${select1Code}, ${select2Code}, ${select3Code}`,
          }),
        }));
    };

    if (!_.isEmpty(sachetProducts)) {
      result.push(sachetProducts);
    }

    if (!_.isEmpty(gentleSleepProduct)) {
      result.push(...gentleSleepProduct);
    }

    if (!_.isEmpty(addonProduct)) {
      result.push(...addonProduct);
    }

    if (!isPi && isSelectedProveIt) {
      const proveItProduct = getProveItProduct();
      if (!_.isEmpty(proveItProduct)) {
        result.push(...proveItProduct);
      }
    }

    return result;
  }, [
    country,
    currentPills,
    currentPillsTotalPrice,
    editedAddon,
    isPi,
    isSelectedProveIt,
    priceTier,
    proveItSkus,
    proveitProductsInfo,
    sachetSku,
    select1Code,
    select2Code,
	  select3Code,
    isMeologyRegular,
    isMeologyPrenatal,
    isMeologyKids,
    prenatalSachetSku,
    kidsSachetSku,
    newKidsInfo,
    prenatalEssentialPillsTotalPrice,
    kidsSachetPrice,
    newKidsNumber,
  ]);

  // analytics products for GA4
  const analyticsProductsForGA4 = useMemo(() => {
    const result = [];
    const pills = currentPills
      .filter(item => item.selected)
      .filter(({ code }) => !isBottledPill(String(code)));
    const pillsCode = pills.map(({ code }) => code).join(',');
    const pillsName = pills.map(({ name }) => name).join(',');

    let sachetProducts = {
      item_name: 'Meology Sachet',
      currency: 'USD',
      quantity: 1,
      item_brand: '',
      item_variant: ''
    };

    if (isMeologyRegular) {
      sachetProducts = {
        ...sachetProducts,
        item_id: sachetSku,
        item_category: 'Meology',
        item_list_id: pillsCode,
        item_list_name: pillsName,
        price: Number(currentPillsTotalPrice)
      }
    }

    if (isMeologyPrenatal) {
      sachetProducts = {
        ...sachetProducts,
        item_id: prenatalSachetSku,
        item_category: 'Prenatal',
        item_list_id: pillsCode,
        item_list_name: pillsName,
        price: Number(prenatalEssentialPillsTotalPrice),
      }
    }

    if (isMeologyKids) {
      const idList = newKidsInfo?.map(({ id }) => id).join(',') ?? '';

      sachetProducts = {
        ...sachetProducts,
        item_id: kidsSachetSku,
        item_category: 'Meology Kids',
        price: Number(kidsSachetPrice * newKidsNumber),
        item_list_id: idList,
        item_list_name: '',
      };
    }

    const getPrice = prices => {
      if (_.isEmpty(prices)) return 0;
      return Number( _.get(
        prices.filter(item => item.priceTier === priceTier)[0],
        'value',
        0
      ));
    };

    const getProductVariant = baseOptions => {
      let productVariant = '';
      if (baseOptions?.length > 0) {
        var flavor = baseOptions[0].selected.flavor || '';
        var size = baseOptions[0].selected.size || '';
        if (flavor && size) {
          productVariant = flavor + ',' + size;
        } else {
          productVariant = flavor || size;
        }
      }
      return productVariant;
    };

    const gentleSleepProduct = currentPills
      .filter(item => item.selected)
      .filter(({ code }) => isBottledPill(String(code)))
      .map(({ name, code, quantity, prices, baseOptions }) => ({
        item_name: name,
        item_id: code,
        currency: 'USD',
        quantity,
        price: getPrice(prices),
        item_brand: '',
        item_variant: getProductVariant(baseOptions),
        item_list_id: '',
        item_list_name: ''
      }));

    const addonProduct = editedAddon
      .filter(item => item.selected)
      .map(({ name, code, quantity, prices, baseOptions }) => ({
          item_name: name,
          item_id: code,
          currency: 'USD',
          quantity,
          price: getPrice(prices),
          item_brand: '',
          item_variant: getProductVariant(baseOptions),
          item_list_id: '',
          item_list_name: ''
      }));

    const getProveItProduct = () => {
      let currentProveIt = proveitProductsInfo;
      if (country === 'CA') {
        currentProveIt = currentProveIt.filter(({ code }) =>
          proveItSkus.includes(code)
        );
      }
      return currentProveIt
        .filter(item => item.selected)
        .map(({ name, code, quantity, prices, baseOptions }) => ({
          item_name: name,
          item_id: code,
          currency: 'USD',
          quantity,
          price: getPrice(prices),
          item_brand: '',
          item_variant: getProductVariant(baseOptions),
          ...({
            item_list_id: `${select1Code}, ${select2Code}, ${select3Code}`,
          }),
          item_list_name: `${select1DefaultName}, ${select2DefaultName}, ${select3DefaultName}`
        }));
    };

    if (!_.isEmpty(sachetProducts)) {
      result.push(sachetProducts);
    }

    if (!_.isEmpty(gentleSleepProduct)) {
      result.push(...gentleSleepProduct);
    }

    if (!_.isEmpty(addonProduct)) {
      result.push(...addonProduct);
    }

    if (!isPi && isSelectedProveIt) {
      const proveItProduct = getProveItProduct();
      if (!_.isEmpty(proveItProduct)) {
        result.push(...proveItProduct);
      }
    }
    let itemsList = [];
    result.forEach((item,index) => {
      itemsList.push({
        ...item,
        index,
        affiliation: baseSiteUidMap[country],
        location_id: 'Pre-Natal Funnel'
      });
    });
    return itemsList;
  }, [
    country,
    currentPills,
    currentPillsTotalPrice,
    editedAddon,
    isPi,
    isSelectedProveIt,
    priceTier,
    proveItSkus,
    proveitProductsInfo,
    sachetSku,
    select1Code,
    select2Code,
	  select3Code,
    select1DefaultName,
    select2DefaultName,
    select3DefaultName,
    isMeologyRegular,
    isMeologyPrenatal,
    isMeologyKids,
    prenatalSachetSku,
    kidsSachetSku,
    newKidsInfo,
    prenatalEssentialPillsTotalPrice,
    kidsSachetPrice,
    newKidsNumber,
  ]);

  const handleCheckout = useCallback(
    (isClickAutoButton = false, isReplace = false) => {
      const isAddToAutoCart = isAutoShipCart.current && Boolean(autoshipCode.current);
      if (!isReplace) {
        setIsAddToCart(true);

        if (isMeologyPrenatal && isAddToAutoCart) {
          if (hasMeologyPrenatalOrderEntry) {
            setLocalStorage('prenatalAutoCartType', 'replace');
          } else {
            setLocalStorage('prenatalAutoCartType', 'exsiting');
          }
        }
      }
      if (isAddToAutoCart) {
        checkoutRequestData.autoship_cart_code = autoshipCode.current;
      }
      checkoutRequestData.add_to_sachet_autoship = isClickAutoButton || isAutoShipCart.current;
      const meologyType = getLocalStorage('meologyType');
      if (isImpersonation) {
        const ids = {
          REGULAR: regularProfileId,
          PRENATAL: prenatalProfileId,
          KIDS: kidsProfileId
        }
        checkoutRequestData.customer_id = accountEmail;
        checkoutRequestData.profile_id = ids[meologyType]
      }
      let _postData = checkoutRequestData
      if(isReplace) { // replace  the prenatal products does not need Addon
        const addonCodes = (addonItems || []).map(t => t.product_code)
        const mainItems = checkoutRequestData.items?.filter(t => !addonCodes.includes(t.product_code))
        _postData = {...checkoutRequestData, items: mainItems || []}
      }
      dispatch(fetchAddToCart(_postData));

      // Google Analysis
      dispatch(
        pushCartToDatalayer({
          event: 'add-to-cart',
          ...(isMeologyPrenatal && { location: 'Pre-Natal Funnel' }),
          ecommerce: {
            currencyCode: 'USD',
            add: {
              products: analyticsProducts,
            },
          },
        })
      );
      //for GA4
      dispatch(pushGA4DataToDatalayer({ ecommerce: null }));
      dispatch(
        pushGA4DataToDatalayer({
          event: "ecommEvent",
          event_name: "add_to_cart",
          ecommerce: {
            items: analyticsProductsForGA4,
          }
        })
      );
    },
    [
      accountEmail,
      addonItems,
      analyticsProducts,
      analyticsProductsForGA4,
      checkoutRequestData,
      dispatch,
      isImpersonation,
      kidsProfileId,
      prenatalProfileId,
      regularProfileId,
      setIsAddToCart,
      isMeologyPrenatal,
      hasMeologyPrenatalOrderEntry,
    ]
  );

  // Click the 'Continue to the cart' button in the popup of backorder
  const handleBackOrderContinue = useCallback(() => {
    if (isAutoShipCart.current) {
      if (isMeologyRegular) {
        if (hasRegularMeologyOrderEntry) {
          setIsShowReplace(true);
        } else {
          handleCheckout();
        }
      }
      if (isMeologyPrenatal) {
        if (hasMeologyPrenatalOrderEntry) {
          handleCheckout();
        } else {
          setShowPrenatalExisting(true);
        }
      }
    } else {
      handleCheckout();
    }
  }, [
    handleCheckout,
    hasMeologyPrenatalOrderEntry,
    hasRegularMeologyOrderEntry,
    isMeologyPrenatal,
    isMeologyRegular,
  ]);

  const getIncompletedRSWStatus = useCallback(() => {
    if(isPrenatalJionFlow) {
      return !(select1Code && select2Code && select3Code)
    } else {
      return false
    }
  }, [isPrenatalJionFlow, select1Code, select2Code, select3Code])

  useEffect(() => {
    if(!getIncompletedRSWStatus()) {
      setShowRSWIncompletedError(false)
    }
  }, [getIncompletedRSWStatus])

  // Click 'ADD TO CART'
  const handleCart = useCallback(() => {
    if(getIncompletedRSWStatus()) {
      setShowRSWIncompletedError(true)
      return
    }
    setShowRSWIncompletedError(false)
    isAutoShipCart.current = false;
    removeLocalStorage("isEditSave");

    if (isBackOrder) {
      setIsShowBackorder(true);
      return;
    }
    handleCheckout(false);
  }, [handleCheckout, getIncompletedRSWStatus, isBackOrder]);

  // Click 'ADD TO EXISTING LOYALTY ORDER'
  const handleAutoCart = useCallback(
    code => {
      if(getIncompletedRSWStatus()) {
        setShowRSWIncompletedError(true)
        return
      }
      setShowRSWIncompletedError(false)
      isAutoShipCart.current = true;
      removeLocalStorage('isEditSave');
      autoshipCode.current = code;
      setCurrentAutoshipCode(code);

      if (isBackOrder) {
        setIsShowBackorder(true);
        return;
      }

      if (isMeologyRegular && hasRegularMeologyOrderEntry) {
        setIsShowReplace(true);
        return;
      }

      if (isMeologyPrenatal && !hasMeologyPrenatalOrderEntry) {
        setShowPrenatalExisting(true);
        return;
      }

      handleCheckout(true);
    },
    [getIncompletedRSWStatus, isBackOrder, isMeologyRegular, hasRegularMeologyOrderEntry, isMeologyPrenatal, hasMeologyPrenatalOrderEntry, handleCheckout]
  );

  useEffect(() => {
    if (callAutoship) {
      checkoutRequestData.add_to_sachet_autoship = true;
      isAutoShipCart.current = true;
      if (meology_autoship_info?.code) {
        autoshipCode.current = meology_autoship_info?.code;
      }
      handleCheckout(true, true)
      setCallAutoship(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAutoship, checkoutRequestData.add_to_sachet_autoship, checkoutRequestData.replace_prenatal_autoship, handleCheckout, meology_autoship_info?.code])
  // PDP
  const setPDPInfo = (pill, defaultProduct, productInfo, type, index) => {
    let reasons = defaultProduct
    ? productInfo[defaultProduct.code]
    : productInfo[pill.code]
    if(type === 'rswStep2') {
      reasons = productInfo[RSW_LIFE_SHAKE]
    } else if (type === 'rswStep3') {
      reasons = productInfo
    }
    setLearnMoreInfo({
      pill: pill,
      product_reasons: reasons,
      type: type,
      defaultProduct: defaultProduct ? defaultProduct : false,
      index,
    });
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const [showProveItModal, setShowProveItModal] = useState(false);
  const handleCloseProveItModal = useCallback(() => setShowProveItModal(false), []);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const handleCloseOrderModal = () => {
    removeUrlParam('status', location);
    setShowOrderModal(false);
  };
  const [showOrderManageModal, setShowOrderManageModal] = useState(false);
  const [showProveltModal, setShowProveltModal] = useState(false);
  const [isBoost, setIsBoost] = useState(false);
  const handleCloseOrderManageModal = () => setShowOrderManageModal(false);
  const handleCloseProveltModal = () => setShowProveltModal(false);

  const selectProveIt = useCallback(() => {
    setShowProveItModal(false);
    setShowMessage(true);
    setIsSelectedProveIt(true);
    resetTier2Pills();
  }, [resetTier2Pills])

  const { y: windowScrollY } = useWindowScroll();
  const { height: windowHeight } = useWindowSize();

  // Fixed checkout
  const topRef = useRef(null);
  const { height: topHeight } = useSize(topRef);
  const checkoutRef = useRef(null);
  const checkoutOffsetTop = useOffsetTop(checkoutRef);
  const [isCheckoutFixed, setIsChekoutFixed] = useState(false);

  const proveItStep2Ref = useRef(null);
  const proveItStep3Ref = useRef(null);

  const scrollToStep2 = () => {
    if (proveItStep2Ref.current) {
      const { offsetTop } = proveItStep2Ref.current;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }

  const scrollToStep3 = () => {
    if (proveItStep3Ref.current) {
      const { offsetTop } = proveItStep3Ref.current;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  }
  useEffect(() => {
    if (!headerHeight && !topHeight) return;
    if (
      windowScrollY >= headerHeight + topHeight &&
      windowScrollY <= checkoutOffsetTop - windowHeight
    ) {
      setIsChekoutFixed(true);
    } else {
      setIsChekoutFixed(false);
    }
  }, [checkoutOffsetTop, headerHeight, topHeight, windowHeight, windowScrollY]);

  const changeProveIt = useCallback(value => {
    const isTier2AllSelected = editedTier2Pills.every(({ selected }) => selected);
    if (basic) {
      if (isMeologyRegular) {
        setShowMessage(value);
      }
      setIsSelectedProveIt(value);
      resetTier2Pills();
    } else {
      if (isTier2AllSelected) {
      if (isMeologyRegular) {
        setShowMessage(value);
      }
        setIsSelectedProveIt(value);
      } else {
        setShowProveItModal(true);
      }
    }
  }, [basic, editedTier2Pills, isMeologyRegular, resetTier2Pills]);

  const changeMeologyType = useCallback(async (type, profileId) => {
    // must set basic to true, because kids/prenatal use tier1 pill
    if (['KIDS', 'PRENATAL'].includes(type)) {
      setBasic(true);
      setIsSelectedProveIt(false);
    }
    let request = {};
    if (isSponsor) {
      request = {
        health_profile_id: profileId,
      }
    } else if (isImpersonation) {
      request = {
        email: accountEmail,
        meology_type: type,
      }
    } else if (isUserLoggedin) {
      request = {
        email: emailRef.current,
        meology_type: type,
      }
    } else {
      request = {
        health_profile_id: profileId,
      }
    }

    const answers = await dispatch(
      getAnswersByHealthProfileId({ postData: request })
    );

    const selectedAnswers = answers && answers.questions;
    const {prenatal_reproductive_status } = selectedAnswers || {}
    const isPrenatalRegularStep  =  prenatal_reproductive_status === 'REGULAR'
    setSelectedAnswers((isPrenatalRegularStep && !(isImpersonation || isView)) ? {...(selectedAnswers || {}), prenatal_reproductive_status: 'POSTPARTUM'} : selectedAnswers);
    setProfileId(profileId);
    setLocalStorage('meologyType', type);
    if (!profileId || isEmpty(selectedAnswers) ) return;
    isCalledRecomendation.current = false;
  },
  [
    accountEmail,
    dispatch,
    isCalledRecomendation,
    isImpersonation,
    isUserLoggedin,
    isView,
    isSponsor,
  ]
  );


  // add to existing loyalty order successfully modal
  useEffect(() => {
    const { status } = getUrlVars();
    if (status === 'success') {
      const { isPrenatalType } = getMeologyType();
      if (isPrenatalType) {
        const prenatalAutoCartType = getLocalStorage('prenatalAutoCartType');
        if (prenatalAutoCartType === 'exsiting') {
          setShowPrenatalExistingSuccess(true);
        } else if (prenatalAutoCartType === 'replace') {
          setShowPrenatalSavedSuccess(true);
        } else {
          removeUrlParam('status', location);
        }
        if (prenatalAutoCartType) {
          removeLocalStorage('prenatalAutoCartType');
        }
      } else {
        setShowOrderModal(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const proveItRef = useRef(null);

  // 1. Show error when no assessment
  // 2. Show error when have assessment but opt out except in CSR impersonation mode
  useEffect(() => {
    const { meologyType } = getMeologyType();
    if (isImpersonation || isView) {
      if (!meology_type) {
        setShowErrorTemplate(meologyType);
      } else if (!opt_in && !isCSR) {
        setShowErrorTemplate(meology_type);
      } else {
        setShowErrorTemplate(null);
      }
    }
  }, [isCSR, isImpersonation, isView, meology_type, opt_in]);

  useEffect(() => {
    if (reproductiveStatus === 'REGULAR' && meology_type === 'PRENATAL') {
      if (isView || isImpersonation) {
        setShowErrorTemplate(meology_type);
      } else {
        setPage('prenatalMarketing');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reproductiveStatus, isImpersonation, isView, meology_type])

  if (loading) {
    return (
      <div
        style={{
          margin: '10% 50%'
        }}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (!isEmpty(showErrorTemplate)) {
    return (
      <ResultProvider
        value={{
          changeMeologyType,
          setShowErrorTemplate,
          showErrorTemplate,
          setPage,
        }}
      >
        <NoMeology />
      </ResultProvider>
    );
  }

  const contextValue = {
    t,
    essentialPills,
    showContent,
    prenatalProductInfo,
    setPDPInfo,
    currentPills,
    pillsCount,
    selectedAnswers,
    changeMeologyType,
    checkoutRequestData,
    currentPillsDailyTablets,
    isEditedKidsRef,
    setShowOrderManageModal,
    setIsAddToCart,
    setShowErrorTemplate,
    setPage,
    isView,
    isPrenatal: isMeologyPrenatal,
    changeProduct: changeAddon,
    handleCart,
    handleAutoCart,
    isAddToCart,
    basic,
    setShowProveltModal,
    setIsBoost,
    isSelectedProveIt,
    changeProveIt,
    isProveit: isSelectedProveIt,
    productsInfo: editedAddon,
    currentPillsTotalPrice: isMeologyPrenatal
      ? prenatalCurrentPillsTotalPrice
      : currentPillsTotalPrice,
    tier2optionalPillsTotalPrice: isMeologyPrenatal
      ? prenatalPlusTotalPrice
      : optionalTotalPrice,
    prenatalPlusTotalPrice,
    addonProductsTotalPrice,
    hideButtons: isView || isPrenatalPreview,
    isCheckoutFixed,
    addToCartResult,
    isAutoShipCart,
    prenatalEssentialPillsTotalPrice,
    showRSWIncompletedError,
    proveitProductsInfo,
    lifeShakeOptions: [
      {
        data:options1,
        onChange: changeSelect1Code,
        currentSelect: select1Code,
        defaultValue: select1DefaultName,
      },
      {
        data:options1,
        onChange: changeSelect2Code,
        currentSelect: select2Code,
        defaultValue: select2DefaultName,
      }
    ],
    boostOptions: [{
      data:options2,
      onChange: changeSelect3Code,
      currentSelect: select3Code,
      defaultValue: select3DefaultName,
    }],
  };

  return (
    <ResultProvider value={contextValue}>
     {isPrenatalJionFlow && <PrenatalJoinFlow />}
     {!isPrenatalJionFlow && !showErrorTemplate && (
        <div
          className={cx('recommendations', {
            kids: isMeologyKids,
            'is-preview-postnatal': isPreviewPostnatal,
          })}
        >
            {isSelectedProveIt && (
              <RecommendationMessage
                message={t('ui.recommendation.message1')}
                showMessage={showMessage}
                setShowMessage={setShowMessage}
              />
            )}

	          {isRegularJionFlow && <RecommendationsStepBanner scrollToStep2={scrollToStep2} scrollToStep3={scrollToStep3} />}
	          {isMeologyKids && <KidsBanner ref={topRef} />}
            {isMeologyPrenatal && (
              <PrenatalBanner
                ref={topRef}
                currentPillsDailyTablets={currentPillsDailyTablets}
                prenatalPlusTotalPrice={prenatalPlusTotalPrice}
                selectedAnswers={selectedAnswers}
                currentPills={currentPills}
                pillsCount={pillsCount}
                isView={isView}
                trimester={trimester}
                onClick={showPrenatalConfirm}
              />
            )}
            {isMeologyRegular && (
              <RecommendationsBanner
                basic={basic}
                setBasic={setBasic}
                selectedAnswers={selectedAnswers}
                currentPills={currentPills}
                pillsCount={pillsCount}
                recommendationData={recommendationData}
                currentPillsDailyTablets={currentPillsDailyTablets}
                currentPillsTotalPrice={currentPillsTotalPrice}
                ref={topRef}
                proveItRef={proveItRef}
                isView={isView}
              />
            )}

          {isMeologyPrenatal && (
            <>
              {!isPreviewPostnatal && (
                <PrenatalNext onConfirm={showPrenatalConfirm} trimester={trimester} />
              )}
              <PrenatalConfirmModal
                show={isShowPrenatalConfirm}
                onHide={hidePrenatalConfirm}
                user={user}
                isMobile={isMobile}
                phase={phase}
                submitPrenatalConfirm={submitPrenatalConfirm}
                hasAutoship={hasMeologyPrenatalOrderEntry}
                dataOptions={{
                  opt_in,
                  email: accountEmail,
                  prenatal_name: prenatalHistory?.questions?.prenatal_name || '',
                  fromReco: true
                }}

              ></PrenatalConfirmModal>
            </>
          )}

          {/* promotion banner */}
          {isRegularIndependentFlow && <ProveItBanner proveItRef={proveItRef} />}

          {!isPreviewPostnatal && (
            <div className="recommendations-content">
              <div className={cx('section-title', {
                'font-gilmer-medium': isMeologyPrenatal
              })} >
		          {isMeologyKids && (
		            t('ui.recommendation.kids-pill.title')
		          )}
		          {!isMeologyKids && (
                  <>
                    {isMeologyPrenatal ?
                    <>
                    {prenatalStep === 'TRYING' && <> {t('ui.recommendation.preparing.title1')}</>}
                    {prenatalStep === 'PREGNANT' && <>{t('ui.recommendation.pregnant.title1')}</>}
                    {prenatalStep === 'POSTPARTUM' && <>{t('ui.recommendation.postnatal.title1')}</>}
                    </>
                    : t('ui.recommendation.tier_1.title')
                    }
                    <div className="section-title-description">
                      {!basic && t('ui.recommendation.reco_1_subtext')}
                    </div>
                  </>
                )}
              </div>

              <div className="pill-section">
                {basic && !_.isEmpty(essentialPills) && (
                  <RecommendationsPill
                    productsInfo={essentialPills}
                    setPDPInfo={(pill, index) =>
                      setPDPInfo(pill, false, isMeologyPrenatal ? prenatalProductInfo : productInfo, 'pill', index)
                    }
                    showContent={showContent}
                    showToggle={false}
                    isEssentialPills
                  />
                )}

                {!basic && !_.isEmpty(editedTier2Pills) && (
                  <RecommendationsPill
                    isSelectedProveIt={isSelectedProveIt}
                    productsInfo={editedTier2Pills}
                    onChange={(index, event, price, pill) => {
                      const isMinPrice = handleMinPriceModal(event, price);
                      if (isMinPrice) return;
                      changeTier2Pills(index, event);

                      // analytics
                      sachetToggleAnalytics(event, pill);
                    }}
                    setPDPInfo={(pill, index) =>
                      setPDPInfo(pill, false, productInfo, 'pill', index)
                    }
                    showContent={showContent}
                  />
                )}
              </div>
        {isMeologyRegular && basic && (
          <div className="more-section">
            <div
              className="btn-more"
              onClick={() => {
                setBasic(false);
                window.scrollTo(0, 0);

                // analytics
                dispatch(
                  pushInteractionToDataLayer({
                    eventCategory: 'Meology',
                    eventAction: 'Essential Plus',
                    eventLabel: 'result page more section',
                  })
                );
              }}>
              <div className="btn-more-text">
                <h2>{t('ui.recommendation.want-more')}</h2>
                <p>{t('ui.recommendation.see-essentials-plus')}</p>
              </div>
              <button className='meology-text-button'>
                <img
                  className="btn-more-img"
                  src="https://images.shaklee.com/meology/images/arrow.svg?v=1"
                  alt="go to Essentials Plus Plan"
                />
              </button>
            </div>
          </div>
        )}
        {isRegularJionFlow && <RecommendationsStepProvelt
          options1={options1}
          options2={options2}
          isSelectedProveIt={isSelectedProveIt}
          select1DefaultName={select1DefaultName}
          select2DefaultName={select2DefaultName}
          select3DefaultName={select3DefaultName}
          changeSelect1Code={changeSelect1Code}
          changeSelect2Code={changeSelect2Code}
          changeSelect3Code={changeSelect3Code}
          proveItStep2Ref={proveItStep2Ref}
          proveItStep3Ref={proveItStep3Ref}
          changeProveIt={changeProveIt}
          basic={basic}
          setShowProveltModal={setShowProveltModal}
          setIsBoost={setIsBoost}
          showContent={showContent}
        />}

              {isRegularIndependentFlow && (
                <ProveIt
            options1={options1}
            options2={options2}
            isSelectedProveIt={isSelectedProveIt}
            isPdpFlow={isPdpFlow}
            select1DefaultName={select1DefaultName}
            select2DefaultName={select2DefaultName}
            select3DefaultName={select3DefaultName}
                  changeSelect1Code={changeSelect1Code}
                  changeSelect2Code={changeSelect2Code}
            changeSelect3Code={changeSelect3Code}
                  changeProveIt={changeProveIt}
            basic={basic}
                  ref={proveItRef}
            setShowProveltModal={setShowProveltModal}
            setIsBoost={setIsBoost}
            showContent={showContent}
                />
              )}

              {!basic && !_.isEmpty(optionalInfo) && (
                <div className="pill-section plus">
                  <div className="pill-section-title2">
                    {t('ui.prove_it_page_1.plan_2.title_plus')}
                  </div>
                  <RecommendationsPill
                    productsInfo={editedOptional}
                    onChange={(index, event, price, pill) => {
                      const isMinPrice = handleMinPriceModal(event, price);
                      if (isMinPrice) return;
                      changeOptional(index, event);

                      // analytics
                      sachetToggleAnalytics(event, pill);
                    }}
                    setPDPInfo={(pill, index) =>
                      setPDPInfo(pill, false, productInfo, 'pill', index)
                    }
                    showContent={showContent}
                    isPlus
                  />
                </div>
              )}
              {isMeologyPrenatal && !_.isEmpty(optionalInfo) && (
                <div className="pill-section prenatal-addon">
                  <div className="prenatal-addon-title font-gilmer-medium">
                    {t('ui.recommendation.prenatal.plus.title')}
                  </div>
                  <div className="prenatal-addon-section">
                    <RecommendationsPill
                      productsInfo={editedOptional}
                      onChange={(index, event, price, pill) => {
                        const isMinPrice = handleMinPriceModal(event, price);
                        if (isMinPrice) return;
                        changeOptional(index, event);

                        // analytics
                        sachetToggleAnalytics(event, pill);
                      }}
                      setPDPInfo={(pill, index) =>
                        setPDPInfo(pill, false, prenatalProductInfo, 'pill', index)
                      }
                      showContent={showContent}
                      isOptionalPills
                    />
                      <OtherRecommendations
                        isPrenatal={isMeologyPrenatal}
                        productsInfo={editedAddon}
                        selectedAnswers={selectedAnswers}
                        setPDPInfo={(pill, defaultCode, index) =>
                          setPDPInfo(
                            pill,
                            defaultCode,
                            prenatalProductInfo,
                            'AddOn',
                            index
                          )
                        }
                        showContent={showContent}
                        isMeologyKids={isMeologyKids}
                      />
                  </div>
                </div>
              )}
              {!_.isEmpty(editedAddon) && showAddOn &&(
                  <OtherRecommendations
                    productsInfo={editedAddon}
                    selectedAnswers={selectedAnswers}
                    setPDPInfo={(pill, defaultCode, index) =>
                      setPDPInfo(pill, defaultCode, productInfo, 'AddOn', index)
                    }
                    showContent={showContent}
                    isMeologyKids={isMeologyKids}
                  />
              )}
              {isPreviewPregnancy && (
                <PrenatalNext onConfirm={showPrenatalConfirm} trimester={trimester} />
              )}
              </div>
            )}
          </div>
     )}
     {!showErrorTemplate && !isPreviewPostnatal && (<>
              <Summary ref={checkoutRef} />
              <RetakeOptin opt_in={opt_in} meologyType={meology_type} isMeologyKids={isMeologyKids} isView={isView} isImpersonation={isImpersonation} />
              {!_.isEmpty(learnMoreInfo) && (
                <LearnMore
                  info={learnMoreInfo}
                  onHide={handleClose}
                  show={modalShow}
                />
              )}
              {
                <RecommendationsDagger
                  showProveItStep={isRSWJionFlow}
                  showProveIt={isIndependentFlow}
                  productsInfos={daggerProductData}
                  page={'reco'}
                  isMeologyKids={isMeologyKids}
                />
              }
              <RecommendationModal
                show={isShowReplace}
                onHide={hideReplace}
                title={t('ui.recommendation.replace-sachet')}
                body={t('ui.recommendation.replace-new-sachet')}
                btnInfo={[
                  {
                    name: `${t('answers.computer_usage.1.title')}`,
                    event: handleCheckout,
                    type: 'checkout',
                  },
                  {
                    name: `${t('answers.computer_usage.0.title')}`,
                    event: hideReplace,
                  },
                ]}
              />

              <RecommendationModal
                show={isShowBackorder}
                onHide={hideBackorder}
                title={t('ui.recommendation.backorder_pop_up.title')}
                body={`
                  <p>${t('ui.recommendation.backorder_pop_up.description_1')}</p>
                  <p>${t('ui.recommendation.backorder_pop_up.description_2')}</p>
                  <p>${t('ui.recommendation.backorder_pop_up.description_3')}</p>
                `}
                btnInfo={[
                  {
                    name: `${t('ui.recommendation.backorder.continue')}`,
                    event: handleBackOrderContinue,
                    type: isRegularAutoShipCart ? undefined : 'checkout',
                  },
                  { name: `${t('questions.cancel')}`, event: hideBackorder },
                ]}
                className={cx('backorder', {
                  'has-two-modal': isRegularAutoShipCart,
                })}
              />

              <RecommendationModal
                show={isShowMin}
                onHide={hideMin}
                title={t('ui.recommendation.min-tablets')}
                body={t('ui.recommendation.cannot-remove')}
                btnInfo={[{ name: 'OK', event: hideMin }]}
              />

              <RecommendationModal
                className="backorder"
                show={showProveItModal}
                onHide={handleCloseProveItModal}
                title={t('ui.recommendation.proveit_popup_title')}
                body={t('ui.recommendation.proveit_popup_description')}
                btnInfo={[
                  {
                    name: t('ui.recommendation.proveit_popup_yes'),
                    event: selectProveIt,
                  },
                  {
                    name: t('questions.cancel'),
                    event: handleCloseProveItModal,
                  },
                ]}
              />

              {/* add to existing loyalty order successfully modal */}
              <RecommendationModal
                className="loyalty-order-modal"
                show={showOrderModal}
                onHide={handleCloseOrderModal}
                title={
                  getLocalStorage('isEditSave')
                    ? t('ui.recommendation.meology_kids_add_to_cart_title19')
                    : t('ui.recommendation.meology_kids_add_to_cart_title20')
                }
                body={
                  <div className="loyalty-order-modal-content">
                    {!getLocalStorage('isEditSave') && (
                      <AutoShipInfo showNextDate />
                    )}
                    <p>
                      {getLocalStorage('isEditSave')
                        ? t(
                            'ui.recommendation.meology_kids_add_to_cart_title21'
                          )
                        : t(
                            'ui.recommendation.meology_kids_add_to_cart_title18'
                          )}
                    </p>
                  </div>
                }
                btnInfo={[{ name: 'OK', event: handleCloseOrderModal }]}
              />

              {/* Manage Existing Loyalty Order */}
              <RecommendationModal
                className="order-manage-modal"
                show={showOrderManageModal}
                onHide={handleCloseOrderManageModal}
                title="Manage Existing Loyalty Order"
                body={
                  <div className="order-manage-modal-content">
                    <p>
                      {t('ui.recommendation.meology_kids_add_to_cart_title17')}
                    </p>
                  </div>
                }
                btnInfo={[
                  {
                    name: t(
                      'ui.recommendation.meology_kids_add_to_cart_title7'
                    ),
                    link: orderUrl,
                    target: '_self',
                  },

                  {
                    name: t('questions.cancel'),
                    event: handleCloseOrderManageModal,
                  },
                ]}
              />

              {/* Prenatal existing loyalty order */}
              <RecommendationModal
                className="modal-prenatal"
                show={showPrenatalExisting}
                onHide={hidePrenatalExisting}
                title={t('ui.recommendation.popup.title1')}
                body={t('ui.recommendation.popup.title2', { nextProcessingDate })}
                btnInfo={[
                  {
                    name: t('questions.cancel'),
                    event: hidePrenatalExisting,
                  },

                  {
                    name: t('ui.continue'),
                    event: () => {
                      handleCheckout();
                    },
                    type: 'checkout',
                  },
                ]}
              />

              {/* Add to existing loyalty order success */}
              <RecommendationModal
                className="modal-prenatal"
                show={showPrenatalExistingSuccess}
                onHide={hidePrenatalExistingSuccess}
                title={t('ui.recommendation.prental.modal.added.loyalty')}
                btnInfo={[
                  {
                    name: t('ui.recommendation.prental.modal.back_to_reco'),
                    event: hidePrenatalExistingSuccess,
                  },

                  {
                    name: t('ui.recommendation.prental.modal.go_to_loyalty'),
                    link: orderUrl,
                    target: '_self',
                    event: () => {
                      removeUrlParam('status', location);
                    }
                  },
                ]}
              />

               {/* Save changes to loyalty order success */}
               <RecommendationModal
                className="modal-prenatal"
                show={showPrenatalSavedSuccess}
                onHide={hidePrenatalSavedSuccess}
                title={t('ui.recommendation.popup.title')}
                btnInfo={[
                  {
                    name: t('ui.text.ok'),
                    event: hidePrenatalSavedSuccess,
                  }
                ]}
              />

        <RecommendationProveltModal
          options1={options1}
          options2={options2}
          getProveltCode={getProveltCode}
          show={showProveltModal}
          isBoost={isBoost}
          closeModal={handleCloseProveltModal}
        />
     </>)}
    </ResultProvider>
  );
};

export default memo(Recommendations);
