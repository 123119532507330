import { isEmpty } from 'assets/js/helper';

const boundMap = {
  core_measurement: {
    inches: {
      lower: 15,
      upper: 80,
    },
    cm: {
      lower: 38,
      upper: 203,
    },
  },
  total_cholesterol: {
    'mg/dL': {
      lower: 20,
      upper: 1000,
    },
    'mmol/L': {
      lower: 0.52,
      upper: 26.0,
    },
  },
  ldl_cholesterol: {
    'mg/dL': {
      lower: 20,
      upper: 1000,
    },
    'mmol/L': {
      lower: 0.52,
      upper: 26.0,
    },
  },
  hdl_cholesterol: {
    'mg/dL': {
      lower: 1,
      upper: 100,
    },
    'mmol/L': {
      lower: 0.025,
      upper: 2.5,
    },
  },
  triglycerides: {
    'mg/dL': {
      lower: 10,
      upper: 2000,
    },
    'mmol/L': {
      lower: 0.11,
      upper: 22.67,
    },
  },
  glucose: {
    'mg/dL': {
      lower: 20,
      upper: 2000,
    },
    'mmol/L': {
      lower: 0.11,
      upper: 22.67,
    },
  },
  hba1c: {
    '%': {
      lower: 2,
      upper: 10,
    },
    'mmol/mol': {
      lower: 0,
      upper: 86,
    },
  },
  hscrp: {
    'mg/L': {
      lower: 0,
      upper: 100,
    },
    'nmol/L': {
      lower: 0,
      upper: 950,
    },
  },
  vitamin_d: {
    'ng/mL': {
      lower: 10,
      upper: 100,
    },
    'nmol/L': {
      lower: 25,
      upper: 250,
    },
  },
  omega_3_index: {
    '%': {
      lower: 1,
      upper: 20,
    },
  },
  systolic_pressure: {
    mmHg: {
      lower: 80,
      upper: 200,
    },
  },
  diastolic_pressure: {
    mmHg: {
      lower: 50,
      upper: 120,
    },
  },
};

const isInteger = value => /^\d+$/.test(value);

export const validateInput = (option, val, specificUnit = 'mmHg') => {
  const { unit = specificUnit, value = val } = val;
  const { lower, upper } = boundMap[option][unit] || {};
  if (isEmpty(lower) || isEmpty(upper) || isNaN(value) || (parseInt(value) === 0 && Object.is(parseInt(value), -0))) return false;
  if (
    ['systolic_pressure', 'diastolic_pressure'].includes(option) &&
    !isInteger(value)
  )
    return false;
  return value >= lower && value <= upper;
};

export const validateHeight = height => {
  if (isEmpty(height) || isNaN(height)) return false;
  return height >= 36 && height <= 144;
};

export const validateWeight = weight => {
  if (isEmpty(weight) || isNaN(weight)) return false;
  return isInteger(weight) && weight >= 50 && weight <= 999;
};
