export const RECOMMENDATION_POST = 'RECOMMENDATION_POST';
export const RECOMMENDATION_API_POST_URL = '/services/recommendations';
export const PRODUCT_INFO = 'PRODUCT_INFO';
export const CONCEPTION_PRODUCT_INFO = 'CONCEPTION_PRODUCT_INFO';
export const PREGNANT_PRODUCT_INFO = 'PREGNANT_PRODUCT_INFO';
export const POSTNATAL_PRODUCT_INFO = 'POSTNATAL_PRODUCT_INFO';
export const HYBRIS_PRODUCT_INFO = 'HYBRIS_PRODUCT_INFO';
export const HYBRIS_CORE_PRODUCT_INFO = 'HYBRIS_CORE_PRODUCT_INFO';
export const PRODUCT_LIFESHAKE_INFO = 'PRODUCT_LIFESHAKE_INFO';
export const PRODUCT_BOOST_INFO = 'PRODUCT_BOOST_INFO';
export const PRODUCT_CODES = 'PRODUCT_CODES';
export const SET_ADD_TO_CART_RESULT = 'SET_ADD_TO_CART_RESULT';
export const ADD_ONS = 'ADD_ONS';
export const TIER_1 = 'TIER_1';
export const TIER_1_PRODUCTS = 'TIER_1_PRODUCTS';
export const TIER_2 = 'TIER_2';
export const TIER_2_PRODUCTS = 'TIER_2_PRODUCTS';
export const TIER_2_OPTIONAL = 'TIER_2_OPTIONAL';
export const PROVE_IT = 'PROVE_IT';
export const PROVE_IT_ADD_ONS = 'PROVE_IT_ADD_ONS';
export const GENTLE_SLEEP_SKU_US = '21400';
export const GENTLE_SLEEP_SKU_CA = '21400_CA';
export const CONCEPTION_PRODUCTS_PROVE_IT = 'CONCEPTION_PRODUCTS_PROVE_IT';
export const PREGNANT_PRODUCTS_PROVE_IT = 'PREGNANT_PRODUCTS_PROVE_IT';
export const POSTNATAL_PRODUCTS_PROVE_IT = 'POSTNATAL_PRODUCTS_PROVE_IT';

// Sachet
export const SACHET_SKU_US = '89543';
export const SACHET_SKU_CA = '89556';
export const KIDS_SACHET_SKU_US = '89580';
export const KIDS_SACHET_SKU_CA = '89580_CA';
export const PRENATAL_TRYING_SACHET_SKU_US = '89596';
export const PRENATAL_PREGNANT_SACHET_SKU_US = '89597';
export const PRENATAL_POSTPARTUM_SACHET_SKU_US = '89598';

export const TURN_AROUND_SKUS_US = ['89280', '89540'];
export const TURN_AROUND_SKUS_CA = ['79280', '89558'];
export const SHAKE_IT_OFF_SKUS_US = ['89537', '89546'];
export const SHAKE_IT_OFF_SKUS_CA = ['89559', '89561'];
export const BUNDLE_SKUS_US = ['89280', '89540', '89541', '89537', '89546', '89545'];
export const BUNDLE_SKUS_CA = ['79280', '89558', '89557', '89559', '89561', '89560'];
export const ENDURANCE_SKUS_US = ['21308', '21309'];
export const ENDURANCE_SKUS_CA = ['54308', '54309'];
export const PROVE_IT_SKU_US = ['89542', '89547'];
export const PROVE_IT_SKU_CA = ['89562', '89563'];
export const OPTION_SKUS_US = ['21321', '21333', '21308', '21385'];
export const OPTION_SKUS_CA = ['56333', '54308'];
export const PIC_PRICE_US = 179;
export const PIC_PRICE_CA = 189;
export const ORGANIC_GREENS_BOOSTER = ['21306', '56306'];
export const LIFE_SHAKE = [
	// soy
  '21321',
  '21324',
  '21327',
  '21329',
  '21391',
  '21397', // maybe been deleted
  '21402',

	// plant
  '21333',
  '21335',

	// plant CA
  '56333',
];
export const LIFE_SHAKE_SOY = ['21329','21321', '21324', '21327'];
export const LIFE_SHAKE_PLANT = '21333';
export const RSW_LIFE_SHAKE = '21321';
export const OMEGA_GELLY = 'ZU611';
export const MULTIM_TABLET = 'ZU573';
export const CHEWABLE_CAL_MAG = 'ZU574';
export const DREAM_SERENE = '21400';
export const TURMERIC_BOOST = '21376';
export const PRENATAL_MULTIV_DRINK = '22097';
export const BOTTLED_PILLS = [DREAM_SERENE, TURMERIC_BOOST];
export const PROVELT_PRODUCT_ATTRIBUTE = {
  21398: ['gluten_free', 'non_gmo', 'dairy_free', 'grass_fed'],
  21306: ['gluten_free', 'vegan', 'non_gmo', 'kosher'],
  21406: ['gluten_free', 'vegan', 'non_gmo', 'kosher'],
  21405: ['gluten_free', 'vegan', 'non_gmo', 'kosher'],
  21408: ['gluten_free', 'kosher', 'non_gmo'],
  21318: ['gluten_free', 'kosher'],

  21505: ['gluten_free', 'dairy_free', 'vegan', 'kosher'],
  21508: ['gluten_free', 'dairy_free'],
  21509: ['gluten_free', 'dairy_free', 'vegan'],
  21506: ['gluten_free', 'dairy_free', 'vegan'],
};
export const PRENATAL_STEP = 'PRENATAL_STEP';

export const CONCEPTION_TIER = 'CONCEPTION_TIER';
export const PREGNANT_TIER = 'PREGNANT_TIER';
export const POSTNATAL_TIER = 'POSTNATAL_TIER';

export const CONCEPTION_TIER_OPTIONAL = 'CONCEPTION_TIER_OPTIONAL';
export const PREGNANT_TIER_OPTIONAL = 'PREGNANT_TIER_OPTIONAL';
export const POSTNATAL_TIER_OPTIONAL = 'POSTNATAL_TIER_OPTIONAL';

export const CONCEPTION_ADD_ONS = 'CONCEPTION_ADD_ONS';
export const PREGNANT_ADD_ONS = 'PREGNANT_ADD_ONS';
export const POSTNATAL_ADD_ONS = 'POSTNATAL_ADD_ONS';
export const SET_REPRODUCTIVE_STATUS = 'SET_REPRODUCTIVE_STATUS';
export const RSW_VALID_DATE = '2022-09-01';
