import React, { memo, useState, useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Recommendations from './Recommendations/Recommendations';
import Footer from 'components/footer/Footer';
import Header from 'components/header/Header';
import Managing from 'components/header/Managing';
import {
  useSize,
  REGULAR_TYPE,
  KIDS_TYPE,
  PRENATAL_TYPE,
  getMeologyType,
} from 'assets/js/helper';
import { ToastProvider } from 'components/Toast';
import Marketing from 'components/Marketing/Marketing';
import {
  useProfileId,
  getImpersonation,
  useMeologyHistory,
} from 'assets/js/hooks';
import { setMarketingType } from 'components/questions/QuestionActions';
import { useDispatch } from 'react-redux';
import SherpaBanner from 'components/sherpaBanner/SherpaBanner';

const Result = () => {
  const [headerRef, setHeaderRef] = useState({ current: null });
  const { height: headerHeight } = useSize(headerRef);
  const [name, setName] = useState(null);
  const [page, setPage] = useState('reco');
  const isCalledRecomendation = useRef(false);
  const { kidsProfileId, regularProfileId, prenatalProfileId } = useProfileId();
  const dispatch = useDispatch();
  const { isImpersonation } = getImpersonation();
  const { isView } = useMeologyHistory();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const hpID = urlParams.get('hpId') || urlParams.get('hpid');
  const isMarketingPage = useMemo(
    () => ['marketing', 'kidsMarketing', 'prenatalMarketing'].includes(page),
    [page]
  );

  useEffect(() => {
    const { meologyType } = getMeologyType();
    if (!meologyType) return;
    const profiles = {
      [REGULAR_TYPE]: {
        profile: regularProfileId,
        page: 'marketing',
      },
      [PRENATAL_TYPE]: {
        profile: prenatalProfileId,
        page: 'prenatalMarketing',
      },
      [KIDS_TYPE]: {
        profile: kidsProfileId,
        page: 'kidsMarketing',
      },
    };
    if (
      isView ||
      isImpersonation ||
      profiles[meologyType].profile ||
      id ||
      hpID
    ) {
      setPage('reco');
    } else {
      setPage(profiles[meologyType].page);
    }
    dispatch(setMarketingType(2));
  }, [
    dispatch,
    isImpersonation,
    kidsProfileId,
    prenatalProfileId,
    regularProfileId,
    isView,
    id,
    hpID,
  ]);

  return (
    <ToastProvider>
      {!isMarketingPage && <Managing name={name} />}
      {isMarketingPage && (
        <>
          <SherpaBanner headerRef={headerRef} />
          <Marketing
            isCalledRecomendation={isCalledRecomendation}
            setPage={setPage}
            page={page}
          />
        </>
      )}
      {page === 'reco' && (
        <>
          <Header
            setHeaderRef={setHeaderRef}
            isCalledRecomendation={isCalledRecomendation}
          />
          <Recommendations
            headerHeight={headerHeight}
            setName={setName}
            isCalledRecomendation={isCalledRecomendation}
            setPage={setPage}
          />
          <Footer isGetAccount={true} />
        </>
      )}
    </ToastProvider>
  );
};

export default memo(Result);
