import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '../results/LearnMore/Accordion';
import { Trans, useTranslation } from 'react-i18next';
import { useHybrisUrlParams, useHybrisHost } from 'assets/js/hooks';
import { displayFaqs } from './faqConfig';

const FAQPannel = ({ type,showIcon, className }) => {
  const { lang, country } = useSelector(state => state.user);
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);
  const { t } = useTranslation();

  const privacyHref = useMemo(
    () =>
      `${hybrisHost}/${
        country === 'CA' ? 'privacy-security-policy' : 'Privacy-Security-Policy'
      }${hybrisUrlParams}`,
    [country, hybrisHost, hybrisUrlParams]
  );

  return (
    <div className={`${className ? '' : 'faq-accordion-pannel'}`} role="tabpanel">
      <div className={`accordion ${className || 'faq-accordion'}`}>
        {(displayFaqs[type] || []).map((faq, index) => {
          return (
            <div key={index}>
              <Accordion
                className="title"
                index={index}
                title={t(faq.question)}
                answer={t(faq.answer)}
                showIcon={showIcon}
                iconSrc={faq.icon}
                active={false}
              >
                <div className="content">
                  {[
                    'ui.faq.meology_privacy',
                    'ui.faq.meology_personal_information',
                    'ui.faq.meology_prenatal_personalinformation',
                  ].includes(faq.question) ? (
                    <Trans>
                      <span
                        dangerouslySetInnerHTML={{ __html: t(faq.answer) }}
                      ></span>{' '}
                      {
                        <a
                          href={privacyHref}
                          title={t('ui.footer-privacy')}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('ui.faq.here')}
                        </a>
                      }
                      .
                    </Trans>
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{ __html: t(faq.answer) }}
                    ></span>
                  )}
                </div>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  )
};

export default memo(FAQPannel);
