import React, { useContext } from 'react';
import cx from 'classnames';
import { MarketingContext } from 'components/Marketing/Marketing';

const Disclaimer = () => {
  const {
    t,
    isRegularMarketing,
    isKidsMarketing,
	isPrenatalMarketing
  } = useContext(MarketingContext);
  return (
    <>
      <div className={cx('disclaimer-container', {
        kids: isKidsMarketing,
      })}>
	      <div className={`disclaimer ${isPrenatalMarketing ? 'prenatal-dcm' : ''}`}>
	      { isKidsMarketing &&
		      <>
			      <p>{t('ui.marketing.disclaimer-1')}</p>
			      <p>{t('ui.marketing.disclaimer-7')}</p>
			      <p dangerouslySetInnerHTML={{ __html: t('ui.marketing.disclaimer-4') }}></p>
			      <p>{t('ui.marketing.disclaimer-5')}</p>
			      <p>{t('ui.marketing.disclaimer-6')}</p>
			      <p>{t('ui.marketing.disclaimer-3')}</p>
	        </>
	      }
	      { isRegularMarketing &&
	        <>
		        <p>{t('ui.marketing.disclaimer-1')}</p>
		        <p>{t('ui.marketing.disclaimer-2')}</p>
		        <p>{t('ui.marketing.disclaimer-3')}</p>
	        </>
	      }
	      { isPrenatalMarketing &&
	        <>
		        <p>{t('ui.prenatal_marketing.section_12_1.content')}</p>
		        <p>{t('ui.prenatal_marketing.section_12_2.content')}</p>
		        <p>{t('ui.prenatal_marketing.section_12_1_1.content')}</p>
		        <p>{t('ui.prenatal_marketing.section_12_3.content')}</p>

	        </>
	      }
	      </div>
      </div>
    </>
  );
};

export default Disclaimer;
