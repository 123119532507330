import React, {
  useMemo,
  useRef,
  memo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import Swiper from 'react-id-swiper';
import { Image } from 'react-bootstrap';
import 'swiper/css/swiper.css';
import CtaBtn from 'components/common/CtaBtn';
import { getBannerProps } from './ItemConfig';

const BannerSlider = ({ t, prenatalLandingPage }) => {
  const imgs = useMemo(() => getBannerProps(t), [t]);
  const [activeSlideKey, setActiveSlideKey] = useState(0);
  const gallerySwiperRef = useRef(null);

  const params = useMemo(
    () => ({
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
    }),
    []
  );

  useEffect(() => {
    if (!gallerySwiperRef?.current?.swiper) {
      return;
    }
    setActiveSlideKey(0);
    const swiper = gallerySwiperRef.current.swiper;    
    swiper.on('imagesReady', ()=>{
      swiper.slideToLoop(0);
      setActiveSlideKey(0);
    })
    swiper.on('slideChange', function() {
      setActiveSlideKey(swiper.realIndex);
    });
  }, [gallerySwiperRef]);

  const click = useCallback(
    idx => {
      const swiper = gallerySwiperRef?.current?.swiper;
      if (swiper) {
        swiper.slideToLoop(idx);
        setActiveSlideKey(idx);
      }
    },
    [gallerySwiperRef]
  );

  return (
    <div className="thumbs-container">
      <div className="custom-slider-container">
        <div className="custom-slider-prevew">
          {imgs.map(({ title, ariaLabel, src }, index) => (
            <div
              key={index}
              className={`view-item ${
                activeSlideKey === index ? 'view-item-active' : ''
              }`}
              onClick={() => click(index)}
            >
              <Image
                title={title}
                aria-label={ariaLabel}
                src={src}
                alt={`Meology_MKT_carousel_${index + 1}`}
                tabIndex="0"
                onKeyDown={e => {
                    if(e.keyCode === 13){
                      click(index)
                    }
                  }
                }
              />
            </div>
          ))}
        </div>
        <section className="custom-slider-current" aria-label="meology prenatal marketing page carousel" tabIndex="0">
          <Swiper {...params} ref={gallerySwiperRef}>
            {imgs.map(({ title, ariaLabel, src }, index) => (
              <div key={index} className="curent-item">
                <Image
                  title={title}
                  aria-label={ariaLabel}
                  src={src}
                  alt={`Meology_MKT_carousel_${index + 1}`}
                />
              </div>
            ))}
          </Swiper>
        </section>
      </div>
      <div className="thumbs-text">
        <h2 className="mkt-section-title">
          {t('ui.prenatal_marketing.section_2.headline')}
        </h2>
        <p className="th-detail">
          {t('ui.prenatal_marketing.section_2.content')}
        </p>
        <CtaBtn
          type="prenatal"
          status={prenatalLandingPage}
          eventLabel="Marketing Page"
          eventAction="Start Here 2"
          isTextBtn
          page='prenatalMarketing'
        />
      </div>
    </div>
  );
};

export default memo(BannerSlider);
