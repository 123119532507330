import React, {
  memo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import RecommendationProveltModalItem from './RecommendationProveltModalItem';

const RecommendationProveltModal = ({ show, closeModal, isBoost, getProveltCode, options1, options2 }) => {
  const { t } = useTranslation();
  const lifeShakeData = useSelector(
    state => state.recommendationsReducer.product_lifeshake_info
  );
  const boostData = useSelector(
    state => state.recommendationsReducer.product_boost_info
  );
  const lifeShakeCode = getProveltCode(options1);
  const boostCode = getProveltCode(options2);

  const isMobile = useSelector(state => state.QuestionReducer.is_mobile);

  const onHide = useCallback(() => {
    closeModal();
  }, [closeModal]);
  


  const lifeShakeOrderData = lifeShakeData?.sort((a, b) => lifeShakeCode.indexOf(a.code) - lifeShakeCode.indexOf(b.code));
  const boostOrderData = boostData?.sort((a, b) => boostCode.indexOf(a.code) - boostCode.indexOf(b.code));
  const containsSoy = lifeShakeOrderData?.filter(item => item.containsSoy === true);
  const containsPea = lifeShakeOrderData?.filter(item => item.containsSoy === false);

  return (
    <Modal
      className="provelt-modal"
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <h3 className="provelt-modal-title">
        {isBoost
          ? t('ui.recommendation.meology_provelt_pop_up_title1')
          : t('ui.recommendation.meology_provelt_pop_up_title3')}
      </h3>
      <p
        className="provelt-modal-intro"
        dangerouslySetInnerHTML={{
          __html: isBoost
            ? t('ui.recommendation.meology_provelt_pop_up_title2')
            : t('ui.recommendation.meology_provelt_pop_up_title4'),
        }}
      ></p>
      {!isBoost && <img className="provelt-modal-img" src={`https://images.shaklee.com/wellness/group${isMobile ? "" : "-137"}.svg`} alt="" />}
      {
        isBoost && <div className="item-container">
          {boostOrderData?.map((item, index) => (
            <RecommendationProveltModalItem pill={item} key={index} isBoost={isBoost}/>
          ))}
        </div>
      }
      {
        !isBoost && <>
          {containsSoy?.length > 0 && <div className="item-container-title1"><img src={`https://images.shaklee.com/wellness/Soy.svg`} alt="" />{t("ui.recommendation.meology_provelt_pop_up_title5")}</div>}
          <div className="item-container">
            {containsSoy?.map((item, index) => (
              <RecommendationProveltModalItem pill={item} key={index} isBoost={isBoost}/>
            ))}
          </div>
          {containsPea?.length > 0 && <div className="item-container-title2"><img src={`https://images.shaklee.com/wellness/plant.svg`} alt="" />{t("ui.recommendation.meology_provelt_pop_up_title6")}</div>}
          <div className="item-container">
            {containsPea?.map((item, index) => (
              <RecommendationProveltModalItem pill={item} key={index} isBoost={isBoost}/>
            ))}
          </div>
        </>
      }


      <button type="button" className="modal-button-close" onClick={onHide}>
        X
      </button>
    </Modal>
  );
};

export default memo(RecommendationProveltModal);
