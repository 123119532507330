import React, { memo, useMemo, useEffect, createContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useIsMarketing } from 'assets/js/hooks';
import { useWindowSize, getQuestionsNumber, setLocalQuestion } from 'assets/js/helper';
import {
  getAnswersByHealthProfileId,
  hpidInLocalStorage,
  setLandingPage,
  setKidsLandingPage,
  setPrenatalLandingPage,
  setMarketingType,
} from 'components/questions/QuestionActions';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import {
  StickyHeader,
  Banner,
  Personal,
  Packaged,
  Social,
  Disclaimer,
  Difference,
  GoodStuff,
  FooterBanner,
  KidsNavigation,
  PrenatalMarketing
} from '../Marketing';
import { ExtraPerks, SafeProven, Award } from '../landing';
import Managing from 'components/header/Managing';

export const MarketingContext = createContext(null);
const Marketing = ({isCalledRecomendation, setPage, page}) => {
  const { isRegularMarketing, isKidsMarketing, isPrenatalMarketing, isMarketing } = useIsMarketing(page);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const landingPage = useSelector(state => state.QuestionReducer.landing_page);
  const prenatalLandingPage = useSelector(state => state.QuestionReducer.prenatal_landing_page);
  const kidsLandingPage = useSelector(
    state => state.QuestionReducer.kids_landing_page
  );
  const isMobile = useSelector(state => state.QuestionReducer.isMobile);
  const user = useSelector(state => state.user);
  const { width: windowWidth } = useWindowSize();
  const isChanged = useMemo(() => windowWidth < 1200, [windowWidth]);

  useEffect(() => {
    const flow = async () => {
      if (user.isUserLoggedin) {
        // user is logged in so call history api
        const postData = {
          email: user.email,
        };
        if(isPrenatalMarketing) {
          postData.meology_type = 'PRENATAL'
        }
        const resFinal = await dispatch(
          getAnswersByHealthProfileId({ postData, setAsyncStatus: false })
        );
        const questionsPageNumber = getQuestionsNumber();
        if (resFinal) {
          // user does have past data so pass to landing page 5/6
          if (resFinal.questions ) {
           
            let pageId = 1;
            if (!questionsPageNumber) {
              pageId = 5;

              //user is loggedin so set the questions to the local storage
                setLocalQuestion(resFinal.questions)
             
            } else {
              pageId = 2;
            }
            if(isPrenatalMarketing) {
              dispatch(setPrenatalLandingPage(pageId, user.rankId, user.contactId));
            }
            if(isRegularMarketing) {
              dispatch(setLandingPage(pageId, user.rankId, user.contactId));
            }
          } else {
            // user does not have past data so pass to landing page 1 or 2
            dispatch(hpidInLocalStorage(user));
          }

          if (resFinal.kids_health_profile_id) {
            dispatch(setKidsLandingPage(2));
          } else {
            dispatch(setKidsLandingPage(1));
          }

          if (resFinal.dna_file_name) {
            localStorage.setItem(
              'fileUploadName',
              JSON.stringify(resFinal.dna_file_name)
            );
          }
          if (resFinal.dna_file_size_in_bytes) {
            localStorage.setItem(
              'fileUploadSize',
              JSON.stringify(resFinal.dna_file_size_in_bytes)
            );
          }
        }
      } else {
        dispatch(hpidInLocalStorage(null));
      }
    };
    flow();
  }, [dispatch, isPrenatalMarketing, isRegularMarketing, user]);

  const props = {
    t,
    history,
    location,
    landingPage,
    isMobile,
    kidsLandingPage,
    isChanged,
    prenatalLandingPage,
    page
  };

  useEffect(() => {
    dispatch(setMarketingType(1));
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="market-container">
      <MarketingContext.Provider
        value={{
          t,
          history,
          location,
          landingPage,
          isMobile,
          kidsLandingPage,
          isChanged,
          isCalledRecomendation,
          page,
          setPage,
          isRegularMarketing,
          isKidsMarketing,
          isPrenatalMarketing,
          isMarketing,
          prenatalLandingPage
        }}
      >
        <Managing name={'guest'} />
        <Header />
        <StickyHeader />
        {isRegularMarketing && <>
	        <LazyLoad>
		        <Banner {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <Personal {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <ExtraPerks {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <Packaged {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <SafeProven {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <Social {...props} />
	        </LazyLoad>
	        <LazyLoad>
		        <Disclaimer {...props} />
	        </LazyLoad>
        </>}
        {isKidsMarketing && <>
        <LazyLoad>
          <Banner />
        </LazyLoad>
	        <LazyLoad>
          <Award
            className="award-kids"
            text={t('ui.award.text3')}
            awards={['PTPA', 'TNPC']}
          />
	        </LazyLoad>
	        <LazyLoad>
          <KidsNavigation />
	        </LazyLoad>
        <LazyLoad>
          <GoodStuff />
        </LazyLoad>
        <LazyLoad>
          <FooterBanner />
        </LazyLoad>
        <LazyLoad>
          <ExtraPerks />
        </LazyLoad>
        <LazyLoad>
          <Packaged />
        </LazyLoad>
        <LazyLoad>
          <Difference />
        </LazyLoad>
        <LazyLoad>
          <Social />
        </LazyLoad>
        <LazyLoad>
          <Disclaimer />
        </LazyLoad>
        </>}
        {isPrenatalMarketing && <PrenatalMarketing />}
        <Footer />
      </MarketingContext.Provider>
    </main>
  );
};

export default memo(Marketing);
