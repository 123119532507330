import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
// import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
// import { getEventAction, getEventLabel } from 'components/analytics/quizAnalytics';

const RenderButton = props => {
  // const dispatch = useDispatch();

  const {
    name,
    options,
    selectedAnswer,
    t,
  } = props;
  const selectedClass = variant => {
    let currentClass = 'form-control';
    if (selectedAnswer === variant || parseInt(selectedAnswer) === parseInt(variant)) {
      currentClass = 'form-control selected';
    }
    return currentClass;
  };
  // const pushAnalytics = (question, answer) => {
  //   const eventAction = getEventAction(question);
  //   const eventLabel = getEventLabel(question, answer);
  //   dispatch(
  //     pushInteractionToDataLayer({
  //       eventCategory: 'Quiz',
  //       eventAction,
  //       eventLabel,
  //     })
  //   );
  // };
  const fieldChange = async e => {
    // click analytics
    // pushAnalytics(name, e.target.value);
    e.persist();
    await props.updateAnswer(props.name, e.target.value, props.page);
  };


  return (
    <Row className="answer">
      {options.map(variant => {
        return (
          <div
            className="btn-container"
            key={variant.answer}
            tabIndex="0"
            aria-label={t('answers.' + name + '.' + variant.answer + '.title')}
            onKeyPress={(e) => {
              if (e.charCode === 13 || e.charCode === 32) {
                props.updateAnswer(props.name, variant.answer, props.page);
              }
            }}
          >
            <button
              type="button"
              name={name}
              className={selectedClass(variant.answer)}
              value={variant.answer}
              aria-pressed={selectedAnswer === variant.answer || parseInt(selectedAnswer) === parseInt(variant.answer) }
              onClick={fieldChange}
              title={t('answers.' + name + '.' + variant.answer + '.title')}
            >
              {t('answers.' + name + '.' + variant.answer + '.title')}
            </button>
          </div>
        );
      })}
    </Row>
  );
};

export default withTranslation()(RenderButton);
