/* eslint-disable no-new-func */
import { useRef, useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import {
  ORGANIC_GREENS_BOOSTER,
  LIFE_SHAKE,
  RSW_VALID_DATE,
} from 'components/results/Recommendations/RecommendationsConstants';

// Meology type constant
export const REGULAR_TYPE = 'REGULAR';
export const KIDS_TYPE = 'KIDS';
export const PRENATAL_TYPE = 'PRENATAL';
export const URL_TYPES = [REGULAR_TYPE, PRENATAL_TYPE, KIDS_TYPE].map(item => item.toLowerCase());

export const dynamicSort = property => {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const filterQuestion = (question, answers) => {
  if (!question.restriction) {
    return question;
  }

  question['restrict'] = Function(
    'answers',
    'return ' + question.restriction
  ).call(this, answers || {});

  const showPrenatal = Cookies.get('prenatal_visible') === 'true';
  if (question.question === 'transition_to_prenatal' && !showPrenatal) {
    question.restrict = false;
  }

  return question;
};

export const filterAnswer = (answer, answers) => {
  if (!answer.answer_restriction) {
    return answer;
  }
  answer['restrict'] = Function(
    'answers',
    'return ' + answer.answer_restriction
  ).call(this, answers || {});
  return answer;
};

export const nextBtnClick = async (
  setNextPage,
  setAnswer,
  selectedAnswers,
  pageQuestion,
  pageId,
  rank,
  shakleeId
) => {
  await setTimeout(() => {
    setAnswer({
      ...pageQuestion,
    });
    setNextPage({
      pageId,
      label: 'next',
      rank,
      shakleeId,
    });
  }, 500);
};

export const nextBtnClickIndividual = async ({
  question,
  questionValue,
  setNextPage,
  updateAns,
  pageId,
  rank,
  shakleeId,
  dispatch,
}) => {
  await setTimeout(() => {
    dispatch(updateAns(question, questionValue, pageId ));
    setNextPage({
      pageId,
      label: 'next',
      rank,
      shakleeId,
    });
  }, 500);
};

export const totalPages = data => {
  let pages = 0;
  const pagesObj = {};
  const pagination = [];
  if (!data) {
    return {
      pages,
      pagesObj,
      pagination,
    };
  }
  const sortedData = data.sort(dynamicSort('page'));
  sortedData.forEach(rec => {
    // if (!pagesObj[rec.page]) {
    //   pagesObj[rec.page] = [];
    //   pagination.push(rec.page);
    // }
    // pagesObj[rec.page].push(rec);

    pagesObj[rec.page] = rec;
    pagination.push(rec.page);
  });
  pages = Object.keys(pagesObj).length;

  return {
    pages,
    pagesObj,
    pagination,
  };
};

export const isProveit = () => {
  const reg = new RegExp('(^|&)referrer_code=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (
    r !== null &&
    (unescape(r[2]) === 'proveit' || unescape(r[2]) === 'PROVEIT')
  ) {
    return true;
  }
  return false;
};

export const getUrlVars = (url = window.location.href) => {
  let vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(match, p1, p2) {
    vars[p1] = p2;
  });
  return vars;
};
export const truncateString = (str, num = 10) => {
  if (!str) return str;
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const removeUrlParam = (key, location) => {
  const replace = `[?&]${key}=[^&]+`;
  const regex = new RegExp(replace);
  const replaceSearch = window.location.search.replace(regex, '').replace(/^&/, '?');
  location.search = replaceSearch;
  window.history.replaceState &&
    window.history.replaceState(
      null,
      '',
      window.location.pathname + replaceSearch + window.location.hash
    );
};
export const changeUrlParam = (key, value, routerLocation) => {
  const replace = `[?&](${key}=[^&]+)`;
  const regex = new RegExp(replace);
  const search = window.location.search.replace(regex, (a, b) =>
    a.replace(b, `${key}=${value}`)
  );

  if (!isEmpty(routerLocation)) {
    routerLocation.search = search;
  }

  window.history.replaceState &&
    window.history.replaceState(
      null,
      '',
      window.location.pathname + search + window.location.hash
    );
};

export const addUrlParam = ({ key, value, routerLocation }) => {
  const url = new URL(window.location);
  url.searchParams.set(key, value);
  window.history.pushState(null, null, url);

  if (!isEmpty(routerLocation)) {
    routerLocation.search = url.searchParams.toString();
  }
};

export const resetQuiz = ({ location, isUserLoggedin, type }) => {
  const { search } = location || {};
  const params = new URLSearchParams(search);
  const local = Cookies.get('locale');
  const { country, language } = local ? JSON.parse(local) : {};
  if (country && language) {
    params.set('lang', `${language}_${country}`);
    params.set('country', country);
  }
  if (
    URL_TYPES.includes(params.get('type')) &&
    params.get('mode')?.startsWith('edit')
  ) {
    params.delete('mode');
    params.delete('type');
  }
  const urlParams = params.toString();
  const isProveit = search && search.includes('referrer_code=proveit');
  if (!isUserLoggedin) {
    removeLocalQuestion();
    localStorage.removeItem('fileUploadName');
    localStorage.removeItem('fileUploadSize');
  }
  setQuestionsNumber(1);
  removeLocalHealthProfile();
  localStorage.removeItem('skipedSectionLength');
  if (!isProveit) {
    localStorage.removeItem('request_products');
  }
  const assessmentPathName = getAssessmentPathName(type);
  window.location.href = assessmentPathName + (urlParams ? `?${urlParams}` : '');
};

export const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

export const setSessionStorage = (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

export const removeLocalStorage = key => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

export const getLocalStorage = key => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const getSessionStorage = key => {
  const value = window.sessionStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const isIosDevice = () => {
  const u = navigator.userAgent;
  if (u.indexOf('iPhone') > -1 || u.indexOf('iOS') > -1) {
    return true;
  }
  return false;
};

export const isAndroid = () => {
  const u = navigator.userAgent;
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    return true;
  }
  return false;
};

export const toThousands = number => {
  let newStr = '';
  let count = 0;
  let str = number ? String(number) : '';
  if (str.indexOf('.') === -1) {
    for (let i = str.length - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr;
    return str;
  } else {
    for (let i = str.indexOf('.') - 1; i >= 0; i--) {
      if (count % 3 === 0 && count !== 0) {
        newStr = str.charAt(i) + ',' + newStr;
      } else {
        newStr = str.charAt(i) + newStr;
      }
      count++;
    }
    str = newStr + (str + '00').substr((str + '00').indexOf('.'), 3);
    return str;
  }
};

export const useRafState = initialSate => {
  const frame = useRef(0);
  const [state, setState] = useState(initialSate);

  const setRafState = useCallback(value => {
    cancelAnimationFrame(frame.current);

    frame.current = requestAnimationFrame(() => {
      setState(value);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => cancelAnimationFrame(frame.current), []);

  return [state, setRafState];
};

export const useWindowSize = () => {
  const [state, setState] = useRafState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handler = () => {
      setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};

export const useWindowScroll = () => {
  const [state, setState] = useRafState({
    x: window.pageXOffset,
    y: window.pageYOffset,
  });

  useEffect(() => {
    const handler = () => {
      setState({
        x: window.pageXOffset,
        y: window.pageYOffset,
      });
    };

    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};

export const useOffsetTop = ref => {
  const [state, setState] = useRafState(0);
  useEffect(() => {
    if (!ref.current) return;
    setState(ref.current.offsetTop);
    const handler = () => {
      const offsetTop  = ref?.current?.offsetTop;
      if (offsetTop) {
        setState(offsetTop);
      }
    };

    window.addEventListener('resize', handler);
    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('scroll', handler);
    };
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};

export const useSize = ref => {
  const isSupportResizeObserver = typeof ResizeObserver === 'function';
  const resizeObserverRef = useRef(null);
  const handlerRef = useRef(null);
  const [size, setSize] = useRafState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isSupportResizeObserver) {
      resizeObserverRef.current = new ResizeObserver(entries => {
        const { contentRect: { width, height } = {} } = entries[0] || {};
        setSize({ width: Number(width), height: Number(height) });
      });

      resizeObserverRef.current.observe(ref.current);
    } else {
      handlerRef.current = () => {
        const { offsetWidth, offsetHeight } = ref.current;
        setSize({ width: offsetWidth, height: offsetHeight });
      };

      setTimeout(handlerRef.current, 35);

      window.addEventListener('scroll', handlerRef.current, {
        capture: false,
        passive: true,
      });
      window.addEventListener('resize', handlerRef.current);
    }

    return () => {
      if (isSupportResizeObserver) {
        resizeObserverRef.current.disconnect();
      } else {
        window.removeEventListener('scroll', handlerRef.current);
        window.removeEventListener('resize', handlerRef.current);
      }
    };
  }, [isSupportResizeObserver, ref.current, setSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return size;
};

export const useEnsuredForwardRef = ref => {
  const ensuredRef = useRef(ref && ref.current);

  useEffect(() => {
    if (!ref) return;

    ref.current = ensuredRef.current;
  }, [ref]);

  return ensuredRef;
};

export const arrayMove = (array, from, to) => {
  const moveItem = array.splice(from, 1)[0];
  array.splice(to, 0, moveItem);
  return array;
};

export const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false);
  const [eTarget, setETarget] = useState(null);

  const handleKeyDown = ({ key,target }) => {
    if (key === targetKey) {
      setKeyPressed(true);
      setETarget(target);
    }
  };

  const handleKeyUp = ({ key, target }) => {
    if (key === targetKey) {
      setKeyPressed(false);
      setETarget(null);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {keyPressed,eTarget};
};

export const useLoadImg = url => {
  const [{ dom, status }, setImg] = useState({
    dom: null,
    status: 'loading',
  });
  useEffect(() => {
    if (!url) return;
    const dom = document.createElement('img');
    const onload = () => setImg({ dom, status: 'loaded' });
    const onerror = () => setImg({ dom: null, status: 'failed' });
    dom.addEventListener('load', onload);
    dom.addEventListener('error', onerror);
    dom.src = url;

    return () => {
      dom.removeEventListener('load', onload);
      dom.removeEventListener('error', onerror);
      setImg({ dom: null, status: 'loading' });
    };
  }, [url]);
  return [status, dom];
};

const isColorHex = hex => /^#[0-9A-F]{6}$/i.test(hex);
export const hexToRgb = hex =>
  isColorHex(hex)
    ? hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => '#' + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16))
    : [0, 0, 0];
export const setLocaleCookie = locale => {
  let localeEncode = encodeURI(JSON.stringify(locale));
  document.cookie = 'locale=' + localeEncode + ';;path=/';
};
export const isQuizCompletion = type => {;
	return ['transition_quiz_completion'].includes(type)
};
export const isInput = type => {
  const { nameKey } = getMeologyType();
  return [nameKey, 'age',].includes(type)
};
export const isInputUnit = type =>
  ['core_measurement', 'lab_results'].includes(type);
export const isInputGroup = type => ['bp_reading'].includes(type);
export const hasInput = type => {
  const { nameKey } = getMeologyType();
  return [
    nameKey,
    'age',
    'core_measurement',
    'lab_results',
    'bp_reading',
    'dna_id',
    'prenatal_due_date',
  ].includes(type);
};

export const removeTag = string => string.replace(/<[^>]*>/gm, '');
export const replaceSpaceString = string => string.replace(/&nbsp;/g, ' ');

export const formatDate = date => {
  const d = new Date(date);
  const resDate = d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
  return resDate;
};

export const getHybrisHost = country =>
  process.env[`REACT_APP_HYBRIS_SERVER_NAME_${country}`];

export const getHybrisUrlParams = (lang, country) => {
  const urlParams = new URLSearchParams(window.location.search);
  const ckid = urlParams.get('ckid');
  return `?lang=${
    lang === 'zh' ? 'zh_CN' : `${lang}_${country}`
  }&source=meology${ckid ? `&ckid=${encodeURIComponent(ckid)}` : ''}`;
};
export const getObjectKeyByValue = (value, unit) =>
  Object.keys(unit).find(key => unit[key] === value);

export const getUnit = name => {
  if (name === 'core_measurement') {
    return { us: 'inches', si: 'cm' };
  } else if (
    [
      'total_cholesterol',
      'ldl_cholesterol',
      'hdl_cholesterol',
      'triglycerides',
      'glucose',
    ].includes(name)
  ) {
    return { us: 'mg/dL', si: 'mmol/L' };
  } else if (name === 'hba1c') {
    return { us: '%', si: 'mmol/mol' };
  } else if (name === 'hscrp') {
    return { us: 'mg/L', si: 'nmol/L' };
  } else if (name === 'vitamin_d') {
    return { us: 'ng/mL', si: 'nmol/L' };
  } else if (name === 'omega_3_index') {
    return { us: '%' };
  } else {
    return {};
  }
};

export const getfilesize = size => {
  const num = 1024.0;
  if (size < num) return size + 'B';
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'KB';
  if (size < Math.pow(num, 3))
    return (size / Math.pow(num, 2)).toFixed(2) + 'MB';
  if (size < Math.pow(num, 4))
    return (size / Math.pow(num, 3)).toFixed(2) + 'G';
  return (size / Math.pow(num, 4)).toFixed(2) + 'T';
};

// get file extension
export const getFileExtension = filename => {
  if (!filename || filename.constructor !== String) return '';
  const array = filename.split('.');
  if (array.length === 1 || (array[0] === '' && array.length === 2)) {
    return '';
  }
  return array.pop().toLowerCase();
};

// number 0 is not empty
export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'number' && isNaN(value)) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const isChecked = (answer, currentAnswer) => {
  if (isEmpty(currentAnswer)) return false;
  if (Array.isArray(currentAnswer)) {
    if (
      currentAnswer[0] === null &&
      (answer === 'NONE' || answer === 'OTHER')
    ) {
      return true;
    } else {
      return currentAnswer.includes(answer);
    }
  } else {
    return currentAnswer.toString() === answer;
  }
};

export const hasValidationError = ({ isValidQuestion, question, options }) => {
  const error = (() => {
    if (question === 'kids_info') {
      const all = [];
      if (isEmpty(isValidQuestion)) return false;
      _.forEach(isValidQuestion[question], ({ error }) => {
        all.push(error);
      });
      return all.some(Boolean);
    } else {
      return _.get(isValidQuestion, `${question}.error`, false);
    }
  })();
  if (['bp_reading', 'lab_results'].includes(question)) {
    return options.some(({ answer }) =>
      _.get(isValidQuestion, `${answer}.error`, false)
    );
  } else {
    return error;
  }
};

export const hasValidationEmpty = ({ values, question, options }) => {
  const answer = _.get(values, question, '');
  if (question === 'lab_results') {
    const values = _.values(answer);
    return isEmpty(values) || values.every(({ value }) => isEmpty(value));
  } else if (question === 'core_measurement') {
    return isEmpty(answer.value);
  } else if (['bp_reading', 'height_and_weight'].includes(question)) {
    const values = _.values(answer);
    return (
      isEmpty(values) ||
      values.length < options.length ||
      values.some(value => isEmpty(value))
    );
  } else if (question === 'kids_info') {
    const values = _.values(answer);
    return (
      values.length === 1 &&
      isEmpty(values[0]['name']) &&
      isEmpty(values[0]['icon'])
    );
  } else {
    return isEmpty(answer);
  }
};

export const getLocalQuestion = () => {
  const { answersKey } = getMeologyType();
  return getLocalStorage(answersKey);
};

export const setLocalQuestion = question => {
  const { answersKey } = getMeologyType();
  setLocalStorage(answersKey, question);
};

export const removeLocalQuestion = () => {
  const { answersKey } = getMeologyType();
  removeLocalStorage(answersKey);
};

export const getLocalHealthProfile = () => {
  const { healthProfileKey } = getMeologyType();
  return getLocalStorage(healthProfileKey);
};

export const removeLocalHealthProfile = () => {
  const { healthProfileKey } = getMeologyType();
  removeLocalStorage(healthProfileKey);
};

export const updateLocalHealthProfile = healthProfile => {
  const { healthProfileKey } = getMeologyType();
  setLocalStorage(healthProfileKey, healthProfile);
};

export const createKidId = kids => {
  if (kids) {
    let Array = [];
    kids.forEach(item => {
      Array.push(getKidCounter(item?.id));
    });
    return Math.max(...Array) + 1;
  } else {
    return 1;
  }
};

const getKidCounter = data => data?.split('-')[2] || 1;

export const getExtByCode = (data, code) => {
  const productInfo = _.get(data, 'product_info', []);
  if (_.isEmpty(productInfo)) return {};
  let result = {};
  _.forEach(productInfo, (value, key) => {
    const ext = _.get(value, 'ext', {});
    const reason = _.get(value, 'reason_categories', {});
    if (key === code) {
      if (!_.isEmpty(reason)) {
        result = { reason_categories: reason };
      }
      if (!_.isEmpty(ext)) {
        result = { ...result, ...ext };
      }
    }
  });
  return result;
};

export const getIsKidsFromLocal = () => {
  const meologyType = getLocalStorage('meologyType');
  return meologyType?.toUpperCase() === 'KIDS' ?? false;
};
// get product summary
export const getProductSummary = productInfo =>
  productInfo?.baseOptions?.[0]?.selected?.summary || productInfo?.summary;

export const getKidsProductSummary = (productInfo, t) => {
  const { code } = productInfo || {};
  if (ORGANIC_GREENS_BOOSTER.includes(code)) {
    return t('ui.faq.meology_kids_orggreenbooster_short');
  } else if (LIFE_SHAKE.includes(code)) {
    return t(`ui.faq.meology_kids_lifeshake_short_${code}`);
  } else {
    return getProductSummary(productInfo);
  }
};

// get product description
export const getProductDescription = productInfo =>
  productInfo?.baseOptions?.[0]?.selected?.description ||
  productInfo?.description;

export const getKidsProductDescription = (productInfo, t) => {
  const { code } = productInfo || {};
  if (ORGANIC_GREENS_BOOSTER.includes(code)) {
    return `${t('ui.faq.meology_kids_orggreenbooster_long')}`;
  } else if (LIFE_SHAKE.includes(code)) {
    return t(`ui.faq.meology_kids_lifeshake_long_${code}`);
  } else {
    return getProductDescription(productInfo);
  }
};

// if the product is backorder
export const getIsBackorder = product => {
  const stockLevelStatus = product?.stock?.stockLevelStatus ?? '';
  return stockLevelStatus?.toUpperCase() === 'BACKORDERED';
};

export const getImageUrl = (product, country) => {
  const { images } = product || {};
  if (!Array.isArray(images)) return;
  if (images.length === 0) return;
  const { url } =
    images.find(
      ({ format = '', imageType = '' }) =>
        format.toLowerCase() === 'zoom' && imageType.toLowerCase() === 'primary'
    ) || {};
  if (!url) return '';
  return url.startsWith('//')
    ? url
    : `${process.env['REACT_APP_HYBRIS_SERVER_NAME_' + country]}${url}`;
};

export const isKidsQuestion = question => question === 'kids_info';

export const getError = (question, isValidQuestion) => {
  if (isKidsQuestion(question)) {
    return isValidQuestion?.[question]?.all ?? {};
  } else {
    return isValidQuestion?.[question] ?? {};
  }
};

export const getIfHasError = async ({
  dispatch,
  validateQuestions,
  question,
  selectedAnswer,
  t,
  options,
}) => {
  const isValidQuestion = await dispatch(
    validateQuestions(
      question,
      selectedAnswer,
      false,
      t,
      undefined,
      undefined,
      true
    )
  );
  return hasValidationError({ isValidQuestion, question, options });
};

export const delParam = (url, paramKey) => {
  let beforeUrl = url.substr(0, url.indexOf('?'));
  let afterUrl = url.substr(url.indexOf('?') + 1);
  let nextUrl = '';
  let arr = [];
  if (afterUrl !== '') {
    let urlParamArr = afterUrl.split('&');
    for (let i = 0; i < urlParamArr.length; i++) {
      let paramArr = urlParamArr[i].split('=');
      if (paramArr[0] !== paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = '?' + arr.join('&');
  }
  url = beforeUrl + nextUrl;
  return url;
};

export const getAssessmentPathName = type =>
  `/${type === 'regular' ? 'adult' : type}/assessment`;

export const getMeologyType = props => {
  let meologyType;
  const { view, type: urlType } = getUrlVars();
  const isView = view === 'true';
  const localType = getLocalStorage('meologyType') || REGULAR_TYPE;
  if (props) {
    const typeMap = {
      adult: REGULAR_TYPE,
      kids: KIDS_TYPE,
      prenatal: PRENATAL_TYPE,
    };
    meologyType = typeMap[props?.match?.params?.type] ?? REGULAR_TYPE;
    setLocalStorage('meologyType', meologyType);
  } else if (urlType) {
    if (['join', 'upgrade'].includes(urlType)) {
      if (window.location.pathname?.includes('prenatal/assessment')) {
        meologyType = PRENATAL_TYPE;
      } else if (window.location.pathname?.includes('adult/assessment')) {
        meologyType = REGULAR_TYPE;
      } else {
        meologyType = localType;
      }
    } else if (URL_TYPES.includes(urlType)) {
      meologyType = urlType?.toUpperCase();
    }
  } else if (isView) {
    meologyType = REGULAR_TYPE;
  } else {
    meologyType = localType;
  }
  const type = meologyType?.toLowerCase();
  const nameKeyMap = {
    regular: 'name',
    prenatal: 'prenatal_name',
    kids: 'name',
  };
  return {
    type,
    meologyType,
    answersKey: `${type}QuestionsSelectedAnswers`,
    healthProfileKey: `${type}HealthProfile`,
    questionsNumberKey: `${type}QuestionsPageNumber`,
    questionsLengthKey: `${type}QuestionsPageLength`,
    nameKey: nameKeyMap[type],
    assessmentPathName: getAssessmentPathName(type),
    isRegularType: meologyType === REGULAR_TYPE,
    isKidsType: meologyType === KIDS_TYPE,
    isPrenatalType: meologyType === PRENATAL_TYPE,
  };
};

export const setQuestionsNumber = pageId => {
  const { isKidsType, questionsNumberKey } = getMeologyType();
  if (isKidsType) return;
  setLocalStorage(questionsNumberKey, pageId);
}
export const removeQuestionsNumber = () => {
  const { isKidsType, questionsNumberKey } = getMeologyType();
  if (isKidsType) return;
  removeLocalStorage(questionsNumberKey);
}
export const getQuestionsNumber = () => {
  const { isKidsType, questionsNumberKey } = getMeologyType();
  if (isKidsType) return;
  const number = getLocalStorage(questionsNumberKey);
  return number ? parseFloat(number) : 0;
}

export const saveQuestionsLength = pageLength => {
  const { isKidsType, questionsLengthKey } = getMeologyType();
  if (isKidsType) return;
  setLocalStorage(questionsLengthKey, pageLength);
}
export const removeQuestionsLength = () => {
  const { isKidsType, questionsLengthKey } = getMeologyType();
  if (isKidsType) return;
  removeLocalStorage(questionsLengthKey);
}
export const getQuestionsLength = () => {
  const { isKidsType, questionsLengthKey } = getMeologyType();
  if (isKidsType) return;
  const length = getLocalStorage(questionsLengthKey);
  return length ? parseFloat(length) : 0;
}

export const getProgress = (pageId, pageLength) => {
  const { isKidsType, isRegularType, isPrenatalType } = getMeologyType();
  if (isKidsType) return 50;
  if (isRegularType) return (pageId / (pageLength - 1)) * 100;
  if (isPrenatalType) return (pageId / pageLength) * 100;
}
// calculate the trimester
// currentDate format YYYY/MM/DD or MM/DD/YYYY
// dueDate format YYYY/MM/DD or MM/DD/YYYY
export const getCurrentTrimester = (dueDate, currentDate) => {
  if (!dueDate) return;
  const currentTimeStamp = Date.parse(currentDate || new Date().toDateString());
  const dueTimeStamp = Date.parse(dueDate);
  const standardPreTotalDays = 280;
  const pregnantDays = standardPreTotalDays - (dueTimeStamp - currentTimeStamp)/(24*60*60*1000);

  if (pregnantDays < 0) return;
  if (pregnantDays <= 97) {
    return 1;
  }
  if (pregnantDays <= 188) {
    return 2;
  }
  return 3;
};

export const getNameFromAnswers = answers => {
  const { nameKey } = getMeologyType();
  return answers?.[nameKey];
};

export const truncateNameFromAnswers = answers => {
  if (!answers) return {};
  const { nameKey } = getMeologyType();
  const name = truncateString(answers?.[nameKey]);
  return name ? { ...answers, [nameKey]: name } : answers;
};

/**
 * the page router is /reco
 * @param {string} prenatalStep Active Phase
 * @param {string} reproductiveStatus Current phase
 * @returns {{past: boolean, current: boolean, next: boolean}}
 */
export const getPrenatalPhaseStatus = (prenatalStep, reproductiveStatus) => {
  const phases = {
    TRYING:1,
    PREGNANT:2,
    POSTPARTUM:3
  }
  const isCurrent = phases[reproductiveStatus] || -1
  const isActive = phases[prenatalStep] || -1
  if(isActive < 0 || isCurrent < 0) return {}
  return {
    past: isActive < isCurrent,
    current: isActive === isCurrent,
    next: isActive > isCurrent
  }
};

export const is = (type, val) =>
  ![undefined, null].includes(val) && val.constructor === type;

export const getNextProcessingDate = ({ autoshipInfos, currentAutoshipCode }) => {
  const currentAutoship = autoshipInfos?.find(
    ({ code }) => code === currentAutoshipCode
  );
  return moment(currentAutoship?.nextProcessingDate, 'MMM-DD-YYYY').format('MM/DD/YYYY');
};

export const changeModeUrl = (nType, routerLocation) => {
  const { type, mode } = getUrlVars();
  if(URL_TYPES.includes(type) && type !== nType) {
    changeUrlParam('type', nType, routerLocation)
    if(mode === 'edit' || mode === 'restore') {
      //When switching types, save the currently edited type so that you can return later
      changeUrlParam('mode', `${mode}__${type}`, routerLocation)
    }
    const editValues = ['edit__regular', 'edit__prenatal', 'edit__kids']
    if(editValues.includes(mode) && mode.endsWith(`__${nType}`)) {
      changeUrlParam('mode', 'edit', routerLocation)
    }
    const restoreValues = ['restore__regular', 'restore__prenatal', 'restore__kids']
    if(restoreValues.includes(mode) && mode.endsWith(`__${nType}`)) {
      changeUrlParam('mode', 'restore', routerLocation)
    }
  }
}

export const getMeologyPriceTier = priceTier => {
  if (!priceTier || priceTier === 'SRP') return 'MP';
  return priceTier;
}

export const isRSWValidTime = ({
  joinDate,
  isPdpFlow,
  isJoinFlow,
  accountType,
}) => {
  if (!joinDate) {
    return true;
  } else {
    if (
      (isJoinFlow || isPdpFlow) &&
      ['ANONYMOUS', 'GUEST'].includes(accountType)
    ) {
      return true;
    } else {
      return new Date(joinDate) >= new Date(RSW_VALID_DATE);
    }
  }
};

export const isRSW = () => {
  const { kit_code, type, whence } = getUrlVars() || {};
  const isJoinFlow = (type === 'join' || type === 'upgrade') && !isEmpty(kit_code);
  const isPdpFlow = whence === 'pdp';
  return {isJoinFlow, isPdpFlow, kitCode: kit_code}
}

export const getPrenatalRSWStatus = async (user, getAnswersByHealthProfileId) => {
  // eslint-disable-next-line no-use-before-define
  const {isJoinFlow, isPdpFlow} = isRSW()
  const isRSWJoinFlow = isJoinFlow || isPdpFlow
  if(!isRSWJoinFlow) return false
  const { country, isUserLoggedin, email } = user || {};
  let history = {}

  if(isUserLoggedin && isRSWJoinFlow) {
    history = await getAnswersByHealthProfileId?.({
      postData: {
        meology_type: 'PRENATAL',
        email
      }
    })
  }

  const { hasWK } = history || {};
  const isPi = _.isBoolean(hasWK) ? hasWK : hasWK === 'true';
//There is not logic of Join Date for Prenatal + RSW.
  return !isPi && isRSWJoinFlow && country !== 'CA';
};

export const getAttributesFromProduct = (product, attrTypes = {}) => {
  const {
    kosherType,
    dairyProduct,
    vegetarianProduct,
    veganProduct,
    containsNuts,
    containsSoy,
    glutenFree,
  } = product || {};
  const attrs = [];
  if (kosherType === 'KOSHERK') {
    attrs.push(attrTypes.kosherType);
  }
  if (kosherType === 'KOSHERD') {
    attrs.push(attrTypes.kosherDiaryType);
  }
  if (!dairyProduct) {
    attrs.push(attrTypes.dairyProduct);
  }
  if (vegetarianProduct && !veganProduct) {
    attrs.push(attrTypes.vegetarianProduct);
  }
  if (veganProduct) {
    attrs.push(attrTypes.veganProduct);
  }
  if (!containsNuts) {
    attrs.push(attrTypes.containsNuts);
  }
  if (!containsSoy) {
    attrs.push(attrTypes.containsSoy);
  }
  if (glutenFree) {
    attrs.push(attrTypes.glutenFree);
  }
  return attrs;
};

export const enhanceTimeout = (hander, time) => {
  let tId = null
  return (e) => {
    if(tId) {
      clearTimeout(tId)
      tId = null
    }
    tId = setTimeout(hander, time, e)
  }
}

export const getCurrentEventCategory = () => {
  const { isRegularType, isPrenatalType, isKidsType } = getMeologyType();
  if (isRegularType) return 'Meology';
  if (isPrenatalType) return 'Prenatal';
  if (isKidsType) return 'Meology Kids';
};

export const post = async ({ url, postData }) => {
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(postData),
    });
    return response.json();
  } catch (error) {
    return error;
  }
};

export const parseValue = (value) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}
export const sortIngredient = ingredient => {
  const order = [
    // macronutrients and vitamins
    'Calories',
    'Calories from Saturated Fat',
    'Total Fat',
    'Saturated Fat',
    'Trans Fat',
    'Polyunsaturated Fat',
    'Monounsaturated Fat',
    'Cholesterol',
    'Total Carbohydrate',
    'Dietary Fiber',
    'Soluble Fiber',
    'Insoluble Fiber',
    'Total Sugars',
    'Added Sugars', // not same, Includes 1 g Added Sugars
    'Sugar Alcohol',
    'Protein',
    'Vitamin A',
    'Vitamin C',
    'Vitamin D',
    'Vitamin E',
    'Vitamin K',
    'Thiamin',
    'Riboflavin',
    'Niacin',
    'Vitamin B6', // not same, Vitamin B₆
    'Folate',
    'Vitamin B12', // not same, Vitamin B₁₂
    'Biotin',
    'Pantothenic Acid',
    'Choline',

    // minerals and micronutrients
    'Calcium',
    'Iron',
    'Phosphorus',
    'Iodine',
    'Magnesium',
    'Zinc',
    'Selenium',
    'Copper',
    'Manganese',
    'Chromium',
    'Molybdenum',
    'Chloride',
    'Sodium',
    'Potassium',
    'Fluoride',

    // other
    'DHA',
    'EPA',
  ];
  return ingredient.sort((a, b) => {
    const indexA = order.findIndex(item => a?.type?.name.includes(item));
    const indexB = order.findIndex(item => b?.type?.name.includes(item));
    if (indexA === -1 && indexB >= 0) {
      return 1;
    } else if (indexA >= 0 && indexB === -1) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });
};

export const wordCount =  (element, countClass) => {
  if (!element) return
  let countElement = document.createElement('span')
  let currentLength = element.value.length
  let maxLength = parseInt(element.getAttribute('maxlength'))

  const toggleCountMaxClass = ({ currentLength, maxLength }) => {
    if (currentLength >= maxLength) {
      countElement.classList.add('count-max')
    } else {
      countElement.classList.remove('count-max')
    }
  }

  toggleCountMaxClass({ currentLength, maxLength })

  countElement.classList.add(countClass)
  countElement.classList.add('count-limit')
  countElement.innerHTML = currentLength + '/' + maxLength
  element.parentNode.insertBefore(countElement, element.nextSibling);

  element.addEventListener('input', function () {
    let currentLength = this.value.length

    if (currentLength > maxLength) {
      this.value = this.value.slice(0, maxLength)
    } else {
      countElement.innerHTML = currentLength + '/' + maxLength
    }

    toggleCountMaxClass({ currentLength, maxLength })
  })
};

export const isNumeric = value => /^\d+$/.test(value);

export const isImageUrl = url =>
  /https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp|svg|bmp)/.test(url);
