import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import cx from 'classnames';
import NextQuestion from './NextQuestion';
import {
  truncateNameFromAnswers,
  getLocalQuestion,
  getHybrisHost,
  getHybrisUrlParams,
  getLocalStorage,
  getMeologyType,
} from 'assets/js/helper';
import AnimateCheckbox from './widgets/AnimateCheckbox';
import { setNextSection } from "./QuestionActions";
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import _ from "lodash";

const mapState = state => {
  const { type } = getMeologyType();
  return {
    user: state.user,
    pageQuestions: state.QuestionReducer[`${type}PageQuestions`],
  };
};
const actions = { setNextSection, pushInteractionToDataLayer };
class TransitionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt_in: false,
      hybrisHost: '',
      hybrisUrlParams: '',
      dnaAnswered: false,
	    btnRef: React.createRef(),
    };
  }

  componentDidMount() {
    const { lang, country } = this.props.user;
    const { opt_in } = getLocalQuestion() || {};
    this.setState({
      opt_in: Boolean(opt_in)
    });
    if (this.props.isMobile) {
      window.scrollTo(0, 0);
    }

    if (lang && country) {
      this.setState({
        hybrisHost: getHybrisHost(country),
        hybrisUrlParams: getHybrisUrlParams(lang, country),
      });
    }
    const sectionQuestions = ["core_measurement", "lab_results", "bp_reading", "dna_id"];
    for (let question of sectionQuestions) {
      if (!_.isEmpty(this.props.pageQuestion[question])) {
        this.setState({
          dnaAnswered: true
        });
        break;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { lang, country } = this.props.user || {};
    const { lang: prevLang, country: prevCountry } = prevProps.user || {};
    if (!lang || !country) return;
    if (country !== prevCountry || lang !== prevLang) {
      this.setState({
        hybrisHost: getHybrisHost(country),
        hybrisUrlParams: getHybrisUrlParams(lang, country),
      });
    }
  }

  updateOptIn(value) {
    this.setState({
      opt_in: value
    });
  }

  handleClick() {
    // analytics
    this.props.pushInteractionToDataLayer({
      eventAction: 'Privacy Policy',
      eventLabel: 'translation page',
    })
  }

  render() {
    const {
      question,
      t,
      updateAnswer,
      pageId,
      required,
      setNextPage,
      setAnswer,
      pageQuestion,
      selectedAnswers,
      pageLength,
      counter,
      history,
      location,
      pageCategory,
      widget,
      setPreSubmitCheck,
      isMobile,
      rank,
      shakleeId,
      isUserLoggedin,
      isClickedRef,
      setIsClickedRef,
      displayNextButton,
      setNextSection
    } = this.props;
    const ans = truncateNameFromAnswers(selectedAnswers);

    const { country } = this.props.user;

    return (
      <Row className="transition">
        <div className={`col-lg-6 content ${question}`}>
          <div className="desc">
            <h2>
              <Trans>{t('questions.' + question + '.title', ans)}</Trans>
            </h2>
            <p>{t('questions.' + question + '.subtitle')}</p>
            {((question === "transition_optional_dna") && (
              <p>{t('questions.' + question + '.subtitle_review')}</p>
            ))}

            {((pageId === 2 || question === "transition_optional_dna") && (
              <p className="first-transition-privacy">
                {t('questions.' + question + '.subtitle_privacy')}
                {
                  (country === 'CA') ?
                    <a
                      href={`${this.state.hybrisHost}/privacy-security-policy${this.state.hybrisUrlParams}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleClick()}
                      aria-label="Privacy & Security"
                      dangerouslySetInnerHTML={{
                        __html: question === 'prenatal_transition_basics' ? t('questions.prenatal_transition_basics.subtitle_privacy.link') : t('ui.email_capture.click_here')
                      }}
                    />
                    :
                    <a
                      href={`${this.state.hybrisHost}/Privacy-Security-Policy${this.state.hybrisUrlParams}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => this.handleClick()}
                      aria-label="Privacy & Security"
                      dangerouslySetInnerHTML={{
                        __html: question === 'prenatal_transition_basics' ? t('questions.prenatal_transition_basics.subtitle_privacy.link') : t('ui.email_capture.click_here')
                      }}
                    />
                }
              </p>
            ))}
          </div>
          {isUserLoggedin && question === 'transition_quiz_completion' && (
            <AnimateCheckbox
              initValue={this.state.opt_in}
              onChange={value => this.updateOptIn(value)}
              meologyType={getLocalStorage('meologyType')}
            />
          )}
          {displayNextButton && (
            <NextQuestion
	            btnRef={this.state.btnRef}
	            updateAnswer={updateAnswer}
              pageId={pageId}
              question={question}
              required={required}
              setNextPage={setNextPage}
              setAnswer={setAnswer}
              pageQuestion={pageQuestion}
              selectedAnswers={selectedAnswers}
              pageLength={pageLength}
              counter={counter}
              pageCategory={pageCategory}
              widget={widget}
              setPreSubmitCheck={setPreSubmitCheck}
              rank={rank}
              shakleeId={shakleeId}
              optIn={this.state.opt_in}
              isUserLoggedin={isUserLoggedin}
              isClickedRef={isClickedRef}
              setIsClickedRef={setIsClickedRef}
            />
          )}
          {(question === "transition_optional_dna" && !this.state.dnaAnswered) && (
            <button
              className="btn btn-skip-section"
              tabIndex="0"
              aria-label="Skip this section"
              {...{
                [isMobile ? 'onTouchStart' : 'onClick']: () => {
                  setNextSection({
                    pageId,
                    label: 'skip this section',
                    rank,
                    shakleeId,
                    updateAnswer,
                    question,
                  });
                },
              }}
            >
              {t('questions.skip_this_section')}
            </button>
          )}

          {question === 'transition_quiz_completion' && (
            <>
              <div className="summary-link"
                role="link"
                title={t('questions.review')}
                aria-label={t('questions.review')}
              >
                <span
                  onClick={async () => {
                    await setPreSubmitCheck(true);
                    history.push({
                      ...location,
                      pathname: '/summary'
                    });
                  }}>
                  {t('questions.review')}
                </span>
              </div>
            </>
          )}
        </div>
        <div className={`col-lg-6 image ${question}`}>
          {
            <div
              className={cx('banner', {
                mobile: isMobile,
                desktop: !isMobile,
              })}
              style={{
                backgroundImage: `url(${t(
                  `questions.${question}.background_image${
                    isMobile ? '_mobile' : ''
                  }`
                )})`,
              }}
            />
          }
        </div>
      </Row>
    );
  }
}

export default withTranslation()(connect(mapState, actions)(TransitionPage));
