import React, {
  memo,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import _ from 'lodash'
import {
  // nextBtnClick,
  useKeyPress,
  hexToRgb,
  nextBtnClickIndividual,
  getLocalQuestion,
  isInput,
	isQuizCompletion,
  hasValidationError,
  hasValidationEmpty,
  isEmpty,
  getError,
  isKidsQuestion,
  getIfHasError,
  getMeologyType,
} from 'assets/js/helper';
import { getImpersonation } from 'assets/js/hooks';
import {
  updateAnswer as updateAnswerAction,
  validateQuestions,
  removeValidateQuestion,
  postUpdate,
  setCurrentPage,
  deleteQuestionFromList,
} from 'components/questions/QuestionActions';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import {
  getEventAction,
  getEventCategory,
  getEventLabel,
} from 'components/analytics/quizAnalytics';

const NextQuestion = ({
  btnRef,
  counter,
  pageId,
  selectedAnswer,
  pageLength,
  setNextPage,
  question,
  required,
  pageQuestion,
  setAnswer,
  pageCategory,
  isValidQuestion,
  widget,
  btnNextContainer,
  updateAnswer,
  setPreSubmitCheck,
  rank,
  shakleeId,
  optIn,
  isUserLoggedin,
  isClickedRef,
  setIsClickedRef,
  options,
  selectedAnswers
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { is_mobile: isMobile, prenatalPagesObj } = useSelector(state => state.QuestionReducer);
  const { lang, country,email: userEmail } = useSelector(state => state.user)
  const hasError = useMemo(
    () => hasValidationError({ isValidQuestion, question, options }),
    [isValidQuestion, question, options]
  );

  const { isImpersonation, isSponsor } = getImpersonation();

  const ifHasError = useCallback(async () => getIfHasError({
    dispatch,
    validateQuestions,
    question,
    selectedAnswer,
    t,
    options
  }), [dispatch, options, question, selectedAnswer, t])

  const isEmptyValue = useMemo(
    () => hasValidationEmpty({ values: pageQuestion, question, options }),
    [pageQuestion, question, options]
  );

  const disabled = useMemo(
    () => widget !== 'transition' && (hasError || isEmptyValue),
    [isEmptyValue, hasError, widget]
  );

  const showSkip = useMemo(() => {
    const answer = _.values(_.get(pageQuestion, question, ''));
    if (['lab_results', 'bp_reading'].includes(question)) {
      const errorLength =
        !isEmpty(options) &&
        Array.isArray(options) &&
        options.filter(({ answer }) =>
          _.get(isValidQuestion, `${answer}.error`, false)
        ).length;
      let valueLength = 0;
      if (question === 'lab_results') {
        valueLength = answer.filter(({ value }) => !isEmpty(value)).length;
      } else if (question === 'bp_reading') {
        valueLength = answer.filter(value => !isEmpty(value)).length;
      }
      return valueLength === errorLength;
    } else if (question === 'height_and_weight') {
      const weightError = _.get(isValidQuestion, `${question}.error`, false);
      const { height, weight } = _.get(pageQuestion, question, {});
      if (!isEmpty(height)) return false;
      if (isEmpty(weight)) return true;
      return weightError;
    } else {
      return disabled && !required;
    }
  }, [disabled, isValidQuestion, options, pageQuestion, question, required]);

  // only verify once
  const isValidate = useRef(false);
  const questionsSelectedAnswersRef = useRef(
    getLocalQuestion()
  );
  useEffect(() => {
    if (!selectedAnswer) return;
    if (
      !isValidate.current &&
      isInput(question) &&
      questionsSelectedAnswersRef.current &&
      questionsSelectedAnswersRef.current[question]
    ) {
      dispatch(validateQuestions(question, selectedAnswer, false, t));
      isValidate.current = true;
    }
  }, [dispatch, question, selectedAnswer, t]);

  const isTransitionToPrenatal = useMemo(
    () => question === 'transition_to_prenatal',
    [question]
  );
  const isTransitionToWrong = useMemo(
    () => question === 'transition_to_wrong',
    [question]
  );
  const isTransitionToRegular = useMemo(
    () => question === 'transition_to_regular',
    [question]
  );
  const buttonText = useMemo(() => {
    if (question === 'transition_quiz_completion')
      return t('ui.summary_page.cta_button');
    if (isTransitionToPrenatal)
      return t('ui.no_thanks.button');
    return t('questions.continue');
  }, [isTransitionToPrenatal, question, t]);
  const {keyPressed: isEnterPress, eTarget} = useKeyPress('Enter');

  const indexOfPrenatalReproductiveStatus = useMemo(
    () =>
      Object.values(prenatalPagesObj || {}).findIndex(
        ({ question }) => question === 'prenatal_reproductive_status'
      ),
    [prenatalPagesObj]
  );

  const handleClick = useCallback(async () => {
    const { isRegularType, isKidsType, isPrenatalType } = getMeologyType();

    // only can click once
    if (isClickedRef?.current) return;
    if (setIsClickedRef) setIsClickedRef(true);

    setPreSubmitCheck(true);

    // Regular
    if (isRegularType) {
      if (question === 'transition_quiz_completion') {
        if (isUserLoggedin) {
          dispatch(updateAnswerAction('opt_in', optIn));
        }
        const eventAction = 'G0: get_result_page';
        const eventLabel = 'get_result_page';
        dispatch(pushInteractionToDataLayer({
          eventCategory: 'Meology Quiz',
          eventAction,
          eventLabel,
        }));
        history.push({
          ...location,
          pathname: '/email',
          state: { canRefresh: true }
        });
      } else {
        let questionValue = pageQuestion[question];
        const selAnswer = { ...pageQuestion };
        if (question === 'dietary_restrictions') {
          await updateAnswer('dietary_preferences', [], pageId, true);
          delete selAnswer.dietary_preferences;
        }
        if (question === 'health_goals') {
          await updateAnswer('health_goals_priority', [], pageId, true);
          delete selAnswer.health_goals_priority;
        }
  
        // remove empty value
        if (question === 'lab_results') {
          questionValue = _.pickBy(
            questionValue,
            ({ value }) => value !== undefined
          );
        }
  
        const obj = {
          question,
          questionValue,
          setNextPage,
          updateAns: updateAnswerAction,
          pageId,
          rank,
          shakleeId,
          dispatch,
        };
        nextBtnClickIndividual(obj);
      }
    }

    // Kids
    if (isKidsType) {
      const isError = await ifHasError();
      if (isError) {
        if (setIsClickedRef) setIsClickedRef(false)
        return
      };
      dispatch(updateAnswerAction(question, pageQuestion[question], pageId));
      history.push({
        ...location,
        pathname: '/email',
        state: { canRefresh: true }
      });
    }
    
    // Prenatal
    if (isPrenatalType) {
      if (pageId === pageLength) {
        history.push({
          ...location,
          pathname: '/email',
          state: { canRefresh: true }
        });
        dispatch(updateAnswerAction(question, pageQuestion[question], pageId));
      } else {
        nextBtnClickIndividual({
          question,
          questionValue: pageQuestion[question],
          setNextPage,
          updateAns: updateAnswerAction,
          pageId,
          rank,
          shakleeId,
          dispatch,
        })
      }
    }
  }, [isClickedRef, setIsClickedRef, setPreSubmitCheck, question, isUserLoggedin, dispatch, history, location, optIn, pageQuestion, setNextPage, pageId, rank, shakleeId, updateAnswer, ifHasError, pageLength]);

  // Determine if the "Enter" event come from the next button or question input
  const isNextOrInputTarget = (question) => {
    if (!eTarget) {
      return;
    }
    const className = eTarget.className;
    const isInputTarget = eTarget.tagName.toLowerCase() === 'input';
    if (isInputTarget) { 
      return  eTarget.name ===question;
    }

    return !!className.includes("btn-next");
  };

  useEffect(() => {
    (async function () {

      if (
        isEnterPress &&
        isNextOrInputTarget(question) && // add rule that just excult code when enter targets are inout and nex button
        (isInput(question) ||
          isKidsQuestion(question) ||
          (isUserLoggedin && isQuizCompletion(question) && optIn === true))
      ) {
        const isError = await ifHasError();
        if (isError) return;
        btnRef.current.focus();
        btnRef.current.style.display = 'none'; // fix btn blink
        handleClick();
      }
    })();
  }, [isEnterPress, eTarget]); // eslint-disable-line react-hooks/exhaustive-deps

  // componentWillUnmount
  useEffect(() => () => {
    if (isKidsQuestion(question)) {
      dispatch(removeValidateQuestion({ question }));
    }
  }, [dispatch, question])

  const hexColor = t('ui.background_color.' + pageCategory);
  const rgbColor = hexToRgb(hexColor).join(',');
  const bgColor = `linear-gradient(to top, ${hexColor}, 87%, rgba(${rgbColor}, 0) 100%)`;

  const [isScroll, setIsScroll] = useState(false);

  const nextButtonClass = useMemo(() => {
    const result = ['btn-next'];
    if (isScroll) result.push('is-scroll');
    if (disabled || isClickedRef?.current) result.push('disabled-btn');
    return result.join(' ');
  }, [disabled, isClickedRef, isScroll])

  const skipButtonClass = useMemo(() => {
    const result = ['btn', 'btn-skip'];
    if (isScroll) result.push('is-scroll');
    if (showSkip) result.push('show-skip');
    return result.join(' ');
  }, [isScroll, showSkip])

  const { error, error_message } = useMemo(
    () => getError(question, isValidQuestion),
    [isValidQuestion, question]
  );

  const handleScroll = () => {
    if (!isScroll) {
      setIsScroll(true);
    }
  };

  const handleScrollStop = () => {
    if (isScroll) {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    return () => {
      if (setIsClickedRef) setIsClickedRef(false);
    };
  }, [setIsClickedRef]);
  return (
    <Row
      className="button-container"
      style={{
        background: bgColor
      }}
      ref={btnNextContainer}>
      <div className="question-buttons">
        {isTransitionToPrenatal && (
          <button className="btn-white" type="button" aria-label="go to prenatal button" onClick={() => {
            history.push({
              ...location,
              pathname: '/meologyprenatal',
            });
          }}>
            {t('ui.to_meology_prenatal.button')}
          </button>
        )}
        {isTransitionToWrong && (
          <button
            className="btn-white"
            type="button"
            aria-label="go to prenatal button"
            onClick={() => {
              // Go back to the question page of Prenatal Reproductive Status
              if (indexOfPrenatalReproductiveStatus > 0) {
                dispatch(setCurrentPage(indexOfPrenatalReproductiveStatus + 1));
                deleteQuestionFromList('prenatal_reproductive_status');
              }

              // Google Analysis
              const eventCategory= getEventCategory(question);
              const eventAction= getEventAction(question);
              const eventLabel= getEventLabel(question);

              dispatch(
                pushInteractionToDataLayer({
                  eventCategory,
                  eventAction,
                  eventLabel,
                })
              );

              history.push({
                ...location,
                pathname: '/',
              });
            }}
          >
            {t('meology_21.promo.more')}
          </button>
        )}
        {
          isTransitionToRegular && (!isImpersonation || isSponsor) && (
            <button className="btn-white" type="button" aria-label="go to adult button" onClick={async () => {
              const {opt_in, email: selectedEmail, prenatal_name, fromReco} = selectedAnswers || {}
              const email = isUserLoggedin ? userEmail : selectedEmail
              if(email && fromReco) {
                const _data = {
                  prenatal_feeding: '0',
                  prenatal_due_date:'',
                  prenatal_reproductive_status: 'REGULAR',
                  opt_in,
                  email,
                  prenatal_name
                  }
                await dispatch(postUpdate(_data, country,lang))
              }
              // Google Analysis
              const eventCategory= getEventCategory(question);
              const eventAction= getEventAction(question);
              const eventLabel= getEventLabel(question);

              dispatch(
                pushInteractionToDataLayer({
                  eventCategory,
                  eventAction,
                  eventLabel,
                })
              );

              window.location.href = '/regular/assessment';
            }}>
              {t('ui.to_meology_regular.button')}
            </button>
          )
        }
        {counter < pageLength && !isTransitionToWrong && !isTransitionToRegular && (
          <div className="nextBtn-container">
            {error && error_message && question !== 'dna_id' && (
              <div className="error-message">
                <div className="arrow"></div>
                <span>{error_message}</span>
              </div>
            )}
            <button
              ref={btnRef}
              className={nextButtonClass}
              disabled={disabled || (isClickedRef && isClickedRef.current)}
              onTouchMove={handleScroll}
              onTouchEnd={handleScrollStop}
              onTouchCancel={handleScrollStop}
              onClick={handleClick}
              aria-label={buttonText}
            >
              {buttonText}
            </button>
          </div>
        )}

        {!required && !isTransitionToWrong && (
          <button
            aria-label="skip"
            className={skipButtonClass}
            onTouchMove={handleScroll}
            onTouchEnd={handleScrollStop}
            onTouchCancel={handleScrollStop}
            {...{
              [isMobile ? 'onTouchStart' : 'onClick']: () => {
                if (pageQuestion[question]) {
                  delete pageQuestion[question];
                  setAnswer({ ...pageQuestion });
                  updateAnswer(question, '', pageId, true);
                }
                setNextPage({
                  label: 'skip',
                  rank: rank,
                  shakleeId: shakleeId,
                });

                if (!isValidQuestion) return;
                if (isValidQuestion[question]) {
                  delete isValidQuestion[question];
                }

                // delete lab results error
                if (question === 'lab_results' || question === 'bp_reading') {
                  _.toPairs(isValidQuestion).length > 0 &&
                    _.toPairs(isValidQuestion).forEach(
                      ([key, value]) => {
                        if (!value) return;
                        const { belong_to } = value || {};
                        if (belong_to === question && isValidQuestion[key]) {
                          delete isValidQuestion[key];
                        }
                      }
                    );
                }

                const eventAction = getEventAction(question);
                const eventLabel = 'skip';
                dispatch(pushInteractionToDataLayer({
                  eventCategory: 'Quiz',
                  eventAction,
                  eventLabel,
                }))
              },
            }}
          >
            {t('questions.skip')}
          </button>
        )}
      </div>
    </Row>
  );
};

export default memo(NextQuestion);
