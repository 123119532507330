import React, { memo } from 'react';

import { useTranslation } from 'react-i18next';

const RecommendationsStepBanner = ({ scrollToStep2, scrollToStep3 }) => {
  const { t } = useTranslation();
  return (
    <div className="recommendations-step-banner">
      <div className="recommendations-step-banner-title">
        {t('ui.recommendation.meology_step_banner_title1')}
      </div>
      <div className="recommendations-step-banner-container">
        <div className="recommendations-step-banner-container-img">
          <img src="https://images.shaklee.com/wellness/Tracker.svg" alt="" />
        </div>
        <div className="recommendations-step-banner-container-text">
          <div className="recommendations-step-banner-container-text-item">
            <div className="logo"></div>
            <div className="step">{t('ui.recommendation.proveit_section_step')} 1</div>
            <div className="title">
              {t('ui.recommendation.meology_step_banner_title2')}
            </div>
            <div className="status">
              {t('ui.recommendation.meology_step_banner_title5')}
              <img src="https://images.shaklee.com/wellness/check.svg" alt="" />
            </div>
          </div>
          <div className="recommendations-step-banner-container-text-item">
            <div className="logo"></div>
            <div className="step">{t('ui.recommendation.proveit_section_step')} 2</div>
            <div className="title" onClick={scrollToStep2}>
              {t('ui.recommendation.meology_step_banner_title3')}
            </div>
          </div>
          <div className="recommendations-step-banner-container-text-item">
            <div className="logo"></div>
            <div className="step">{t('ui.recommendation.proveit_section_step')} 3</div>
            <div className="title" onClick={scrollToStep3}>
              {t('ui.recommendation.meology_step_banner_title4')}
            </div>
          </div>
        </div>
      </div>
      <div className="recommendations-step-banner-title hidden">
        {t('ui.recommendation.meology_step_banner_title1')}
      </div>
    </div>
  );
};

export default memo(RecommendationsStepBanner);
