import React, { memo, useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown  } from "@fortawesome/free-solid-svg-icons";

const ResultSelect = ({
  data,
  defaultValue,
  onChange,
  disabled,
  className,
  placeholder
}) => {
  const [showOption, setShowOption] = useState(false);
  const [text, setText] = useState(defaultValue);
  const selectRef = useRef(null);
  const handleClickOutside = event => {
    if (selectRef.current.contains(event.target)) {
      return;
    } else {
      setShowOption(false)
    }
  }

  useEffect(() => {
    if (!defaultValue) return;
    setText(defaultValue);
  }, [defaultValue])

  useEffect(() => {
    if (showOption) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOption])

  return (
    <div className={`result-Select${className ? ` ${className}` : ''}`} ref={selectRef}>
      <div
        role="combobox"
        aria-controls="id_combobox_00"
        aria-expanded={showOption}
        tabIndex="0"
        className="select-wrap"
        onClick={e => {
          e.nativeEvent.stopImmediatePropagation();
          if (!disabled) {
            setShowOption(!showOption)
          }
        }}
      >
        {
        text 
        ? <div className="select-text">{text}</div>
        : <div className="placeholder-text">{placeholder || ''}</div>
        } 
        <FontAwesomeIcon icon={faChevronDown} className={showOption ? 'iconUp' : 'iconDown'}/>
      </div>
      <div id='id_combobox_00' role="listbox" className={'option-wrap'} style={showOption ? {position: 'absolute'} : {display: 'none'}}>
        {data &&
          (data.map((item, index) => {
            let optionClassName = '';
            if (text === item.name) {
              optionClassName = 'option-item selected';
            } else {
              optionClassName = 'option-item';
            }
            
            return (
              item.name && (
                <div
                  onClick={e => {
                    e.nativeEvent.stopImmediatePropagation();
                    if (onChange) {
                      onChange(item);
                    }
                    setText(item.name);
                    setShowOption(!showOption);
                  }}
                  className={optionClassName}
                  key={index}
                >
                  {item.name}
                </div>
              )
            );
          }))
        }
      </div>
    </div>
  );
};

export default memo(ResultSelect);
