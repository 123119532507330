import React, { memo, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const InstructionsModal = ({
  text,
  title,
  isOpen,
  closeModal,
  content,
  comeFrom,
}) => {
  const { t } = useTranslation();
  const isDueDate = useMemo(() => content === 'prenatal_due_date', [content]);
  return (
    <>
      <Modal
        show={isOpen}
        onHide={closeModal}
        className={cx('instructions-modal', {
          [content]: content,
          [comeFrom]: comeFrom,
        })}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-container">
            <div className="modal-container-content">
              <div className="modal-body-title">{title}</div>
              <div
                className="modal-body-text"
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
              {isDueDate && (
                <ol className="due-date-step">
                  <li>
                    <div className="due-date-step-img">
                      <img src="https://images.shaklee.com/meology/prenatal/icon_drop.svg" alt="" />
                    </div>
                    <p>{t(`questions.${content}.step1.text`)}</p>
                  </li>
                  <li>
                    <div className="due-date-step-img">
                      <img src="https://images.shaklee.com/meology/prenatal/3_calendar_month.svg" alt="" />
                    </div>
                    <p>{t(`questions.${content}.step2.text`)}</p>
                  </li>
                  <li>
                    <div className="due-date-step-img">
                      <img src="https://images.shaklee.com/meology/prenatal/1_year_7_days.svg" alt="" />
                    </div>
                    <p>{t(`questions.${content}.step3.text`)}</p>
                  </li>
                </ol>
              )}
            </div>
            {!isDueDate && (
              <div>
                <button
                  className="instructions-modal-back"
                  onClick={closeModal}
                >
                  {t('questions.back')}
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(InstructionsModal);
