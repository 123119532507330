import React, {useCallback, useState} from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import RecommendationModal from "./RecommendationModal";
import AnimateCheckbox from "../../questions/widgets/AnimateCheckbox";
import { updateAssessment } from "../../questions/QuestionActions";
import { getMeologyType, resetQuiz, getCurrentEventCategory } from 'assets/js/helper';
import { useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions'
import cx from "classnames";

const RetakeOptin = ({  opt_in, meologyType, isMeologyKids, isView, isImpersonation }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [optIn, setOptin] = useState(opt_in);
	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => setShowModal(false);
	const [showOptinModal, setShowOptinModal] = useState(false);
	const { isUserLoggedin } = useSelector(state => state.user);
	const location = useLocation();
	const { history } = useSelector(state => state.QuestionReducer);
	const eventCategory = getCurrentEventCategory();
	const returnQuiz = () => {
		const { type } = getMeologyType()
		resetQuiz({ location, isUserLoggedin, type });

		// analytics
		dispatch(
			pushInteractionToDataLayer({
				eventCategory,
				eventAction: "Retake Assessment",
				eventLabel: 'result page modal',
			})
		);
	};

	const updateOptin = () => {
		dispatch(updateAssessment(optIn, history));
		handleCloseModal();
		// analytics
		dispatch(
			pushInteractionToDataLayer({
				eventCategory,
				eventAction: "Optin Assessment",
				eventLabel: 'result page modal',
			})
		);
	};

	const handleCloseOptinModal = () => {
		setOptin(opt_in);
		setShowOptinModal(false)
	};

	const handleChange = useCallback((value) => {
		setOptin(value);
	}, []);

	return (
		<>
			{!isMeologyKids && !isView && (
				<div className="restart-section font-gilmer-regular">
					{!isImpersonation && (
					<button
						className="btn btn-link restart"
						onClick={() => {
							setShowModal(true)

							// Google Analysis
							dispatch(
								pushInteractionToDataLayer({
									eventCategory,
									eventAction: 'Retake Assessment',
									eventLabel: 'Reco Page',
								})
							);
						}}>
						<img className="icon" src="https://images.shaklee.com/meology/icons/arrow-counterclockwise.svg" alt="restart" />
						{t('ui.recommendation.restart')}
					</button>
					)}
					<button
						className="btn btn-link optin"
						onClick={() => {
							setShowOptinModal(true);

							// Google Analysis
							dispatch(
								pushInteractionToDataLayer({
									eventCategory,
									eventAction: 'Privacy Setting',
									eventLabel: 'Reco Page',
								})
							);
						}}>
						<img className="icon" src="https://images.shaklee.com/meology/icons/sliders.svg" alt="optin" />
						{t('ui.recommendation.privacy_setting')}
					</button>
				</div>
			)}

			<RecommendationModal
				show={showModal}
				onHide={handleCloseModal}
				title={t('ui.recommendation.assessment_header')}
				body={t('ui.recommendation.assessment_sub_header')}
				btnInfo={[
					{
						name: t('ui.recommendation.assessment_retake_btn'),
						event: returnQuiz
					},
					{ name: t('ui.summary_page.cancel'), event: handleCloseModal }
				]}
				className={cx('retake')}
			/>

			<RecommendationModal
				show={showOptinModal}
				onHide={handleCloseOptinModal}
				title={t('ui.recommendation.privacy_setting')}
				body={<AnimateCheckbox initValue={optIn}
				                       onChange={handleChange}
				                       meologyType={meologyType}/> }
				btnInfo={[
					{
						name: t('ui.recommendation.meology_kids_edit_pop_up_title9'),
						event: updateOptin
					}
				]}
				className={cx('optin')}
			/>
		</>
	);
};

export default withTranslation()(RetakeOptin);
