/* eslint-disable array-callback-return */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PROVELT_PRODUCT_ATTRIBUTE } from 'components/results/Recommendations/RecommendationsConstants';

const RecommendationProveltModalAttributes = ({ pill, isBoost }) => {
  const { t } = useTranslation();
  if (!pill) return;

  return (
    <div className="product-attributes-list">
      {isBoost &&
        PROVELT_PRODUCT_ATTRIBUTE[pill.code]?.map((attribute, index) => (
          <div className="product-attributes-list-item" key={index}>
            <img
              src={t(`ui.recommendation.meology_icon_${attribute}`)}
              alt={attribute}
            />
          </div>
        ))}
      {!isBoost && (
        <>
          <div className="product-attributes-list-item">
            <img
              src={t('ui.recommendation.meology_icon_kosher')}
              alt="Kosher"
            />
          </div>
          <div className="product-attributes-list-item">
            <img
              src={t('ui.recommendation.meology_icon_gluten_free')}
              alt="Gluten Free"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(RecommendationProveltModalAttributes);
