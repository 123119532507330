import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { validateQuestions } from '../QuestionActions';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import { getEventAction } from 'components/analytics/quizAnalytics';

const actions = { validateQuestions, pushInteractionToDataLayer };

class HeightDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feet: '',
      inches: '',
    };
  }
  setFeetInches () {
    const { selectedAnswer: { height } = {} } = this.props || {};
    if (!height) return;
    this.setState({
      feet: Math.floor(height / 12),
      inches: height % 12,
    })
  }
  componentDidMount () {
    this.setFeetInches();
  }
  componentDidUpdate (prevProps) {
    if (this.props.selectedAnswer === prevProps.selectedAnswer) return;
    this.setFeetInches();
  }
  pushAnalytics = (question, answer) => {
    const eventAction = getEventAction(question);
    this.props.pushInteractionToDataLayer({
      eventCategory: 'Quiz',
      eventAction,
      eventLabel: answer,
    })
  };
  fieldChange = e => {
    e.persist();
    this.props.updateAnswer(this.props.name, e.target.value, this.props.page);
  };
  fieldFeet = e => {
    e.persist();
    this.setState({ feet: e.target.text, inches: this.state.inches || 0 }, () => {
      this.conversion();
    });
  };

  clearValue = () => {
    this.setState({ feet: '', inches: ''}, () => {
      this.conversion();
    });
  }

  fieldInches = e => {
    e.persist();
    this.setState({ inches: e.target.text, feet: this.state.feet || 3 }, () => {
      this.conversion();
    });
  };
  conversion = () => {
    const height =
      parseInt(this.state.feet * 12, 10) + parseInt(this.state.inches, 10);
    this.props.updateAnswer(this.props.name, height, this.props.page);
    let currentValue = _.cloneDeep(this.props.selectedAnswer);
    if (!currentValue) {
      currentValue = {};
    }
    currentValue.height = height;
    this.props.validateQuestions(this.props.parent, currentValue, false, this.props.t);

    // click analytics
    // this.pushAnalytics(this.props.name, height);
  };
  render() {
    const { t } = this.props;
    const feetInches = [3, 4, 5, 6, 7, 8, 9, 10, 11];
    const heightInches = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    return (
      <Row className="answer">
        <Dropdown>
          <Dropdown.Toggle id="feet-dropdown">
            {this.state.feet === '' ? t('questions.height.feet.placeholder') : this.state.feet + ' ft'}
             </Dropdown.Toggle>
          <Dropdown.Menu>
            {feetInches.map(inch => (
              <Dropdown.Item key={inch} onClick={this.fieldFeet}>
                {inch}
              </Dropdown.Item>
            ))}
            {this.state.feet !== '' && (
              <Dropdown.Item onClick={this.clearValue}>{t('questions.height.feet.placeholder')}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle id="inches-dropdown">
            {this.state.inches === ''
              ? t('questions.height.inches.placeholder')
              : ((this.state.inches === 1)
		            ? this.state.inches + ' ' + t('questions.inch')
		            : this.state.inches + ' ' + t('questions.height_inches.placeholder'))}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {heightInches.map(inch => (
              <Dropdown.Item key={inch} onClick={this.fieldInches}>
                {inch}
              </Dropdown.Item>
            ))}
            {this.state.inches !== '' && (
              <Dropdown.Item onClick={this.clearValue}>{t('questions.height.inches.placeholder')}</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Row>
    );
  }
}

export default withTranslation()(connect(null, actions)(HeightDropdown));
