import React, { useContext } from 'react';
import CtaBtn from 'components/common/CtaBtn';
import { MarketingContext } from 'components/Marketing/Marketing';

const FooterBanner = () => {
  const {
    t,
    isChanged,
    isMarketing,
    kidsLandingPage,
  } = useContext(MarketingContext);
  return (
    <>
      <div 
        className="yummy-container"
        tabIndex="0"
        role="img"
        aria-label="100% Yummy = 100% Nourished"
      >
        <div className="yummy">
          <h2 className="title">
            {t('ui.marketing.overview_of_each_gummy_title2')}
          </h2>
          <div className="sub_title">
	          <p dangerouslySetInnerHTML={{ __html: t('ui.marketing.overview_of_each_gummy_title3') }}></p>
          </div>
          <div className="img-container">
            <img src={isChanged ? t('ui.marketing.yummy-gummy-mobile') : t('ui.marketing.yummy-gummy')} alt="mobile-100-yummy" />
            {isMarketing && (
               <div className="cta-button-wrap cta-button-wrap-kids cta-button-wrap3">
                  <CtaBtn
                    type="kids"
                    status={kidsLandingPage}
                    eventLabel="marketing page cta section"
                  />
              </div>
            )}
          </div>
        </div>
      </div>
      <div 
        className="stack-container"
        tabIndex="0"
        role="img"
        aria-label="Superior Nutrition"
      >
        <div className="stack">
          <h2 className="title">
            {t('ui.marketing.comparison_chart_title2')}
          </h2>
          <div className="sub_title">
            {t('ui.marketing.comparison_chart_title3')}
          </div>
          <div className="img-container">
            <img src={isChanged ? t('ui.marketing.how-stack-up-mobile') : t('ui.marketing.how-stack-up')} alt="How-We-Stack-Up" />
          </div>
          <div className="disclaimer">
	          <p>{t('ui.marketing.comparison_chart_title4')}</p>
          </div>
        </div>
      </div>
      <div className="fun-container">
	      {/*<div className="all fun">*/}
		    {/*    <div className="title">{t('ui.marketing.comparison_chart_title6')}</div>*/}
				{/*		<ul className="list">*/}
				{/*			<li>{t('ui.marketing.comparison_chart_title7')}</li>*/}
				{/*			<li>{t('ui.marketing.comparison_chart_title8')}</li>*/}
				{/*			<li>{t('ui.marketing.comparison_chart_title9')}</li>*/}
				{/*		</ul>*/}
	      {/*</div>*/}
	      {/*<div className="all fuss">*/}
		    {/*  <div className="title">{t('ui.marketing.comparison_chart_title6-1')}</div>*/}
		    {/*  <ul className="list">*/}
			  {/*    <li>{t('ui.marketing.comparison_chart_title7-1')}</li>*/}
			  {/*    <li>{t('ui.marketing.comparison_chart_title8-1')}</li>*/}
			  {/*    <li>{t('ui.marketing.comparison_chart_title9-1')}</li>*/}
		    {/*  </ul>*/}
	      {/*</div>*/}
	      <img src={isChanged ? t('ui.marketing.fun-fuss-mobile') : t('ui.marketing.fun-fuss')} alt="all-in-one-superior-nutrition" className="bg" />
      </div>
    </>
  );
};

export default FooterBanner;
