import {
  RECOMMENDATION_POST,
  PRODUCT_INFO,
  CONCEPTION_PRODUCT_INFO,
  POSTNATAL_PRODUCT_INFO,
  PREGNANT_PRODUCT_INFO,
  RECOMMENDATION_API_POST_URL,
  SET_ADD_TO_CART_RESULT,
  PRODUCT_CODES,
  KIDS_SACHET_SKU_US,
  HYBRIS_PRODUCT_INFO,
  PRENATAL_STEP,
  SET_REPRODUCTIVE_STATUS,
  PRENATAL_TRYING_SACHET_SKU_US,
  PRENATAL_PREGNANT_SACHET_SKU_US,
  PRENATAL_POSTPARTUM_SACHET_SKU_US,
  HYBRIS_CORE_PRODUCT_INFO,
  PRODUCT_LIFESHAKE_INFO,
  PRODUCT_BOOST_INFO,
  LIFE_SHAKE_PLANT,
  LIFE_SHAKE_SOY,
} from './RecommendationsConstants';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from '../../async/asyncActions';
import {pushToDataLayer} from "../../analytics/analyticsActions";
import { getMeologyType, isEmpty, post } from 'assets/js/helper';

const getAddToCartResult = postData => {
  return post({
    url: '/services/cart',
    postData,
  });
};

// get product information
export const getProductsInfo = async (country, language, productcodes) => {
  if (isEmpty(productcodes)) return;
  const getUrl = `/products?country=${country}&language=${language}&fields=FULL&product_codes=${productcodes}`;
  const result = await fetch(getUrl)
    .then(response => {
      return response.json();
    })
    .then(record => {
      return record;
    })
    .catch(err => {
      throw err;
    });
  let obj = {};
  if (result && result.length > 0) {
    result.forEach(elem => {
      obj[elem.sku] = elem;
    });
  }
  return obj;
};

export const getProductsProveltInfo = async (country, language, productcodes) => {
  if (isEmpty(productcodes)) return;
  const getUrl = `/products?country=${country}&language=${language}&fields=FULL&product_codes=${productcodes}`;
  const result = await fetch(getUrl)
    .then(response => {
      return response.json();
    })
    .then(record => {
      return record;
    })
    .catch(err => {
      throw err;
    });
  return result;
};

// get product code from recommendation data
const getProductCode = data => {
  let productCode = [];
  for (let k in data) {
    const tier = data[k];
    for (let j in tier) {
      const item = tier[j];
      productCode.push(item.code)
    }
  }
  // remove duplicate data
  const result = [...new Set(productCode)];
  return result;
};

// get product code from recommendation data
export const getProveltCode = data => {
  let productCode = [];
  if (data) {
    for (let item of data) {
      productCode.push(item.code?.split("_")[1])
    }
  }
  // remove duplicate data
  const result = [...new Set(productCode)];
  return result;
};

// merge product info into recommendation data
const mergeProduct = (data, info) => {
  if (!data) return data;

  for (let k in data) {
    const tier = data[k];
    for (let j in tier) {
      const item = tier[j];
      if (info && info[item.code]) {
        data[k][j] = { ...data[k][j], ...info[item.code] };
      } else {
        delete data[k][j]
      }
    }
    // filter empty element
    data[k] = data[k].filter(Boolean)
  }
  return data;
};

// container to get recommendation data and manipulate it by getting product codes, product info, merging product info
// moved code into different smaller functions
// removed hard coded tier1, tier2... and made it useful for all other key value pairs.Which will take care of other tiers
// such as TIER_1, TIER_2, TIER_2_OPTIONAL, TIER_1_OPTIONAL, TIER_1_PROVEIT, TIER_2_PROVEIT, ADD_ONS
export const getRecommendation = ({ selectedAnswers, country, lang, rank, shakleeId, health_profile_id, showSoyLifeShake }) => {
  return async dispatch => {
    try {
      const { isKidsType, isPrenatalType } = getMeologyType();
      dispatch(asyncActionStart());
      // Step 1: get recommendation data
      const recommendationData = await post({
        url: RECOMMENDATION_API_POST_URL,
        postData: {...selectedAnswers, health_profile_id, country_code: country, language: lang},
      });
      if (recommendationData.status !== 0) {
        window.location.href = '/';
        return;
      }
      // Step 2: get products code
      let productCode = getProductCode(recommendationData.recommendations);
      if (isKidsType) {
        productCode.push(KIDS_SACHET_SKU_US)
      }
      dispatch({
        type: PRODUCT_CODES,
        payload: productCode,
      });
      // Step 3: get products info
      const coreProductCodes = [
        PRENATAL_TRYING_SACHET_SKU_US,
        PRENATAL_PREGNANT_SACHET_SKU_US,
        PRENATAL_POSTPARTUM_SACHET_SKU_US
      ]

      const showSoy = isPrenatalType && showSoyLifeShake && productCode.includes(LIFE_SHAKE_PLANT)
      if (isPrenatalType) {
        productCode = productCode.concat(coreProductCodes);
        // add soy life shake
        if(showSoy) {
          productCode.push(LIFE_SHAKE_SOY[0])
        }
      }
      const productcodes = productCode.join(',');
      const allProductInfo = await getProductsInfo(
        country,
        lang,
        productcodes
      );
      const getProducts = (_data) => _data?.reduce((_obj, _key)=>{
        return {..._obj, [_key]: allProductInfo[_key]}
      },{})
      if(showSoy) {
        const soy = allProductInfo?.[LIFE_SHAKE_SOY[0]]?.baseOptions?.[0]?.options?.filter(t => LIFE_SHAKE_SOY.includes(t.code))
        if(soy?.length > 0) {
          // eslint-disable-next-line no-unused-expressions
          allProductInfo?.[LIFE_SHAKE_PLANT]?.baseOptions?.[0]?.options?.push(...soy)
        }
      }
      const coreProducts = getProducts(coreProductCodes)
      dispatch({ type: HYBRIS_CORE_PRODUCT_INFO, coreProducts });
      const productsInfo = getProducts(productCode)
      dispatch({ type: HYBRIS_PRODUCT_INFO, productsInfo });
      dispatch({
        type: PRODUCT_INFO,
        payload: recommendationData.product_info,
      });
      dispatch({
        type: POSTNATAL_PRODUCT_INFO,
        payload: recommendationData.postnatal_product_info,
      });
      dispatch({
        type: PREGNANT_PRODUCT_INFO,
        payload: recommendationData.pregnant_product_info,
      });
      dispatch({
        type: CONCEPTION_PRODUCT_INFO,
        payload: recommendationData.conception_product_info,
      });
      // Step 4: merge product info into recommendation data
      const recommendation = mergeProduct(
        recommendationData.recommendations,
        productsInfo
      );
      recommendationData.recommendations = recommendation;
      dispatch({
        type: RECOMMENDATION_POST,
        payload: recommendationData,
      });
      dispatch(asyncActionFinish());
      dispatch(
          pushToDataLayer({
            label: 'reco',
            rank: rank,
            shakleeId: shakleeId,
          })
      );
    } catch (error) {
      dispatch(asyncActionError(error.message));
    }
  };
};

export const getRecommendationProvelt = ({ country, lang, options1, options2}) => {
  return async dispatch => {
    try {
      dispatch(asyncActionStart());

      const lifeShakeCode = getProveltCode(options1);
      const boostCode = getProveltCode(options2);

      const productProveltcodes = [...lifeShakeCode,...boostCode]?.join(',');
      const productsProveltInfo = await getProductsProveltInfo(
        country,
        lang,
        productProveltcodes
      );

      dispatch({
        type: PRODUCT_LIFESHAKE_INFO,
        payload: productsProveltInfo.filter(item => lifeShakeCode.includes(item.code)),
      });
      dispatch({
        type: PRODUCT_BOOST_INFO,
        payload: productsProveltInfo.filter(item => boostCode.includes(item.code)),
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error.message));
    }
  };
};

export const setaddToCartResult = data => ({
  type: SET_ADD_TO_CART_RESULT,
  data,
});

export const setPrenatalStep = data => ({
  type: PRENATAL_STEP,
  data,
});


export const fetchAddToCart = (postData) => async dispatch => {
  try {
    const addToCartResult = await getAddToCartResult(postData);
    dispatch(setaddToCartResult(addToCartResult));
  } catch (error) {
    dispatch(asyncActionError(error.message));
  }
};

export const changePrenatalStep = data => dispatch => {
  try {
    dispatch(setPrenatalStep(data));
  } catch (error) {
    dispatch(asyncActionError(error.message));
  }
};

export const setReproductiveStatus = data => ({
  type: SET_REPRODUCTIVE_STATUS,
  data,
})
