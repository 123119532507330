import React, { memo, useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Dropdown } from 'react-bootstrap';
import _ from 'lodash';

import { getObjectKeyByValue, getUnit } from 'assets/js/helper';
import { validateQuestions } from 'components/questions/QuestionActions';

const UnitDropdown = ({
  name,
  options,
  option,
  selectedAnswer,
  updateAnswer,
  isMobile,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unit = getUnit(option);
  const unitKeys = Object.keys(unit);
  const unitValues = Object.values(unit);
  const { unit: unitValue, value } =
    (options.length > 1
      ? selectedAnswer && selectedAnswer[option]
      : selectedAnswer) || {};
  const unitKey = getObjectKeyByValue(unitValue, unit);
  const [currentUnit, setCurrentUnit] = useState(unitKey);
    
  useEffect(() => {
    if (unitKey) {
      setCurrentUnit(unitKey);
    } else {
      setCurrentUnit('us');
    }
  }, [unitKey]);

  const currentText = useMemo(
    () =>
      currentUnit
        ? name === 'core_measurement'
          ? t(`questions.${name}.unit.${currentUnit}${isMobile ? '_mobile' : ''}`)
          : unit[currentUnit]
        : t(`questions.${name}.unit.placeholder`),
    [currentUnit, name, t, unit, isMobile]
  );

  const handleClick = useCallback(
    (key, index) => {
      setCurrentUnit(key);
      let currentValue = _.cloneDeep(selectedAnswer);
      if (!currentValue) {
        currentValue = {};
      }
      if (options.length > 1) {
        if (!currentValue[option]) {
          currentValue[option] = {};
        }
        currentValue[option].unit = unitValues[index];
      } else {
        currentValue.unit = unitValues[index];
      }

      updateAnswer(name, currentValue);
      if (value) {
        dispatch(validateQuestions(name, currentValue, false, t, option));
      }
    },
    [
      dispatch,
      name,
      option,
      options,
      selectedAnswer,
      t,
      unitValues,
      updateAnswer,
      value,
    ]
  );

  return (
    <Row className="answer">
      {unitKeys.length > 1 && (
        <Dropdown>
          <Dropdown.Toggle
            className={currentUnit ? 'has-unit' : ''}
            id="unit-dropdown"
          >
            {currentText}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {unitKeys.map((key, index) => (
              <Dropdown.Item key={key} onClick={() => handleClick(key, index)}>
                {name === 'core_measurement'
                  ? t(`questions.${name}.unit.${key}${isMobile ? '_mobile' : ''}`)
                  : unitValues[index]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Row>
  );
};

export default memo(UnitDropdown);
