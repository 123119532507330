import {
    GET_USER_PROFILE,
    UPDATE_COUNTRY,
    UPDATE_LANGUAGE,
    UPDATE_PRICE_TIER,
  } from '../userProfile/userConstants';
import {setLocaleCookie} from "../../assets/js/helper";

export const getUserProfile = () => {
    return {
        type: GET_USER_PROFILE
    };
}
export const updateCountry = (data) => {
    return async dispatch => {
        setLocaleCookie(data);
        dispatch({
            type: UPDATE_COUNTRY,
            data
        });
    };
}
export const updateLanguage = (data) => {
    return async dispatch => {
        setLocaleCookie(data);
        dispatch({
            type: UPDATE_LANGUAGE,
            data
        });
    };
}

export const updatePriceTier = priceTier => ({
    type: UPDATE_PRICE_TIER,
    priceTier,
})
