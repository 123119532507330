import React, { memo } from 'react';

const PillItem = ({ title, content, tip, imgUrl, icons, imgUrlMo, iconClassNames }) => {
  return (
    <div className="mkt-pill-item-container">
      <div className="pill-img">
        {imgUrl &&<>
          <img src={imgUrl} alt=""></img>
          <img src={imgUrlMo || imgUrl} alt=""></img>
        </> }
      </div>
      <div className="pill-text">
        {title && <h3 dangerouslySetInnerHTML={{
            __html: title
          }}></h3>}
        {content && <p dangerouslySetInnerHTML={{
            __html: content
          }}></p>}
        {tip && <span dangerouslySetInnerHTML={{
            __html: tip
          }}></span>}
        {icons?.length > 0 && (
          <div className="pill-icons">
            {icons.map((ic, index) => (
              <i
                key={index}
                className={iconClassNames?.[index] || ''}
                style={{
                  backgroundImage: `url('${ic}')`,
                }}
              ></i>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(PillItem);
