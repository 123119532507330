import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

const mapState = state => {
  return {
    user: state.user
  };
};

const actions = {};

function Logout(props) {
  const signoutTime = 60 * 60 * 24;
  const warningTime = 5000;
  const [sessionTimeOut, setSessionTimeOut] = useState(false);
  const { isUserLoggedin } = props.user;
  useEffect(() => {
    if (!isUserLoggedin) return;
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];
    let counter = 0;
    const resetCounter = () => {
      counter = 0;
    };
    for (let i in events) {
      window.addEventListener(events[i], resetCounter);
    }

    let interval = setInterval(function() {
      counter++;
      if (counter >= signoutTime) {
        setSessionTimeOut(true);
        window.setTimeout(function() {
          window.location.href = '/Logout';
        }, warningTime);
      }
    }, 1000);

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetCounter);
      }
      window.clearInterval(interval);
    };
  }, [isUserLoggedin, signoutTime]);

  return (
    <Modal show={sessionTimeOut}>
      <Modal.Body>
        Since the session is expired you are getting redirected to login page!
      </Modal.Body>
    </Modal>
  );
}

export default connect(mapState, actions)(Logout);
