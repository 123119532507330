import React, { memo } from 'react';
import cx from 'classnames';
import { useMeologyHistory } from 'assets/js/hooks';
import { isEmpty } from 'assets/js/helper';

const AutoShipInfo = ({
  showNextDate = false,
  handleAutoCart,
  setTipsOpen,
  dropdown = false,
}) => {
  const { autoshipInfos } = useMeologyHistory();

  if (isEmpty(autoshipInfos)) return null;

  return (
    <ul
      className={cx({
        'dropdown-menu': dropdown,
      })}
    >
      {autoshipInfos?.map(
        (
          {
            code,
            nextProcessingDate,
            autoshipName,
            frequency,
            firstName,
            lastName,
            line1,
            line2,
            address,
          },
          index
        ) => (
          <li
            className="autoship-card-pdp"
            key={index}
            tabIndex="0"
            role="button"
            onClick={() => {
              if (dropdown) {
                setTipsOpen(false);
                handleAutoCart(code);
              }
            }}
          >
            <div className="autoship-details">
              {showNextDate && nextProcessingDate && (
                <p>Next Processing Date: {nextProcessingDate}</p>
              )}
              {autoshipName && <div>{autoshipName}</div>}
              {frequency && <div>Deliver {frequency}</div>}
              {(firstName || lastName) && (
                <div>{firstName + ' ' + lastName}</div>
              )}
              {line1 && <div>{line1}</div>}
              {line2 && <div>{line2}</div>}
              {address && <div>{address}</div>}
            </div>
          </li>
        )
      )}
    </ul>
  );
};
export default memo(AutoShipInfo);
