import _ from 'lodash';
import { getMeologyType } from 'assets/js/helper';

const quizGaMap = {
  name: {
    category: "Meology Quiz",
    questionTag: 'name',
    orderId: 'A0',
  },
  transition_basics: {
    category: "Meology Quiz",
    questionTag: 'general_section_start',
    orderId: 'B0',
  },
  gender: {
    category: "Meology Quiz",
    questionTag: 'gender',
    answerTag: {
      F: 'female',
      M: 'male',
    },
    orderId: 'B01',
  },
  age: {
    category: "Meology Quiz",
    questionTag: 'age',
    orderId: 'B02',
  },
  reproductive_status: {
    category: "Meology Quiz",
    questionTag: 'pregnancy',
    answerTag: {
      PREGNANT: 'pregnant',
      TRYING: 'trying_pregnant',
      FEEDING: 'breastfeeding',
      PMS: 'pms',
      NONE: 'no',
    },
    orderId: 'Op_B02a',
  },
  reproductive_problems: {
    category: "Meology Quiz",
    questionTag: 'pms_or_menopause',
    answerTag: {
      PMS: 'pms',
      MENOPAUSE: 'menopause',
      NONE: 'no',
    },
    orderId: 'Op_B02b',
  },
  health_goals: {
    category: "Meology Quiz",
    questionTag: 'top3goals',
    answerTag: {
      WEIGHT: 'weight',
      IMMUNE: 'immunity',
      FITNESS: 'fitness',
      BRAIN: 'brain',
      DIGESTIVE: 'digestion',
      AGING: 'aging',
      HEART: 'heart',
      JOINT: 'joints',
      ENERGY: 'energetic',
      STRESS: 'stress',
      SLEEP: 'sleep',
    },
    orderId: 'B03',
  },
  health_goals_priority: {
    category: "Meology Quiz",
    questionTag: 'top1goal',
    answerTag: {
      WEIGHT: 'weight',
      IMMUNE: 'immunity',
      FITNESS: 'fitness',
      BRAIN: 'brain',
      DIGESTIVE: 'digestion',
      AGING: 'aging',
      HEART: 'heart',
      JOINT: 'joints',
      ENERGY: 'energetic',
      STRESS: 'stress',
      SLEEP: 'sleep',
    },
    orderId: 'B04',
  },
  transition_life_style: {
    category: "Meology Quiz",
    questionTag: 'active_level_section_start',
    orderId: 'C0',
  },
  exercise_frequency: {
    category: "Meology Quiz",
    questionTag: 'exercise_frequency',
    answerTag: {
      0: 'little_or_no_exercise',
      1: '1to2days',
      2: '3to5days',
      3: '6to7days',
    },
    orderId: 'C01',
  },
  exercise_type: {
    category: "Meology Quiz",
    questionTag: 'exercise_type',
    answerTag: {
      CARDIO: 'cardio',
      STRENGTH: 'strength',
      FLEXIBILITY: 'flexibility',
      OTHER: 'other',
    },
    orderId: 'Op_C01a',
  },
  exercise_nutrition: {
    category: "Meology Quiz",
    questionTag: 'nutrition_exercise',
    answerTag: {
      PERFORMANCE: 'endurance',
      MUSCLE: 'muscle',
      RECOVERY: 'recover',
      HYDRATION: 'hydrated',
      OTHER: 'other',
    },
    orderId: 'Op_C01b',
  },
  exercise_work: {
    category: "Meology Quiz",
    questionTag: 'daily_movement',
    answerTag: {
      0: 'sit',
      1: 'stand_walk_some',
      2: 'stand_walk_mostly',
    },
    orderId: 'C02',
  },
  energy: {
    category: "Meology Quiz",
    questionTag: 'energy_level',
    answerTag: {
      1: 'enough',
      2: 'need_a_bit_more',
      3: 'tired',
    },
    orderId: 'C03',
  },
  stress: {
    category: "Meology Quiz",
    questionTag: 'stress_level',
    answerTag: {
      0: 'low',
      1: 'okay',
      2: 'high',
    },
    orderId: 'C04',
  },
  sleep: {
    category: "Meology Quiz",
    questionTag: 'sleep_quality',
    answerTag: {
      SLEEP_WELL: 'great',
      SLEEP_OK: 'not_enough',
      TROUBLE_FALLING_ASLEEP: 'trouble_falling_asleep',
      TROUBLE_STAYING_ASLEEP: 'trouble_staying_asleep',
    },
    orderId: 'C05',
  },
  memory: {
    category: "Meology Quiz",
    questionTag: 'brain_health',
    answerTag: {
      EXCELLENT: 'excellent',
      GOOD: 'worry_changing',
      OK: 'trouble_focusing',
      NOT_BAD: 'trouble_remembering',
      GETTING_WORSE: 'getting_worse',
    },
    orderId: 'C06',
  },
  alcohol_frequency: {
    category: "Meology Quiz",
    questionTag: 'alcoholic_level',
    answerTag: {
      NEVER: 'never',
      MONTHLY: 'monthly_or_less',
      TWO_FOUR_MONTHLY: '2-4_monthly',
      TWO_THREE_WEEKLY: '2-3_weekly',
      FOUR_PLUS_WEEKLY: '>4_weekly',
      NOT_SAY: 'rather_not_to_say',
    },
    orderId: 'C07',
  },
  alcohol_amount: {
    category: "Meology Quiz",
    questionTag: 'alcoholic_quantity',
    answerTag: {
      ONE_TWO: '1_or_2',
      THREE: '3',
      FOUR: '4',
      FIVE_PLUS: '5+',
      NOT_SAY: 'rather_not_to_say',
    },
    orderId: 'Op_C07a',
  },
  smoking_status: {
    category: "Meology Quiz",
    questionTag: 'smoking_level',
    answerTag: {
      DAILY: 'daily',
      OCCASIONAL: 'occasional',
      FORMER: 'former',
      NEVER: 'never',
      NOT_SAY: 'rather_not_to_say',
    },
    orderId: 'C08',
  },
  sun_exposure: {
    category: "Meology Quiz",
    questionTag: 'sun_level',
    answerTag: {
      1: 'yes',
      0: 'no',
    },
    orderId: 'C09',
  },
  computer_usage: {
    category: "Meology Quiz",
    questionTag: 'screen_time',
    answerTag: {
      1: 'yes',
      0: 'no',
    },
    orderId: 'C10',
  },
  transition_dietary_patterns: {
    category: "Meology Quiz",
    questionTag: 'food_section_start',
    orderId: 'D0',
  },
  dietary_restrictions: {
    category: "Meology Quiz",
    questionTag: 'food_allergies',
    answerTag: {
      DAIRY: 'dairy',
      WHEAT: 'wheat',
      NUTS: 'nuts_and_peanuts',
      FISH: 'fish',
      SOY: 'soy',
      NONE: 'no',
    },
    orderId: 'D01',
  },
  dietary_preferences: {
    category: "Meology Quiz", 
    questionTag: 'dietary_preferences',
    answerTag: {
      CAFFEINE_FREE: 'caffeine_free',
      DAIRY: 'dairy_free',
      GLUTEN: 'gluten_free',
      KETO: 'keto',
      KOSHER: 'kosher',
      PALEO: 'paleo',
      PESCATARIAN: 'pescatarian',
      SOY: 'soy-free',
      VEGAN: 'vegan',
      VEGETARIAN: 'vegetarian',
      NONE: 'no',
    },
    orderId: 'D02',
  },
  non_kosher: {
    category: "Meology Quiz",
    questionTag: 'non_kosher_product_agreement',
    answerTag: {
      YES: 'yes_to_kosher',
      DAIRY: 'no_ok_to_kosher_dairy',
      NO: 'no_kosher_only',
    },
    orderId: 'Op_D02a',
  },
  vegetables: {
    category: "Meology Quiz",
    questionTag: 'servings_veggie_color',
    answerTag: {
      0: '<1',
      1: '1',
      2: '2',
      3: '3+',
    },
    orderId: 'D03',
  },
  fruits: {
    category: "Meology Quiz",
    questionTag: 'servings_fruit',
    answerTag: {
      0: '<1',
      1: '1',
      2: '2',
      3: '3+',
    },
    orderId: 'D04',
  },
  dairy: {
    category: "Meology Quiz",
    questionTag: 'servings_dairy',
    answerTag: {
      0: '<1',
      1: '1',
      2: '2',
      3: '3+',
    },
    orderId: 'Op_D05',
  },
  healthy_fats: {
    category: "Meology Quiz",
    questionTag: 'fish_frequency',
    answerTag: {
      0: '0',
      1: 'once',
      2: 'twice',
    },
    orderId: 'Op_D06',
  },
  transition_biology: {
    category: "Meology Quiz",
    questionTag: 'home_stretch_section_start',
    orderId: 'E0',
  },
  height_and_weight: {
    category: "Meology Quiz",
    questionTag: 'height_weight',
    orderId: 'E01',
  },
  healthcare_concern: {
    category: "Meology Quiz",
    questionTag: 'doc_health_concerns',
    answerTag: {
      BLOOD_PRESSURE: 'blood_pressure',
      BONE: 'bone',
      BLOOD_SUGAR: 'blood_sugar',
      CHOLESTEROL: 'cholesterol',
      DIGESTIVE: 'digestive_health',
      JOINT: 'joint',
      WEIGHT: 'weight',
      NONE: 'no',
    },
    orderId: 'E02',
  },
  digestive_issues: {
    category: "Meology Quiz",
    questionTag: 'digest_issue',
    answerTag: {
      GAS: 'gas_bloating_occasional',
      IRREGULARITY: 'irregularity',
      STOMACH: 'stomach_upset',
      NONE: 'none',
    },
    orderId: 'Op_E02a',
  },
  family_history: {
    category: "Meology Quiz",
    questionTag: 'family_health_history',
    answerTag: {
      HEART: 'heart_disease',
      CHOLESTEROL: 'high_cholesterol',
      BLOOD_PRESSURE: 'high-blood_pressure',
      OSTEOPOROSIS: 'osteoporosis',
      DIABETES: 'diabetes',
      NONE: 'none',
    },
    orderId: 'E03',
  },
  healthcare_recommended_nutrition: {
    category: "Meology Quiz",
    questionTag: 'doc_reco',
    answerTag: {
      IRON: 'iron',
      MULTIVITAMIN: 'multivitamin',
      OMEGA_3: 'omega_3',
      PROBIOTICS: 'probiotics',
      VITAMIN_D: 'vitamin_d',
      NONE: 'no',
    },
    orderId: 'E04',
  },
  medications: {
    category: "Meology Quiz",
    questionTag: 'current_medication',
    answerTag: {
      CHOLESTEROL_LOWERING: 'cholesterol_lowering',
      BLOOD_THINNING: 'blood_thinning',
      DIABETES: 'diabetes',
      BLOOD_PRESSURE_LOWERING: 'blood_pressure_lower',
      NONE: 'no',
    },
    orderId: 'E05',
  },
  supplements_usage: {
    category: "Meology Quiz",
    questionTag: 'current_supplement',
    answerTag: {
      1: 'yes',
      0: 'no',
    },
    orderId: 'E06',
  },
  supplements_goals: {
    category: "Meology Quiz",
    questionTag: 'reason_to_take_supplement',
    answerTag: {
      GOALS: 'weight',
      FOOD: 'balance_diet',
      HEALTH_ISSUES: 'specific_ health_issue',
      PREVENTIVE: 'preventative',
      ROUTINE: 'part_of_routine',
    },
    orderId: 'Op_E06a',
  },
  transition_optional_dna: {
    category: "Meology Quiz",
    questionTag: 'really_personalized_section_start',
    orderId: 'F0',
  },
  core_measurement: {
    category: "Meology Quiz",
    questionTag: 'waist',
    orderId: 'Op_F01',
  },
  core_measurement_howto_popup: {
    category: "Meology Quiz",
    questionTag: 'waist_howto_popup',
    orderId: 'Op_F01a',
  },
  lab_results: {
    category: "Meology Quiz",
    questionTag: 'blood_test',
    orderId: 'Op_F02',
  },
  lab_results_howto_popup: {
    category: "Meology Quiz",
    questionTag: 'blood_test_howto_popup',
    orderId: 'Op_F02a',
  },
  bp_reading: {
    category: "Meology Quiz",
    questionTag: 'blood_pressure',
    orderId: 'Op_F03',
  },
  bp_reading_howto_popup: {
    category: "Meology Quiz",
    questionTag: 'blood_pressure_howto_popup',
    orderId: 'Op_F03a',
  },
  dna_data: {
    category: "Meology Quiz",
    questionTag: 'dna_data',
    answerTag: {
      1: 'yes',
      0: 'no',
    },
    orderId: 'Op_F04',
  },
  dna_id: {
    category: "Meology Quiz",
    questionTag: 'dna_upload',
    orderId: 'Op_F05',
  },
  dna_id_howto_popup: {
    category: "Meology Quiz",
    questionTag: 'dna_upload_howto_popup',
    orderId: 'Op_F05a',
  },

  // Prenatal Quiz
  prenatal_name: {
    category: "Prenatal Quiz",
    eventAction: 'A0',
    eventLabel: 'name',
  },
  prenatal_transition_basics: {
    category: "Prenatal Quiz",
    eventAction: 'A01',
    eventLabel: 'nice_to_meet_you',
  },
  prenatal_reproductive_status: {
    category: "Prenatal Quiz",
    eventAction: 'B0',
    eventLabel: 'select_stage',
  },
  prenatal_due_date: {
    category: "Prenatal Quiz",
    eventAction: 'Op_B01_pregnant',
    eventLabel: 'due_date',
  },
  prenatal_due_date_howto_popup: {
    category: "Prenatal Quiz",
    eventAction: 'Op_B02_pregnant',
    eventLabel: 'calculate_due_date',
  },
  prenatal_feeding: {
    category: "Prenatal Quiz",
    eventAction: 'Op_B01_postpartum',
    eventLabel: 'breast_feeding_status',
  },
  transition_to_regular: {
    category: "Prenatal Quiz",
    eventAction: 'Op_B03_postpartum',
    eventLabel: 'to_adult_meology_quiz_start',
  },
  transition_to_wrong: {
    category: "Prenatal Quiz",
    eventAction: 'B01_none',
    eventLabel: 'to_adult_meology_landing',
  },
};

const getOrderId = question => _.get(quizGaMap, `${question}.orderId`, '');

export const getQuestionTag = question =>
  _.get(quizGaMap, `${question}.questionTag`, '');

export const getEventCategory = question =>
  _.get(quizGaMap, `${question}.category`, '');

export const getEventAction = question => {
  const { isRegularType, isPrenatalType } = getMeologyType();

  if (isRegularType) return `${getOrderId(question)}: ${getQuestionTag(question)}`;
  if (isPrenatalType) return _.get(quizGaMap, `${question}.eventAction`, '');
};

export const getEventLabel = (question, answer) => {
  const { isPrenatalType } = getMeologyType();
  if (isPrenatalType) {
    return _.get(quizGaMap, `${question}.eventLabel`, '');
  } else if (question?.indexOf('transition') > -1) {
    return `${getOrderId(question)}`;
  } else if (question?.indexOf('_howto_popup') > -1) {
    return _.get(quizGaMap, `${question}.questionTag`, '');
  } else if (Array.isArray(answer) && answer.length > 1) {
    let newArray = [];
    answer.forEach(item => {
      newArray.push(_.get(quizGaMap, `${question}.answerTag.${item}`, ''));
    });
    return newArray;
  } else {
    return _.get(quizGaMap, `${question}.answerTag.${answer}`, '');
  }
};
