import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Row } from 'react-bootstrap';
import { isEmpty, isChecked, getLocalQuestion } from 'assets/js/helper';
import _ from 'lodash';

import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import { getEventAction, getEventLabel } from 'components/analytics/quizAnalytics';
const actions = { pushInteractionToDataLayer };

class Checkbox extends Component {
  pushAnalytics = (question, answer) => {
    const eventAction = getEventAction(question);
    const eventLabel = getEventLabel(question, answer);
    this.props.pushInteractionToDataLayer({
      eventCategory: 'Quiz',
      eventAction,
      eventLabel,
    })
  };
  fieldChange = e => {
    let selectedAnswer = _.cloneDeep(this.props.selectedAnswer);
    if (!selectedAnswer || typeof selectedAnswer === 'string') {
      selectedAnswer = [];
    }
    if (selectedAnswer.length > 0 && selectedAnswer[0] === null) {
      const index = selectedAnswer.indexOf(null);
      selectedAnswer.splice(index, 1);
    }
    if (e.target.checked && selectedAnswer.indexOf(e.target.value) === -1) {
      selectedAnswer.push(e.target.value);
    }
    //delete the item from array if unchecked
    if (!e.target.checked) {
      selectedAnswer = selectedAnswer.filter(record => {
        if (e.target.value === record) {
          return false;
        }
        return true;
      });
    }
    // following lines of code is used when user click none then all other options are gone in array except none.
    if (e.target.value === 'NONE' && e.target.checked) {
      selectedAnswer = ['NONE'];
    }

    // following lines of code is used when user clicks other option except none and none is already in array then we remove none from array.
    if (e.target.value !== 'NONE' && e.target.checked) {
      const index = selectedAnswer.indexOf('NONE');
      if (index !== -1) {
        selectedAnswer.splice(index, 1);
      }
    }
    this.props.updateAnswer(this.props.name, selectedAnswer, this.props.page);

    // click analytics
    // this.pushAnalytics(this.props.name, e.target.value);
  };

  componentDidMount() {
    const { selectedAnswer, name, updateAnswer } = this.props;
    // if can't get current answer then get from local storage
    if (isEmpty(selectedAnswer)) {
      const questionsSelectedAnswers = getLocalQuestion() || {};
      const answer = questionsSelectedAnswers[name];
      if (!isEmpty(answer)) {
        updateAnswer(name, answer);
      }
    }
  }

  render() {
    const { name, options, t, isMobile, selectedAnswer } = this.props;
    let rowClass = name;
    if (!isMobile) {
      if (options.length > 4) {
        rowClass = rowClass + ' justify-content-start';
      }
      if (options.length > 4) {
        rowClass = rowClass + ' options-4-plus';
      }
      if (options.length === 3) {
        rowClass = rowClass + ' options-3';
      }
    }
    let columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-12';
    if (isMobile) {
      columnClass = 'col-xs-12 col-sm-12 col-md-6 col-lg-4';
    } else if (options.length >= 5) {
      columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-4';
    } else if (options.length === 4) {
      columnClass = 'col-xs-12 col-sm-12 col-md-12 col-lg-6';
    }

    return (
      <Row className={'answer checkbox-container ' + rowClass} role="group">
        {options.map((option, index) => {
          if (option.restrict) {
            return null;
          }
          const ifChecked = isChecked(option.answer, selectedAnswer);
          return (
            <div className={columnClass + ' round-label'} key={index}>
              <label>
                <input
                  type="checkbox"
                  className="check-box"
                  name={name}
                  value={option.answer}
                  onChange={this.fieldChange}
                  checked={ifChecked}
                />
                <span
                  className="label"
                  tabIndex="0"
                  role="checkbox"
                  aria-checked={ifChecked}
                  onKeyPress={(e) => {
                    if (e.charCode === 13 || e.charCode === 32) {
                      e.target.checked = !ifChecked;
                      e.target.value = option.answer;
                      this.fieldChange(e)
                    }
                  }}
                >
                  <span className="text">
                    <Trans>
                      {t('answers.' + name + '.' + option.answer + '.title')}
                    </Trans>
                  </span>
                </span>
              </label>
            </div>
          );
        })}
      </Row>
    );
  }
}

export default withTranslation()(connect(null, actions)(Checkbox));
