import React, { memo } from 'react';
import cx from 'classnames';
import { isNumeric, isImageUrl } from 'assets/js/helper';
import Video from 'components/common/Video';

const MarketingBanner = ({ className, resource, videoTitle, imageAlt }) => {
  const value = resource?.trim()
  const Banner = () => {
    if (isNumeric(value)) {
      return <Video id={value} title={videoTitle} />;
    } else if (isImageUrl(value)) {
      return (
        <div className="marketing-banner-image">
          <img src={value} alt={imageAlt} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={cx('marketing-banner', { [className]: className })}>
      <Banner />
    </div>
  );
};

export default memo(MarketingBanner);
