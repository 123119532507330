import React, { memo, useMemo, forwardRef,useRef, useEffect, useContext, useCallback,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import {
  useMeologyHistory,
  useKidsSachetPrice,
  useKidsPricePerDay,
  usePrenatalPricePerDay,
} from 'assets/js/hooks';
import { useEnsuredForwardRef, isEmpty } from 'assets/js/helper';
import { useToast } from 'components/Toast';
import CartBtn from 'components/results/CartBtn';
import { RecommendationContext } from 'components/results/ContextProvider';
import {
  PIC_PRICE_US,
  PIC_PRICE_CA,
} from 'components/results/Recommendations/RecommendationsConstants';
import LearnMoreModal from 'components/results/Recommendations/LearnMoreModal';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const usePicPrice = country =>
  useMemo(() => {
    const map = {
      US: PIC_PRICE_US,
      CA: PIC_PRICE_CA,
    };
    return map[country];
  }, [country]);

const KidsPrice = ({ data, price }) => {
  const { t } = useTranslation();
  if (isEmpty(data)) return null;
  return data?.map(({ name, id }) => (
    <div key={id} className="price-content1">
      <span>{t('ui.recommendation.meology_kids_other_recommendations_title8')} {name}</span>
      <span>${price}</span>
    </div>
  ));
};

const Summary = (props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.user);
  const {
    newKidsInfo,
    newKidsNumber,
    isMeologyKids,
    isMeologyRegular,
    isMeologyPrenatal,
  } = useMeologyHistory();
  const kidsSachetPrice = useKidsSachetPrice();
  const kidsPricePerDay = useKidsPricePerDay();
  const { prenatalStep } = useSelector(
    state => state.recommendationsReducer
  );
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const moreRef = useRef(null)
  const { hideCartBtn, hideAutoCartBtn, isPrenatalToRegular } = useMeologyHistory();

  let {
    currentPillsTotalPrice,
    tier2optionalPillsTotalPrice,
    prenatalPlusTotalPrice,
    addonProductsTotalPrice,
    basic,
    isPrenatal,
    isProveit,
    hideButtons,
    isCheckoutFixed,
    addToCartResult,
    prenatalEssentialPillsTotalPrice,
    showRSWIncompletedError,
  } = useContext(RecommendationContext);

  const prenatalPricePerDay = usePrenatalPricePerDay(prenatalPlusTotalPrice);

  const { addToast } = useToast();

  useEffect(() => {
    const { status } = addToCartResult || {};
    if (!status || String(status) === '200') return;
    addToast(t('ui.recommendation.addToCartError'), 3000);
  }, [addToCartResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkoutRef = useEnsuredForwardRef(ref);

  if (basic) {
    tier2optionalPillsTotalPrice = 0;
  }

  const picPrice = usePicPrice(country);

  const totalPrice = useMemo(() => {
    if (isMeologyKids) {
      return (
        Number(kidsSachetPrice * newKidsNumber) +
        Number(addonProductsTotalPrice)
      ).toFixed(2);
    }

    if (isProveit) {
      return (
        picPrice +
        Number(isPrenatal ? prenatalPlusTotalPrice : tier2optionalPillsTotalPrice) +
        Number(addonProductsTotalPrice)
      ).toFixed(2);
    } else {
      return (
        Number(currentPillsTotalPrice) + Number(addonProductsTotalPrice)
      ).toFixed(2);
    }
  }, [isMeologyKids, isProveit, kidsSachetPrice, newKidsNumber, addonProductsTotalPrice, picPrice, isPrenatal, prenatalPlusTotalPrice, tier2optionalPillsTotalPrice, currentPillsTotalPrice]);

  const getPrenatalText = useCallback((type) => {
    switch(prenatalStep) {
      case "TRYING": {
        let _text = ''
        if(type === 'essentials') {
          _text = t('ui.recommendation.preparing.title2')
        }
        if(type === "solutions" ) {
          return t('ui.recommendation.preparing.title3')
        }
        return _text
      }
      case "PREGNANT": {
        let _text = ''
        if(type === 'essentials') {
          _text = t('ui.recommendation.pregnant.essentials')
        }
        if(type === "solutions" ) {
          return t('ui.reconmendation.prenatal.plus.price.title')
        }
        return _text
      }
      case "POSTPARTUM": {
        let _text = ''
        if(type === 'essentials') {
          _text = t('ui.recommendation.postnatal.title2')
        }
        if(type === "solutions" ) {
          return t('ui.recommendation.postnatal.title3')
        }
        return _text
      }
      default : return ''
    }
  }, [prenatalStep, t])
  const meologyText = useMemo(() => {
    if (isProveit) {
      return t(isPrenatal ? 'ui.reco.prenatal_rsw.rsw_bundle' : 'ui.recommendation.prove_it_challenge');
    } else if (isPrenatal) {
      return getPrenatalText("essentials")
    } else {
      return basic
        ? t('ui.recommendation.essentials')
        : t('ui.recommendation.essentials_plus');
    }
  }, [basic, getPrenatalText, isPrenatal, isProveit, t]);

  const meologyPrice = useMemo(
    () =>
      `$${
    	  isProveit
          ? picPrice.toFixed(2)
          : (isPrenatal ? prenatalEssentialPillsTotalPrice : currentPillsTotalPrice)
      }`,
    [currentPillsTotalPrice, isPrenatal, isProveit, picPrice, prenatalEssentialPillsTotalPrice]
  );

  const supplyText = useMemo(() => {
    const getPricePerDayText = price =>
      t('ui.recommendation.monthly_supply') +
      ` | $${price} ` +
      t('ui.recommendation.daily_cost');

    if (isProveit) {
      return t('ui.recommendation.monthly_supply');
    }

    if (isMeologyRegular) {
      const pricePerDay = (currentPillsTotalPrice / 30).toFixed(2);
      return getPricePerDayText(pricePerDay);
    }

    if (isMeologyPrenatal) {
      return getPricePerDayText(prenatalPricePerDay);
    }

    if (isMeologyKids) {
      return (
        t('ui.recommendation.meology_kids_title4_2') +
        ` | $${kidsPricePerDay} ` +
        t('ui.recommendation.meology_kids_title4_3')
      );
    }

    return '';
  }, [
    isMeologyKids,
    isProveit,
    currentPillsTotalPrice,
    t,
    kidsPricePerDay,
    prenatalPricePerDay,
    isMeologyPrenatal,
    isMeologyRegular,
  ]);

  const onMore = useCallback((e) => {
    // eslint-disable-next-line no-unused-expressions
    e?.stopPropagation?.()
    if( e?.target?.nodeName === 'U' && e?.target?.dataset?.reco === 'learn-more'){
      setShowLearnMoreModal(true)

      // Google Analysis
      dispatch(
        pushInteractionToDataLayer({
          eventCategory: 'Prenatal',
          eventAction: 'Learn More_bottom',
          eventLabel: 'Reco Page',
        })
      );
    }
  }, [dispatch])
  useEffect(() => {
    if(!moreRef?.current) return
    const nodeP = moreRef.current
    nodeP.addEventListener('click', onMore)
    return () =>{
      nodeP.removeEventListener('click', onMore)
    }
  },[onMore,moreRef])

  const errorMsg = useMemo(() => {
    return showRSWIncompletedError ? (
      <p className="incompleted-error">
        <span> {t('ui.reco.prenatal_rsw.incomplete_error')} </span>
      </p>
    ) : '';
  }, [showRSWIncompletedError, t]);

  return (
    <div className={cx('result-wrap2', {
      'prenatal': isPrenatal,
    })} ref={checkoutRef}>
      <div className="result-wrap1">
        {isMeologyKids ? (
          <KidsPrice data={newKidsInfo} price={kidsSachetPrice} />
        ) : (
          <div className={cx('price-content1', {
            'font-gilmer-regular': isPrenatal
          })}>
            <span>{meologyText}</span>
            <span>{meologyPrice}</span>
          </div>
        )}
        {!isPrenatal && (!isMeologyKids || newKidsNumber !== 0) && (
          <div className="price-content1 supply">{supplyText}</div>
        )}
        {!basic && tier2optionalPillsTotalPrice > 0 && (
          <div className="price-content1">
            <span>{t('ui.prove_it_page_1.plan_2.title_plus')}</span>
            <span>${tier2optionalPillsTotalPrice}</span>
          </div>
        )}
        {
          isPrenatal && prenatalPlusTotalPrice > 0 && (
            <div className="price-content1 font-gilmer-regular">
            <span>{getPrenatalText("solutions")}</span>
            <span>${prenatalPlusTotalPrice}</span>
          </div>
          )
        }
        {isPrenatal && (!isMeologyKids || newKidsNumber !== 0) && (
          <div className="price-content1 supply font-gilmer-regular">{supplyText}</div>
        )}
        {addonProductsTotalPrice > 0 && (
          <div className={cx('price-content1', {
            'font-gilmer-regular': isPrenatal
          })}>
            <span>{t('ui.recommendation.add_ons')}</span>
            <span>${addonProductsTotalPrice}</span>
          </div>
        )}
        <div className={cx('price-content1 subtotal', {
            'font-gilmer-regular': isPrenatal
          })}>
          <span>{t('ui.recommendation.subtotal')}</span>
          <span>${totalPrice}</span>
        </div>
        <div className={cx('checkout-section', { hasText: isMeologyKids })}>
          {isMeologyKids && (
            <p className="checkout-section-text">
              {t('ui.recommendation.meology_kids_add_to_cart_title2')}
            </p>
          )}
          {!isPrenatalToRegular && !hideButtons && (
            <CartBtn
              noSelectedProduct={totalPrice === '0.00'}
              where="summary"
            />
          )}
        </div>
        {errorMsg}
      </div>
      {!isPrenatalToRegular && !hideButtons && (
        <div
          className={`fixed-checkout-wrap${isCheckoutFixed ? ' fixed' : ''} ${isPrenatal ? 'prenatal' : ''}`}
        >
          <div className={cx("fixed-checkout", {
            'two-btns': !hideAutoCartBtn && !hideCartBtn
          })}>
            <div className="fixed-checkout-subtotal">{`${t(
              'ui.recommendation.subtotal'
            )}: $${totalPrice}`}</div>
            <CartBtn noSelectedProduct={totalPrice === '0.00'} where="fixed" />
          </div>
          {errorMsg}
        </div>
      )}
      <LearnMoreModal
        show={showLearnMoreModal}
        closeModal={()=>{
          setShowLearnMoreModal(false);
        }}
      />
    </div>
  );
};

export default memo(forwardRef(Summary));
