/* eslint-disable array-callback-return */
// Product item template
import React, { memo, useContext, useState, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import cx from 'classnames';

import { getImageUrl } from 'assets/js/helper';
import { RecommendationContext } from 'components/results/ContextProvider';
import {
  BUNDLE_SKUS_US,
  BUNDLE_SKUS_CA,
  OPTION_SKUS_US,
  OPTION_SKUS_CA,
} from './Recommendations/RecommendationsConstants';
import ResultSelect from './ResultSelect';
import RecommendationProductAttributes from './Recommendations/RecommendationProductAttributes';
import { pushProductClick } from 'components/results/Recommendations/RecommendationsPill';
import { useSkus, useIsSku, useMeologyHistory, usePrice, usePrenatalStatus } from 'assets/js/hooks';
import { getProductSummary, getKidsProductSummary } from 'assets/js/helper';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const getOptions = product => {
  const { baseOptions: [{ options } = {}] = [] } = product || {};
  if (!Array.isArray(options)) return;
  if (options.length === 0) return;
  return (
    options
      // just in case
      .filter(({ code = '' }) => !code.toLowerCase().startsWith('v_'))
      .filter(
        ({ size = '' }) => !size || size.toLowerCase().includes('canister')
      )
  );
};


const getDefaultValue = (data,productCode) => {
  if (data && data.find(item => (item.code === productCode))) {
    return data.find(item => (item.code === productCode))['name']
  }
  return 'choose flavor'
}

const stepNO = {
  TRYING: 1,
  PREGNANT:2,
  POSTPARTUM: 3
}

const ProductItem = ({
  isPrenatal,
  index,
  item,
  setPDPInfo,
  showContent,
}) => {
  const dispatch = useDispatch();
  const { country } = useSelector(state => state.user);
  const { t } = useTranslation();
  let {
    sku,
    required,
    selected,
    name,
    code,
    quantity,
    dsheaFlag,
    recoPageDagger,
  } = item;
  const { isMeologyKids } = useMeologyHistory();

  const productInfo = useSelector(state => state.recommendationsReducer.data.product_info);
  const prenatalStep = useSelector(
    state => state.recommendationsReducer.prenatalStep
  );
  
  const displaySummary = useMemo(() => {
    if(isPrenatal) {
      return t(`recommendation.prenatal_detail_summary.${item.sku}.${prenatalStep}`,
      t(`recommendation.prenatal_detail_summary.${item.sku}`))
    }
    if (isMeologyKids) {
      return getKidsProductSummary(item, t);
    } else {
      return getProductSummary(item)
    }
  }, [isMeologyKids, isPrenatal, item, prenatalStep, t]);

  const bundleSkus = useSkus(country, {
    US: BUNDLE_SKUS_US,
    CA: BUNDLE_SKUS_CA,
  });
  const optionSkus = useSkus(country, {
    US: OPTION_SKUS_US,
    CA: OPTION_SKUS_CA,
  })

  const isBundle = useIsSku(bundleSkus, sku);
  const isOption = useIsSku(optionSkus, sku);

  const recoWarningText = productCode => {
	  return productInfo && productInfo[productCode] && productInfo[productCode].warnings_reco ? productInfo[productCode] && productInfo[productCode].warnings_reco : [];
  };

  const getReasonKeyData = productCode => {
    let reasonData = [];
    if (productInfo && productInfo[productCode] && productInfo[productCode].reason_categories) {
      (productInfo[productCode].reason_categories).map((item) => {
        item.reasons.map(val => {
          reasonData.push(val.title)
        })
      });
      return [...new Set(reasonData)]
    }
    return []
  }

  const imageUrl = useMemo(() => getImageUrl(item, country),  [item, country])

  const options = useMemo(() => getOptions(item), [item]);
  const defaultOption = useMemo(() => getDefaultValue(options, code), [options, code])
  const defaultProduct = useRef({...item});
  const defalutPrice = usePrice({ ...item, quantity }, 'addon');
  const [isChangeOption, setIsChangeOption] = useState(false);
  const curReasonKeyData = getReasonKeyData(isChangeOption ? defaultProduct.current.code : code);
  const curRecoWarningText = recoWarningText(isChangeOption ? defaultProduct.current.code : code);
  const [curName, setCurName] = useState(name);
  const [currentImage, setCurrentImage] = useState(imageUrl);
  const [curPrice, setCurPrice] = useState(defalutPrice);
  const [currentDefaultOption, setCurrentDefaultOption] = useState(defaultOption)
  const { isPrenatalPreview } = usePrenatalStatus();


  useEffect(() => {
    if (!imageUrl && !isPrenatal) return;
    if(isPrenatal){
      setCurrentImage(t(`recommendation.prenatal.image_url.addon.${code}_${stepNO[prenatalStep]}`))
    } else {
      setCurrentImage(imageUrl)
    }
  }, [code, imageUrl, isPrenatal, prenatalStep, t])

  useEffect(() => {
    if (!defaultOption) return;
    setCurrentDefaultOption(defaultOption)
  }, [defaultOption])

  useEffect(() => {
    if (!name) return;
    setCurName(name);
  }, [name])

  useEffect(() => {
    if (!defalutPrice) return;
    setCurPrice(defalutPrice);
  }, [defalutPrice])

  const { changeProduct } = useContext(RecommendationContext);

  const onChange = item => {
    let url = t(`recommendation.prenatal.image_url.addon.${item.code}_${stepNO[prenatalStep]}`)
    if(!isPrenatal) {
      url = getImageUrl(item, country);
    }
    changeProduct(index, null, item);
    setCurName(item.name);
    setCurrentImage(url);
    setCurPrice(defalutPrice);
    setIsChangeOption(true);
  };


  return (
      <div className="add-on-section-content-item">
      <Card className="product-item">
        {!isPrenatal && !required && (
          <Form.Check
            type="switch"
            label={selected ? t('ui.recommendation.included') : t('ui.recommendation.include')}
            className="product-info-switch"
            id={`addon-product-switch${index}`}
            checked={selected}
            onChange={event => changeProduct(index, event)}
            tabIndex="0"
            aria-label="include toggle btn"
            onKeyDown={event => {
              if(event.keyCode === 13){
                event.target.checked = !event.target.checked
                changeProduct(index, event)
              }
            }
          }
          />
        )}
        {(currentImage || isPrenatal) && (
          <div className={cx("product-info-image", {
            'prenatal-option': isPrenatal,
          })}>
          <Card.Img
            className="item-img"
            variant="top"
            src={currentImage}
            onError={e => {
            e.target.src = 'https://images.shaklee.com/meology/coming-soon.jpg';
            }}
            alt=""
          />
          </div>
        )}
        <Card.Body  className={cx({
            'font-gilmer-medium': isPrenatal
          })}>
          <div className="product-item-content">
          <Card.Title>{curName}</Card.Title>
          <Card.Text className={cx({
            'font-gilmer-regular': isPrenatal
          })} dangerouslySetInnerHTML={{ 
            __html: isPrenatal 
            ? displaySummary 
            : (`${displaySummary}${dsheaFlag ? '*' : ''}${recoPageDagger ? '‡' : ''}`)
          }}></Card.Text>
          <div className="list">
            <RecommendationProductAttributes pill = {item}/>
          </div>

          { !isPrenatal && showContent && curReasonKeyData.length > 0 && (
            <>
              <Card.Title>{isMeologyKids ? t('ui.recommendation.why-foy-you') : t('ui.recommendation.why')}</Card.Title>
              <ul className="reason-list">
                {curReasonKeyData.slice(0,5).map((item,index) => (
                    <li className="reason-list-item" key={index}>
                        {t('recommendation.'+ item)}
                    </li>
                ))}
              </ul>
            </>
          )}
          {isBundle &&
            <div className="special-tag">
              <div>{t('ui.meal_snack_bar.cart')}</div>
              {curRecoWarningText.length > 0 && (
              	<>
	              {curRecoWarningText.map((item,index) => (
	              	<div key={index}>{t(item)}</div>
	              ))}
	              </>
              )}
            </div>
          }
          {isOption && !_.isEmpty(options) && (
            <ResultSelect
              className={cx({
                'prenatal': isPrenatal
              })}
              data={options}
              defaultValue={currentDefaultOption}
              onChange={onChange}
            />
          )}
          </div>
          <div>
            <div className={cx('product-item-footer', { hide: !showContent })}>
              {showContent && (
                <Button
                  tabIndex="0"
                  aria-label="Product Details"
                  onClick={() => {
                    // analytics
                    pushProductClick({
                      dispatch,
                      product: item,
                      price: curPrice,
                      index,
                      country
                    });
                    setPDPInfo(
                      item,
                      isChangeOption ? defaultProduct.current : null,
                      index
                    );
                  }}
                  className="product-item-footer-link"
                  variant="link"
                >
                  {t('ui.recommendation.learn_more')}
                </Button>
              )}
              <div className="product-item-footer-price">
                <span>${curPrice}</span>
              </div>
            </div>
            {isPrenatal && (
              <label
                className={cx('checkbox-button', {
                  selected,
                  disabled: isPrenatalPreview,
                })}
                htmlFor={`checkbox-button-addons-${index}`}
                tabIndex="0"
                aria-label="add button"
              >
                <input
                  className="sr-only"
                  type="checkbox"
                  id={`checkbox-button-addons-${index}`}
                  checked={selected}
                  disabled={isPrenatalPreview}
                  onChange={event => {
                    changeProduct(index, event)

                    // Google Analysis
                    if (event.target.checked) {
                      dispatch(
                        pushInteractionToDataLayer({
                          eventCategory: 'Prenatal',
                          eventAction: 'Product Add On',
                          eventLabel: curName,
                        })
                      );
                    }
                  }}
                />
                {selected
                  ? t('ui.recommendation.prenatal.cta4')
                  : t('ui.recommendation.prenatal.cta3')}
              </label>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default memo(ProductItem);
