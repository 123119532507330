import React, { createContext, memo } from 'react';

export const RecommendationContext = createContext(null);

const ResultProvider = ({ children, value }) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <RecommendationContext.Provider value={value}>
    {children}
  </RecommendationContext.Provider>
);

export default memo(ResultProvider)
