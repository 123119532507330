import { ASYNC_ACTION_START, ASYNC_ACTION_FINISH, ASYNC_ACTION_ERROR } from './asyncConstants';

const initialState = {
  loading: false,
  asyncMessage: null,
  asyncError: false
}

const AsyncReducer = (state = initialState, action) => {
	switch (action.type) {
		case ASYNC_ACTION_START:
			state = {
				...state, 
				loading: true,
				asyncError: false,
				asyncMessage: action.payload
			}
			break;
		case ASYNC_ACTION_FINISH:
			state = {
				...state, 
				loading: false,
				asyncError: false,
				asyncMessage: null
			}
			break;
		case ASYNC_ACTION_ERROR:
			state = {
				...state, 
				loading: false,
				asyncError: true,
				asyncMessage: action.payload
			}
			break;
		default:
			break;
	}

	return state;
};

export default AsyncReducer;