import React, { useMemo, memo, useRef, useEffect,useContext, useCallback } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import PillItem from './PillItem';
import LearnMoreBtn from './LearnMoreBtn';
import { stepTypes, getStepsProps } from './ItemConfig';
import { useSelector, useDispatch } from 'react-redux';
import { changePrenatalStep } from 'components/results/Recommendations/RecommendationsActions';
import { MarketingContext } from 'components/Marketing/Marketing';

const Division = ({ text }) => {
  return <p className="mkt-division">{text}</p>;
};

const StepImg = ({t, sachetImg, sachetImgMo}) => {
  return <div className='step-imgs-container'>
    <div>
      <img src={t('marketing.prenatal.image_url.step_slider')} alt=""></img>
      <img src={t('marketing.prenatal.image_url.step_slider_mobile')} alt=""></img>
    </div>
    <img src={sachetImg} alt=""></img>
    <img src={sachetImgMo || sachetImg} alt=""></img>
  </div>
}
const stepStates = ['TRYING', 'PREGNANT', 'POSTPARTUM', 'MEOLOGY'];
const silderKeys = {
  TRYING: 0,
  PREGNANT: 1,
  POSTPARTUM: 2,
  MEOLOGY: 3,
};
const StepSlider = () => {
  const { t } = useContext(MarketingContext);
  const { prenatalStep } = useSelector(state => state.recommendationsReducer);
  const { lang, country } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = swiperRef?.current?.swiper;
    if (swiper) {
      swiper.slideToLoop(silderKeys[prenatalStep]);
    }
  }, [prenatalStep, swiperRef]);

  useEffect(() => {
    if (!swiperRef?.current?.swiper) {
      return;
    }
    const swiper = swiperRef.current.swiper;
    swiper.on('slideChange', function() {
      dispatch(changePrenatalStep(stepStates[swiper.realIndex]));
    });
  }, [dispatch, swiperRef]);
  const params = useMemo(
    () => ({
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
    }),
    []
  );

  const onMore = useCallback(() => {
    const lng = lang === 'zh' ? 'zh_CN' : `${lang}_${country}`
    window.open(`/meology?lang=${lng}&country=${country}`, "_self");
  }, [country, lang])

  const items = useMemo(() => getStepsProps(t), [t]);
  return (
    <section className="step-slider-container" aria-label="meology prenatal marketing page step carousel" tabIndex="0">
      <Swiper {...params} ref={swiperRef}>
        {items?.map(({ stepType, pills, addons, sachetImg, sachetImgMo }, index) => {
          return (
            <div key={index} className="mkt-step-slider-content">
              <div className="mkt-step-detail">
              {stepType === stepTypes.MEOLOGY ? (
                <>
                  <div className="mkt-step-meology">
                    <h2>{t('ui.prenatal_marketing.section_4_4.title')}</h2>
                    <p>{t('ui.prenatal_marketing.section_4_4.content')}</p>
                    <LearnMoreBtn text={t('ui.prenatal_marketing.cta_1')} onClick={onMore} />
                    <img src={sachetImg} alt="market step detail sachet img" aria-label="market step detail sachet img"></img>
                    <img src={sachetImgMo} alt="market step detail sachet imgMo" aria-label="market step detail sachet imgMo"></img>
                  </div>
                </>
              ) : (
                <>
                  <StepImg t={t} sachetImg={sachetImg} sachetImgMo={sachetImgMo}/>
                  <div className="mkt-step-detail-items">
                    <div className="mkt-step-detail-top">
                      <PillItem {...pills[0]} />
                    </div>
                    <div className="mkt-step-detail-pills">
                      <PillItem {...pills[1]} />
                      <PillItem {...pills[2]} />
                      <Division text="Optional" />
                      <PillItem {...pills[3]} />
                      <PillItem {...pills[4]} />
                    </div>
                  </div>
                </>
              )}
              {addons?.length > 0 && (
                <>
                  <Division text="ADD-ON" />
                  <div className="mkt-addons">
                    {addons.map((ad, idx) => (
                      <PillItem key={idx} {...ad} />
                    ))}
                  </div>
                </>
              )}
              </div>
            </div>
          );
        })}
      </Swiper>
    </section>
  );
};

export default memo(StepSlider);
