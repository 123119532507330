import React, { useMemo, memo } from 'react';
import cx from 'classnames';
import { useTranslation} from 'react-i18next';
import { PrenatalCompletedTag } from './';

export const BannerStepTag = ({ text, isActive, isCompleated, stepNo = 1 }) => {
  const {t} = useTranslation()
  return (
    <div
      className={cx('res-banner-step-tag', {
        active: isActive,
      })}
    >
      <span>
        {`${t('ui.recommendation.proveit_section_step')} ${stepNo} `}
        <PrenatalCompletedTag className="simple" isHide={!isCompleated} />
      </span>
      <div dangerouslySetInnerHTML={{
        __html: text,
      }}></div>
    </div>
  );
};

const TopStepBanner = ({ className, currentStep = 1 }) => {
  const {t} = useTranslation()
  const steps = useMemo(() => {
    return [
      { 
        text: t('ui.reco.prenatal_rsw.step1'),
        isActive: currentStep === 1,
        isCompleated: currentStep > 1,
        stepNo: 1,
      },
      {
        text: t('ui.reco.prenatal_rsw.step2'),
        isActive: currentStep === 2,
        isCompleated: currentStep > 2,
        stepNo: 2,
      },
      {
        text: t('ui.reco.prenatal_rsw.step3'),
        isActive: currentStep === 3,
        isCompleated: currentStep > 3,
        stepNo: 3,
      },
    ];
  }, [currentStep, t]);
  return (
    <div className={`rsw-top-step-banner-container ${className??''}`}>
      <span className="rsw-log" dangerouslySetInnerHTML={{
        __html: t('ui.reco.prenatal_rsw.logo_text')
      }} />
      <div className="step-items">
        {steps.map(item => (
          <BannerStepTag key={item.stepNo} {...item} />
        ))}
      </div>
    </div>
  );
};

export default memo(TopStepBanner);
