import React, { memo, useState, useMemo, useContext } from 'react';
import { useSelector,  useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useMeologyHistory } from 'assets/js/hooks';
import IconModal from './../IconModal';
import { KidsIconContext } from 'components/results/Kids/EditModal';
import { validateQuestions } from 'components/questions/QuestionActions';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const RenderInputWithIconItem = props => {
  const {
    inputWithIconData,
    index,
    selectedAnswer,
    page,
    name,
    updateAnswer,
    item,
    iconList,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { autoshipKidsId } = useMeologyHistory();
  const { where } = useContext(KidsIconContext) || {};
  const { validate_question } = useSelector(state => state.QuestionReducer);
  const { error, error_message } = useMemo(
    () => validate_question?.[name]?.[index] ?? '',
    [index, name, validate_question]
  );

  const { lang } = useSelector(state => state.user)

  const openModal = () => {
    dispatch(pushInteractionToDataLayer({
      eventCategory: 'a_name_icon',
      eventAction: 'A0a: Pick an icon (popup)',
      eventLabel: 'Pick an icon (popup)',
    }))
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleIcon = (icon, index) => {
    dispatch(pushInteractionToDataLayer({
      eventCategory: 'a_name_icon',
      eventAction: `A0b: Pick an icon ${icon}`,
      eventLabel: `Pick an icon ${icon}`,
    }))
    let array = [...selectedAnswer];
    array[index].icon = icon;
    array[index].id = `${array[index].id.split('-')[0]}-${icon}-${
      array[index].id.split('-')[2]
    }`;
    updateAnswer(name, array, page);
    dispatch(validateQuestions(name, array, false, t))
  };
  
  const showIndicator = useMemo(() =>  autoshipKidsId?.map(it => it.split('-')[2])?.includes(item.id.split('-')[2]), [autoshipKidsId, item.id]);

  const isEdit = useMemo(() => where === 'edit', [where]);

  const showCloseButton = useMemo(() => {
    if (isEdit) {
      return !showIndicator;
    } else {
      return !showIndicator && inputWithIconData?.length > 1;
    }
  }, [isEdit, showIndicator, inputWithIconData]);

  return (
    <div
      className={cx('kids-answer-card', {
        'has-indicator': showIndicator,
      })}
      key={index}
    >
      {showCloseButton && (
        <button
          type="button"
          className="close"
          onClick={() => {
            let array = [...selectedAnswer];
            array.splice(index, 1);
            updateAnswer(name, array, page);
          }}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      )}

      <button
        className="kids-answer-icon"
        onClick={openModal}
        tabIndex="0"
      >
        <img
          src={
            item?.icon
              ? `https://images.shaklee.com/meology/sachet_icons/${item.icon}.svg`
              : 'https://images.shaklee.com/meology/icons/smile.png'
          }
          alt={item.icon ? item.icon : 'smile'}
          onError={e => {
            e.target.src = 'https://images.shaklee.com/meology/prenatal/pick-icon.png';
          }}
        />
        {!item?.icon && (
          <span className="kids-answer-icon-text">
            {t('ui.recommendation.meology_kids_edit_pop_up_title4')}
          </span>
        )}
      </button>
      <input
        type="text"
        name={name}
        className={cx("kids-answer-input", {'spanish': lang === 'es'})}
        onChange={e => {
          let array = [...selectedAnswer];
          array[index].name = e.target.value;
          array[index].id = `${e.target.value}-${
            array[index].id.split('-')[1]
          }-${array[index].id.split('-')[2]}`;
          updateAnswer(name, array, page);
        }}
        placeholder={t('ui.recommendation.meology_kids_edit_pop_up_title5')}
        autoComplete="off"
        value={item.name}
      />
      {error && error_message && (
        <div className="error-message">
          <div>{error_message}</div>
          <span className="arrow"></span>
        </div>
      )}
      {showIndicator && (
        <span className="kids-answer-indicator">
          {t('ui.recommendation.meology_kids_add_to_cart_title16')}
        </span>
      )}
      <IconModal
        isOpen={isOpen}
        closeModal={closeModal}
        content={name}
        handleIcon={handleIcon}
        index={index}
        iconList={iconList}
        isEdit={isEdit}
        t={t}
      />
    </div>
  );
};

export default memo(RenderInputWithIconItem);
