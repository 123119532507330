import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import _ from 'lodash'
import { isAndroid, isInput, isInputUnit, isInputGroup, getUnit } from 'assets/js/helper';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import {
  validateQuestions,
  resetValidateQuestion,
  removeValidateQuestion,
} from 'components/questions/QuestionActions';
const actions = {
  validateQuestions,
  resetValidateQuestion,
  removeValidateQuestion,
  pushInteractionToDataLayer,
};

class RenderInput extends Component {
  static windowHeight = window.innerHeight;
  constructor(props) {
    super(props);
    this.inputWrapRef = createRef();
    this.inputRef = createRef()
    this.state = {
      display: '',
      show: false,
      value: '',
      isFocus: false,
      currentValue: '',
    };
  }

  validate (name, value) {
    const { option, t, validateEmail, validateQuestions, options} = this.props
    if (name === 'email') {
      validateEmail(value);
    } else {
      if (option) {
        validateQuestions(name, value, false, t, option, options)
      } else {
        validateQuestions(name, value, false, t);
      }
    }
  }

  setRetainData () {
    const { selectedAnswer, name, option, options, removeValidateQuestion } = this.props || {};
    if (!selectedAnswer) {
      if (isInputUnit(name) || isInputGroup(name)) {
        removeValidateQuestion({
          question: option,
        });
      }
      return;
    }
    this.setState({
      currentValue: selectedAnswer
    })
    if (isInput(name)) {
      this.setState({
        display: selectedAnswer
      });
    }
    if (name === 'weight') {
      this.setState({
        display: selectedAnswer.weight || ''
      });
    }
    if (isInputUnit(name)) {
      if (options.length > 1) {
        this.setState({
          display: (selectedAnswer[option] && selectedAnswer[option].value) || ''
        })
      } else {
        this.setState({
          display: selectedAnswer.value || ''
        })
      }
    }
    if (isInputGroup(name)) {
      if (options.length > 1) {
        this.setState({
          display: (selectedAnswer[option]) || ''
        })
      } else {
        this.setState({
          display: selectedAnswer.value || ''
        })
      }
    }
  }


  showText () {
    const { selectedAnswer, name, options, option } = this.props || {};
    if (!selectedAnswer || (!isInputUnit(name) && !isInputGroup(name)) || options.length <= 1) return;
    const value = _.get(selectedAnswer, `${option}.value`, '');
    if (value && !this.state.isFocus) {
      this.setState({ show: true, value })
    }
  }

  componentDidMount() {
    this.setRetainData();
    this.showText();
    this.validate(
      this.props.parent ? this.props.parent : this.props.name,
      this.props.selectedAnswer
    );
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.isMobile) {
      window.scrollTo(0, 0);
      if (isAndroid()) {
        window.addEventListener('resize', () => {
          const resizeWindowsHeight = window.innerHeight;
          if (RenderInput.windowHeight > resizeWindowsHeight) {
            document.body.classList.add('show-android-keyboard');
            if (this.inputRef && this.inputRef.current) {
              this.inputRef.current.scrollIntoView(true);
            }
          } else {
            document.body.classList.remove('show-android-keyboard');
            if (this.inputRef && this.inputRef.current) {
              this.inputRef.current.scrollIntoView(false);
            }
          }
        });
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectedAnswer === prevProps.selectedAnswer) return;
    if (this.props.name === 'lab_results' && this.props.showError) {
      this.props.pushInteractionToDataLayer({
        eventCategory: 'Quiz',
        eventAction: 'Op_F2b: blood_test_error',
        eventLabel: 'blood_test_error',
      })
    }
    this.showText();
    this.setRetainData();
    this.validate(
      this.props.parent || this.props.name,
      this.props.selectedAnswer
    );
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  hide = () => {
    if (this.props.isMobile && this.props.btnNextContainer && this.props.btnNextContainer.current && this.props.btnNextContainer.current.classList && !isAndroid()) {
        this.props.btnNextContainer.current.classList.add('mobile-next');
    }
  };
  fieldChange = e => {
    const {
      name,
      updateEmailAnswer,
      updateAnswer,
      selectedAnswer,
      page,
      option,
      options,
    } = this.props;
    this.validate(this.props.parent ? this.props.parent : this.props.name, e.target.value);
    this.hide();
    if (name === 'email') {
      updateEmailAnswer(name, e.target.value);
    } else if (isInputUnit(name)) {
      let inputValue = e.target.value;
      if (inputValue.length > 7) {
        inputValue = this.inputRef.current.value = inputValue.slice(0, 7)
      }
      const unit = getUnit(option);
      let currentValue = _.cloneDeep(selectedAnswer);
      if (!currentValue) {
        currentValue = {};
      };
      if (options.length > 1) {
        if (!currentValue[option]) {
          currentValue[option] = {};
        }
        currentValue[option].value = inputValue;
        if (!currentValue[option].unit) {
          currentValue[option].unit = unit.us;
        }
      } else {
	      currentValue.value = inputValue;
	      if (!currentValue.unit) {
          currentValue.unit = unit.us;
        }
      }
	    this.setState({
        currentValue
      });
      updateAnswer(name, currentValue, page);
    } else if (isInputGroup(name)) {
      let inputValue = e.target.value;
      let currentValue = _.cloneDeep(selectedAnswer);
      if (!currentValue) {
        currentValue = {};
      };
      if (options.length > 1) {
        if (!currentValue[option]) {
          currentValue[option] = '';
        }
        if (inputValue === '') {
          delete currentValue[option]
        } else {
          currentValue[option] = inputValue;
        }
      } else {
        currentValue = inputValue;
      }

      this.setState({
        currentValue
      });
      updateAnswer(name, currentValue, page);
    } else {
      updateAnswer(name, e.target.value, page);
    }
    this.setState({
      show: false
    })
  };
  focus = event => {
    const {
      name,
      option,
      options,
      removeValidateQuestion,
    } = this.props;

    this.setState({
      isFocus: true,
    });

    if ((isInputUnit(name) || isInputGroup(name)) && options.length > 1) {
      if (event.target.value !== '') {
        removeValidateQuestion({
          question: option,
        });
      }
      return;
    }

    this.setState({
      show: false,
    });
  };
  blurchange = e => {
    this.setState({
      isFocus: false
    })
	  if(['name', 'prenatal_name'].includes(this.props.name) || this.props.name === 'email') {
		  this.props.setLogs(this.props.name, e.target.value);
	  }
    this.validate(this.props.parent ? this.props.parent : this.props.name, this.state.currentValue);
    if (this.props.isMobile && this.props.btnNextContainer && this.props.btnNextContainer.current && this.props.btnNextContainer.current.classList && !isAndroid()) {
      this.props.btnNextContainer.current.classList.remove('mobile-next');
      window.scrollTo(0,0);
    }

    this.setState({
      show: true
    })
  };

  handleClickOutside = event => {
    if (this.inputWrapRef.current.contains(event.target)) {
      return;
    } else {
      this.validate(this.props.parent ? this.props.parent : this.props.name, this.state.currentValue);
    }
  }

  placeholder = (t,name,option) => {
    if (name === 'lab_results' || name === 'core_measurement') {
      return ''
    } else if (name === 'bp_reading') {
      return t(`questions.${option}.placeholder`)
    } else {
      return t(`questions.${name}.placeholder`)
    }
  }

  render() {
    const { t, name, parent, option } = this.props;
    const placeholder = this.placeholder(t,name,option);

    return (
      <Row className={`answer ${parent || ''}`} ref={this.inputWrapRef}>
        <input
          ref={this.inputRef}
          type="text"
          name={name}
          className={`form-control text-field`}
          onKeyDown={this.hide}
          onMouseDown={this.hide}
          onChange={this.fieldChange}
          onBlur={this.blurchange}
          onFocus={this.focus}
          defaultValue={this.state.display}
          placeholder={placeholder}
          autoComplete="off"
          aria-label={placeholder}
          onWheel={e => {
            if (!isInputUnit(this.props.name) && !isInputGroup(this.props.name)) return;
            this.inputRef.current.blur();
          }}
        />
        {this.props.showError && (
          <div className="error-message">
            <div className="arrow"></div>
            <span>{this.props.error_message}</span>
          </div>
        )}
      </Row>
    );
  }
}

export default withTranslation()(connect(null, actions)(RenderInput));