import React, { memo } from 'react';
import ResultSelect from 'components/results/ResultSelect';
import { useTranslation } from 'react-i18next';

const RecommendationsStepProvelt = ({
  options1,
  options2,
  select1DefaultName,
  select2DefaultName,
  select3DefaultName,
  changeSelect1Code,
  changeSelect2Code,
  changeSelect3Code,
  proveItStep2Ref,
  proveItStep3Ref,
  setShowProveltModal,
  setIsBoost,
  showContent,
}) => {
  const { t } = useTranslation();

  return (
    <div className="recommendations-step-provelt">
      <div
        className="recommendations-step-provelt-container"
        ref={proveItStep2Ref}
      >
        <div className="recommendations-step-provelt-info">
          <div className="product-info">
            <div className="product-info-left">
              <img
                className="product-info-left-picture"
                src={'https://images.shaklee.com/wellness/RSW_Shakes_Step2.png'}
                alt=""
              />
            </div>
            <div className="product-info-right">
              <div className="product-info-right-step">{t('ui.recommendation.proveit_section_step')} 2</div>
              <h4 className="prove-it-list-title">
                {t('ui.recommendation.proveit_section_4subtitle')}
              </h4>
              <p className="prove-it-list-content">
                {t('ui.recommendation.proveit_section_6subtitle_description')}
              </p>
              <div className="prove-it-list-select-wrap">
                <ResultSelect
                  data={options1}
                  defaultValue={select1DefaultName}
                  onChange={changeSelect1Code}
                  className="prove-it-list-select"
                />
                <ResultSelect
                  data={options1}
                  defaultValue={select2DefaultName}
                  onChange={changeSelect2Code}
                  className="prove-it-list-select"
                />
              </div>
            </div>
          </div>
          {showContent && (
            <div className="product-footer">
              <div className="product-footer-left">
                {
                  <button
                    aria-label={t('ui.recommendation.learn_more')}
                    className="btn btn-link learn-more"
                    onClick={() => {
                      setIsBoost(false);
                      setShowProveltModal(true);
                    }}
                  >
                    {t('ui.recommendation.learn_more')}
                  </button>
                }
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="recommendations-step-provelt-container"
        ref={proveItStep3Ref}
      >
        <div className="recommendations-step-provelt-info">
          <div className="product-info">
            <div className="product-info-left">
              <img
                className="product-info-left-picture"
                src={'https://images.shaklee.com/wellness/new/RSW_Step2_Boosters.png'}
                alt=""
              />
            </div>
            <div className="product-info-right">
              <div className="product-info-right-step">{t('ui.recommendation.proveit_section_step')} 3</div>
              <h4 className="prove-it-list-title">
                {t('ui.recommendation.proveit_section_5subtitle')}
              </h4>
              <p className="prove-it-list-content">
                {t('ui.recommendation.proveit_section_7subtitle_description')}
              </p>
              <div className="prove-it-list-select-wrap">
                <ResultSelect
                  data={options2}
                  defaultValue={select3DefaultName}
                  onChange={changeSelect3Code}
                  className="prove-it-list-select"
                />
              </div>
            </div>
          </div>
          {showContent && (
            <div className="product-footer">
              <div className="product-footer-left">
                {
                  <button
                    aria-label={t('ui.recommendation.learn_more')}
                    className="btn btn-link learn-more"
                    onClick={() => {
                      setIsBoost(true);
                      setShowProveltModal(true);
                    }}
                  >
                    {t('ui.recommendation.learn_more_all')}
                  </button>
                }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(RecommendationsStepProvelt);
