import React, { useContext } from 'react';
import CtaBtn from 'components/common/CtaBtn';
import { MarketingContext } from 'components/Marketing/Marketing';

const Difference = () => {
  const {
    t,
    isKidsMarketing,
    kidsLandingPage,
  } = useContext(MarketingContext);
  return (
  <div
    className="difference-container"
    tabIndex="0"
    role="img"
    aria-label="The Meology Difference"
  >
    <div className="difference">
      <h2 className="title">{t('ui.marketing.standards_title2')}</h2>
      <div className="difference-text">
        <div className="section">
          <h3 className="header">
            {t('ui.marketing.standards_title3')}
          </h3>
          <div className="context">
            {t('ui.marketing.standards_title4_1')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">
            {t('ui.landing_page_1.warranty.title_3')}
          </h3>
          <div className="context">
            {t('ui.marketing.standards_title8')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">
            {t('ui.marketing.standards_title7')}
          </h3>
          <div className="context">
            {t('ui.marketing.standards_title18')}
          </div>
        </div>
        <div className="section">
          <h3 className="header">
            {t('ui.marketing.standards_title11')}
          </h3>
          <div className="context">
            {t('ui.marketing.standards_title12')}
          </div>
        </div>
      </div>
      {isKidsMarketing && (
        <div className="cta-button-wrap cta-button-wrap-kids cta-button-wrap6">
          <CtaBtn
            type="kids"
            status={kidsLandingPage}
            eventLabel="marketing page cta section"
          />
        </div>
      )}
    </div>
  </div>
)};

export default Difference;
