import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { isChecked } from 'assets/js/helper';
import {
  setNextPage,
  updateAnswer,
} from 'components/questions/QuestionActions';

const RadioWithImage = ({
  question,
  updateAnswerLocally,
  options,
  currentAnswer,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterOptions = useMemo(
    () => options?.filter(({ restrict }) => !restrict),
    [options]
  );

  const handleClickNone = useCallback(
    value => {
      dispatch(setNextPage({}));
      dispatch(updateAnswer(question, value));
    },
    [dispatch, question]
  );

  const handleItemClick = useCallback(
    (event, answer) => {
      const value = answer ? answer : event?.target?.value;

      if ((value === currentAnswer)) {
        if (value === 'NONE') {
          dispatch(setNextPage({}));
        }
        return
      }
      updateAnswerLocally(question, value);
      if (value === 'NONE') {
        handleClickNone('NONE');
      }
    },
    [currentAnswer, updateAnswerLocally, question, dispatch, handleClickNone]
  )

  return (
    <>
      <ul className="radio-image-list">
        {filterOptions?.map(({ answer }, index) => {
          const ifChecked = isChecked(answer, currentAnswer);
          const isNone = answer === 'NONE';
          return (
            <li
              key={index}
              className={cx({ on: ifChecked })}
              role="radio"
              tabIndex="0"
              aria-checked={ifChecked}
              onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleItemClick(e, answer)} // for ADA enter event 
            >
              <label htmlFor={answer}>
                <input
                  id={answer}
                  className="sr-only"
                  tabIndex="-1"
                  type="radio"
                  name={question}
                  value={answer}
                  checked={ifChecked}
                  onChange={() => {}}
                  onClick={handleItemClick}
                  
                />
                {!isNone && (
                  <div className="radio-image-list-img">
                    <img src={`https://images.shaklee.com/meology/prenatal/answer_${answer}.svg`} alt="" />
                  </div>
                )}
                <span
                  role="button"
                  className={cx({ 'button-link': isNone })}>
                  {t(`answers.${question}.${answer}.title`)}
                </span>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default memo(RadioWithImage);
