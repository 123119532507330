import React, { useContext } from 'react';
import cx from 'classnames';
import { useSelector } from "react-redux";
import { MarketingContext } from 'components/Marketing/Marketing';
import CtaBtn from 'components/common/CtaBtn';

const Packaged = () => {
  const { lang } = useSelector(state => state.user);
  const {prenatal_landing_page: prenatalLandingPage} = useSelector(
    state => state.QuestionReducer
  );
  const { t, isKidsMarketing,isPrenatalMarketing } = useContext(MarketingContext);
  return (
    <>
      <div className={cx('packaged-container', {
        'kids': isKidsMarketing,
        'spanish': lang === 'es',
        'prenatal': isPrenatalMarketing
      })}
      >
        <div className="packaged">
          <h2 className="title">
            {t('ui.marketing.packaged_with_a_purpose_title2')}
          </h2>
          <div className="sub_title">
            {t('ui.marketing.packaged_with_a_purpose_title3')}
          </div>
          {isPrenatalMarketing && <CtaBtn
              type="prenatal"
              status={prenatalLandingPage}
              eventLabel="Marketing Page"
              eventAction="Start Here 4"
              page='prenatalMarketing'
            />}
        </div>
      </div>
    </>
  );
};

export default Packaged;
