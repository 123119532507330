import React, { memo, forwardRef } from 'react';
import cx from 'classnames';
import { useEnsuredForwardRef } from 'assets/js/helper';
import { useTranslation } from 'react-i18next';

import ResultSelect from 'components/results/ResultSelect';

const ProveIt = (
  {
    options1,
    options2,
    isSelectedProveIt,
    isPdpFlow,
    select1DefaultName,
    select2DefaultName,
    select3DefaultName,
    changeSelect1Code,
    changeSelect2Code,
    changeSelect3Code,
    changeProveIt,
    setShowProveltModal,
    setIsBoost,
    showContent,
  },
  ref
) => {
  const proveItRef = useEnsuredForwardRef(ref);
  const { t } = useTranslation();
  return (
    <div className="prove-it" ref={proveItRef}>
      <div className="prove-it-title">
        {t('ui.recommendation.proveit_section_price')}
      </div>
      <div className="prove-it-box">
        <div className="prove-it-header">
          <h2
            className="prove-it-header-title"
            dangerouslySetInnerHTML={{
              __html: t('ui.recommendation.proveit_section_title1'),
            }}
          ></h2>
          <p
            className="prove-it-header-content"
            dangerouslySetInnerHTML={{
              __html: t('ui.recommendation.proveit_section_description1'),
            }}
          ></p>
        </div>
        <ul className="prove-it-list">
          <li className="prove-it-list-item1">
            <div className="prove-it-list-pic">
              <img
                src={
                  'https://images.shaklee.com/wellness/RSW_Meology_Step_1.svg'
                }
                alt=""
              />
            </div>
            <div className="prove-it-list-step">
              {t('ui.recommendation.proveit_section_step')} 1
            </div>
            <h3 className="prove-it-list-title">
              {t('ui.recommendation.proveit_section_3subtitle')}
            </h3>
            <p
              className="prove-it-list-content mobile"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_3subtitle_description'
                ),
              }}
            ></p>
          </li>
          <li className="prove-it-list-item2">
            <div className="prove-it-list-pic">
              <img
                src={'https://images.shaklee.com/wellness/RSW_Shakes_Step2.png'}
                alt=""
              />
            </div>
            <div className="prove-it-list-step">
              {t('ui.recommendation.proveit_section_step')} 2
            </div>
            <h3 className="prove-it-list-title">
              {t('ui.recommendation.proveit_section_4subtitle')}
            </h3>
            <p
              className="prove-it-list-content mobile"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_4subtitle_description'
                ),
              }}
            ></p>
            {showContent && (
              <div
                className="prove-it-list-link mobile"
                onClick={() => {
                  setIsBoost(false);
                  setShowProveltModal(true);
                }}
              >
                {t('ui.recommendation.proveit_section_4subtitle_link')}
              </div>
            )}
            <div className="prove-it-list-select-wrap mobile">
              <ResultSelect
                data={options1}
                defaultValue={select1DefaultName}
                onChange={changeSelect1Code}
                className="prove-it-list-select"
              />
              <ResultSelect
                data={options1}
                defaultValue={select2DefaultName}
                onChange={changeSelect2Code}
                className="prove-it-list-select"
              />
            </div>
          </li>
          <li className="prove-it-list-item3">
            <div className="prove-it-list-pic">
              <img
                src={'https://images.shaklee.com/wellness/RSW_Step3.png'}
                alt=""
              />
            </div>
            <div className="prove-it-list-step">
              {t('ui.recommendation.proveit_section_step')} 3
            </div>
            <h3 className="prove-it-list-title">
              {t('ui.recommendation.proveit_section_5subtitle')}
            </h3>
            <p
              className="prove-it-list-content mobile"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_5subtitle_description'
                ),
              }}
            ></p>
            {showContent && (
              <div
                className="prove-it-list-link mobile"
                onClick={() => {
                  setIsBoost(true);
                  setShowProveltModal(true);
                }}
              >
                {t('ui.recommendation.proveit_section_5subtitle_link')}
              </div>
            )}
            <div className="prove-it-list-select-wrap mobile">
              <ResultSelect
                data={options2}
                defaultValue={select3DefaultName}
                onChange={changeSelect3Code}
                className="prove-it-list-select"
              />
            </div>
          </li>
        </ul>
        <ul className="prove-it-list desktop">
          <li className="prove-it-list-item1">
            <p
              className="prove-it-list-content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_3subtitle_description'
                ),
              }}
            ></p>
          </li>
          <li className="prove-it-list-item2">
            <p
              className="prove-it-list-content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_4subtitle_description'
                ),
              }}
            ></p>
            {showContent && (
              <div
                className="prove-it-list-link"
                onClick={() => {
                  setIsBoost(false);
                  setShowProveltModal(true);
                }}
              >
                {t('ui.recommendation.proveit_section_4subtitle_link')}
              </div>
            )}
          </li>
          <li className="prove-it-list-item3">
            <p
              className="prove-it-list-content"
              dangerouslySetInnerHTML={{
                __html: t(
                  'ui.recommendation.proveit_section_5subtitle_description'
                ),
              }}
            ></p>
            {showContent && (
              <div
                className="prove-it-list-link"
                onClick={() => {
                  setIsBoost(true);
                  setShowProveltModal(true);
                }}
              >
                {t('ui.recommendation.proveit_section_5subtitle_link')}
              </div>
            )}
          </li>
        </ul>
        <ul className="prove-it-list desktop">
          <li className="prove-it-list-item1"></li>
          <li className="prove-it-list-item2">
            <div className="prove-it-list-select-wrap">
              <ResultSelect
                data={options1}
                defaultValue={select1DefaultName}
                onChange={changeSelect1Code}
                className="prove-it-list-select"
              />
              <ResultSelect
                data={options1}
                defaultValue={select2DefaultName}
                onChange={changeSelect2Code}
                className="prove-it-list-select"
              />
            </div>
          </li>
          <li className="prove-it-list-item3">
            <div className="prove-it-list-select-wrap">
              <ResultSelect
                data={options2}
                defaultValue={select3DefaultName}
                onChange={changeSelect3Code}
                className="prove-it-list-select"
              />
            </div>
          </li>
        </ul>
        <button
          type="button"
          className={cx('prove-it-toggle-button', {
            isSelectedProveIt: isSelectedProveIt,
            isPdpFlow: isPdpFlow,
          })}
          aria-label="prove it toggle button"
          onClick={() => {
            changeProveIt(!isSelectedProveIt);
          }}
        >
          {isSelectedProveIt
            ? t('ui.recommendation.remove_from_order')
            : t('ui.recommendation.add_to_order')}
        </button>
      </div>
    </div>
  );
};

export default memo(forwardRef(ProveIt));
