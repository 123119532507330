import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from "react-redux";
import { MarketingContext } from 'components/Marketing/Marketing';
import { getUrlVars, isEmpty } from 'assets/js/helper';

const Social = () => {
  const { t, isKidsMarketing, isPrenatalMarketing, isRegularMarketing } = useContext(MarketingContext);
  const arr = [...Array.from({ length: 8 }).keys()];
  const { country, lang } = useSelector(state => state.user);

  const faqUrl = useMemo(() => {
    const { site } = getUrlVars();
    const searchParams = new URLSearchParams();

    if (lang) {
      if (lang === 'zh') {
        searchParams.append('lang', 'zh_CN');
      } else {
        searchParams.append('lang', `${lang}_${country}`);
      }
    }

    if (country) {
      searchParams.append('country', country);
    }

    if (isKidsMarketing) {
      searchParams.append('faq_kids', true);
    }

    if (isPrenatalMarketing) {
      searchParams.append('faq_prenatal', true);
    }

    if (!isEmpty(site)) {
      searchParams.append('site', site);
    }

    return `/FAQ/?${searchParams.toString()}`;
  }, [country, lang, isKidsMarketing, isPrenatalMarketing])

  return (
    <>
      <div className={cx('more-container', {
        kids: isKidsMarketing,
        prenatal: isPrenatalMarketing
      })}>
        <div className="more">
          <h2 className="title">
            {t('ui.marketing.standards_title13')}
            {isKidsMarketing && <img src="https://images.shaklee.com/meology/marketing/question-mark.svg" alt="" />}
          </h2>
          <Link
            className="learn_more"
            to={faqUrl}
            target="_blank"
          >
            {t('ui.marketing.standards_title14')}
          </Link>
        </div>
      </div>


      <div className="social-container">
        <div className="social">
          <h2 className={cx('title', {
           ' prenatal-title':isPrenatalMarketing
          })} data-text={isPrenatalMarketing ? t('ui.prenatal_marketing.section_11.eyebrow'):''}>
            {isKidsMarketing && <>{t('ui.marketing.meology-kids-social')}</>}
            {isRegularMarketing && <>{t('ui.marketing.meology-social')}</>}
            {isPrenatalMarketing && <>{t('ui.prenatal_marketing.section_11.headline')}</>}
          </h2>
          <div className="pic-container">
            {arr.map((item, index) => (
              <div className="pic-container-item" key={index}>
                {isPrenatalMarketing
                ? <img src={t(`marketing.prenatal.image_url.social_${item + 1}`)} alt={`prenatal-social-${item + 1}`} key={index} />
                : <img src={t(isKidsMarketing ? `marketing.kids.image_url.social_${item + 1}`: `marketing.regular.image_url.social_${item + 1}`)} alt={`${isKidsMarketing ? 'kids-' : ''}social-${item + 1}`} key={index} />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;