import React, { useContext } from 'react';
import { Trans } from "react-i18next";
import { Award } from 'components/landing';
import CtaBtn from 'components/common/CtaBtn';
import { MarketingContext } from 'components/Marketing/Marketing';
import LearnMoreBtn from 'components/common/LearnMoreBtn';

const Personal = () => {
  const { t, isChanged, isMarketing, landingPage } = useContext(MarketingContext);

  return (
    <>
      <div className="personal-container">
        <div className="personal"
          tabIndex="0"
          role="img"
          aria-label="Precisely personalized"
        >
          <h2 className="title">
            {t('ui.marketing.how_it_works_title12')}
          </h2>
          <div className="sub_title">
            <Trans>{t('ui.marketing.how_it_works_title13')}</Trans>
          </div>
          <div className="img-container">
            <img src={isChanged ? t('ui.marketing.personal-mobile') : t('ui.marketing.personal')} alt="Personalized" />
          </div>
        </div>
        <div className="real-container"
          tabIndex="0"
          role="img"
          aria-label="Lets get real personal"
        >
          <div className="real">
            <div className="text">
              <img src="https://images.shaklee.com/meology/marketing/celluloid.svg" alt="celluloid" className="logo" />
              <h2 className="title">{t('ui.marketing.blood_dna_title2')}</h2>
              <div className="sub_title">{t('ui.marketing.blood_dna_title3')}</div>
              <div className="context">{t('ui.marketing.blood_dna_title4')}</div>
            </div>
            {isMarketing && (
              <div className="cta-button-wrap cta-button-wrap2">
                <CtaBtn
                  type="regular"
                  status={landingPage}
                  eventLabel="marketing page cta section"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <Award
        className="award-regular"
        text={t('ui.award.text2')}
        awards={['BIBA']}
      />
      <div
        className="life-time-container"
        tabIndex="0"
        role="img"
        aria-label="Landmark Study"
      >
        <div className="life-time">
          <h2 className="banner">
            {t('ui.marketing.landmark_studies_title-1')}
          </h2>
          <div className="text">
            <div className="left">
              <div className="desc" dangerouslySetInnerHTML={{ __html: t('ui.marketing.landmark_studies_title2') }}></div>
              <LearnMoreBtn
                ariaLabel="{t('ui.marketing.landmark_studies_title3')}"
                pathName=''
                text={t('ui.marketing.landmark_studies_title3')}
                onClickEvent={() => {
                  window.open("https://us.shaklee.com/about-us", "_blank");
                }}
              />
            </div>
            <div className="right">
              <div className="card">
                <div className="card-item">
                  <div className="card-item-percentage">32%</div>
                  <div className="card-item-text">{t('ui.marketing.benefit-1')}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-percentage">13%</div>
                  <div className="card-item-text">{t('ui.marketing.benefit-2')}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-percentage">36%</div>
                  <div className="card-item-text">{t('ui.marketing.benefit-3')}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-percentage">32%</div>
                  <div className="card-item-text">{t('ui.marketing.benefit-4')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Personal;
