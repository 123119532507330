import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import RecommendationProveltModalAttributes from './RecommendationProveltModalAttributes';
import { getImageUrl } from 'assets/js/helper';


const RecommendationProveltModalItem = ({ pill, isBoost }) => {
  const { country } = useSelector(state => state.user);
  const imageUrl = useMemo(() => getImageUrl(pill, country), [pill, country]);
  return (
    <div className="provelt-modal-item">
      <div className="provelt-modal-item-name">{pill.name}</div>
      <img className="provelt-modal-item-picture" src={imageUrl} alt="" />
      <div className="provelt-modal-item-why">
        <RecommendationProveltModalAttributes pill={pill} isBoost={isBoost} />
      </div>
      {pill.summary && <div className="provelt-modal-item-desc-title" dangerouslySetInnerHTML={{ __html:`${pill.summary}`}}></div>}
      {isBoost && pill.healthPrintDescription && <div className="provelt-modal-item-desc" dangerouslySetInnerHTML={{ __html: `${pill.healthPrintDescription}` }}></div>}
    </div>
  );
};

export default memo(RecommendationProveltModalItem);
