import React, { memo } from 'react';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import 'swiper/css/swiper.css';

const ScienceNavigation = () => {
  const { t } = useTranslation();
  const params = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  };
  return (
    <section className="swiper-science-container" aria-label="meology landing page science carousel" tabIndex="0">
      <Swiper {...params}>
        <div className="slide-pic">
          {/* <div className="img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/persona-01.png"
              alt="persona-01"
            />
          </div> */}
          <div className="text">
            <div className="star">
              <img
                src="https://images.shaklee.com/meology/images/landing/desktop/rating.png"
                alt="persona-01"
                aria-label="science for rating"
              />
            </div>
            <div className="description">
              {t('ui.marketing.review-1-text')}
            </div>
            <div className="name">{t('ui.marketing.review-1-name')}</div>
          </div>
        </div>
        <div className="slide-pic">
          {/* <div className="img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/persona-02.png"
              alt="persona-01"
            />
          </div> */}
          <div className="text">
            <div className="star">
              <img
                src="https://images.shaklee.com/meology/images/landing/desktop/rating.png"
                alt="persona-01"
                aria-label="science for rating"
              />
            </div>
            <div className="description">
              {t('ui.marketing.review-2-text')}
            </div>
            <div className="name">{t('ui.marketing.review-2-name')}</div>
          </div>
        </div>
        <div className="slide-pic">
          {/* <div className="img">
            <img
              src="https://images.shaklee.com/meology/images/landing/desktop/persona-03.png"
              alt="persona-01"
            />
          </div> */}
          <div className="text">
            <div className="star">
              <img
                src="https://images.shaklee.com/meology/images/landing/desktop/rating.png"
                alt="persona-01"
                aria-label="science for rating"
              />
            </div>
            <div className="description">
              {t('ui.marketing.review-3-text')}
            </div>
            <div className="name">{t('ui.marketing.review-3-name')}</div>
          </div>
        </div>
      </Swiper>
    </section>
  );
};

export default memo(ScienceNavigation);
