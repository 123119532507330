import React, { memo, useCallback, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  truncateString,
  useEnsuredForwardRef,
  getNameFromAnswers,
} from 'assets/js/helper';
import { useHideMeology } from 'assets/js/hooks';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';
import { Toggle } from 'components/results/Kids';
import cx from 'classnames';

import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import RecommendationCard from './RecommendationCard';

const Banner = (
  {
    selectedAnswers,
    currentPills,
    pillsCount,
    basic,
    setBasic,
    currentPillsDailyTablets,
    currentPillsTotalPrice,
    isView,
  },
  topRef
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ensuredTopRef = useEnsuredForwardRef(topRef);
  const name = getNameFromAnswers(selectedAnswers);
  const { isShowToggle } = useHideMeology();
  const reasonData = [];
  let reasonKeyData = [];
  const productInfo = useSelector(
    state => state.recommendationsReducer.data.product_info
  );

  if (productInfo) {
    Object.keys(productInfo).forEach(item => {
      if (productInfo[item] && productInfo[item].reason_categories) {
        productInfo[item].reason_categories.forEach(subItem => {
          if (subItem.category) {
            reasonData.push(subItem.category);
          }
        });
      }
    });
    reasonKeyData = [...new Set(reasonData)];
  }

  const handleBasic = useCallback(
    value => {
      if (value !== basic) {
        setBasic(value);
      }
    },
    [basic, setBasic]
  );

  return (
    <div className="top-wrap" ref={ensuredTopRef}>
      <div className={cx('top-wrap-logo', { 'no-toggle': isView })}>
        {isShowToggle && !isView && <Toggle theme="light" />}
        <img
          className="logo-regular"
          src="https://images.shaklee.com/meology/images/meology-white.svg"
          alt="Logo"
        />
      </div>
      <div className="top-wrap-headtext">
        <div>{t('ui.hp-health')}</div>
      </div>
      <div className="top-wrap-subtext">
        <div>
          <Trans>
            {t('ui.recommendation.monthly_options_1', {
              name: truncateString(name),
            })}
          </Trans>
        </div>
        <div>{t('ui.recommendation.monthly_options_2')}</div>
      </div>
      <div className="tab1-wrap">
        <ul className="tab1" role="tablist">
          <li>
            <button
              role="tab"
              aria-selected={basic}
              type="button"
              onClick={() => {
                dispatch(
                  pushInteractionToDataLayer({
                    eventCategory: 'Meology',
                    eventAction: 'Essential',
                    eventLabel: 'result page tab',
                  })
                );
                handleBasic(true);
              }}
              className={`btn1${basic ? ' on' : ''}`}
            >
              {t('ui.recommendation.tier_1.button_1')}
            </button>
          </li>
          <li>
            <button
              role="tab"
              aria-selected={!basic}
              type="button"
              onClick={() => {
                handleBasic(false);

                // analytics
                dispatch(
                  pushInteractionToDataLayer({
                    eventCategory: 'Meology',
                    eventAction: 'Essential Plus',
                    eventLabel: 'result page tab',
                  })
                );
              }}
              className={`btn1${basic ? '' : ' on'}`}
            >
              {t('ui.recommendation.tier_2.button_1')}
            </button>
          </li>
        </ul>
      </div>
      <RecommendationCard
        currentPills={currentPills}
        pillsCount={pillsCount}
        selectedAnswers={selectedAnswers}
      />
      <div className="font-bold">
        {`${currentPillsDailyTablets} ${
          currentPillsDailyTablets > 1
            ? t('ui.recommendation.daily_tablets')
            : t('ui.recommendation.daily_tablet')
        } | ${t('ui.recommendation.monthly_supply')} | $${(
          currentPillsTotalPrice / 30
        ).toFixed(2)} ${t('ui.recommendation.daily_cost')}`}
      </div>

      <div className="content2">
        {basic ? (
          <Trans>{t('ui.recommendation.tier_1.title_copy')}</Trans>
        ) : (
          <Trans>{t('ui.recommendation.tier_2.title_copy')}</Trans>
        )}
      </div>
      <ul className="tag-list">
        {reasonKeyData.map((item, index) => (
          <li className="tag-item" key={index}>
            <img
              src={t('reason_tag.' + item + '.image_white') + '?v=1'}
              onError={e => {
                e.target.src = 'https://dummyimage.com/55x55/fff/026854.png';
              }}
              alt=""
            />
            <div className="tag-list-text">
              {t('reason_tag.' + item + '.name') &&
                t('reason_tag.' + item + '.name')
                  .split(' ')
                  .map((subItem, subIndex) => (
                    <div key={subIndex}>{subItem}</div>
                  ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default memo(forwardRef(Banner));
