import {
  ADD_MEOLOGY_EVENT,
  RESET_DATALAYER,
  ADD_NAVIGATION_EVENT,
  ADD_INTERACTION_EVENT,
  ADD_PRODUCT_EVENT,
  ADD_TO_CART_EVENT,
  ADD_GA4_EVENT,
} from './analyticsConstants';

const DEFAULT_STATE = {
  dataLayer: {},
};

const analyticsReducer = (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case ADD_MEOLOGY_EVENT:
      return {
        ...state,
        dataLayer: {
          event: 'Meology',
          ...action.data,
        },
      };
    case ADD_NAVIGATION_EVENT:
      return {
        ...state,
        dataLayer: {
          ...action.data,
        },
      };
    case ADD_INTERACTION_EVENT:
      return {
        ...state,
        dataLayer: {
          event: 'interactionEvent',
          ...action.data,
        },
      };
    case ADD_PRODUCT_EVENT:
      return {
        ...state,
        dataLayer: {
          ...action.data,
        },
      };
    case ADD_TO_CART_EVENT:
      return {
        ...state,
        dataLayer: {
          ...action.data,
        },
      };
    case RESET_DATALAYER:
      return {
        ...state,
        dataLayer: null,
      };
    case ADD_GA4_EVENT:
      return {
        ...state,
        dataLayer: action.data ? {
          ...action.data,
        } : null,
      };
    default:
      return state;
  }
};
export default analyticsReducer;
