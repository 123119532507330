import React, { memo, useMemo } from 'react';
import Swiper from 'react-id-swiper';

const swiperParams = {
  loop: true,
  rebuildOnUpdate: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
};

const AnimationImages = ({ swiperImgClassName, sigleImgClassName,  imgUrls = [] }) => {
  const sliderImgs = useMemo(() => {
    if (imgUrls?.length < 2) return [];
    return imgUrls?.map((item, index) => {
      return (
        <div key={index} className={swiperImgClassName}>
          <img
            src={item}
            onError={e => {
              e.target.src =
                'https://images.shaklee.com/meology/coming-soon.jpg';
            }}
            alt="prenatal rsw carousel img"
            aria-label="prenatal rsw carousel img"
          />
        </div>
      );
    });
  }, [imgUrls, swiperImgClassName]);

  return (
    <>
      {imgUrls?.length < 2 ? (
        <img
          src={imgUrls?.[0]}
          className={sigleImgClassName}
          onError={e => {
            e.target.src = 'https://images.shaklee.com/meology/coming-soon.jpg';
          }}
          alt="" 
        />
      ) : (
        <section 
          aria-labelledby="meology prenatal rsw step carousel"
          tabIndex="0"
        >
          <Swiper {...swiperParams}>{sliderImgs}</Swiper>
        </section>
      )}
    </>
  );
};

export default memo(AnimationImages);
