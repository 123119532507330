import {
    UPDATE_COUNTRY,
    UPDATE_LANGUAGE,
    UPDATE_PRICE_TIER,
  } from '../userProfile/userConstants';
import Cookies from "js-cookie";
import { getImpersonation } from 'assets/js/hooks';

const langList = { US: ['en', 'es', 'zh'], CA: ['en', 'fr'] };
// const langList = { US: ['en', 'es', 'zh'] };
function getAccountType(string) {
	if(string === 'BUSINESS_LEADER') {
		return 'BL'
	} else {
		let sentence = string.toLowerCase();
		return sentence.charAt(0).toUpperCase() + sentence.slice(1);
	}
}

function setUserProfie() {
    let userInfo = {};
    let langCountry = (Cookies.get('locale'))?JSON.parse(Cookies.get('locale')): {};
    let hpEntity = (Cookies.get('hpEntity'))?JSON.parse(atob(Cookies.get('hpEntity'))): {};
    const { isSponsor } = getImpersonation();

    userInfo.country = Object.keys(langCountry).length !== 0 ?  langCountry['country'] : 'US';
    userInfo.lang = Object.keys(langCountry).length !== 0 ? langCountry['language'] : "en";
    userInfo.email = hpEntity['email'] ? hpEntity['email'] : "guest@precision.com";
    userInfo.firstName = hpEntity['firstName'] ? hpEntity['firstName'] : "Guest";
    userInfo.lastName = hpEntity['lastName'] ? hpEntity['lastName'] : "Guest";
    userInfo.shakleeId = hpEntity['shakleeId'] ? hpEntity['shakleeId'] : '';
    userInfo.isUserLoggedin = hpEntity['userLogged'] && !isSponsor;
    userInfo.rankId = hpEntity['rankId'] ? hpEntity['rankId'] : '';
    userInfo.accountType = hpEntity['accountType'] ? getAccountType(hpEntity['accountType']) : 'Guest';
    userInfo.contactId = hpEntity['contactId'] ? hpEntity['contactId'] : '';
    userInfo.languages = langList[langCountry['country']] ? langList[langCountry['country']] : langList['US'];
    userInfo.countries = Object.keys(langList);

    let { priceTier } = hpEntity;
    if (priceTier && priceTier !== 'SRP') {
        userInfo.priceTier = priceTier
    } else {
        userInfo.priceTier = 'MP'
    }
    return userInfo
}


const INITIAL_STATE = setUserProfie();

const userReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case UPDATE_COUNTRY:
            const {country} = action.data;
            state = {
                ...state,
                lang: langList[country][0],
                country: country,
                languages: langList[country]
            }
            break;
        case UPDATE_LANGUAGE:
            state = {
                ...state,
                lang: action.data.language,
            }
            break;
        case UPDATE_PRICE_TIER:
            state = {
                ...state,
                priceTier: action.priceTier,
            }
            break;
        default:
            break;
    }
    return state;
}
export default userReducer;
