import React, { memo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useLocation } from "react-router-dom";
import { setLocalStorage, changeModeUrl } from 'assets/js/helper';
import { MarketingContext } from 'components/Marketing/Marketing';
import { useProfileId, useHideMeology } from 'assets/js/hooks';

const Toggle = () => {
  const {
    t,
    isCalledRecomendation,
    setPage,
    isRegularMarketing,
    isKidsMarketing,
    isPrenatalMarketing,
  } = useContext(MarketingContext);
  const { kidsProfileId, regularProfileId, prenatalProfileId } = useProfileId();
  const { hideKids, hidePrenatal } = useHideMeology();
  const location = useLocation();
  const { is_mobile: isMobile } = useSelector(state => state.QuestionReducer);

  const onClick = useCallback(({isCurrentType, currentType, page, profileId }) => () => {
    if(!isCurrentType) {
      changeModeUrl(currentType?.toLowerCase(), location)
      setLocalStorage('meologyType', currentType);
      if(profileId) {
        setPage('reco');
      } else {
        setPage(page);
      }
      // re-get recommendations
      if (isCalledRecomendation) {
        isCalledRecomendation.current = false;
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalledRecomendation, setPage])

  return (
    <ul
    className='meology-toggle' >
      <li
        {...(!isMobile && {tabIndex: '0'})}
        role="button"
        aria-label="toggle regular"
        className={cx({ active: isRegularMarketing })}
        onClick={onClick({
          isCurrentType: isRegularMarketing,
          currentType: 'REGULAR',
          page: 'marketing',
          profileId: regularProfileId,
        })} >
        {t('ui.recommendation.toggle.regular')}
        <span className="meology-toggle-tips right">{t('ui.recommendation.toggle.regular.tooltip')}</span>
      </li>
      {!hidePrenatal && <li
        {...(!isMobile && {tabIndex: '0'})}
        role="button"
        aria-label="toggle prenatal"
        className={cx({ active: isPrenatalMarketing })}
        onClick={onClick({
          isCurrentType: isPrenatalMarketing,
          currentType: 'PRENATAL',
          page: 'prenatalMarketing',
          profileId: prenatalProfileId,
        })} >
        {t('ui.recommendation.toggle.prenatal')}
        <span className="meology-toggle-tips">{t('ui.recommendation.toggle.prenatal.tooltip')}</span>
      </li>}
      {!hideKids && <li
        {...(!isMobile && {tabIndex: '0'})}
        role="button"
        aria-label="toggle kids"
        className={cx({ active: isKidsMarketing })}
        onClick={onClick({
          isCurrentType: isKidsMarketing,
          currentType: 'KIDS',
          page: 'kidsMarketing',
          profileId: kidsProfileId,
        })} >
         {t('ui.recommendation.toggle.kids')}
        <span className="meology-toggle-tips">{t('ui.recommendation.toggle.kids.tooltip')}</span>
      </li>}
    </ul>
  );
};

export default memo(Toggle);
